import { Flex, useDisclosure } from "@chakra-ui/react";
import { IconButtonDelete, Tooltip, IconButtonEdit, ModalButton } from "../../components";
import { useDeleteBulletinMutation } from "../../hooks/queries";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes";

type BulletinRowProps = {
    bulletinId: string;
};

export const BulletinRowActions = ({ bulletinId }: BulletinRowProps) => {
    const { onClose, isOpen, onOpen } = useDisclosure();
    const deleteBulletinMutation = useDeleteBulletinMutation(bulletinId);

    const confirmation = {
        title: "Delete Bulletin",
        description: "Are you sure you want to delete this bulletin?",
    };
    const navigate = useNavigate();
    return (
        <>
            <Flex>
                <Tooltip label="Delete Bulletin" aria-label="Delete Bulletin">
                    <IconButtonDelete
                        onClick={onOpen}
                        aria-label={"Delete Bulletin"}
                        isLoading={deleteBulletinMutation.isLoading}
                    />
                </Tooltip>
                <Tooltip label="Edit Bulletin" aria-label="Edit Bulletin">
                    <IconButtonEdit
                        onClick={() => navigate(generatePath(ROUTES.BULLETIN_EDIT, { bulletinId }))}
                        area-label={"Edit Bulletin"}
                    />
                </Tooltip>
            </Flex>
            <div style={{ display: "none" }}>
                <ModalButton
                    isOpen={isOpen}
                    onClose={onClose}
                    confirmation={confirmation}
                    onAction={() => deleteBulletinMutation.mutate()}
                ></ModalButton>
            </div>
        </>
    );
};
