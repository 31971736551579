import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
    Input,
    Button,
    Select,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    Checkbox,
} from "@chakra-ui/react";
import { FieldError, Label } from "../../../../components";

type IntervalFormProps = {
    prefix: string;
    params: {
        interval_count: number | string;
        buckets?: number | string;
        interval_unit?: string;
        cvr_revenue?: string;
        show_text?: boolean | string;
        absolute?: boolean | string;
    };
    style?: React.CSSProperties;
};

export const IntervalForm = ({ prefix, params, style = {} }: IntervalFormProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            [`${prefix}_interval_unit`]: params.interval_unit,
            ...("interval_count" in params ? { [`${prefix}_interval_count`]: params.interval_count } : {}),
            ...("buckets" in params ? { [`${prefix}_buckets`]: params.buckets } : {}),
            ...("show_text" in params ? { [`${prefix}_show_text`]: params.show_text } : {}),
            ...("absolute" in params ? { [`${prefix}_absolute`]: params.absolute } : {}),
            ...("cvr_revenue" in params ? { [`${prefix}_cvr_revenue`]: params.cvr_revenue } : {}),
        },
    });

    const handleMetricSubmit = (data: any) => {
        const allParams = {
            ...Object.fromEntries(searchParams.entries()),
            ...data,
        };
        setSearchParams(allParams);
    };

    return (
        <Accordion allowToggle style={style}>
            <AccordionItem>
                <AccordionButton>
                    <span>Metric Parameters</span>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <form onSubmit={handleSubmit(handleMetricSubmit)}>
                        {/* Interval Count */}
                        <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                            Interval Count
                        </Label>
                        <Input
                            type="number"
                            {...register(`${prefix}_interval_count`, { required: true })}
                            maxWidth={150}
                        />
                        <FieldError>{errors[`${prefix}_interval_count`]?.message}</FieldError>

                        {/* Interval Unit */}
                        {"interval_unit" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Interval Unit
                                </Label>
                                <Select
                                    {...register(`${prefix}_interval_unit`, { required: true })}
                                    isRequired={true}
                                    maxWidth="fit-content"
                                    display={"inline-flex"}
                                >
                                    <option value="hours">Hours</option>
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                    <option value="years">Years</option>
                                </Select>
                                <FieldError>{errors[`${prefix}_interval_unit`]?.message}</FieldError>
                            </>
                        )}

                        {/* Buckets */}
                        {"buckets" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Buckets
                                </Label>
                                <Input
                                    type="number"
                                    {...register(`${prefix}_buckets`, { required: true })}
                                    maxWidth={150}
                                />
                                <FieldError>{errors[`${prefix}_buckets`]?.message}</FieldError>
                            </>
                        )}

                        {/* Interval Unit */}
                        {"cvr_revenue" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Revenue or CVR
                                </Label>
                                <Select
                                    {...register(`${prefix}_cvr_revenue`, { required: true })}
                                    isRequired={true}
                                    maxWidth="fit-content"
                                    display={"inline-flex"}
                                >
                                    <option value="revenue">Revenue</option>
                                    <option value="cvr">CVR</option>
                                </Select>
                                <FieldError>{errors[`${prefix}_cvr_revenue`]?.message}</FieldError>
                            </>
                        )}

                        {/* Absolute or Relative */}
                        {"absolute" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Absolute
                                </Label>
                                <Checkbox {...register(`${prefix}_absolute`)} />
                                <FieldError>{errors[`${prefix}_absolute`]?.message}</FieldError>
                            </>
                        )}

                        {/* Show Text */}
                        {"show_text" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Show Text
                                </Label>
                                <Checkbox {...register(`${prefix}_show_text`)} />
                                <FieldError>{errors[`${prefix}_show_text`]?.message}</FieldError>
                            </>
                        )}

                        {/* Dispaly X-Axis Labels */}
                        {"show_labels" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Show X-Axis Labels
                                </Label>
                                <Checkbox {...register(`${prefix}_show_labels`)} />
                                <FieldError>{errors[`${prefix}_show_labels`]?.message}</FieldError>
                            </>
                        )}

                        {/* Submit */}
                        <Button colorScheme={"button-primary"} type="submit">
                            Submit
                        </Button>
                    </form>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};
