import { SVC_URLS } from "../../config";
import { useQuery } from "@tanstack/react-query";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useBulletin = (bulletinId: string) => {
    return useQuery(["bulletin", bulletinId], () => getBulletin(bulletinId));
};

const getBulletin = async (bulletinId: string) => {
    const GET_BULLETIN = `${SVC_URLS["ecom"]}/bulletin/${bulletinId}`;
    const options = await fetchOptions();
    const response = await fetch(GET_BULLETIN, options).catch(handleErrors);

    return fetchResponse(response);
};
