import {
    Card,
    Spinner,
    Flex,
    Stack,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Button,
    Text,
    Badge,
} from "@chakra-ui/react";
import { CSSProperties } from "react";
import { useShopMetadata } from "../../hooks";
import { truncate, storeModeBadge } from "../../lib";
import { StoreModes } from "../../types";

type ShopProps = {
    style: CSSProperties;
    shop: {
        customerUrl: string;
        name: string;
        mode: StoreModes;
    };
};

export const Shop = ({ shop, style }: ShopProps) => {
    const { data, isLoading } = useShopMetadata(shop.customerUrl);

    const { label, color } = storeModeBadge(shop?.mode);

    const storeLink = `https://${shop.customerUrl}?forceEdge`;

    return (
        <Card style={style}>
            <div
                style={{
                    height: "200px",
                    backgroundImage: `url("${data?.image})"`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundColor: "#f5f5f5",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                }}
            >
                {label !== "Enabled" && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "var(--chakra-colors-red-100)",
                            color: "var(--chakra-colors-red-800)",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                        }}
                    >
                        {label}
                    </div>
                )}
            </div>
            <Stack style={{ flexGrow: 1 }}>
                <CardHeader>
                    <Heading size="md">
                        <a href={storeLink} target="_blank" rel="noreferrer">
                            {shop.name} - {shop.customerUrl}
                        </a>
                    </Heading>
                    {data && <h2>{data?.title}</h2>}
                </CardHeader>
                {isLoading && (
                    <CardBody>
                        <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
                            <Spinner color="primary-default" />
                        </Flex>
                    </CardBody>
                )}
                {data && (
                    <CardBody style={{ flexGrow: 1 }}>
                        <Text>{truncate(data?.description, 100)}</Text>
                    </CardBody>
                )}
                <CardFooter
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "auto",
                    }}
                >
                    <Badge colorScheme={color}>{label}</Badge>
                    <a href={storeLink} target="_blank" rel="noreferrer">
                        <Button>Visit</Button>
                    </a>
                </CardFooter>
            </Stack>
        </Card>
    );
};
