import { useQuery } from "@tanstack/react-query";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { handleErrors } from "../../lib/http/handleErrors";
import { SVC_URLS } from "../../config/SVC_URLS";
import { useParams } from "react-router-dom";
import { useAuthenticatedUser } from "../useAuthenticatedUser";
import { Experiment } from "../../types";

export const useListExperiments = (storeId: string | undefined) => {
    const params = useParams<{ customerUrl: string }>();
    const customerUrl = params.customerUrl ?? "";

    const { isCloudOpsRole } = useAuthenticatedUser();

    return useQuery<Experiment[]>(
        generateListExperimentsQueryKey(storeId),
        () => fetchExperimentsList(customerUrl, storeId!),
        {
            select: (experiments: Experiment[]) =>
                isCloudOpsRole ? experiments : experiments.filter(internalExperiments),
        },
    );
};

const fetchExperimentsList = async (customerUrl: string, storeId: string) => {
    const GET_EXPERIMENTS_LIST = `${SVC_URLS["experiment"]}/experiment/stores/${storeId}/experiments`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(GET_EXPERIMENTS_LIST, options).catch(handleErrors);

    return fetchResponse(response);
};

/**
 * Generates a query key for the list of experiments.
 */
export const generateListExperimentsQueryKey = (storeId: string | undefined) => {
    return ["experiments", storeId];
};

const internalExperiments = (experiment: Experiment): boolean => {
    const INTERNAL_TAG = "[INTERNAL]";
    const SPEED_EXPERIMENT = "Speed";
    return experiment.name !== SPEED_EXPERIMENT && !experiment.name.includes(INTERNAL_TAG);
};
