import { useScheduleRecurringCacheMutation, generatePendingCacheQueryKey } from "../../hooks";
import {
    Input,
    useToast,
    Button,
    Select,
    Flex,
    FormControl,
    FormHelperText,
    InputGroup,
    InputLeftAddon,
    FormErrorMessage,
} from "@chakra-ui/react";
import { Store, ScheduleRecurringPurgeCacheRequest } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { FieldSet, Label } from "../../components";
import { transformUrlToPath } from "../../lib";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

type Props = {
    customerUrl: string;
    store: Store;
};

export const RecurringScheduleFieldSet = ({ customerUrl, store }: Props) => {
    dayjs.extend(advancedFormat);
    const scheduleRecurringCacheMutation = useScheduleRecurringCacheMutation();
    const showToast = useToast({ isClosable: true, duration: 5000, variant: "subtle" });
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        watch,
        control,
        formState: { errors, isSubmitted },
    } = useForm<ScheduleRecurringPurgeCacheRequest>({
        defaultValues: { start: "", interval: 86400000, next: "", end: "", metadata: "" },
    });
    const { start } = watch();

    const recurringPurgeCacheRequest = (formData: ScheduleRecurringPurgeCacheRequest) => {
        formData.end && formData.end !== ""
            ? (formData.end = new Date(formData.end).toISOString())
            : (formData.end = null);
        const now = new Date();
        let startDate = new Date(formData.start);
        if (startDate < now) {
            const timeDifference = now.getTime() - startDate.getTime();
            const intervalsPassed = Math.floor(timeDifference / formData.interval);
            startDate = new Date(startDate.getTime() + intervalsPassed * formData.interval);
        }
        formData.start = startDate.toISOString();
        formData.next = formData.start;
        formData.metadata && formData.metadata !== ""
            ? (formData.metadata = { pathNames: [formData.metadata] })
            : (formData.metadata = null);

        const onError = () => {
            showToast({
                title: "Error",
                description:
                    "An error occurred while adding your recurring requests to the queue. If the error persists, contact support@nostra.ai",
                status: "error",
            });
        };

        const onSuccess = () => {
            queryClient.invalidateQueries(generatePendingCacheQueryKey(store.id));
            showToast({
                title: "Success",
                description: "Your recurring requests have been placed in the queue.",
                status: "success",
            });
        };

        scheduleRecurringCacheMutation.mutate(
            { customerUrl, formData },
            {
                onSuccess: onSuccess,
                onError: onError,
            },
        );
    };

    useEffect(
        function resetFormFields() {
            if (scheduleRecurringCacheMutation.isSuccess) {
                reset();
            }
        },
        [reset, scheduleRecurringCacheMutation.isSuccess],
    );

    return (
        <FormControl py="spacer-4">
            <FieldSet legend="Recurring Clear Cache">
                <form onSubmit={handleSubmit(recurringPurgeCacheRequest)} className="clear-cache-submission">
                    <Label mb={1} htmlFor="metadata">
                        Path
                    </Label>
                    <FormControl isInvalid={!!errors.metadata}>
                        <Controller
                            control={control}
                            name="metadata"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <InputGroup mt={"spacer-2"}>
                                    <InputLeftAddon
                                        display={{ base: "none", md: "flex" }}
                                    >{`https://${customerUrl}`}</InputLeftAddon>
                                    <Input
                                        type="text"
                                        placeholder={"/products/example-t-shirt"}
                                        onPaste={transformUrlToPath((pathname) => setValue("metadata", pathname))}
                                        {...field}
                                        {...register("metadata", {
                                            required: "Path is required",
                                        })}
                                        style={{
                                            borderColor: errors.metadata && "blue.500"!,
                                            boxShadow: errors.metadata && "none",
                                        }}
                                    />
                                </InputGroup>
                            )}
                        />
                        {isSubmitted && errors.metadata ? (
                            <FormErrorMessage>Path is required</FormErrorMessage>
                        ) : (
                            <FormErrorMessage />
                        )}
                    </FormControl>
                    <Label mb={1} htmlFor="start" pt="spacer-6">
                        Start Date
                    </Label>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        mt={"spacer-2"}
                        {...register("start", { required: "Start date is required" })}
                    />
                    <Label mb={1} htmlFor="end" pt="spacer-6">
                        End Date
                    </Label>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        mt={"spacer-2"}
                        {...register("end")}
                    />
                    <FormHelperText pt="spacer-2">
                        If no end date is selected, your cache clearing will continue indefinitely.
                    </FormHelperText>
                    <Label mb={1} htmlFor="interval" pt="spacer-6">
                        Frequency
                    </Label>
                    <Select
                        my="spacer-3"
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        required
                        onChange={(event) => setValue("interval", parseInt(event.target.value))}
                    >
                        <option value="86400000">
                            {start ? `Daily at ${dayjs(start).format("hh:mm A")}` : "Daily"}
                        </option>
                        <option value="604800000">
                            {start
                                ? `Every ${dayjs(start).format("dddd")} at ${dayjs(start).format("hh:mm A")}`
                                : "Weekly"}
                        </option>
                        <option value="2592">
                            {start ? `Monthly on the ${dayjs(start).format("Do")}` : "Monthly"}
                        </option>
                        <option value="31536">
                            {start ? `Annually on ${dayjs(start).format("MMMM Do")}` : "Annually"}
                        </option>
                    </Select>
                    <Flex justifyContent={"flex-end"} pt={{ base: "spacer-12", md: "spacer-4" }}>
                        <Button variant="outline" colorScheme="button-primary" onClick={() => reset()} mr={3}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            colorScheme="button-primary"
                            isDisabled={!store.edgeEnabled && store.mode !== "testing"}
                        >
                            Submit
                        </Button>
                    </Flex>
                </form>
            </FieldSet>
        </FormControl>
    );
};
