import { PageContainer } from "../../PageContainer";
import { Text, Box } from "@chakra-ui/react";
import { Header, SubHeader, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ManageExperimentsResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"A/B Testing"}
                menuItem1Link={ROUTES.DOCUMENTATION_AB_TESTING}
                menuItem2={"Manage Experiments"}
            />
            <Header pb={"spacer-8"}>Manage Experiments</Header>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="manage-experiments">
                Create Experiment
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The <strong>Experiments</strong> page is where {BRAND_NAME}'s A/B Testing lives. Clicking the "Manage
                Experiments" icon on the <strong>Stores</strong> page will take you to your experiments.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-24/f53f2498-cad3-45fb-a6f6-09ee5b92daa8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2128,768&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=109,291"
                alt="Manage experiments icon"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can edit, delete, or turn your experiment on/off from there. You can also select "Create" to add a
                new experiment.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="manage-experiments">
                Manage Experiment
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The important terms to keep in mind here are "Control" and "Variation." The control option is your
                current live site that you want to test variations against. Any variations you add will contain your
                page's new, initially untested edits.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-12-27/658b11de-1197-4263-830e-5c97cbdc6e10/user_cropped_screenshot.jpeg?tl_px=30,0&br_px=2324,924&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=848,311"
                alt="Edit experiment page"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Clicking "Edit" next to your variation will take you to our state-of-the-art A/B editor. Select the
                element you want to test, add your eye-catching edits, and save. Not only does our server-side editor
                keep track of your updates, but it also provides various device previews so you can be confident your
                changes are responsive.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2023-12-27/1e3726da-cd2e-4500-97d3-19dadf71581d/user_cropped_screenshot.jpeg?tl_px=482,0&br_px=2776,868&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1033,288"
                alt="Edit experiment element"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
