import { useAuthenticatedUser } from "../../hooks";
import { CustomerReports } from "./CustomerReports";
import { InternalReports } from "./InternalReports";

export const ReportsPage = () => {
    const { isCloudOpsRole, isInternalRole } = useAuthenticatedUser();
    if (isCloudOpsRole || isInternalRole) {
        return <InternalReports />;
    }

    return <CustomerReports />;
};
