import { DurationUnitType } from "../../../types";

export const toSecondsMultiplier = (unit: DurationUnitType) => {
    switch (unit) {
        case "week":
            return 7 * 24 * 60 * 60;
        case "day":
            return 24 * 60 * 60;
        case "hour":
            return 60 * 60;
        case "minute":
            return 60;
        case "second":
            return 1;
        default:
            throw new Error("Invalid unit of time. Please choose from 'Weeks', 'Days', 'Hours', 'Minutes'.");
    }
};
