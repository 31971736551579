import { VStack } from "@chakra-ui/react";
import { useExperimentationEditor } from "./hooks/useExperimentationEditor";
import { useEditorContext } from "./EditorProvider";
import { mobileViewport } from "../../styles";

export const EditorPage = () => {
    const { iframeRef } = useExperimentationEditor();
    const { editorContext } = useEditorContext();
    const viewportClass = editorContext.viewport.name !== "Desktop" ? mobileViewport : {};

    return (
        <VStack height="100%" width="100%" justify={"center"}>
            <iframe
                id="iFrame"
                title="Experimentation Creator"
                style={viewportClass}
                ref={iframeRef}
                width={editorContext.viewport.width}
                height={editorContext.viewport.height}
            ></iframe>
        </VStack>
    );
};
