import dayjs from "dayjs";
import { DurationUnitType } from "../../../types";
import { availableUnitsOfTime } from "./availableUnitsOfTime";

export const convertToHighestUnit = (seconds: number): { amount: number; unit: DurationUnitType } => {
    let dur = dayjs.duration(seconds * 1000); // Convert seconds to milliseconds

    for (let unit of availableUnitsOfTime) {
        let amount = dur.as(unit);
        if (amount >= 1) {
            return { amount: Math.floor(amount), unit: unit };
        }
    }

    // If the duration is less than a minute, return in seconds
    return { amount: Math.floor(dur.as("seconds")), unit: DurationUnitType.Second };
};
