/**
 * Valid JSON object or empty string or undefined
 */
export const isValidJSON = (value: string | undefined) => {
    if (value === "") return true;
    if (value === undefined) return true;

    try {
        JSON.parse(value);
        return true;
    } catch (e) {
        return "Please enter a valid JSON object";
    }
};
