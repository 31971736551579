import {
    Input,
    useToast,
    Divider,
    InputGroup,
    InputLeftAddon,
    Button,
    RadioGroup,
    Stack,
    Radio,
    Text,
    HStack,
    Flex,
    FormControl,
    VStack,
    Select,
    useDisclosure,
} from "@chakra-ui/react";
import { ScheduleClearCacheFormData, SchedulePurgeCacheRequest, Store } from "../../types";
import {
    generatePendingCacheQueryKey,
    useEnqueuePurgeCacheMutation,
    useListFavoriteCachePaths,
    useDeleteFavoriteCachePathMutation,
    useAddFavoriteCachePathMutation,
} from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { AlertMessage, FieldError, FieldSet, ModalButton } from "../../components";
import { formatDateTimeDisplay, transformUrlToPath } from "../../lib";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { useEffect } from "react";
import { IconButtonDelete, IconButtonFavorite } from "../../components";

type Props = {
    customerUrl: string;
    store: Store;
};

export const CacheScheduleFieldSet = ({ customerUrl, store }: Props) => {
    const enqueuePurgeCacheMutation = useEnqueuePurgeCacheMutation();
    const { data: favoritePaths } = useListFavoriteCachePaths(customerUrl);
    const deleteFavoriteCachePathMutation = useDeleteFavoriteCachePathMutation();
    const addFavoriteCachePathMutation = useAddFavoriteCachePathMutation();
    const showToast = useToast({ isClosable: true, duration: 5000, variant: "subtle" });
    const queryClient = useQueryClient();
    const { onClose, isOpen, onOpen } = useDisclosure();
    const { onClose: onAdditionClose, isOpen: isAdditionOpen, onOpen: onAdditionOpen } = useDisclosure();

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        control,
        setFocus,
        watch,
        resetField,
        formState: { errors },
    } = useForm<ScheduleClearCacheFormData>({
        defaultValues: {
            path: "",
            selectedDate: "",
            scheduleValue: "now",
            scheduleTypeOption: "singlePath",
            singleFavorite: "",
        },
    });

    const { scheduleValue, scheduleTypeOption, singleFavorite, path } = watch();
    useEffect(
        function setFavoriteAfterLoad() {
            if (favoritePaths?.favoriteCachePaths && favoritePaths.favoriteCachePaths.length > 0) {
                setValue("singleFavorite", favoritePaths.favoriteCachePaths[0]);
            }
        },
        [favoritePaths?.favoriteCachePaths, setValue],
    );

    useEffect(
        function focusOnDateInput() {
            if (scheduleValue === "schedule") {
                setFocus("selectedDate");
            }
        },
        [setFocus, scheduleValue],
    );

    useEffect(
        function focusOrResetOnPathInput() {
            if (scheduleTypeOption === "singlePath") {
                resetField("path");

                setFocus("path");
            } else if (scheduleTypeOption === "favoritePath") {
                resetField("path");
                setFocus("singleFavorite");
            } else {
                resetField("path");
            }
        },
        [setFocus, scheduleTypeOption, resetField],
    );

    useEffect(
        function resetFormFields() {
            if (enqueuePurgeCacheMutation.isSuccess) {
                reset();
                setValue(
                    "singleFavorite",
                    favoritePaths?.favoriteCachePaths ? favoritePaths?.favoriteCachePaths[0] : "",
                );
            }
        },
        [enqueuePurgeCacheMutation.isSuccess, reset, setValue, favoritePaths?.favoriteCachePaths],
    );

    const enqueuePurgeCacheSubmit = (formData: ScheduleClearCacheFormData) => {
        const { path, selectedDate, singleFavorite } = formData;
        let enqueueRequest: SchedulePurgeCacheRequest = {
            customerUrl: customerUrl,
        };

        if (selectedDate) {
            enqueueRequest = {
                ...enqueueRequest,
                date: new Date(selectedDate).toISOString(),
            };
        }

        if (scheduleTypeOption === "singlePath") {
            enqueueRequest = {
                ...enqueueRequest,
                pathNames: [path.toLowerCase()],
            };
        } else if (scheduleTypeOption === "favoritePath") {
            enqueueRequest = {
                ...enqueueRequest,
                pathNames: [singleFavorite!],
            };
        }

        const onEnqueueError = () => {
            showToast({
                title: "Error",
                description:
                    "An error occurred while adding your request to the queue. If the error persists, contact support@nostra.ai",
                status: "error",
            });
        };

        const onEnqueueSuccess = () => {
            queryClient.invalidateQueries(generatePendingCacheQueryKey(store.id));
            showToast({
                title: "Success",
                description: "Your request has been placed in the queue.",
                status: "success",
            });
        };

        enqueuePurgeCacheMutation.mutate(enqueueRequest, {
            onSuccess: onEnqueueSuccess,
            onError: onEnqueueError,
        });

        return void 0;
    };

    const onDeleteFavoritePath = (customerUrl: string, path: string) => {
        const onDeletionSuccess = () => {
            queryClient.invalidateQueries(["favoriteCachePaths", customerUrl]);
            showToast({
                title: "Success",
                description: "The path was removed from your favorites list.",
                status: "success",
            });
        };
        const onDeletionError = () => {
            showToast({
                title: "Error",
                description:
                    "An error occurred while removing this path from your favorites. If the error persists, please contact support.",
                status: "error",
            });
        };

        deleteFavoriteCachePathMutation.mutate(
            { customerUrl, path },
            {
                onSuccess: onDeletionSuccess,
                onError: onDeletionError,
            },
        );
    };

    const onAddFavoritePath = (path: string, customerUrl: string) => {
        const newPath = path.toLowerCase();
        const onAddFavoriteSuccess = () => {
            queryClient.invalidateQueries(["favoriteCachePaths", customerUrl]);
            showToast({
                title: "Success",
                description: "The path was added to your favorites list.",
                status: "success",
            });
        };
        const onDeleteFavoriteSuccess = () => {
            showToast({
                title: "Error",
                description:
                    "An error occurred while adding this path to your favorites. If the error persists, please contact support.",
                status: "error",
            });
        };

        addFavoriteCachePathMutation.mutate(
            { customerUrl, path: newPath },
            {
                onSuccess: onAddFavoriteSuccess,
                onError: onDeleteFavoriteSuccess,
            },
        );
    };

    return (
        <FormControl>
            {!store.edgeEnabled ? (
                <AlertMessage status="warning">
                    Nostra is off. Scheduled items require Nostra on to process.
                </AlertMessage>
            ) : null}
            <HStack pt="spacer-4" justifyContent={"flex-end"}>
                <Text
                    color={"text-secondary"}
                    textStyle={"text-link-meta-regular"}
                    textDecoration={"none"}
                    display={{ base: "none", lg: "block" }}
                >
                    Last Updated: {formatDateTimeDisplay(store?.updatedAt ?? "")}
                </Text>
            </HStack>
            <form onSubmit={handleSubmit(enqueuePurgeCacheSubmit)} className="clear-cache-submission">
                <FieldSet legend="Clear Cache">
                    <Stack>
                        <Controller
                            name="scheduleTypeOption"
                            control={control}
                            defaultValue="singlePath"
                            render={({ field }) => (
                                <RadioGroup width={"100%"} {...field}>
                                    <Stack direction={{ base: "column", md: "row" }}>
                                        <Radio value="singlePath" minWidth={"fit-content"}>
                                            Single Page
                                        </Radio>
                                        <InputGroup>
                                            <InputLeftAddon
                                                display={{ base: "none", md: "flex" }}
                                                color={field.value === "entireSite" ? "gray" : "black"}
                                            >{`https://${customerUrl}`}</InputLeftAddon>
                                            <Input
                                                type="text"
                                                placeholder={
                                                    field.value === "entireSite" ? "" : "/products/example-t-shirt"
                                                }
                                                isDisabled={field.value !== "singlePath"}
                                                onPaste={transformUrlToPath((pathname) => setValue("path", pathname))}
                                                {...register("path", {
                                                    required: {
                                                        value: field.value === "singlePath",
                                                        message: "Path is required",
                                                    },
                                                })}
                                            />
                                            <IconButtonFavorite onClick={onAdditionOpen} isDisabled={path === ""} />
                                            <div style={{ display: "none" }}>
                                                <ModalButton
                                                    isOpen={isAdditionOpen}
                                                    onClose={onAdditionClose}
                                                    confirmation={{
                                                        title: "Add Favorite Path",
                                                        description: (
                                                            <Text>
                                                                Are you sure you want to add <strong>{path}</strong> to
                                                                your store's favorites list?
                                                            </Text>
                                                        ),
                                                    }}
                                                    onAction={() => onAddFavoritePath(path, customerUrl)}
                                                    buttonStyle="button-primary"
                                                    innerButtonText="Add"
                                                ></ModalButton>
                                            </div>
                                        </InputGroup>
                                    </Stack>
                                    <VStack align={"flex-start"}>
                                        <Radio pb="spacer-2" pt="spacer-4" value="entireSite">
                                            Entire Site
                                        </Radio>
                                    </VStack>
                                    <Stack direction={{ base: "column", md: "row" }} width={"100%"} pb="spacer-2">
                                        <Radio
                                            pb="spacer-2"
                                            pt="spacer-4"
                                            minWidth={"fit-content"}
                                            value="favoritePath"
                                        >
                                            Favorites&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Radio>
                                        <InputGroup>
                                            <HStack width={"100%"}>
                                                {favoritePaths?.favoriteCachePaths &&
                                                favoritePaths?.favoriteCachePaths?.length > 0 ? (
                                                    <>
                                                        <Select
                                                            defaultValue={favoritePaths.favoriteCachePaths[0]}
                                                            width={{ base: "100%", md: "25%" }}
                                                            minWidth={{ md: "15rem" }}
                                                            {...register("singleFavorite")}
                                                            isDisabled={scheduleTypeOption !== "favoritePath"}
                                                        >
                                                            {favoritePaths.favoriteCachePaths.length > 0 &&
                                                                favoritePaths?.favoriteCachePaths.map(
                                                                    (path: string) => (
                                                                        <option value={path} key={path}>
                                                                            {path}
                                                                        </option>
                                                                    ),
                                                                )}
                                                        </Select>
                                                        <IconButtonDelete
                                                            onClick={onOpen}
                                                            isDisabled={field.value !== "favoritePath"}
                                                        />
                                                        <div style={{ display: "none" }}>
                                                            <ModalButton
                                                                isOpen={isOpen}
                                                                onClose={onClose}
                                                                confirmation={{
                                                                    title: "Delete Favorite Path",
                                                                    description: (
                                                                        <Text>
                                                                            Are you sure you want to delete{" "}
                                                                            <strong>{singleFavorite}</strong> from your
                                                                            store's favorites list?
                                                                        </Text>
                                                                    ),
                                                                }}
                                                                onAction={() =>
                                                                    onDeleteFavoritePath(customerUrl, singleFavorite!)
                                                                }
                                                            ></ModalButton>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Input value="No favorites found" isDisabled></Input>
                                                )}
                                            </HStack>
                                        </InputGroup>
                                    </Stack>
                                </RadioGroup>
                            )}
                        ></Controller>
                        <Divider />
                        <Controller
                            name="scheduleValue"
                            control={control}
                            defaultValue="now"
                            render={({ field }) => (
                                <RadioGroup pt="spacer-2" {...field}>
                                    <Stack direction={{ base: "column", md: "row" }}>
                                        <Radio value="now">Now</Radio>
                                        <Radio value="schedule">Schedule</Radio>
                                        <Input
                                            width={{ base: "100%", md: "25%" }}
                                            minWidth={{ md: "15rem" }}
                                            type="datetime-local"
                                            isDisabled={field.value !== "schedule"}
                                            {...register("selectedDate", {
                                                required: {
                                                    value: field.value === "schedule",
                                                    message: "Date is required",
                                                },
                                            })}
                                        />
                                    </Stack>
                                </RadioGroup>
                            )}
                        ></Controller>
                        <FieldError>{firstError(errors)}</FieldError>
                        <Flex alignSelf={"flex-end"} pt={"spacer-4"}>
                            <Button variant="outline" colorScheme="button-primary" onClick={() => reset()} mr={3}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                colorScheme="button-primary"
                                isDisabled={!store.edgeEnabled && store.mode !== "testing"}
                            >
                                Submit
                            </Button>
                        </Flex>
                    </Stack>
                </FieldSet>
            </form>
        </FormControl>
    );
};

const firstError = (errors: FieldErrors<ScheduleClearCacheFormData>) => {
    const key = Object.keys(errors).find(
        (key) => errors[key as keyof FieldErrors<ScheduleClearCacheFormData>]?.message,
    );
    return errors[key as keyof FieldErrors<ScheduleClearCacheFormData>]?.message;
};
