import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { useAuthenticatedUser } from "../useAuthenticatedUser";

const ONE_MINUTE = 60 * 1000;
export const useEntriAuth = (customerUrl: string) => {
    const { userId } = useAuthenticatedUser();

    return useQuery(generateEntriAuthQueryKey(userId), getEntriAuthToken(customerUrl), {
        cacheTime: ONE_MINUTE,
        refetchOnWindowFocus: false,
    });
};

export const generateEntriAuthQueryKey = (userId: string) => ["entriAuth", userId];

const getEntriAuthToken = (customerUrl: string) => async (): Promise<EntriAuthResponse> => {
    const options = await fetchOptions({
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(`${SVC_URLS.portal}/entri/token`, options).catch(handleErrors);
    return await fetchResponse(response);
};

type EntriAuthResponse = {
    token: string;
};
