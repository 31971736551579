import { PageContainer } from "../../PageContainer";
import {
    Text,
    Box,
    UnorderedList,
    ListItem,
    Alert,
    AlertIcon,
    Code,
    TableContainer,
    Table,
    Thead,
    Th,
    Tr,
    Tbody,
    Td,
} from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const CruxReportsDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Reports"}
                menuItem1Link={ROUTES.DOCUMENTATION_REPORTS}
                menuItem2={"CrUX Reports"}
            />
            <Header pb={"spacer-8"}>CrUX Reports</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Real user data, or field data, supplies key information for evaluating website performance and user
                interactions, such as when users first see content loading, how they visit your site, and the time it
                takes for a page to become responsive.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The Chrome User Experience Report (CrUX) is a public dataset that feeds Google's most popular
                <Link
                    textDecoration="underline"
                    to={"https://developer.chrome.com/docs/crux/methodology/tools"}
                    target="new"
                >
                    performance tools
                </Link>{" "}
                and is the official source of Google's Web Vitals. Google uses the metrics provided by CrUX to measure
                and evaluate thousands of websites, making it a valuable resource for website owners, web developers,
                and digital marketers.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} has incorporated the following CrUX reports into your customer portal so you can easily
                track your store's progress and trends, using the CrUX History API to keep the graphs updated. As one of
                several CrUX tools, the CrUX History API provides access to approximately six months of websites'
                real-user data. The dataset is{" "}
                <Link textDecoration="underline" to={"https://developer.chrome.com/docs/crux/history-api"} target="new">
                    updated
                </Link>{" "}
                weekly on Monday around 04:00 UTC with data up until the previous Saturday, creating a "standard 2-day
                lag."
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    The data you see in your CrUX reports{" "}
                    <strong>will not reflect your most recent updates until the newest week's data release.</strong>{" "}
                    Until then, you will see rolling averages based on your store's previous data.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Key Metrics
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The following metrics, which include the Core Web Vitals, are available in the <strong>Reports</strong>{" "}
                section of your customer portal to provide your team with valuable insights into various aspects of user
                experience and site responsiveness.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Time-To-First-Byte (TTFB)</strong> measures web server responsiveness by tracking the time a
                    server takes to respond to a client's request and deliver the first byte to the user. This timing
                    includes redirects, the DNS lookup, and more.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>First Contentful Paint (FCP) </strong> measures when the first DOM element becomes visible
                    to a user and occurs before your LCP. That content could be any text, image, icon, etc. FCP is
                    important to consider as it marks the first moment visitors can visually perceive the page response.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/1d1336ef-0646-4606-b608-138942b65f8a/screenshot.jpeg?tl_px=28,0&br_px=1011,308&force_format=png&width=983"
                    alt="First Contentful Paint graphs"
                    loading="eager"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/largest-contentful-paint-lcp"
                            target="new"
                        >
                            Largest Contentful Paint (LCP)
                        </Link>
                    </strong>{" "}
                    indicates when the largest element on a given page (usually a photo or video in the eCommerce world)
                    becomes visible to your customers. LCP measures the time it takes the element to render and begins
                    when a user requests the page via its URL.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Cumulative Layout Shift (CLS)</strong> refers to how much a page's layout or content moves
                    during a customer's visit. A score of 0 means no shifting is present, so the closer your score is to
                    0, the better your user experience will be.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/a43ab0bc-27ac-4fd2-bc5c-118fc3ba12b7/screenshot.jpeg?tl_px=27,0&br_px=1010,306&force_format=png&width=983"
                    alt="Cumulative Layout Shift graphs"
                    loading="eager"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Interaction To Next Paint (INP)</strong> determines a page's level of responsiveness to
                    human interactions throughout its lifespan based on the latency (or delay) in milliseconds for the
                    browser to react to actions like a click or tap.{" "}
                    <Link
                        textDecoration="underline"
                        to="https://support.google.com/webmasters/answer/9205520?hl=en"
                        target="new"
                    >
                        Google states
                    </Link>{" "}
                    the "final INP value is the longest interaction observed, ignoring outliers." A low INP ensures that
                    the page will be reliably responsive at all times.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Form Factors</strong> display the percentage of users who visit your site using a desktop,
                    mobile device, or tablet. In the eCommerce world, it is common to see most mobile users, followed by
                    desktop and tablet users.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/cde30e92-8587-459f-8eb0-eeb5b8a21e9d/screenshot.jpeg?tl_px=0,0&br_px=555,325&force_format=png&width=625"
                    alt="Form factors graph"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://developer.chrome.com/blog/crux-navigation-types"
                            target="new"
                        >
                            Navigation Types
                        </Link>
                    </strong>{" "}
                    &nbsp;provide insight into how users navigate around your site. They were added to the dataset in
                    March 2024 and track navigation types, including <Code>reload</Code>, <Code>back_forward</Code>,{" "}
                    <Code>prerender</Code>, and <Code>navigate_cache</Code>. A high number of page reloads could
                    indicate frustrated users, for example, while content that was prerendered could result in positive
                    page load times.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                        It is important to note that <Code>navigate_cache</Code> refers to the browser's HTTP cache, not
                        {BRAND_NAME}'s advanced {MAIN_PRODUCT} caching.{" "}
                    </Text>
                </Alert>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/49889ed1-245c-4360-acf7-d68824f07d01/screenshot.jpeg?tl_px=0,0&br_px=562,317&force_format=png&width=688"
                    alt="Navigation types graph"
                />
            </UnorderedList>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Interpreting The Reports
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most of the metric reports include not one but two graphs, the leftmost one charting the P75, or 75th
                percentile, value of the metric and the rightmost one tracking the percentage of your users who scored
                "good," "needs improvement," or "poor," according to CrUX's score definitions.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The 75th percentile is an{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS_PERCENTILES} target="new">
                    industry standard
                </Link>{" "}
                used to deliver a majority user experience while minimizing outliers to prevent significant skews in the
                data.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    If you see a vertical black line in either report, this indicates the general date your store was
                    onboarded with {BRAND_NAME}.
                </strong>{" "}
                The CrUX History API only tracks about 6 months, or 25 weeks, of data, so you will only see the line if
                you onboarded recently.{" "}
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    The dataset reflects rolling averages, so your most recent changes will not instantly appear.
                    Additionally,{" "}
                    <strong>
                        the graphs do not currently indicate when {BRAND_NAME} was enabled versus disabled across your
                        site.
                    </strong>
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/a5f78454-b2df-4e9e-bc84-9e4dedb552d3/screenshot.jpeg?tl_px=22,0&br_px=1005,308&force_format=png&width=983"
                alt="Graph showing onboarding indicator"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each predefined score correlates to a color and a label, as shown per metric in the table below. The
                only exceptions are the form factors and navigation types, as those two metrics do not have a value
                associated with a score.{" "}
            </Text>
            <TableContainer pb={"spacer-6"}>
                <Table variant="striped" colorScheme="gray" size={"md"} whiteSpace={"pre-wrap"}>
                    <Thead>
                        <Tr>
                            <Th>Metric</Th>
                            <Th>Good (Green)</Th>
                            <Th>Needs Improvement (Yellow)</Th>
                            <Th>Poor (Red)</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Time-To-First-Byte</Td>
                            <Td>Less than 800 ms</Td>
                            <Td>Between 800 and 1800 ms</Td>
                            <Td>More than 1800 ms</Td>
                        </Tr>
                        <Tr>
                            <Td>First Contentful Paint</Td>
                            <Td>Less than 1800 ms</Td>
                            <Td>Between 1800 and 3000 ms</Td>
                            <Td>More than 3000 ms </Td>
                        </Tr>
                        <Tr>
                            <Td>Largest Contentful Paint</Td>
                            <Td>Less than 2500 ms</Td>
                            <Td>Between 2500 and 4000 ms</Td>
                            <Td>More than 4000 ms </Td>
                        </Tr>
                        <Tr>
                            <Td>Cumulative Layout Shift </Td>
                            <Td>Less than 0.1</Td>
                            <Td>Between 0.1 and 0.25</Td>
                            <Td>Greater than 0.25</Td>
                        </Tr>
                        <Tr>
                            <Td>Interaction To Next Paint</Td>
                            <Td>Less than 200 ms</Td>
                            <Td>Between 200 ms and 500 ms</Td>
                            <Td>Greater than 500 ms</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    The horizontal trend line in each metric value-based chart reflects the majority user score at the
                    75th percentile.
                </strong>{" "}
                As an example, a TTFB percentile value of 281 milliseconds would mean that 75% of users measured had a
                TTFB of less than 281 milliseconds at the recorded date, while the remaining 25% of users experienced a
                TTFB of greater than 281 milliseconds.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-07/55707c5a-b18f-4de7-9288-10edbace271d/screenshot.jpeg?tl_px=0,0&br_px=526,298&force_format=png&width=688"
                alt="Example TTFB graph with a p75 value of 281"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    As with any metric related to {BRAND_NAME}, remember that our products are optimization tools. If
                    you see your data shift noticeably, consider what may have been added to, removed from, or otherwise
                    changed on your site that could have affected your user experience unrelated to your {MAIN_PRODUCT}{" "}
                    benefits, such as a new script, updated theme, refreshed hero image, or plugin adjustment.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                CrUX data provides many benefits when it comes to assessing real user behavior and experiences; however,
                the dataset does have some limitations. As you review your CrUX reports, keep the following data
                restrictions in mind:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Users are limited.</strong> To be included in the dataset, users must meet certain criteria,
                    such as using a desktop or Android version of Chrome. Chrome on iOS, for example, is not supported.
                    Sites must also be indexed and be "sufficiently popular," per the{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://developer.chrome.com/docs/crux/methodology"}
                        target="new"
                    >
                        documentation
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Not all websites are tracked through CrUX. </strong>To be included, websites must receive
                    enough traffic for statistically significant data.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>CrUX can only monitor public data,</strong> so a logged-in experience, for example, would
                    not be reflected in CrUX.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s Shopify-based customers have seen overall performance improvements of 20-30%, while
                Salesforce Commerce Cloud-based customers have seen overall performance improvements of over 40%.
                Remember, it will take some time to see the impact of any changes made to your site because of the CrUX
                History API's update timeline.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
