import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { UpdatedAppInfo, SingleShopifyApp } from "../../types";

type Props = {
    requestBody: UpdatedAppInfo;
    appId: string;
};

export const useEditAppNoteMutation = () => {
    return useMutation(({ requestBody, appId }: Props) => editAppNote({ requestBody, appId }));
};

const editAppNote = async ({ requestBody, appId }: Props): Promise<SingleShopifyApp> => {
    const editNote = `${SVC_URLS["ecom"]}/app/${appId}`;
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(requestBody),
    });
    const response = await fetch(editNote, options).catch(handleErrors);
    return fetchResponse(response);
};
