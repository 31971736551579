import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse, queryParameters } from "../../lib";
import { GlobalHistoryFilters } from "../../pages/EditStorePage/ListStoreGlobalHistoryTab";
import { GlobalHistoryLogResponse } from "../../types";

export const useListStoreGlobalHistory = (customerUrl: string, filters: GlobalHistoryFilters, page: string) => {
    return useQuery(generateListLogHistoryQueryKey(customerUrl, filters), listLogHistory(customerUrl, filters, page));
};

const listLogHistory =
    (customerUrl: string, filters: GlobalHistoryFilters, page: string) =>
    async (): Promise<GlobalHistoryLogResponse> => {
        const logHistory = `${SVC_URLS["portal"]}/global-history?${queryParameters(filters)}&page=${page}`;
        const options = await fetchOptions({
            headers: { Store: customerUrl },
        });
        const response = await fetch(logHistory, options).catch(handleErrors);
        return fetchResponse(response);
    };

export const generateListLogHistoryQueryKey = (customerUrl: string, filters: GlobalHistoryFilters) => [
    "globalHistoryLog",
    customerUrl,
    queryParameters(filters),
];
