import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { Link } from "../components/_ui/Link";
import { ROUTES } from "../Routes";

export const SignOutLink = () => {
    const { signOut } = useAuthenticator((context) => [context.signOut]);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const handleSignOut = () => {
        signOut();
        queryClient.clear();
        navigate("/login");
    };

    return (
        <Link width="100%" onClick={handleSignOut} to={ROUTES.HOME}>
            Sign Out
        </Link>
    );
};
