import { IconButton, forwardRef } from "@chakra-ui/react";
import { IconStar } from "@tabler/icons-react";

export const IconButtonFavorite = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Add to favorites"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconStar stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
