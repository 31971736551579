import { useAuthenticator } from "@aws-amplify/ui-react";

export const useAuthenticatedUser = () => {
    const { route, user } = useAuthenticator();
    const role = getRole(user);
    const orgId = getOrgId(user);
    const userId = getUserId(user);
    const roles = {
        isAuthenticated: route === "authenticated" && user,
        isCloudOpsRole: role === "CloudOps",
        isInternalRole: role === "Internal",
        isAdminRole: role === "Admin",
        isOwnerRole: role === "Owner",
    };

    return {
        role: role,
        orgId: orgId,
        userId: userId,
        ...roles,
    };
};

const getOrgId = (user: any): string => {
    if (user !== undefined && user.attributes !== undefined) {
        return user.attributes["custom:nostra-org-id"];
    }
    return "";
};

const getRole = (user: any) => {
    if (user !== undefined && user.attributes !== undefined) {
        return user.attributes["custom:nostra-user-role"];
    }
    return "";
};

const getUserId = (user: any) => {
    if (user !== undefined && user.attributes !== undefined) {
        return user.attributes["custom:nostra-user-id"];
    }
    return "";
};
