import { Avatar, Box, Flex, Text, Badge, HStack, Divider, VStack, Button, useDisclosure } from "@chakra-ui/react";
import { userTypeBadge, userStatusBadge } from "../../lib";
import { ToggleUserStatus, ResetPasswordLink, DeleteUserLink } from "../Users";
import { User } from "../../types";
import { SubHeader, CopyButton } from "../../components";
import { EditUserModal } from "../Users";
import { useState, useEffect } from "react";
import { CreateUserModal } from "../Users/CreateUserModal";
import { useParams } from "react-router-dom";
import { useOrg, useListUsers } from "../../hooks";
import { LoadingPage } from "../LoadingPage";

export const ListUsersTab = () => {
    const { orgId } = useParams();
    const { isLoading: organizationLoading, data: organization } = useOrg(orgId);
    const { isLoading: usersLoading, data: users } = useListUsers(orgId);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [display, setDisplay] = useState(window.innerWidth < 414 ? "none" : "block");

    useEffect(() => {
        const handleResize = () => {
            setDisplay(window.innerWidth < 414 ? "none" : "block");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (organizationLoading || usersLoading) return <LoadingPage />;

    return (
        <VStack width="100%" alignItems={"baseline"} pt="spacer-2">
            {users?.length === 0 || users === undefined ? (
                <>
                    <Flex alignSelf={{ md: "flex-end" }}>
                        <Button ml={"auto"} colorScheme={"button-primary"} onClick={onOpen}>
                            Create
                        </Button>
                    </Flex>
                    <SubHeader alignSelf="center">No users found</SubHeader>
                </>
            ) : (
                <Flex alignSelf={{ md: "flex-end" }}>
                    <Button ml={"auto"} colorScheme={"button-primary"} onClick={onOpen}>
                        Create
                    </Button>
                </Flex>
            )}

            {users?.map((user: User) => {
                const typeBadge = userTypeBadge(user.role);
                const statusBadge = userStatusBadge(user.active);

                return (
                    <Flex
                        key={user.id}
                        padding={"spacer-2"}
                        border={"1px"}
                        borderColor={"gray.200"}
                        borderRadius={"0.75rem"}
                        width={"100%"}
                    >
                        <Avatar name={user.name} />
                        <Box ml="3">
                            <Text fontWeight="bold">
                                {user.name}
                                <Badge ml="spacer-1" colorScheme={typeBadge.color}>
                                    {user.email === organization?.onboardingEmail
                                        ? "Primary Owner (Onboarding)"
                                        : user.role}
                                </Badge>
                                <Badge ml="spacer-1" colorScheme={statusBadge.color}>
                                    {statusBadge.label}
                                </Badge>
                            </Text>
                            <Text fontSize="sm" maxWidth={"230px"} textOverflow={"ellipsis"} overflow="hidden">
                                <CopyButton value={user.email} />
                                {user.email}
                            </Text>
                            <HStack height={{ base: "30%", lg: "15px" }} width="100%" mt="spacer-2">
                                <ResetPasswordLink userId={user.id} />
                                <Divider orientation={"vertical"} />
                                <ToggleUserStatus userId={user.id} active={user.active} />
                                <Divider orientation={"vertical"} />
                                <EditUserModal user={user} />
                                <Divider orientation={"vertical"} style={{ display }} />
                                <div style={{ display }}>
                                    <DeleteUserLink orgId={user.orgId} userId={user.id} />
                                </div>
                            </HStack>
                        </Box>
                    </Flex>
                );
            })}
            <CreateUserModal isOpen={isOpen} onClose={onClose} orgId={organization?.id} />
        </VStack>
    );
};
