import { Box } from "@chakra-ui/react";

export const SubHeader = (props: any) => {
    props = {
        pb: "spacer-10",
        as: props.as ? props.as : "h3", // default to h2
        textStyle: {
            base: "text-body-regular",
            md: "text-body-large-regular",
            lg: "text-body-large-regular",
            xl: "text-body-large-regular",
            "2xl": "text-body-large-regular",
            xxl: "text-body-large-regular",
        },
        ...props,
    };

    return <Box {...props} />;
};
