import { createContext, Dispatch, useContext, useReducer } from "react";
import { CurrentVariation, EditorContext, EditorContextAction } from "../../types";
import { viewports, editorContextReducer } from "./lib";

export const EditorProvider = ({ children }: EditorProviderProps) => {
    const [editorContext, dispatch] = useReducer(editorContextReducer, INITIAL_EDITOR_CONTEXT);

    return <Editor.Provider value={{ editorContext, dispatch }}>{children}</Editor.Provider>;
};

type EditorProviderProps = {
    children: React.ReactNode;
};

/**
 * EditorContext: Default Values
 */
export const emptyVariation: CurrentVariation = {
    element: null,
    textContent: "",
    originalTextContent: "",
    actions: [],
};

const INITIAL_EDITOR_CONTEXT: EditorContext = {
    pageSet: { id: "" },
    experiment: undefined,
    currentVariation: emptyVariation,
    currentAction: undefined,
    selectedElement: undefined,
    mode: "BROWSING",
    viewport: viewports[0],
};

/**
 * Create Context
 */
const Editor = createContext<EditorContextType | null>(null);
export const useEditorContext = () => {
    const context = useContext(Editor);

    if (context === null) {
        throw new Error("useEditorContext must be used within an EditorProvider");
    }

    return context as EditorContextType; // Type assertion
};

type EditorContextType = {
    editorContext: EditorContext;
    dispatch: Dispatch<EditorContextAction>;
};
