import { Tabs, VStack, TabList, Tab, TabPanels, useMediaQuery, HStack } from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { Header, StoreSwitchHeader } from "../../components";
import { useParams, useNavigate, useLocation, generatePath, Outlet } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { TabPaths } from "../../types";
import { ROUTES } from "../../Routes";
import { getFeatureFlag } from "../../lib";
import { useStore } from "../../hooks";

export const InternalReports = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState("");
    const { customerUrl } = useParams();
    const storeQuery = useStore();
    const orgId = storeQuery?.data?.orgId ?? "";

    const tabPaths: TabPaths = useMemo(() => {
        return {
            ...(getFeatureFlag("REPORTS_PERFORMANCE") ? { Performance: "performance" } : undefined),
            ...(getFeatureFlag("REPORTS_REVENUE") ? { Revenue: "revenue" } : undefined),
            ...(getFeatureFlag("REPORTS_CRUX") ? { CrUX: "crux" } : undefined),
            Customer: "customer",
        };
    }, []);

    useEffect(() => {
        if (customerUrl) {
            const path = location.pathname.split("/").pop();
            const matchingKey = Object.keys(tabPaths).find((key) => tabPaths[key] === path);
            const initialTab = matchingKey ? tabPaths[matchingKey] : Object.keys(tabPaths)[0];
            const basePath = generatePath(ROUTES.STORE_REPORTS, { customerUrl });
            if (!matchingKey && currentTab === "") {
                setCurrentTab(initialTab);
                navigate(`${basePath}/${tabPaths[initialTab]}`, { replace: true });
            }
            if (matchingKey) {
                setCurrentTab(matchingKey);
            }
        }
    }, [location.pathname, tabPaths, navigate, currentTab, customerUrl]);

    const handleTabChange = (index: string) => {
        const basePath = generatePath(ROUTES.STORE_REPORTS, { customerUrl });
        const path = tabPaths[index];
        navigate(basePath + "/" + path);
    };

    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const mobileStyle = {
        paddingRight: 0,
        paddingLeft: 0,
    };

    return (
        <PageContainer style={{ display: "flex", flexDirection: "column" }}>
            <VStack alignItems={"baseline"} width="100%">
                <HStack justifyContent={"space-between"} width={"100%"} pb="spacer-1">
                    <Header>Reports</Header>
                    {storeQuery && customerUrl ? <StoreSwitchHeader orgId={orgId} currentStore={customerUrl} /> : null}
                </HStack>
                <Tabs
                    isLazy
                    width="100%"
                    index={Object.keys(tabPaths).indexOf(currentTab)}
                    onChange={(index) => handleTabChange(Object.keys(tabPaths)[index])}
                >
                    <TabList>
                        {Object.keys(tabPaths).map((tabName) => {
                            return <Tab key={tabName}>{tabName}</Tab>;
                        })}
                    </TabList>
                    <TabPanels>
                        <div style={{ ...(isMobile ? mobileStyle : {}) }}>
                            <Outlet />
                        </div>
                    </TabPanels>
                </Tabs>
            </VStack>
        </PageContainer>
    );
};
