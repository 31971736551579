import { useCustomerPerformanceReport } from "../../../../hooks";
import { RenderMetric } from "../RenderMetric";

type AccelerationByURIProps = {
    dateRange: { from_hour: string; upto_hour: string };
    metric: string;
};

export const AccelerationByURI = ({ metric, dateRange }: AccelerationByURIProps) => {
    const metricParams = {
        report: "performance_by_uri",
        limit_direction: "DESC",
        limit_by: "relative_acceleration",
        metric,
        ...dateRange,
    };

    const { data, isLoading, isError } = useCustomerPerformanceReport(metricParams);

    return <RenderMetric data={data} isError={isError} isLoading={isLoading} />;
};
