import { sharedRefs } from "./sharedRefs";

type PostMessageData = {
    name: string;
    data: any;
};

export const postMessage = (eventType: string, data: any) => {
    const { iframeRef, isReady } = sharedRefs;
    if (isReady(iframeRef)) {
        const updateEvent: PostMessageData = { name: eventType, data };
        // console.debug("[Message] Editor -> Site: ", updateEvent);

        // @ToDo: Update the second parameter to be the correct origin (security)
        iframeRef!.current!.contentWindow!.postMessage(updateEvent, "*");
    } else {
        throw new Error("Error: Unable to update content, iFrame not loaded.");
    }
};
