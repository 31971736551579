export const containsAnyQueryParameters = (url: string): boolean => {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.search.length > 0;
    } catch (error) {
        // Invalid URL
        // console.error("Invalid URL:", url, error);
        return false;
    }
};
