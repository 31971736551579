import {
    Link,
    Tooltip,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { generateUsersQueryKey, useDeleteUserMutation } from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    userId: string;
    orgId: string;
};

export const DeleteUserLink = ({ userId, orgId }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const deleteUserMutation = useDeleteUserMutation();
    const cancelRef = useRef(null);
    const queryClient = useQueryClient();
    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });

    const handleUserDelete = () => {
        const onDeleteSucess = () => {
            queryClient.invalidateQueries(generateUsersQueryKey(orgId));
            showToast({
                title: "Success",
                description: "User deleted successfully",
                status: "success",
            });
        };
        const onDeleteError = () => {
            showToast({
                title: "Error",
                description: "An unexpected error occurred. If this error persists, please contact customer success.",
                status: "error",
            });
        };
        deleteUserMutation.mutate(userId, {
            onSuccess: onDeleteSucess,
            onError: onDeleteError,
        });
    };
    return (
        <>
            <Tooltip label="Delete User" aria-label="Delete User">
                <Link
                    textStyle={{
                        base: "text-link-meta-regular",
                        md: "text-link-meta-regular",
                    }}
                    onClick={onOpen}
                >
                    delete
                </Link>
            </Tooltip>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete User
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            Are you sure you want to delete this user? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                variant={"outline"}
                                colorScheme={"button-primary"}
                                ref={cancelRef}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                colorScheme={"button-delete"}
                                onClick={() => {
                                    handleUserDelete();
                                    onClose();
                                }}
                                ml={3}
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
