import { useMutation } from "@tanstack/react-query";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { SVC_URLS } from "../../config/SVC_URLS";
import { useParams } from "react-router-dom";
import { UpdateWeightRequest } from "../../types";

export const useUpdateWeightMutation = (customerUrl: string) => {
    const { experimentId } = useParams<{ experimentId: string }>();
    if (!experimentId) throw new Error("experimentId is required");
    const url = `${SVC_URLS["experiment"]}/experiment/${experimentId}/weights`;

    return useMutation({ mutationFn: updateWeight(url, customerUrl) });
};

const updateWeight = (url: string, customerUrl: string) => async (request: UpdateWeightRequest) => {
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(request),
        headers: { Store: customerUrl },
    });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
