import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconEye } from "@tabler/icons-react";

export const IconButtonShow = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Show"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconEye stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
