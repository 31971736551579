import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";

type BulletinFilter = "published" | "all" | undefined;
type UseListBulletinOptions =
    | {
          filter: BulletinFilter;
      }
    | undefined;

export const useListBulletin = ({ filter }: UseListBulletinOptions = { filter: undefined }) => {
    const queryKey = generateBulletinQueryKey(filter);
    const { data, isLoading } = useQuery(queryKey, async () => await listBulletin(filter));

    return { data, isLoading };
};

const listBulletin = async (filter: string | undefined) => {
    const url = filter ? `${SVC_URLS["ecom"]}/bulletin/${filter}` : `${SVC_URLS["ecom"]}/bulletin`;
    const options = await fetchOptions();
    const response = await fetch(url, options).catch(handleErrors);
    return fetchResponse(response);
};

export const generateBulletinQueryKey = (bulletinFilter: BulletinFilter) => ["bulletin", bulletinFilter ?? "all"];
