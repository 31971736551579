import { Header, SubHeader } from "../../components";
import { PageContainer } from "../PageContainer";
import { useListUsers, useAuthenticatedUser } from "../../hooks";
import {
    VStack,
    useDisclosure,
    HStack,
    Button,
    Spacer,
    Avatar,
    Badge,
    Flex,
    Text,
    Box,
    useToast,
    Divider,
} from "@chakra-ui/react";
import { CreateUserModal } from "./CreateUserModal";
import { LoadingPage } from "../LoadingPage";
import { useEffect, useState } from "react";
import { userTypeBadge, userStatusBadge } from "../../lib";
import { ToggleUserStatus } from "./ToggleUserStatus";
import { ResetPasswordLink } from "./ResetPasswordLink";
import { EditUserModal } from "./EditUserModal";
import { DeleteUserLink } from "./DeleteUserLink";

export const ListUsers = () => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { orgId } = useAuthenticatedUser();
    const { data: users, isLoading, isError } = useListUsers(orgId);
    const showToast = useToast();
    const [display, setDisplay] = useState(window.innerWidth < 414 ? "none" : "block");

    useEffect(() => {
        const handleResize = () => {
            setDisplay(window.innerWidth < 414 ? "none" : "block");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description:
                    "We encountered an error loading this page. Please wait a few moments and try again. If this error persists, please contact Customer Success",
                status: "error",
                duration: null,
                isClosable: true,
                variant: "subtle",
            });

            return;
        }
        if (!users) return;
    }, [isError, showToast, users]);

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <HStack align={"baseline"}>
                <VStack alignItems={"baseline"}>
                    <Header>Manage Users</Header>
                    <SubHeader>Users</SubHeader>
                </VStack>
                <Spacer />
                <Button colorScheme={"button-primary"} size="sm" onClick={onOpen}>
                    Create User
                </Button>
            </HStack>
            {users?.length === 0 || users === undefined ? (
                <SubHeader>No users found</SubHeader>
            ) : (
                <>
                    <VStack alignItems={"baseline"}>
                        {users?.map((user) => {
                            const statusBadge = userStatusBadge(user.active);
                            const typeBadge = userTypeBadge(user.role);

                            return (
                                <Flex
                                    key={user.id}
                                    padding={"spacer-2"}
                                    border={"1px"}
                                    borderColor={"gray.200"}
                                    borderRadius={"0.75rem"}
                                    width={"100%"}
                                >
                                    <Avatar name={user.name} />
                                    <Box ml="3">
                                        <Text fontWeight="bold">
                                            {user.name}
                                            <Badge ml="spacer-1" colorScheme={typeBadge.color}>
                                                {user.role}
                                            </Badge>
                                            <Badge ml="spacer-1" colorScheme={statusBadge.color}>
                                                {statusBadge.label}
                                            </Badge>
                                        </Text>
                                        <Text fontSize="sm">{user.email}</Text>
                                        <HStack height={{ base: "30%", lg: "15px" }} width="100%" mt="spacer-2">
                                            <ResetPasswordLink userId={user.id} />
                                            <Divider orientation={"vertical"} />
                                            <ToggleUserStatus userId={user.id} active={user.active} />
                                            <Divider orientation={"vertical"} />
                                            <EditUserModal user={user} />
                                            <Divider orientation={"vertical"} style={{ display }} />
                                            <div style={{ display }}>
                                                <DeleteUserLink orgId={user.orgId} userId={user.id} />
                                            </div>
                                        </HStack>
                                    </Box>
                                </Flex>
                            );
                        })}
                        ;
                    </VStack>
                </>
            )}
            <CreateUserModal isOpen={isOpen} onClose={onClose} orgId={orgId} />
        </PageContainer>
    );
};
