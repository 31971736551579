export const UPDATE_TEXT_CONTENT = "UPDATE_TEXT_CONTENT";
export const UPDATE_HTML_CONTENT = "UPDATE_HTML_CONTENT";
export const BUILD_SELECTOR = "BUILD_REPLACE_SELECTOR";
export const SELECTOR_BUILT = "SELECTOR_BUILT";
export const ELEMENT_SELECTED = "click";
export const IFRAME_LOADED = "iframe-loaded";
export const REPLACE_HTML_FRAGMENT = "REPLACE_HTML_FRAGMENT";
export const HTML_FRAGMENT_REPLACED = "HTML_FRAGMENT_REPLACED";
export const SELECT_EXISTING_CHANGE = "SELECT_EXISTING_CHANGE";
export const EDIT_CANCELED = "EDIT_CANCELED";
export const SET_EDITOR_MODE = "SET_EDITOR_MODE";
