import { PageContainer } from "./PageContainer";
import { useListSingleShopifyApp, useEditAppNoteMutation, useAuthenticatedUser } from "../hooks";
import { LoadingPage } from "./LoadingPage";
import { FieldSet, Label, FieldError, Link, Header } from "../components";
import {
    UnorderedList,
    ListItem,
    Text,
    Avatar,
    HStack,
    Checkbox,
    Button,
    Stack,
    FormControl,
    Box,
    Grid,
    useToast,
} from "@chakra-ui/react";
import { useMemo, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { IconCheck, IconX, IconQuestionMark } from "@tabler/icons-react";
import { ROUTES } from "../Routes";
import { generatePath } from "react-router-dom";
import { UpdatedAppInfo } from "../types";
import { useQueryClient } from "@tanstack/react-query";

export const SingleAppPage = () => {
    const { isInternalRole } = useAuthenticatedUser();
    const { data: shopifyApp, isLoading, isError } = useListSingleShopifyApp();
    const { handleSubmit, register, setValue } = useForm();
    const editAppNoteMutation = useEditAppNoteMutation();
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const queryClient = useQueryClient();

    useEffect(() => {
        register("notes");
    }, [register]);

    const onNotesChange = (notes: string) => {
        setValue("notes", notes);
    };

    // filter and alphabetize app active/deleted apps
    const installedAppList = useMemo(() => {
        return shopifyApp?.EcomStoreApp.filter((store) => !store.deleted && !store.store.deleted).sort((a, b) => {
            const storeA = a.store.customerUrl.toLowerCase();
            const storeB = b.store.customerUrl.toLowerCase();
            if (storeA < storeB) {
                return -1;
            }
            if (storeA > storeB) {
                return 1;
            }
            return 0;
        });
    }, [shopifyApp]);

    const deletedAppList = useMemo(() => {
        return shopifyApp?.EcomStoreApp.filter((store) => store.deleted && !store.store.deleted).sort((a, b) => {
            const storeA = a.store.customerUrl.toLowerCase();
            const storeB = b.store.customerUrl.toLowerCase();
            if (storeA < storeB) {
                return -1;
            }
            if (storeA > storeB) {
                return 1;
            }
            return 0;
        });
    }, [shopifyApp]);

    const deletedCustomerList = useMemo(() => {
        return shopifyApp?.EcomStoreApp.filter((store) => store.store.deleted).sort((a, b) => {
            const storeA = a.store.customerUrl.toLowerCase();
            const storeB = b.store.customerUrl.toLowerCase();
            if (storeA < storeB) {
                return -1;
            }
            if (storeA > storeB) {
                return 1;
            }
            return 0;
        });
    }, [shopifyApp]);

    if (isLoading) {
        return <LoadingPage />;
    }

    if (isError) {
        return <Text>There was an error loading the page.</Text>;
    }

    const handleAppInfoUpdate: SubmitHandler<any> = async (formData) => {
        if (!shopifyApp.id) return;
        const { notes, supported } = formData;
        const requestBody: UpdatedAppInfo = {
            notes,
            supported,
        };

        const onEditAppSuccess = () => {
            queryClient.invalidateQueries(["singleShopifyApp", shopifyApp.id]);
            queryClient.invalidateQueries(["listStoreApps"]);
            showToast({
                title: "Success",
                description: "You have successfully updated this app's information.",
                status: "success",
            });
        };

        const onEditAppError = () => {
            showToast({
                title: "Error",
                description:
                    "There was an error updating this app's information. If the problem persists, please contact support.",
                status: "error",
            });
        };

        editAppNoteMutation.mutate(
            { requestBody, appId: shopifyApp.id },
            {
                onSuccess: onEditAppSuccess,
                onError: onEditAppError,
            },
        );
    };

    const richTextToolbarSettings = [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
    ];

    return (
        <PageContainer>
            <Link to={`https://apps.shopify.com/${shopifyApp?.handle}`} target="new">
                <HStack pb={"spacer-6"}>
                    <Avatar src={shopifyApp?.iconUrl ? shopifyApp?.iconUrl : ""}></Avatar>
                    <Header>{shopifyApp?.title}</Header>
                </HStack>
            </Link>
            <FormControl>
                <form onSubmit={handleSubmit(handleAppInfoUpdate)}>
                    <FieldSet legend={"App Details"} style={{ paddingBottom: "1rem" }}>
                        <Label htmlFor="description">Developer</Label>
                        <Text>{shopifyApp?.developerName}</Text>
                        <FieldError></FieldError>
                        <Label htmlFor="description">Description</Label>
                        <Text>{shopifyApp?.description}</Text>
                        <FieldError></FieldError>
                        <Label htmlFor="features">Features</Label>

                        {shopifyApp?.features ? (
                            <UnorderedList>
                                {shopifyApp.features.map((feature) => (
                                    <ListItem key={feature}>{feature}</ListItem>
                                ))}
                            </UnorderedList>
                        ) : null}
                        <FieldError></FieldError>
                        <HStack>
                            <Checkbox
                                {...register("supported")}
                                defaultChecked={shopifyApp.supported}
                                disabled={isInternalRole}
                            ></Checkbox>
                            <Label htmlFor="supported">Supported</Label>
                        </HStack>
                        <FieldError></FieldError>
                        <Label htmlFor="notes">Notes</Label>
                        {isInternalRole ? (
                            <Text dangerouslySetInnerHTML={{ __html: shopifyApp?.notes ?? "No notes" }} />
                        ) : (
                            <ReactQuill
                                theme="snow"
                                onChange={onNotesChange}
                                modules={{ toolbar: richTextToolbarSettings }}
                                defaultValue={shopifyApp?.notes ?? ""}
                            ></ReactQuill>
                        )}
                    </FieldSet>
                    {!isInternalRole && (
                        <Stack
                            flexDirection={{ base: "column", md: "row" }}
                            justifyContent={{ md: "flex-end" }}
                            maxWidth={{ base: "50%", md: "100%" }}
                            pb={"spacer-6"}
                        >
                            <Button
                                isLoading={editAppNoteMutation.isLoading}
                                type="submit"
                                colorScheme="button-primary"
                            >
                                Update App
                            </Button>
                        </Stack>
                    )}
                </form>
            </FormControl>
            <HStack width={"100%"} alignContent={"center"}>
                <Box display={{ base: "none", md: "block" }}>
                    <IconCheck stroke={"2"} color={"green"} size={"50px"} />
                </Box>
                <FieldSet legend={`Active Customers: Installed`} style={{ width: "100%" }}>
                    {installedAppList?.length === 0 ? (
                        <Text>No active stores are currently using this app.</Text>
                    ) : null}
                    <UnorderedList>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}>
                            {installedAppList?.map((store) => {
                                return (
                                    <ListItem key={store.store.id}>
                                        {!isInternalRole ? (
                                            <Link
                                                to={generatePath(ROUTES.STORE_EDIT, {
                                                    customerUrl: store.store.customerUrl,
                                                })}
                                            >
                                                {store.store.customerUrl}
                                            </Link>
                                        ) : (
                                            store.store.customerUrl
                                        )}
                                    </ListItem>
                                );
                            })}
                        </Grid>
                    </UnorderedList>
                </FieldSet>
            </HStack>
            <HStack width={"100%"} alignContent={"center"}>
                <Box display={{ base: "none", md: "block" }}>
                    <IconQuestionMark stroke={"2"} color={"gold"} size={"50px"} />
                </Box>
                <FieldSet legend={`Inactive Customers: Previously Installed`} style={{ width: "100%" }}>
                    {deletedCustomerList?.length === 0 ? (
                        <Text>No inactive customers have a history of using this app.</Text>
                    ) : null}
                    <UnorderedList>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}>
                            {deletedCustomerList?.map((store) => {
                                return (
                                    <ListItem key={store.store.id}>
                                        {!isInternalRole ? (
                                            <Link
                                                to={generatePath(ROUTES.STORE_EDIT, {
                                                    customerUrl: store.store.customerUrl,
                                                })}
                                            >
                                                {store.store.customerUrl}
                                            </Link>
                                        ) : (
                                            store.store.customerUrl
                                        )}
                                    </ListItem>
                                );
                            })}
                        </Grid>
                    </UnorderedList>
                </FieldSet>
            </HStack>
            <HStack width={"100%"} alignContent={"center"}>
                <Box display={{ base: "none", md: "block" }}>
                    <IconX stroke={"2"} color={"red"} size={"50px"} />
                </Box>
                <FieldSet legend={`Active Customers: Uninstalled`} style={{ width: "100%" }}>
                    {deletedAppList?.length === 0 ? (
                        <Text>No stores have a history of uninstalling this app.</Text>
                    ) : null}
                    <UnorderedList>
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}>
                            {deletedAppList?.map((store) => {
                                return (
                                    <ListItem key={store.store.id}>
                                        {!isInternalRole ? (
                                            <Link
                                                to={generatePath(ROUTES.STORE_EDIT, {
                                                    customerUrl: store.store.customerUrl,
                                                })}
                                            >
                                                {store.store.customerUrl}
                                            </Link>
                                        ) : (
                                            store.store.customerUrl
                                        )}
                                    </ListItem>
                                );
                            })}
                        </Grid>
                    </UnorderedList>
                </FieldSet>
            </HStack>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
