import { PageContainer } from "../../PageContainer";
import { Select, Input, Button, Flex, Spacer, FormControl, Textarea, FormHelperText, useToast } from "@chakra-ui/react";
import { Store, UpdateModeFormData, StoreModes } from "../../../types";
import { useAuthenticatedUser, useEnqueueDeploymentMutation, useStoreMutation } from "../../../hooks";
import { useEffect, useState } from "react";
import { FieldError, Label, StoreStatusAlert } from "../../../components";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { StoreHistoryLog } from "./StoreHistoryLog";
import { isStoreMode, storeModeLabel } from "../../../lib";
import { transformUrlToPath } from "../../../lib/transformUrlToPath";

type Props = {
    store: Store;
};

export const StoreSettingsTab = ({ store }: Props) => {
    const { isCloudOpsRole } = useAuthenticatedUser();
    const useStoreUpdateMutation = useStoreMutation(store.id, store.customerUrl);
    const { mode, testModePath } = store;
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });

    const [selectedMode, setSelectedMode] = useState<string | undefined>(mode);
    const queryClient = useQueryClient();

    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    const isInTestingMode = mode === StoreModes.Testing && !isCloudOpsRole;
    const isDisabledByProvider = mode === StoreModes.DisabledByProvider && !isCloudOpsRole;
    const isUnknownMode = !isStoreMode(mode) && !isCloudOpsRole;
    const isInDNSSetupMode = mode === StoreModes.DnsSetup && !isCloudOpsRole;

    // Automate onboarding dates for EDE, CO
    const isStoreOnboarding = !store.edgeEnabledAt && !store.staticSiteEnabledAt;
    const onboardingDate = new Date();

    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<UpdateModeFormData>({
        defaultValues: { mode: isStoreMode(mode) ? mode : StoreModes.Unknown, testModePath, updateModeReason: "" },
    });

    useEffect(
        function watchStoreMode() {
            const subscription = watch((formData: Partial<UpdateModeFormData>) => setSelectedMode(formData.mode));
            return () => subscription.unsubscribe();
        },
        [watch],
    );

    const onSubmit = (formData: UpdateModeFormData) => {
        const { updateModeReason, testModePath, mode } = formData;
        let request: UpdateModeFormData = {
            mode: mode,
            updateModeReason,
            ...(mode === StoreModes.Testing && { testModePath }),
        };

        if (isStoreOnboarding) {
            request = {
                ...request,
                ...(mode === StoreModes.Testing && { edgeEnabled: true, staticSiteEnabled: true }),
                ...(mode === StoreModes.Enabled && {
                    edgeEnabledAt: onboardingDate.toISOString(),
                    staticSiteEnabledAt: onboardingDate.toISOString(),
                }),
            };
        }
        const onUpdateStatusSuccess = () => {
            enqueueDeployment.mutate(store.customerUrl);
            queryClient.invalidateQueries({ queryKey: ["store", store.id] });
            queryClient.invalidateQueries({
                queryKey: ["storeHistoryLogs", store.id],
            });
            showToast({
                title: "Success",
                description: "You have successfully updated the store status.",
                status: "success",
            });
            reset({ ...request, updateModeReason: "" });
        };

        const onUpdateStatusError = () => {
            showToast({
                title: "Error",
                description:
                    "There was an error updating the status. Please try again in a few minutes. If the problem persists, please contact support.",
                status: "error",
            });
        };

        useStoreUpdateMutation.mutate(request, {
            onSuccess: onUpdateStatusSuccess,
            onError: onUpdateStatusError,
        });
    };

    const disableForm = isInTestingMode || isDisabledByProvider || isUnknownMode || isInDNSSetupMode;
    const disableSubmit = !isStoreMode(selectedMode ?? "") || selectedMode === StoreModes.Unknown;
    const disableSelect = !isCloudOpsRole && disableSubmit;

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxWidth={"1440px"}>
            {mode !== "enabled" ? <StoreStatusAlert store={store} /> : null}
            <FormControl maxWidth={{ md: "100%" }} pt="spacer-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Label htmlFor="mode">Update Status</Label>
                    <Select isDisabled={disableForm || disableSelect} {...register("mode")}>
                        <option value={StoreModes.Enabled}>{storeModeLabel(StoreModes.Enabled)}</option>
                        <option value={StoreModes.Disabled}>{storeModeLabel(StoreModes.Disabled)}</option>
                        {isCloudOpsRole || isInTestingMode || isDisabledByProvider ? (
                            <>
                                <option value={StoreModes.DisabledByProvider}>
                                    {storeModeLabel(StoreModes.DisabledByProvider)}
                                </option>
                                <option value={StoreModes.Testing}>{storeModeLabel(StoreModes.Testing)}</option>
                                <option value={StoreModes.DnsSetup}>{storeModeLabel(StoreModes.DnsSetup)}</option>
                            </>
                        ) : null}
                        {disableForm || getValues("mode") === StoreModes.Unknown || disableSubmit ? (
                            <option disabled value={StoreModes.Unknown}>
                                Unknown
                            </option>
                        ) : null}
                    </Select>
                    {selectedMode === StoreModes.Enabled ||
                    selectedMode === StoreModes.Disabled ||
                    ((selectedMode === StoreModes.DisabledByProvider || selectedMode === StoreModes.DnsSetup) &&
                        isCloudOpsRole) ? (
                        <>
                            <Label htmlFor="updateModeReason" pt="spacer-6">
                                Reason
                            </Label>
                            <FormHelperText pb="spacer-2">
                                Enter a description of why you are changing the store status. If you are noticing issues
                                with Nostra, additionally submit a support case.
                            </FormHelperText>
                            <Textarea
                                isDisabled={disableForm}
                                placeholder="Example: Testing theme changes"
                                {...register("updateModeReason", {
                                    required: {
                                        value: true,
                                        message: "Reason is required",
                                    },
                                })}
                            />
                            <FieldError>{errors.updateModeReason?.message}</FieldError>
                        </>
                    ) : null}
                    {selectedMode === StoreModes.Testing && isCloudOpsRole ? (
                        <>
                            <Label htmlFor="testModePath" pt="spacer-6git ">
                                Test Path
                            </Label>
                            <FormHelperText pb="spacer-2">
                                Enter either a URL or a specific path you would like to test. Valid URLs will be parsed
                                before submission to include only the path.
                            </FormHelperText>
                            <Input
                                isDisabled={disableForm}
                                type="text"
                                placeholder="Example: /collections/all"
                                onPaste={transformUrlToPath((transformedValue) =>
                                    setValue("testModePath", transformedValue),
                                )}
                                {...register("testModePath", {
                                    required: {
                                        value: true,
                                        message: "Test path is required",
                                    },
                                })}
                            />
                            <FieldError>{errors.testModePath?.message}</FieldError>
                            <Label htmlFor="updateModeReason">Test Mode Reason</Label>
                            <Textarea
                                isDisabled={disableForm}
                                placeholder="Example: Updating session content"
                                {...register("updateModeReason", {
                                    required: {
                                        value: true,
                                        message: "Reason is required",
                                    },
                                })}
                            />
                            <FieldError>{errors.updateModeReason?.message}</FieldError>
                        </>
                    ) : null}
                    <Flex pt={"spacer-2"}>
                        <Spacer />
                        <Button isDisabled={disableForm || disableSubmit} type="submit" colorScheme={"button-primary"}>
                            Submit
                        </Button>
                    </Flex>
                </form>
            </FormControl>
            <StoreHistoryLog store={store} />
        </PageContainer>
    );
};
