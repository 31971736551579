export const handleErrors = (response: any) => {
    if (!response.ok) {
        if (response.statusText) {
            console.error(`Network Response: ${response.statusText}`);
            throw Error(response.statusText);
        }

        if (response.message) {
            console.error(`fetch() error: ${response.message}`);
            throw Error(response.message);
        }

        console.error({ response });
        throw Error("Unknown error");
    }
    return response;
};
