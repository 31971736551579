import { Spinner, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { focusManager } from "@tanstack/react-query";
import { useCallback } from "react";
import { useEnqueueDeploymentMutation, useStore, useToggleExperimentActivationMutation } from "../../hooks";
import { BRAND_NAME } from "../../config/BRAND_NAME";
import { useQueryClient } from "@tanstack/react-query";
import { ToggleExperimentModal } from "../../components";

type Props = {
    experiment: { id: string; name: string; active?: boolean };
    isChecked: boolean;
    edgeEnabled: boolean;
    onDeploymentSuccess?: () => void;
    customerUrl: string;
};

export const ToggleExperimentActivation = ({
    experiment,
    isChecked,
    edgeEnabled,
    onDeploymentSuccess,
    customerUrl,
}: Props) => {
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toggleMutation = useToggleExperimentActivationMutation();
    const enqueueDeployment = useEnqueueDeploymentMutation(
        {
            onDeploymentSuccess: () => {
                showToast({
                    title: "Success",
                    description: `"${experiment.name}" is now ${experiment.active ? "disabled" : "active"}!`,
                    status: "success",
                });
                onDeploymentSuccess && onDeploymentSuccess();
                focusManager.setFocused(undefined); // https://github.com/TanStack/query/issues/2960
                queryClient.invalidateQueries(["store", customerUrl]);
            },
        },
        customerUrl,
    );
    const { data: store } = useStore();
    const showToast = useToast();

    const toggleActivateOnExperiment = useCallback(
        (experimentId: string) => {
            if (experimentId === undefined || store === undefined) return;

            if (!edgeEnabled) {
                showToast({
                    icon: "⚠️",
                    title: `WARNING: ${BRAND_NAME} services are paused.`,
                    description: `Changing the status of your experiment will not affect production until ${BRAND_NAME} services are no longer paused.`,
                    status: "warning",
                    duration: 14000,
                    isClosable: true,
                });
            }

            focusManager.setFocused(false);
            toggleMutation.mutate(
                { experimentId, customerUrl },
                {
                    onSuccess: (_data, _context, _variables) => enqueueDeployment.mutate(store.customerUrl),
                    onError: (error: any) => {
                        showToast({
                            title: "Error",
                            description: error.message,
                            status: "error",
                        });
                    },
                },
            );

            return void 0;
        },
        [toggleMutation, store, enqueueDeployment, showToast, edgeEnabled, customerUrl],
    );

    /**
     * Loading state
     */
    if (toggleMutation.isLoading || enqueueDeployment.isLoading || enqueueDeployment.isPolling) {
        return <Spinner speed="0.65s" emptyColor="gray.200" color="primary-default" />;
    }

    return (
        <>
            <Switch
                size={{ base: "sm", md: "md" }}
                isChecked={isChecked}
                onChange={() => (experiment.active ? onOpen() : toggleActivateOnExperiment(experiment.id))}
            ></Switch>
            <div style={{ display: "none" }}>
                <ToggleExperimentModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onAction={() => toggleActivateOnExperiment(experiment.id)}
                ></ToggleExperimentModal>
            </div>
        </>
    );
};
