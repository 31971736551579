import { VStack, HStack } from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { Header, StoreSwitchHeader } from "../../components";
import { useParams } from "react-router-dom";
import { useStore } from "../../hooks";
import { CustomerReport } from "./Reports/Customer/CustomerReport";

export const CustomerReports = () => {
    const { customerUrl } = useParams();
    const storeQuery = useStore();
    const orgId = storeQuery?.data?.orgId ?? "";

    return (
        <PageContainer style={{ display: "flex", flexDirection: "column" }}>
            <VStack alignItems={"baseline"} width="100%">
                <HStack justifyContent={"space-between"} width={"100%"} pb="spacer-1">
                    <Header>Reports</Header>
                    {storeQuery && customerUrl ? <StoreSwitchHeader orgId={orgId} currentStore={customerUrl} /> : null}
                </HStack>
            </VStack>
            <CustomerReport />
        </PageContainer>
    );
};
