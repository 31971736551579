import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { HistoryFields, HistoryRow } from "../../types";

export const useCacheSettingsHistory = (customerUrl: string, page: string) => {
    return useQuery(["history", customerUrl, "cache-settings"], getCacheSettingsHistory(customerUrl, page));
};

const getCacheSettingsHistory = (customerUrl: string, page: string) => async (): Promise<ListHistoryResponse> => {
    const url = `${SVC_URLS["portal"]}/history/cache-settings?page=${page}`;
    const options = await fetchOptions({
        headers: { Store: customerUrl },
    });

    const raw = await fetch(url, options).catch(handleErrors);
    return fetchResponse(raw);
};

type ListHistoryResponse = {
    title: string;
    fields: HistoryFields[];
    rows: HistoryRow[];
    hasMore?: boolean;
};
