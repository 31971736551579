import { useQuery } from "@tanstack/react-query";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SVC_URLS } from "../../config/SVC_URLS";
import { StoreHistoryLogsResponse } from "../../types";

export const useStoreLogs = (storeId: string, customerUrl: string) => {
    return useQuery(generateStoreHistoryQueryKey(storeId), async () => await getStoreLog(storeId, customerUrl));
};

const getStoreLog = async (storeId: string, customerUrl: string): Promise<StoreHistoryLogsResponse> => {
    const STORE_HISTORY = `${SVC_URLS["ecom"]}/store/${storeId}/logs`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(STORE_HISTORY, options).catch(handleErrors);

    return fetchResponse(response);
};

export const generateStoreHistoryQueryKey = (storeId: string): string[] => ["storeHistoryLogs", storeId];
