import { FormControl, Input, Select, useToast, Text, Spacer, Flex, Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { FieldError, FieldSet, Header, Label } from "../../components";
import { useCreateBulletinMutation } from "../../hooks";
import { ROUTES } from "../../Routes";
import { CreateBulletinRequest, BulletinFormData } from "../../types";
import { PageContainer } from "../PageContainer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import { BRAND_FOUNDING_MIN_DATE } from "../../config/BRAND_NAME";

export const CreateBulletinPage = () => {
    return (
        <PageContainer>
            <Header pb={"spacer-6"}>Create Bulletin</Header>
            <CreateBulletinForm />
        </PageContainer>
    );
};

const CreateBulletinForm = () => {
    const navigate = useNavigate();
    const createBulletin = useCreateBulletinMutation();
    const showToast = useToast({ duration: 5000, isClosable: true, variant: "subtle" });
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
    } = useForm<BulletinFormData>({
        defaultValues: initialFormValues,
    });
    const queryClient = useQueryClient();

    useEffect(() => {
        register("description", {
            required: {
                value: true,
                message: "Description is required",
            },
        });
    }, [register]);

    const onDescriptionChange = (content: string) => {
        setValue("description", content);
    };

    const onSubmitCreateBulletin = async (formData: BulletinFormData) => {
        const { type, title, description, publishAt, unpublishAt } = formData;
        const publishDate = new Date(publishAt ?? "").toISOString();
        const request: CreateBulletinRequest = {
            type,
            title,
            description,
            publishAt: publishDate,
            unpublishAt: unpublishAt ? new Date(unpublishAt).toISOString() : null,
        };

        const onSuccess = () => {
            queryClient.invalidateQueries({ queryKey: ["bulletins"] });
            navigate(generatePath(ROUTES.LIST_BULLETINS_PAGE));
            reset(initialFormValues);
            showToast({
                title: "Success",
                description: "Bulletin created successfully.",
                status: "success",
            });
        };
        const onError = () => {
            showToast({
                title: "Error",
                description: "An error occurred while creating the bulletin. If this error persists, contact support.",
                status: "error",
            });
        };
        createBulletin.mutate(request, { onSuccess, onError });
    };

    const richTextToolbarSettings = [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
    ];

    return (
        <FormControl>
            <form onSubmit={handleSubmit(onSubmitCreateBulletin)}>
                <FieldSet legend={"Bulletin Details"}>
                    <Label mb={1} htmlFor="type">
                        Type
                    </Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Select the bulletin type which determines the styling for the bulletin when displayed.
                    </Text>
                    <Select {...register("type")} placeholder="Select Type" isRequired={true}>
                        <option value="info">Informational</option>
                        <option value="warning">Warning</option>
                        <option value="danger">Danger</option>
                    </Select>
                    <FieldError />

                    <Label mb={1} htmlFor="title">
                        Title
                    </Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Enter the title of the bulletin using plain text.
                    </Text>
                    <Input
                        type="text"
                        {...register("title", {
                            required: {
                                value: true,
                                message: "Title is required",
                            },
                        })}
                    />
                    <FieldError>{errors.title?.message}</FieldError>

                    <Label mb={1} htmlFor="description">
                        Body
                    </Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Enter the description of the bulletin using the rich text editor. Please note that{" "}
                        <strong>links should be underlined</strong> so they are clearly visible.
                    </Text>
                    <ReactQuill
                        className="ql-editor"
                        defaultValue={initialFormValues.description}
                        theme="snow"
                        onChange={onDescriptionChange}
                        modules={{ toolbar: richTextToolbarSettings }}
                    ></ReactQuill>
                    <FieldError>{errors.description?.message}</FieldError>

                    <Label mb={1} htmlFor="publishAt">
                        Publish Date
                    </Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        The bulletin will be visible to users from this date and time. To publish the bulletin
                        immediately, select today's date and the current time.
                    </Text>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        min={BRAND_FOUNDING_MIN_DATE}
                        {...register("publishAt", {
                            required: {
                                value: true,
                                message: "Publish at date is required",
                            },
                        })}
                    />
                    <FieldError>{errors.publishAt?.message}</FieldError>

                    <Label mb={1} htmlFor="unpublishAt">
                        Unpublish Date
                    </Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        The bulletin will no longer be visible to users after this date and time.
                    </Text>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        min={BRAND_FOUNDING_MIN_DATE}
                        {...register("unpublishAt", {
                            required: {
                                value: true,
                                message: "Unpublish at date is required",
                            },
                        })}
                    />
                    <FieldError>{errors.unpublishAt?.message}</FieldError>
                </FieldSet>

                <Flex pb="spacer-6">
                    <Spacer />
                    <Button colorScheme={"button-primary"} type="submit">
                        Create
                    </Button>
                </Flex>
            </form>
        </FormControl>
    );
};

const initialFormValues: BulletinFormData = {
    type: "info",
    title: "",
    description: "",
    publishAt: "",
    unpublishAt: null,
};
