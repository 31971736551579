import { AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, HStack } from "@chakra-ui/react";

type MenuItemDropDownProps = {
    children?: React.ReactNode;
    menuItems?: React.ReactNode[];
    maxBgWidth?: string;
};

export const MenuItemDropDown = ({ children, menuItems, maxBgWidth }: MenuItemDropDownProps) => {
    const MENU_ITEM_BG_WIDTH = "13rem";
    const width = maxBgWidth ?? MENU_ITEM_BG_WIDTH;
    return (
        <AccordionItem style={{ border: "none", width: width, maxWidth: width }}>
            <AccordionButton
                paddingLeft={maxBgWidth ? "spacer-2" : "spacer-3"}
                _hover={{
                    backgroundColor: "background-action-active",
                    borderRadius: "8px",
                    cursor: "pointer",
                    width: width,
                }}
            >
                <HStack justify={"space-between"} width={width}>
                    <Box as="span">{children}</Box>
                    <AccordionIcon color={"gray.500"} marginTop={"spacer-.5"} />
                </HStack>
            </AccordionButton>
            <AccordionPanel paddingY={0} width={width}>
                {menuItems && menuItems.map((menuItem, index) => <div key={index}>{menuItem}</div>)}
            </AccordionPanel>
        </AccordionItem>
    );
};
