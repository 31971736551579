import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";

export const useShopMetadata = (customerUrl: string) => {
    const config = {
        queryKey: ["shopMetadata", customerUrl],
        queryFn: () => fetchShopMetadata(customerUrl),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24,
    };

    return useQuery(config);
};

const fetchShopMetadata = async (customerUrl: string) => {
    const url = `${SVC_URLS["portal"]}/shops/${customerUrl}`;
    const options = await fetchOptions();
    const response = await fetch(url, options);
    return fetchResponse(response);
};
