import { Divider, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../../../components";
import { ReportParameters } from "./ReportParameters";
import { TTFBByURI } from "./TTFBByURI";
import { Top30TTFBByURI } from "./Top30TTFBByURI";
import { TTFBVsSource } from "./TTFBVsSource";
import { LoadByURI } from "./LoadByURI";
import { Top30LoadByURI } from "./Top30LoadByURI";
import { LoadVsSource } from "./LoadVsSource";
import { CacheRatio } from "./CacheRatio";
import { CumulativeLayoutShiftScore } from "./CumulativeLayoutShiftScore";
import { FirstContentfulPaint } from "./FirstContentfulPaint";
// import { FirstPaint } from "./FirstPaint";
import { LargestContentfulPaint } from "./LargestContentfulPaint";
import { VisuallyReady } from "./VisuallyReady";
import { BounceRate } from "./BounceRate";
import { BounceRateByReferer } from "./BounceRateByReferer";

export const PerformanceReport = () => {
    const [searchParams] = useSearchParams();

    const enabled = searchParams.get("from_hour") !== null && searchParams.get("upto_hour") !== null;

    return (
        <>
            <ReportParameters />
            {enabled && (
                <>
                    <Header style={{ marginTop: "20px" }}>TTFB</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <TTFBByURI />
                    <TTFBVsSource />
                    <Divider style={{ margin: "20px 0" }} />
                    <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                        TTFB Top 30 - Sorted by Acceleration
                    </Header>
                    <Top30TTFBByURI />
                    <Header style={{ marginTop: "20px" }}>Load</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <LoadByURI />
                    <Divider style={{ margin: "20px 0" }} />
                    <LoadVsSource />
                    <Header style={{ marginTop: "20px" }} as="h3" textStyle="text-header-L">
                        Load Top 30 - Sorted by Acceleration
                    </Header>
                    <Top30LoadByURI />
                    <Header style={{ marginTop: "20px" }}>Cache Ratio</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <CacheRatio />
                    <Header style={{ marginTop: "20px" }}>Web Vitals</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <CumulativeLayoutShiftScore />
                    <FirstContentfulPaint />
                    <LargestContentfulPaint />
                    <VisuallyReady />
                    <Header style={{ marginTop: "20px" }}>Bounce Rate</Header>
                    <Alert style={{ marginTop: "20px" }} status="warning" mb={"spacer-6"}>
                        <AlertIcon />
                        <Text>It is important to note that the selected percentile does not apply to bounce rate.</Text>
                    </Alert>
                    <Divider style={{ margin: "20px 0" }} />
                    <BounceRate />
                    <Header style={{ marginTop: "20px" }}>Bounce Rate By Referer</Header>
                    <Alert style={{ marginTop: "20px" }} status="warning" mb={"spacer-6"}>
                        <AlertIcon />
                        <Text>A source must have at least 200 bounces to be included in the dataset.</Text>
                    </Alert>
                    <Divider style={{ margin: "20px 0" }} />
                    <BounceRateByReferer />
                </>
            )}
        </>
    );
};
