import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Heading,
    Spacer,
    Flex,
    useToast,
    FormControl,
    Input,
    Select,
} from "@chakra-ui/react";
import { useCreateUserMutation, generateUsersQueryKey, useAuthenticatedUser } from "../../hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import { FieldError, Label } from "../../components";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    orgId?: string;
};

export const CreateUserModal = ({ isOpen, onClose, orgId }: Props) => {
    const createUserMutation = useCreateUserMutation();
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const queryClient = useQueryClient();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<CreateUserFormData>({
        defaultValues: initialFormValues,
    });

    const onCreateUserSuccess = () => {
        queryClient.invalidateQueries(generateUsersQueryKey());
        showToast({
            title: "Success",
            description: "You have successfully created a new user.",
            status: "success",
        });
        reset(initialFormValues);
        onClose();
    };

    const onCreateUserError = () => {
        showToast({
            title: "Error",
            description:
                "There was an error creating this user. This often happens when the email address is registered with another organization or an inactive account. If the problem persists, please contact support.",
            status: "error",
        });
    };

    const onSubmit: SubmitHandler<CreateUserFormData> = async (formData) => {
        if (!orgId) return;
        const request: CreateUserFormData = createUserCreationRequest(formData, orgId);
        createUserMutation.mutate(request, {
            onSuccess: onCreateUserSuccess,
            onError: onCreateUserError,
        });
    };

    return (
        <Modal
            isCentered
            size={"3xl"}
            isOpen={isOpen}
            onClose={() => {
                onClose();
                reset(initialFormValues);
            }}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading as="h2" size="md">
                        Create User
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl maxWidth={{ md: "100%" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Label htmlFor="name">Name</Label>
                            <Input
                                id="name"
                                type="name"
                                placeholder="Name"
                                {...register("name", {
                                    required: "Name is required",
                                })}
                            />
                            <FieldError>{errors.name?.message}</FieldError>
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder="Email"
                                {...register("email", {
                                    required: "Email is required",
                                })}
                            />
                            <FieldError>{errors.email?.message}</FieldError>
                            <Label htmlFor="phone">Phone</Label>
                            <Input id="phone" type="phone" placeholder="Phone (Optional)" {...register("phone")} />
                            <FieldError></FieldError>
                            <Label htmlFor="role">Role</Label>
                            <Select {...register("role")} name="role" id="role" defaultValue="Admin">
                                <option value="Admin">Store Administrator</option>
                                <option value="Owner">Organization Owner</option>
                                {isCloudOpsRole && <option value="Internal">Internal</option>}
                                {isCloudOpsRole && <option value="CloudOps">CloudOps - Super User</option>}
                            </Select>
                            <FieldError> </FieldError>
                            <Flex pb="spacer-6">
                                <Spacer />
                                <Button
                                    variant="outline"
                                    colorScheme="button-primary"
                                    onClick={() => {
                                        onClose();
                                        reset(initialFormValues);
                                    }}
                                    mr={3}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={createUserMutation.isLoading}
                                    loadingText="Creating User"
                                    type="submit"
                                    colorScheme="button-primary"
                                >
                                    Submit
                                </Button>
                            </Flex>
                        </form>
                    </FormControl>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const createUserCreationRequest = (data: CreateUserFormData, orgId: string) => {
    return {
        email: data.email,
        phone: data.phone,
        name: data.name,
        orgId: orgId,
        role: data.role,
    };
};

const initialFormValues: CreateUserFormData = {
    email: "",
    phone: "",
    name: "",
    orgId: "",
    role: "Admin",
};

type CreateUserFormData = {
    email: string;
    phone: string;
    name: string;
    orgId: string;
    role: "Admin" | "Owner" | "CloudOps";
};
