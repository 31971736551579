import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SchedulePurgeCacheRequest } from "../../types";

export const useEnqueuePurgeCacheMutation = () => {
    return useMutation(schedulePurgeCache);
};

const schedulePurgeCache = async (request: SchedulePurgeCacheRequest) => {
    const PURGE_CACHE = `${SVC_URLS["cronos"]}/cache/enqueue`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Store: request.customerUrl,
        },
    });
    const response = await fetch(PURGE_CACHE, options).catch(handleErrors);

    return fetchResponse(response);
};
