import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { OrganizationResponse } from "../../types";

export const useOrg = (orgId: string = "") => {
    return useQuery(["org", orgId], () => getOrg(orgId));
};

const getOrg = async (orgId: string): Promise<OrganizationResponse> => {
    const GET_ORG = `${SVC_URLS["auth"]}/org/${orgId}`;
    const options = await fetchOptions();
    const response = await fetch(GET_ORG, options).catch(handleErrors);

    return fetchResponse(response);
};
