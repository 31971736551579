import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";

export const useAllStoresReport = () => {
    return useQuery({
        queryKey: ["all-stores-report"],
        queryFn: () => fetchReports(),
        refetchOnWindowFocus: false,
    });
};

const fetchReports = async () => {
    let environment;
    if (process.env.REACT_APP_STACK === "prd") {
        environment = "com";
    } else if (process.env.REACT_APP_STACK === "stg") {
        environment = "red";
    } else {
        environment = "dev";
    }

    const endpoint = `${SVC_URLS["portal"]}/all_stores`;
    const options = await fetchOptions({
        headers: { Store: `speed-preview.${environment}` },
    });
    const response = await fetch(endpoint, options).catch(handleErrors);
    return fetchResponse(response);
};
