import { Box } from "@chakra-ui/react";

export const Header = (props: any) => {
    props = {
        as: props.as ? props.as : "h2", // default to h2
        textStyle: {
            base: "text-header-S",
            md: "text-header-XL",
            lg: "text-header-XL",
            xl: "text-header-XL",
            "2xl": "text-header-2XL",
            xxl: "text-header3XL-",
        },
        ...props,
    };

    return <Box {...props} />;
};
