import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const SingleAppPageResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify Apps"}
                menuItem1Link={ROUTES.DOCUMENTATION_INSTALLED_APPS}
                menuItem2={"Single Application Page"}
            />
            <Header pb={"spacer-8"}>Single Application Page</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This page displays detailed information about the individual Shopify app. Users can see the app's
                developer, description, and main features, as well as provide input on whether the app is supported
                by&nbsp;
                {BRAND_NAME} and any relevant notes.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/9cc47125-6bbd-464c-9008-2c8867dd6386/user_cropped_screenshot.jpeg?tl_px=0,21&br_px=2117,1302&force_format=png&width=1120.0"
                alt="Single app page"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Supported" checkbox and the notes field for any specific details we need about the app can be
                updated as needed by selecting the indicated button below.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/5070b75f-9b4e-499e-afd3-d6d41a719ddd/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2084,1267&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=974,615"
                alt="Single app page"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Below this section, users will find detailed information about which {BRAND_NAME} customers are using
                the app. Our database currently tracks three categories:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Active customers who have the app installed</ListItem>
                <ListItem pb={"spacer-4"}>
                    Inactive customers who had the app installed at one point, but we no longer know their status
                </ListItem>
                <ListItem pb={"spacer-6"}>Active customers who uninstalled the app</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Clicking on an individual store URL will bring the user to that customer's Edit Store page within the
                portal.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/e11cf53a-6a26-4043-b424-9780980e656a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2088,1470&force_format=png&width=1120.0"
                alt="Installed apps with app title clicked"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                One additional note is that the name of the application links to that application's Shopify App Store
                page.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/17a75a33-f5aa-4365-96a7-d79af3417503/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2117,1281&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=134,11"
                alt="Linked app title"
            />
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/ed9fd3fc-8f37-4cec-b905-892e372ff713/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2588,1250&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=160,346"
                alt="Shopify app store page"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
