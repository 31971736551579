import {
    ComposedChart,
    Line,
    Legend,
    ReferenceArea,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
} from "recharts";
import { useMediaQuery } from "@chakra-ui/react";
import { CrUXReportType } from "../../../../types";
import { dateFormatter, labelDateFormatter, closestDate, colors } from "./utils";

type PercentilesReportProps = {
    report: CrUXReportType;
};

export const PercentilesReport = ({ report }: PercentilesReportProps) => {
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const good = report.percentileThresholds?.good;
    const improve = report.percentileThresholds?.improve;
    const max = +(report.percentileThresholds?.max + report.percentileThresholds?.max * 0.25).toFixed(2);

    return (
        <div style={{ height: "300px", maxWidth: "500px", width: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={report.data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="firstDate" interval={isMobile ? 8 : 4} tickFormatter={dateFormatter} />
                    <YAxis type="number" domain={[0, Math.max(max, improve)]} />
                    <Legend />
                    <Tooltip labelFormatter={labelDateFormatter} />
                    <ReferenceArea
                        isAnimationActive={false}
                        type="monotone"
                        stroke={colors.green}
                        fill={colors.green}
                        x1={report.data[0].firstDate}
                        x2={report.data[report.data.length - 1].firstDate}
                        y1={0}
                        y2={good}
                    />
                    <ReferenceArea
                        isAnimationActive={false}
                        type="monotone"
                        stroke={colors.yellow}
                        fill={colors.yellow}
                        x1={report.data[0].firstDate}
                        x2={report.data[report.data.length - 1].firstDate}
                        y1={good}
                        y2={Math.max(improve, max)}
                    />
                    {max > improve && (
                        <ReferenceArea
                            isAnimationActive={false}
                            type="monotone"
                            stroke={colors.red}
                            fill={colors.red}
                            x1={report.data[0].firstDate}
                            x2={report.data[report.data.length - 1].firstDate}
                            y1={improve}
                            y2={max}
                        />
                    )}
                    {report?.edgeEnabledAt && (
                        <ReferenceLine
                            stroke="#000"
                            x={closestDate(report.edgeEnabledAt, report)}
                            isFront={true}
                            strokeWidth={1}
                        />
                    )}
                    <Line isAnimationActive={false} type="monotone" dataKey="p75" stroke="#333333" dot={false} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
