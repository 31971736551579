import { Box, Text, useToast } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { DataTable } from "../../components";
import { Store, Webhooks } from "../../types";
import { useListWebhooks } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { LoadingPage } from "../LoadingPage";
import { formatDateTimeDisplay } from "../../lib";

type Props = {
    store: Store;
};

export const ListWebhookTable = ({ store }: Props) => {
    const { data: listWebhooks, isLoading, isError } = useListWebhooks(store.id, store.customerUrl);
    const showToast = useToast({ duration: 5000, isClosable: true, variant: "subtle" });
    const columns = useMemo(buildColumns, []);

    useEffect(function handleError() {
        if (isError) {
            showToast({
                title: "Error: Unable to load webhooks",
                description: "An unexpected error has occurred. If the error persists, please contact support.",
                status: "error",
            });
        }
    });

    if (isLoading) return <LoadingPage />;

    return (
        <Box pt={"spacer-6"} width={"100%"}>
            <Text pb={"spacer-4"}>
                Webhooks are used to automatically clear the cache when relevant changes or updates are made to the
                store.
            </Text>
            {listWebhooks === undefined || listWebhooks.length === 0 ? (
                <Text pt={"spacer-2"} textStyle={"text-body-bold"}>
                    No Webhooks have been registered for this store.
                </Text>
            ) : (
                <DataTable columns={columns} data={listWebhooks} />
            )}
        </Box>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<Webhooks>();
    const columns = [
        columnHelper.accessor("topic", {
            cell: (props) => props.getValue(),
            header: "Registered Topic",
        }),
        columnHelper.accessor("created_at", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Created At",
        }),
    ];

    return columns;
};
