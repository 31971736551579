import { EntriConfig } from "./types";

const ENTRI_APPLICATION_ID = process.env.REACT_APP_ENTRI_APPLICATION_ID;
if (ENTRI_APPLICATION_ID === undefined) throw new Error("Missing ENTRI_APPLICATION_ID environment variable.");
const UPDATE_ME = "";

export const defaultEntriConfig: EntriConfig = {
    applicationId: ENTRI_APPLICATION_ID,
    token: UPDATE_ME,
    // prefilledDomain: '', // Uncomment and set to store.customerUrl to prefill the domain field once our Entri account supports this feature
    userId: UPDATE_ME,
    hostRequired: false,
    dnsRecords: {
        domain: [
            {
                type: "TXT",
                host: "cloudflare-verify",
                value: UPDATE_ME,
                ttl: 300,
            },
        ],
        subDomain: [
            {
                type: "TXT",
                host: "cloudflare-verify.{SUBDOMAIN}",
                value: UPDATE_ME,
                ttl: 300,
            },
        ],
    },
};
