import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { FavoriteCachePathsResponse } from "../../types";

export const useListFavoriteCachePaths = (customerUrl: string) => {
    return useQuery(["favoriteCachePaths", customerUrl], async () => await listFavoriteCachePaths(customerUrl));
};

const listFavoriteCachePaths = async (customerUrl: string): Promise<FavoriteCachePathsResponse> => {
    const listFavoritePaths = `${SVC_URLS["portal"]}/cache/favorites`;
    const options = await fetchOptions({
        headers: { Store: customerUrl },
    });
    const response = await fetch(listFavoritePaths, options).catch(handleErrors);
    return fetchResponse(response);
};
