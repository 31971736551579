import {
    Button,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spacer,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FieldError, Header, Label, FieldSet, AlertMessage } from "../../components";
import { ModalButton } from "../../components/ModalButton";
import {
    useEnqueueDeploymentMutation,
    useRegisterWebhooksMutation,
    generateStoreQueryKey,
    useStoreMutation,
    useDeleteWebhooksMutation,
    generateListWebhooksQueryKey,
} from "../../hooks";
import { Store, UpdateStoreFormData, UpdateStoreRequest } from "../../types";
import { PageContainer } from "../PageContainer";
import { ListWebhookTable } from "./ListWebhookTable";
import { ShopifyAppsList } from "./ShopifyAppsList";

type ShowFieldsState = {
    [fieldName: string]: boolean;
};

export const ShopifyTab = ({ store }: Props) => {
    const registerWebhooksMutation = useRegisterWebhooksMutation(store.customerUrl, store.id);
    const deleteWebhooksMutation = useDeleteWebhooksMutation(store.id, store.customerUrl);
    const [showFields, setShowFields] = useState<ShowFieldsState>({});
    const { onClose, isOpen, onOpen } = useDisclosure();
    const queryClient = useQueryClient();
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const confirmation = {
        title: "Delete Webhooks",
        description: "Are you sure you want to delete all webhooks for this store?",
    };

    const toggleFieldVisibility = (fieldName: string) => {
        setShowFields((prevShowFields: ShowFieldsState) => ({
            ...prevShowFields,
            [fieldName]: !prevShowFields[fieldName],
        }));
    };
    const visibilityButtonLabelFor = (fieldName: string) => (showFields[fieldName] ? "Hide" : "Show");
    const fieldTyperFor = (fieldName: string) => (showFields[fieldName] ? "text" : "password");

    const defaultValues: UpdateStoreFormData = buildDefaultValues(store);

    const onDelete = (storeId: string) => {
        if (!storeId) return;
        deleteWebhooksMutation.mutate(storeId);
    };

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<UpdateStoreFormData>({
        defaultValues: defaultValues,
    });

    const { onSubmit, isLoading } = useShopifySubmit(store);

    const registerWebhooks = () => {
        const onWebhookSuccess = () => {
            queryClient.invalidateQueries(generateListWebhooksQueryKey(store.id));
            showToast({
                title: "Success",
                status: "success",
                description: "Webhooks successfully registered with Shopify.",
            });
        };

        const onWebhookError = () => {
            showToast({
                title: "Error: Webhook Registration Failed",
                description: "An unexpected error has occurred. If the error persists, please contact support.",
                status: "error",
            });
        };

        registerWebhooksMutation.mutate(void 0, {
            onSuccess: onWebhookSuccess,
            onError: onWebhookError,
        });
    };

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxWidth={"1440px"}>
            <VStack align={"left"}>
                <form onSubmit={handleSubmit(onSubmit)} id="shopifyTab">
                    <FieldSet legend="Shopify Integration">
                        <Label htmlFor="shopifyAuthToken">Admin API access token</Label>
                        <InputGroup size="md" pb={"spacer-4"}>
                            <Input
                                pr="4.5rem"
                                placeholder=""
                                type={fieldTyperFor("shopifyAuthToken")}
                                id="shopifyAuthToken"
                                {...register("shopifyAuthToken")}
                                defaultValue={store.shopifyAuthToken}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => toggleFieldVisibility("shopifyAuthToken")}>
                                    {visibilityButtonLabelFor("shopifyAuthToken")}
                                </Button>
                            </InputRightElement>
                            <FieldError>{errors.shopifyAuthToken?.message}</FieldError>
                        </InputGroup>

                        <Label htmlFor="shopifyStorefrontToken">Storefront API access token </Label>
                        <InputGroup size="md" pb={"spacer-4"}>
                            <Input
                                placeholder=""
                                type={fieldTyperFor("shopifyStorefrontToken")}
                                id="shopifyStorefrontToken"
                                {...register("shopifyStorefrontToken")}
                                defaultValue={store.shopifyStorefrontToken}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() => toggleFieldVisibility("shopifyStorefrontToken")}
                                >
                                    {visibilityButtonLabelFor("shopifyStorefrontToken")}
                                </Button>
                            </InputRightElement>
                            <FieldError>{errors.shopifyStorefrontToken?.message}</FieldError>
                        </InputGroup>

                        <Label htmlFor="shopifyApiKey">API Key</Label>
                        <InputGroup size="md" pb={"spacer-4"}>
                            <Input
                                placeholder=""
                                type={fieldTyperFor("shopifyApiKey")}
                                id="shopifyApiKey"
                                {...register("shopifyApiKey")}
                                defaultValue={store.shopifyApiKey}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => toggleFieldVisibility("shopifyApiKey")}>
                                    {visibilityButtonLabelFor("shopifyApiKey")}
                                </Button>
                            </InputRightElement>
                            <FieldError>{errors.shopifyApiKey?.message}</FieldError>
                        </InputGroup>

                        <Label htmlFor="shopifySecretKey">API secret key</Label>
                        <InputGroup size="md" pb={"spacer-4"}>
                            <Input
                                placeholder=""
                                type={fieldTyperFor("shopifySecretKey")}
                                id="shopifySecretKey"
                                {...register("shopifySecretKey")}
                                defaultValue={store.shopifySecretKey}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => toggleFieldVisibility("shopifySecretKey")}>
                                    {visibilityButtonLabelFor("shopifySecretKey")}
                                </Button>
                            </InputRightElement>
                            <FieldError>{errors.shopifySecretKey?.message}</FieldError>
                        </InputGroup>
                    </FieldSet>
                    <Flex pb="spacer-4">
                        <Button isLoading={isLoading} type="submit" colorScheme={"button-primary"} ml="auto">
                            Submit
                        </Button>
                    </Flex>
                    <Header pt={"spacer-4"} as="h3" textStyle="text-header-M">
                        Webhooks
                    </Header>
                </form>
            </VStack>
            {!store.shopifyAuthToken ? (
                <>
                    <Text pt={"spacer-6"} pb={"spacer-4"}>
                        Webhooks are used to automatically clear the cache when relevant changes or updates are made to
                        the store.
                    </Text>
                    <AlertMessage status="warning">
                        To register Webhooks, enter and submit the Shopify Auth token{" "}
                        <strong>(Admin API access token)</strong> for this store.
                    </AlertMessage>
                </>
            ) : (
                <ListWebhookTable store={store} />
            )}
            <HStack pt={"spacer-4"}>
                <Spacer />
                <ModalButton
                    isLoading={deleteWebhooksMutation.isLoading}
                    onClick={onOpen}
                    isOpen={isOpen}
                    onClose={onClose}
                    confirmation={confirmation}
                    onAction={() => onDelete(store.id)}
                >
                    Delete
                </ModalButton>
                <Button
                    isLoading={registerWebhooksMutation.isLoading}
                    isDisabled={!store.shopifyAuthToken}
                    onClick={registerWebhooks}
                    colorScheme={"button-primary"}
                >
                    Register
                </Button>
            </HStack>
            <ShopifyAppsList storeId={store.id} customerUrl={store.customerUrl}></ShopifyAppsList>
        </PageContainer>
    );
};

const useShopifySubmit = (store: Store) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const storeMutation = useStoreMutation(store.id, store.customerUrl);
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });
    const queryClient = useQueryClient();
    const isLoading = storeMutation.isLoading;
    const onSubmit = (formData: UpdateStoreFormData) => {
        const requestBody: UpdateStoreRequest = {
            shopifyApiKey: formData.shopifyApiKey,
            shopifyAuthToken: formData.shopifyAuthToken,
            shopifySecretKey: formData.shopifySecretKey,
            shopifyStorefrontToken: formData.shopifyStorefrontToken,
        };

        storeMutation.mutate(requestBody, {
            onSuccess: () => {
                enqueueDeployment.mutate(store.customerUrl!);
                queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                showToast({
                    title: "Success",
                    description: `Shopify settings updated successfully.`,
                    status: "success",
                });
            },
            onError: (error) => {
                console.error(error);
                showToast({
                    title: "Error",
                    description: "There was an error updating shopify settings. Try again in a few moments.",
                    status: "error",
                });
            },
        });
    };

    return {
        onSubmit,
        isLoading,
    };
};

const buildDefaultValues = (store: Store) => ({
    shopifyApiKey: store.shopifyApiKey === null ? "" : store.shopifyApiKey,
    shopifyAuthToken: store.shopifyAuthToken === null ? "" : store.shopifyAuthToken,
    shopifySecretKey: store.shopifySecretKey === null ? "" : store.shopifySecretKey,
    shopifyStorefrontToken: store.shopifyStorefrontToken === null ? "" : store.shopifyStorefrontToken,
});

type Props = {
    store: Store;
};
