import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useMediaQuery } from "@chakra-ui/react";
import { CrUXReportType } from "../../../../types";
import { dateFormatter, labelDateFormatter, percentFormatter, colors } from "./utils";

type FractionReportProps = {
    report: CrUXReportType;
};

export const FractionReport = ({ report }: FractionReportProps) => {
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const series = Object.keys(report.data[0]).filter((key) => key !== "firstDate" && key !== "lastDate");

    return (
        <div style={{ height: "300px", maxWidth: "500px", width: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={report.data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="firstDate" interval={isMobile ? 8 : 4} tickFormatter={dateFormatter} />
                    <YAxis tickFormatter={percentFormatter} domain={[0, "dataMax"]} />
                    <Tooltip formatter={percentFormatter} labelFormatter={labelDateFormatter} />
                    <Legend />
                    {series.map((key, index) => (
                        <Bar
                            isAnimationActive={false}
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={Object.values(colors)[index]}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
