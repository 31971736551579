import { Text, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { generatePath } from "react-router-dom";
import { Link } from "../../components";
import { ROUTES } from "../../Routes";

type Props = {
    menuItem1: string;
    menuItem1Link: string;
    menuItem2: string;
};

export const DocsBreadcrumbComponent = ({ menuItem1, menuItem1Link, menuItem2 }: Props) => {
    return (
        <HStack pb={4} display={{ base: "none", md: "block" }}>
            <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={generatePath(ROUTES.HOME)}>
                        Home
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={generatePath(ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL)}>
                        Documentation
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={menuItem1Link}>
                        {menuItem1}
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage isLastChild>
                    <Text>{menuItem2}</Text>
                </BreadcrumbItem>
            </Breadcrumb>
        </HStack>
    );
};
