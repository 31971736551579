import { useQuery } from "@tanstack/react-query";
import { EntriCheckDomainResponse, EntriConfig } from "../../components";

type DomainSupportResponse = EntriCheckDomainResponse & { showEntriConnect: boolean };

export const useEntriCheckDomain = (customerUrl: string, entriConfig: EntriConfig) => {
    return useQuery(
        generateEntriCheckDomainQueryKey(customerUrl, JSON.stringify(entriConfig)),
        async () => await checkDomainWrapper(customerUrl, entriConfig),
        {
            select: (response: EntriCheckDomainResponse): DomainSupportResponse => ({
                ...response,
                showEntriConnect: showEntriConnect(response),
            }),
        },
    );
};

const showEntriConnect = (response: EntriCheckDomainResponse): boolean => {
    const { provider, NSSupport } = response;
    const isCloudFlare = provider === "Cloudflare";
    const isRootDomain = NSSupport.root;

    return !(isCloudFlare && isRootDomain);
};

const checkDomainWrapper = async (customerUrl: string, entriConfig: EntriConfig): Promise<EntriCheckDomainResponse> => {
    return await window.entri.checkDomain(customerUrl, entriConfig);
};

export const generateEntriCheckDomainQueryKey = (customerUrl = "", entriConfig = ""): string[] => [
    "entriCheckDomain",
    customerUrl,
    entriConfig,
];
