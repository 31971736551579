import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { useAuthenticatedUser } from "../../../hooks";
import { NotFound404 } from "../../ErrorPages";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ManageAllOrganizationsResource = () => {
    const { isCloudOpsRole } = useAuthenticatedUser();

    return (
        <>
            {isCloudOpsRole ? (
                <PageContainer>
                    <DocsBreadcrumbComponent
                        menuItem1={"Organization"}
                        menuItem1Link={ROUTES.DOCUMENTATION_MANAGE_USERS}
                        menuItem2={"Manage Organizations"}
                    />
                    <Header pb={"spacer-8"}>Manage Organizations</Header>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        The <strong>Manage Organizations</strong> page allows CloudOps users to view every registered
                        organization associated with their primary organization simultaneously.
                    </Text>
                    <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="create-users">
                        Manage An Organization
                    </SubHeader>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        Each individual organization includes its name, onboarding email, onboarding date and time, and
                        the option to manage or delete that organization.
                    </Text>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        Selecting "manage" will open that group's Manage Organization page, allowing the CloudOps user
                        to see its associated users and stores tabs.
                    </Text>
                    <DocumentationImage
                        src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-05/f967b1ea-1c85-4501-bc47-7d6ec81b119c/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2151,1100&force_format=png&width=1120.0"
                        alt="Manage All Organizations page"
                        loading="eager"
                    />
                    <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="create-users">
                        Delete An Organization
                    </SubHeader>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        Selecting “delete” will allow the CloudOps user to delete the entire organization. Deleting an
                        organization is only possible assuming:
                    </Text>
                    <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                        <ListItem pb={"spacer-4"}>All the organization's registered stores have been deleted.</ListItem>
                        <ListItem pb={"spacer-6"}>The organization does not belong to {BRAND_NAME}.</ListItem>
                    </UnorderedList>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        Be aware that deleting an organization will also delete all the users in that organization's
                        users list.
                    </Text>
                    <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
                </PageContainer>
            ) : (
                <NotFound404 />
            )}
        </>
    );
};
