import { Input, Box } from "@chakra-ui/react";
import {
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    FieldErrors,
    UseFormWatch,
    Path,
    PathValue,
} from "react-hook-form";
import { isValidJSON } from "../../../lib";
import { FieldError } from "./FieldError";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";

type UseFormSettings<TFieldValues extends FieldValues> = {
    register: UseFormRegister<TFieldValues>;
    setValue: UseFormSetValue<TFieldValues>;
    errors: FieldErrors<TFieldValues>;
    watch: UseFormWatch<TFieldValues>;
};

type InputJsonProps<TFieldValues extends FieldValues> = {
    name: Path<TFieldValues>;
    placeholder?: string | undefined;
    formSettings: UseFormSettings<TFieldValues>;
};

export const InputJson = <TFieldValues extends FieldValues>({
    name,
    placeholder = "",
    formSettings: { register, setValue, errors, watch },
}: InputJsonProps<TFieldValues>) => {
    const currentWatchedValue = watch(name);

    const errorMessage: string = typeof errors[name]?.message === "string" ? (errors[name]?.message as string) : "";

    return (
        <>
            <Box border="1px solid" borderColor={"gray.200"}>
                <CodeMirror
                    value={currentWatchedValue}
                    placeholder={placeholder}
                    onChange={(changedValue: PathValue<TFieldValues, Path<TFieldValues>>, _viewUpdate) =>
                        setValue(name, changedValue)
                    }
                    extensions={[json()]}
                />
                <Input
                    type="hidden"
                    {...register(name, {
                        validate: isValidJSON,
                    })}
                />
            </Box>
            <FieldError>{errorMessage}</FieldError>
        </>
    );
};
