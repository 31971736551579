export const toJSON = (value: string | undefined, options: Options | undefined) => {
    const defaultOptions = {
        nullValue: [],
        onError: (e: string) => {
            console.error(e);
        },
    };

    const { nullValue, onError } = { ...defaultOptions, ...options };

    if (value === undefined) return nullValue;
    if (value === "") return nullValue;
    try {
        return JSON.parse(value);
    } catch (e: unknown) {
        if (e instanceof Error) {
            console.error(e.message);
            onError && onError(e.message);
        } else if (typeof e === "string") {
            console.error(e);
            onError && onError(e);
        } else {
            console.error(e);
            onError && onError("An error occurred parsing JSON");
        }

        return void 0;
    }
};

type Options = {
    nullValue?: [] | {} | undefined | "";
    onError?: (e: string) => void;
};
