import { Button, useToast, useDisclosure } from "@chakra-ui/react";
import { focusManager, useQueryClient } from "@tanstack/react-query";
import {
    useEnqueueDeploymentMutation,
    generateExperimentQueryKey,
    useToggleExperimentActivationMutation,
} from "../hooks";
import { BRAND_NAME } from "../config/BRAND_NAME";
import { Experiment } from "../types";
import { useNavigate, generatePath } from "react-router-dom";
import { ROUTES } from "../Routes";
import { ToggleExperimentModal } from "./ToggleExperimentModal";

export const ToggleExperimentButton: React.FC<ToggleExperimentButtonProps> = ({
    experiment,
    customerUrl,
    edgeEnabled,
}) => {
    const queryClient = useQueryClient();
    const showToast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const enqueueDeployment = useEnqueueDeploymentMutation(
        {
            onDeploymentSuccess: () => {
                showToast({
                    title: "Success",
                    description: `"${experiment.name}" is now ${experiment.active ? "disabled" : "active"}!`,
                    status: "success",
                });
                focusManager.setFocused(undefined); // https://github.com/TanStack/query/issues/2960
                queryClient.invalidateQueries(generateExperimentQueryKey(experiment.id));
                queryClient.invalidateQueries(["store", customerUrl]);
            },
        },
        customerUrl,
    );
    const experimentActive = useToggleExperimentActivationMutation();
    const isLoading = experimentActive.isLoading || enqueueDeployment.isLoading || enqueueDeployment.isPolling;

    const toggleExperiment = () => {
        focusManager.setFocused(false); // https://github.com/TanStack/query/issues/2960

        if (!edgeEnabled) {
            showToast({
                icon: "⚠️",
                title: `WARNING: ${BRAND_NAME} services are paused.`,
                description: `Changing the status of your experiment will not affect production until ${BRAND_NAME} services are no longer paused.`,
                status: "warning",
                duration: 14000,
                isClosable: true,
            });
        }

        experimentActive.mutate(
            { experimentId: experiment.id, customerUrl: customerUrl },
            {
                onSuccess: (response) => {
                    enqueueDeployment.mutate(customerUrl);
                    if (response.experimentId !== experiment.id) {
                        navigate(
                            generatePath(ROUTES.MANAGE_EXPERIMENT, {
                                customerUrl,
                                experimentId: response.experimentId,
                            }),
                        );
                    }
                },
                onError: () => console.log("error"),
            },
        );

        return void 0;
    };

    return (
        <>
            <Button
                isLoading={isLoading}
                loadingText={experiment.active ? "Deactivating" : "Activating"}
                {...(experiment.active ? { colorScheme: "red" } : { colorScheme: "button-primary" })}
                size="sm"
                onClick={experiment.active ? onOpen : toggleExperiment}
            >
                {experiment.active ? "Disable" : "Activate"}
            </Button>
            <div style={{ display: "none" }}>
                <ToggleExperimentModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onAction={toggleExperiment}
                ></ToggleExperimentModal>
            </div>
        </>
    );
};

type ToggleExperimentButtonProps = {
    experiment: Experiment;
    customerUrl: string;
    edgeEnabled: boolean;
};
