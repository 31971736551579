import { VStack, Text, HStack, Spacer, Tabs, TabList, TabPanels, Tab, Badge, Alert, AlertIcon } from "@chakra-ui/react";
import { useAuthenticatedUser, useStore } from "../../hooks";
import { PageContainer } from "../PageContainer";
import { Header, SubHeader, StoreSwitchHeader } from "../../components";
import { LoadingPage } from "../LoadingPage";
import { useNavigate, Outlet, useLocation, generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { useEffect, useMemo, useState } from "react";
import { TabPaths } from "../../types";

export const ManageCachePage = () => {
    const { data: store } = useStore();
    const { isAdminRole } = useAuthenticatedUser();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams<Record<string, string>>();
    const customerUrl = params.customerUrl ? params.customerUrl : store?.customerUrl;
    const [currentTab, setCurrentTab] = useState("");
    const [tabPathsLoaded, setTabPathsLoaded] = useState(false);

    const tabPaths: TabPaths = useMemo(() => {
        let initialTabPaths: TabPaths = {};
        if (!isAdminRole) {
            initialTabPaths = {
                "Clear Cache": "clear-cache",
                "Cache History": "cache-history",
                "Cache Settings": "cache-settings",
            };
        } else if (isAdminRole) {
            initialTabPaths = {
                "Clear Cache": "clear-cache",
                "Cache History": "cache-history",
            };
        }
        setTabPathsLoaded(true);
        return initialTabPaths;
    }, [isAdminRole]);

    const globalCacheVersion = useMemo(() => {
        const globalVersion = store && store.cacheDurationMap.__global__;
        return globalVersion ? globalVersion : "";
    }, [store]);

    useEffect(() => {
        if (tabPathsLoaded) {
            const path = location.pathname.split("/").pop();
            const matchingKey = Object.keys(tabPaths).find((key) => tabPaths[key] === path);
            const initialTab = matchingKey ? tabPaths[matchingKey] : Object.keys(tabPaths)[0];
            const basePath = generatePath(ROUTES.MANAGE_CACHE_AUTHENTICATED, { customerUrl });
            if (!matchingKey && currentTab === "") {
                setCurrentTab(initialTab);
                navigate(`${basePath}/${tabPaths[initialTab]}`, { replace: true });
            }
            if (matchingKey) {
                setCurrentTab(matchingKey);
            }
        }
    }, [location.pathname, tabPaths, navigate, customerUrl, tabPathsLoaded, currentTab]);

    const handleTabChange = (index: string) => {
        const basePath = generatePath(ROUTES.MANAGE_CACHE_AUTHENTICATED, { customerUrl });
        const path = tabPaths[index];
        navigate(basePath + "/" + path);
    };

    /**
     * Render loading state
     */
    if (store === undefined) return <LoadingPage />;

    return (
        <PageContainer>
            <HStack align={"baseline"}>
                <VStack alignItems="baseline" width={"100%"}>
                    <HStack justifyContent={"space-between"} width={"100%"}>
                        <Header>Manage Cache</Header>
                        <StoreSwitchHeader orgId={store.orgId} currentStore={store.customerUrl} />
                    </HStack>
                    {!store.edgeEnabled ? (
                        <>
                            <Spacer />
                            <Badge colorScheme={"orange"}>Edge Delivery Engine: OFF</Badge>
                        </>
                    ) : (
                        <Text
                            display={{ base: "none", lg: "block" }}
                            textStyle={"text-link-meta-regular"}
                            color="text-secondary"
                            textDecoration={"none"}
                            pt="spacer-3"
                        >
                            Cache Version: {globalCacheVersion}
                        </Text>
                    )}
                    <Tabs
                        isLazy
                        width="100%"
                        index={Object.keys(tabPaths).indexOf(currentTab)}
                        onChange={(index) => handleTabChange(Object.keys(tabPaths)[index])}
                    >
                        <TabList alignItems={"baseline"}>
                            <SubHeader pb={0} mr="auto" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                                <span className="trackCustomerUrl">{store.customerUrl}</span>
                            </SubHeader>
                            <Spacer />
                            {Object.keys(tabPaths).map((tabName) => {
                                return <Tab key={tabName}>{tabName}</Tab>;
                            })}
                        </TabList>
                        <Alert status="warning" mt={"spacer-6"}>
                            <AlertIcon />
                            <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                                Hello, we have been notified that Cache Clear functionality is temporarily disabled by
                                Cloudflare. We are actively working with them to provide a solution. If you are looking
                                to deploy any new changes to your website now, please notify{" "}
                                <a href="mailto: support@nostra.ai">support@nostra.ai</a> immediately for us to directly
                                disable Nostra from your system to fully refresh your website. We completely apologize
                                for this difficulty and will notify you when we are able to re-enable Nostra once
                                Cloudflare resolves this issue. Thank you so much, The Nostra Team
                            </Text>
                        </Alert>
                        <TabPanels>
                            <Outlet />
                        </TabPanels>
                    </Tabs>
                </VStack>
            </HStack>
        </PageContainer>
    );
};
