import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box } from "@chakra-ui/react";
import { Header, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const WhenToContactNostraPage = () => {
    return (
        <>
            <PageContainer>
                <DocsBreadcrumbComponent
                    menuItem1={"Getting Started"}
                    menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                    menuItem2={`When To Contact ${BRAND_NAME} With Updates`}
                />{" "}
                <Header pb={"spacer-8"}>When To Contact {BRAND_NAME} With Updates</Header>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    Your store's optimization is of the utmost importance. As such, our engineers configure each new
                    site to work seamlessly with {BRAND_NAME}'s products during onboarding and may need to adjust your
                    configuration as you make major changes.
                </Text>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    The following information will guide you through when the {BRAND_NAME} team needs to get involved
                    versus when you can manually clear your cache.{" "}
                </Text>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    <b>Please contact your CSM team before:</b>
                </Text>
                <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                    <ListItem pb={"spacer-4"}>
                        You change any dynamic elements of your theme, such as forms, shopping carts, checkout pages,
                        advertisements, product recommendations, and personalized greetings/messages.
                    </ListItem>
                    <ListItem pb={"spacer-4"}>
                        You change any elements that will affect the flow of a slide-out cart.
                    </ListItem>
                    <ListItem pb={"spacer-4"}>You add a personalized app to your store.</ListItem>
                    <ListItem pb={"spacer-4"}>
                        You change any elements that will change a page when an item is in the cart.
                    </ListItem>
                    <ListItem pb={"spacer-6"}>
                        You change any elements that will add new functions to your cart or significantly modify the
                        functions of your cart.
                    </ListItem>
                </UnorderedList>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    <b>Use your clear cache function when:</b>
                </Text>
                <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                    <ListItem pb={"spacer-4"}>
                        You change any static elements of your theme. Static elements remain fixed and do not change
                        based on a website visitor's interaction.{" "}
                    </ListItem>
                    <ListItem pb={"spacer-4"}>You change any cosmetic features of your cart.</ListItem>
                    <ListItem pb={"spacer-6"}>You make theme revisions within a duplication of your theme.</ListItem>
                </UnorderedList>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    As a reminder, {BRAND_NAME}'s webhooks will update your cache in real time whenever you make any
                    inventory changes and will include any pages affected by those changes.{" "}
                </Text>
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    If you need additional information, visit the{" "}
                    <Link textDecoration="underline" to={`${ROUTES.DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT}`}>
                        Static Vs. Dynamic Content user guide
                    </Link>{" "}
                    or the{" "}
                    <Link textDecoration="underline" to={`${ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}`}>
                        When And Why To Clear Your Cache user guide
                    </Link>
                    .{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        Contact support
                    </Link>{" "}
                    if you have any other questions or concerns.
                </Text>
                <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
            </PageContainer>
        </>
    );
};
