import { Viewport } from "../../../types";

export const viewports: Viewport[] = [
    {
        name: "Desktop",
        width: "100%",
        height: "100%",
    },
    {
        name: "Mobile",
        width: "480px",
        height: "800px",
    },
    {
        name: "iPhone SE",
        width: "375px",
        height: "667px",
    },
    {
        name: "Pixel 7",
        width: "412px",
        height: "732px",
    },
    {
        name: "iPhone 14",
        width: "390px",
        height: "844px",
    },
    {
        name: "iPad Air",
        width: "768px",
        height: "1024px",
    },
    {
        name: "Galaxy S22 ultra",
        width: "384px",
        height: "824px",
    },
];
