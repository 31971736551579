import { Alert, AlertDescription, AlertIcon, forwardRef } from "@chakra-ui/react";

export const AlertMessage = forwardRef(({ children, ...rest }, ref) => {
    return (
        <Alert ref={ref} {...rest}>
            <AlertIcon />
            <AlertDescription width="100%">{children}</AlertDescription>
        </Alert>
    );
});
