import { PageContainer } from "../../PageContainer";
import { Text, Box, ListItem, Alert, AlertIcon, ListIcon, List } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";
import { ChevronRightIcon } from "@chakra-ui/icons";

export const RoiOverview = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={"ROI Overview"}
            />
            <Header pb={"spacer-8"}>ROI Overview</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} prides itself on optimization and efficiency, so our team understands the importance of
                tracking your return on investment.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our bread and butter is taking site performance to the next level, but does site speed really matter?
                You don't have to take our word for it: The data-backed conclusion is a resounding yes.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Prioritizing speed has proven to lead to{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.conductor.com/academy/page-speed-resources/faq/amazon-page-speed-study/"}
                        target="new"
                    >
                        more revenue
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={
                            "https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/"
                        }
                        target="new"
                    >
                        fewer bounces
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.shopify.com/blog/site-speed-importance"}
                        target="new"
                    >
                        better conversion rates
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.google.com/search/howsearchworks/how-search-works/ranking-results/"}
                        target="new"
                    >
                        improved search engine page rankings
                    </Link>
                    ,&nbsp;
                    <Link
                        textDecoration="underline"
                        to={"https://www.facebook.com/business/help/423781975167984"}
                        target="new"
                    >
                        lower advertising costs along with higher ad quality
                    </Link>
                    , and more.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                These metric guides will help you ascertain the key measurements you need to assess {BRAND_NAME}'s
                eCommerce Edge Delivery Platform. Many factors influence your business' success, so it is important to
                note that not all metrics will apply to every site.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Studies around the benefits of speed show that a site needs SEO-standardized content and a strong
                technical foundation already established to receive the advantages of speed optimizations.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In other words,{" "}
                <strong>
                    the effectiveness of these metrics depends greatly on your individual site and its current state of
                    performance
                </strong>
                . Therefore, it's crucial to thoroughly analyze and understand your situation to make the most of these
                insights. Let's explore the metrics you should investigate further.
            </Text>
            <List textStyle={"text-body-regular"} pl={"spacer-6"}>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_TRADITIONAL}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Traditional Metrics
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_KEY_REVENUE}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Key Revenue Metric
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_CUSTOMER_JOURNEY}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Customer Journey Metrics
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_WEB_VITALS}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Web Vitals
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_SEARCH_ENGINE_RANKING}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Search Engine Page Ranking
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_ADVERTISING}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Advertising Metrics
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_CRAWLER_OPTIMIZATION}>
                    <ListItem pb={"spacer-8"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>
                        {SECONDARY_PRODUCT} Metrics
                    </ListItem>
                </Link>
            </List>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Note On Shopify Session Counts
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You may notice that your number of sessions, as reported by Shopify, reduces when you turn on{" "}
                {BRAND_NAME}. This is because {BRAND_NAME} is helping filter out a bug where Shopify is counting any bot
                producing RUM data as a real human, meaning these RUM bots are counted as visitors and sessions.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your visitor and session counts will both be reduced and corrected to the real human number if you turn
                on {BRAND_NAME}, and will return to the higher numbers if you turn off {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This does not change any external reporting if your other Shopify reports are correct because this bot
                data does not reflect human customers.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While {BRAND_NAME} has not been informed of a specific timeline, we assume this will be temporary as
                Shopify will likely fix the bug at some point.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
