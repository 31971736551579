import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchResponse, handleErrors, fetchOptions } from "../../lib";

type MutationVariables = {
    variationId: string;
    actionId: string;
    customerUrl: string;
};

export const useDeleteActionMutation = () => useMutation({ mutationFn: deleteAction });

const deleteAction = async ({ variationId, actionId, customerUrl }: MutationVariables) => {
    const url = `${SVC_URLS["experiment"]}/variations/${variationId}/action/${actionId}`;
    const options = await fetchOptions({ method: "DELETE", headers: { Store: customerUrl } });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
