import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { useMediaQuery } from "@chakra-ui/react";
import { CrUXReportType } from "../../../../types";
import { percentFormatter, dateFormatter, labelDateFormatter, closestDate, colors } from "./utils";

type HistogramReportProps = {
    report: CrUXReportType;
};

export const HistogramReport = ({ report }: HistogramReportProps) => {
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    return (
        <>
            <div style={{ height: "300px", maxWidth: "500px", width: "100%" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={report.data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="firstDate" interval={isMobile ? 8 : 4} tickFormatter={dateFormatter} />
                        <YAxis
                            type="number"
                            interval="equidistantPreserveStart"
                            domain={[0, "dataMax"]}
                            tickFormatter={percentFormatter}
                        />
                        <Legend />
                        <Tooltip formatter={percentFormatter} labelFormatter={labelDateFormatter} />
                        <Bar isAnimationActive={false} dataKey="good" stackId="a" fill={colors.green} />
                        <Bar isAnimationActive={false} dataKey="improve" stackId="a" fill={colors.yellow} />
                        <Bar isAnimationActive={false} dataKey="poor" stackId="a" fill={colors.red} />
                        {report?.edgeEnabledAt && (
                            <ReferenceLine
                                stroke="#000"
                                x={closestDate(report.edgeEnabledAt, report)}
                                isFront={true}
                                strokeWidth={1}
                            />
                        )}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};
