import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { generatePendingCacheQueryKey } from "../../hooks";

export const useDeleteRecurringPurgeCacheMutation = (id: string, customerUrl: string, storeId: string) => {
    const queryClient = useQueryClient();
    const showToast = useToast({ duration: 5000, isClosable: true, variant: "subtle" });
    return useMutation(() => deleteRecurringPurgeCacheMutation(id, customerUrl), {
        onSuccess: () => {
            queryClient.invalidateQueries(["recurringCachePurges", id]);
            queryClient.invalidateQueries(generatePendingCacheQueryKey(storeId));
            showToast({
                title: "Success",
                description: "Your recurring clear cache series has been removed from the queue.",
                status: "success",
            });
        },
        onError: () => {
            showToast({
                title: "Error",
                description:
                    "An error occurred while removing your recurring clear cache series from the queue. If the error persists, contact support.",
            });
        },
    });
};

const deleteRecurringPurgeCacheMutation = async (id: string, customerUrl: string): Promise<void> => {
    const deleteRecurringPurgeCache = `${SVC_URLS["cronos"]}/cache/recurring/${id}`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(deleteRecurringPurgeCache, options).catch(handleErrors);
    return fetchResponse(response);
};
