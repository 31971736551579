import { PageContainer } from "./PageContainer";
import { Flex, Spinner } from "@chakra-ui/react";

export const LoadingPage = () => {
    return (
        <PageContainer centerContent>
            <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
                <Spinner color="primary-default" />
            </Flex>
        </PageContainer>
    );
};
