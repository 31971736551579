import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from "@chakra-ui/react";
import { useEditorContext } from "../EditorProvider";
import { HtmlEditor } from "./HtmlEditor";
import { postMessage } from "../lib/postMessage";
import { UPDATE_HTML_CONTENT } from "../lib/events";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export const HtmlEditorModal = ({ isOpen, onClose }: Props) => {
    const { editorContext, dispatch } = useEditorContext();

    const cancelChanges = () => {
        dispatch({ type: "editHtmlCanceled" });
        onClose();
    };

    const previewChanges = () => {
        if (editorContext.selectedElement === undefined) return;
        postMessage(UPDATE_HTML_CONTENT, editorContext.selectedElement.element.current.innerHTML);
        onClose();
    };

    return (
        <Modal isCentered size={"6xl"} isOpen={isOpen} onClose={cancelChanges} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>HTML Editor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HtmlEditor />
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" colorScheme="button-primary" onClick={cancelChanges} mr={3}>
                        Cancel
                    </Button>
                    <Button colorScheme="button-primary" onClick={previewChanges}>
                        Preview
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
