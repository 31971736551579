import { Stack } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { Header } from "../components";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { BRAND_NAME } from "../config/BRAND_NAME";

const url = "https://status.nostra.ai/";

export const StatusPage = () => {
    return (
        <PageContainer>
            <Stack style={{ height: "100%" }}>
                <Header>{BRAND_NAME} Status Page</Header>
                <div>
                    <a href={url} style={{ display: "flex", alignItems: "center" }} target="_blank" rel="noreferrer">
                        Open in new tab <ExternalLinkIcon />
                    </a>
                </div>
                <iframe style={{ width: "100%", aspectRatio: 1, flexGrow: 1 }} src={url} title="Nostra Status Page" />
            </Stack>
        </PageContainer>
    );
};
