import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const TurnNostraOnOffResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Debugging"}
                menuItem1Link={ROUTES.DOCUMENTATION_TURN_OFF_ON}
                menuItem2={`Turn ${BRAND_NAME} On/Off`}
            />
            <Header pb={"spacer-8"}>Turn {BRAND_NAME} On/Off</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                We understand that your business is the top priority. If you experience any issues that require
                immediate troubleshooting, you can turn {BRAND_NAME} off across your entire site anytime by selecting
                the "Edit Store" icon on the <strong>Stores</strong> page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/f0f13a71-d3e0-4f75-9dc1-9f0b5fabd2de/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1294,427&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=64,239"
                alt="Edit store icon"
                loading="eager"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="edit-store-status">
                Edit Store Status
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You will be taken to your store's settings, where you can set {BRAND_NAME} to "Enabled" or "Disabled"
                after completing our onboarding process.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-16/54702507-a1d9-4f49-ab05-95b8d464759e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2293,962&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=97,169"
                alt="Update store status"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You will be asked to include why you turn {BRAND_NAME} on or off. You will see your response logged in
                the page's <strong>History</strong> section, along with the date and time of {BRAND_NAME}'s
                enabling/disabling and which user made the change. This log is intended to help you track updates to
                your site.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you choose to disable {BRAND_NAME}, our services will be turned off until you are comfortable
                switching them back on.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-16/ad1f9eb6-fc80-4815-800a-164e8e9bd845/user_cropped_screenshot.jpeg?tl_px=10,0&br_px=2304,995&force_format=png&width=1120.0"
                alt="Store status history"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="edit-store-status">
                Testing Mode
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Please note that if you are in "Testing" mode, our engineers are currently working to configure your
                store with {BRAND_NAME} and make sure you have the best results possible. You will not have access to
                the "Enable" or "Disable" options until our team has completed testing.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our engineers will record the reason they are testing your store, which you can see in the{" "}
                <strong>History</strong> section of the page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-17/febaa87a-256d-43c5-84d1-a647e068dab2/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2148,1052&force_format=png&width=1120.0"
                alt="Testing mode"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="edit-store-status">
                Disabled By Provider
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If your site has been "Disabled By Provider," it means {BRAND_NAME} or a qualified service partner has
                turned off {BRAND_NAME}'s services for your site. You will not be able to enable or disable {BRAND_NAME}
                's services until&nbsp;
                {BRAND_NAME} or the qualified service partner has re-enabled your site.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This could happen at any time for several reasons, including:{" "}
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <b>Onboarding:</b> Your store has not yet been configured for optimization with {BRAND_NAME}.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <b>System upgrades:</b> {BRAND_NAME} may turn off our systems temporarily for upgrades or
                    maintenance.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <b>Reliability:</b> If any of our services' key cloud infrastructure providers are not operating
                    effectively, we may temporarily disable {BRAND_NAME} services to prevent your site from being
                    interrupted.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <b>Discontinuation of service:</b> {BRAND_NAME} is in the process of being removed from your store
                    or organization.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As with a "Testing" status, the provider will indicate why your store was disabled.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-24/48a837c6-cac4-4378-b0fa-085c46cdd8b8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2105,1252&force_format=png&width=1120.0"
                alt="Disabled by provider"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our team will assist you if you have any questions or concerns about your store's status or need support
                during troubleshooting to ensure you have the best experience with {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>{" "}
                with details of any issues you see (screenshots, videos, examples, etc.). We will work with you to
                provide the help you need.{" "}
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
