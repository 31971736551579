import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Heading,
} from "@chakra-ui/react";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    pathNames: string[];
};

export const PathNameDisplayModal = ({ isOpen, onClose, pathNames }: Props) => {
    return (
        <Modal isCentered size="5xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading as="h2" size="md">
                        Pages
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {pathNames.map((pathname, index) => (
                        <Text key={index}>{pathname}</Text>
                    ))}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
