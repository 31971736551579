import { Flex, useToast, useDisclosure, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { IconButtonDelete, Tooltip, ModalButton, IconButtonRepeat } from "../../components";
import {
    useDeletePurgeMutation,
    generatePendingCacheQueryKey,
    useListRecurringCachePurges,
    useDeleteRecurringPurgeCacheMutation,
} from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { formatDateTimeDisplay } from "../../lib";

type PurgeCacheRowProps = {
    id: string;
    customerUrl: any;
    requestedBy: string;
    storeId: string;
};

export const PurgeCacheRowActions = ({ id, customerUrl, requestedBy, storeId }: PurgeCacheRowProps) => {
    const requestId = requestedBy.split(" - ")[1];
    const { data: recurringPurges } = useListRecurringCachePurges(storeId, customerUrl);
    const currentRecurringPurge = useMemo(
        () => recurringPurges?.filter((purge) => purge.id === requestId),
        [recurringPurges, requestId],
    );
    const deletePurgeMutation = useDeletePurgeMutation();
    const deleteRecurringPurgeCacheMutation = useDeleteRecurringPurgeCacheMutation(requestId, customerUrl, storeId);
    const { onClose, isOpen, onOpen } = useDisclosure();
    const { onClose: onRecurringClose, isOpen: isRecurringOpen, onOpen: onRecurringOpen } = useDisclosure();
    const queryClient = useQueryClient();
    const showToast = useToast({ duration: 5000, isClosable: true, variant: "subtle" });
    const deletePurgeRequest = {
        id: id,
        customerUrl: customerUrl,
    };

    const confirmation = {
        title: "Delete Clear Cache Job",
        description: "Are you sure you want to delete this clear cache job?",
    };

    const deletePurgeCacheJob = () => {
        const onDeleteSuccess = () => {
            queryClient.invalidateQueries(generatePendingCacheQueryKey(storeId));
            showToast({
                title: "Success",
                description: "Successfully deleted clear cache job",
                status: "success",
            });
        };
        const onDeleteError = () => {
            showToast({
                title: "Error",
                description: "An unexpected error has occurred. If the error persists, please contact support.",
                status: "error",
            });
        };

        deletePurgeMutation.mutate(deletePurgeRequest, {
            onSuccess: onDeleteSuccess,
            onError: onDeleteError,
        });
    };

    const deleteRecurringPurgeCache = () => {
        deleteRecurringPurgeCacheMutation.mutate();
    };

    const frequency = (milliseconds: number | undefined) => {
        switch (milliseconds) {
            case 86400000:
                return "daily";
            case 604800000:
                return "weekly";
            case 2592:
                return "monthly";
            case 31536:
                return "annually";
            default:
                return `every ${milliseconds} milliseconds`;
        }
    };

    const recurringConfirmation = {
        title: "Recurring Clear Cache",
        description: (
            <>
                <Text mb="spacer-2">
                    Your recurring clear cache series runs{" "}
                    <strong>{frequency(currentRecurringPurge?.[0]?.interval)}</strong>.
                </Text>
                <UnorderedList mb="spacer-2">
                    <ListItem>
                        <strong>Start Date:</strong> {formatDateTimeDisplay(currentRecurringPurge?.[0]?.start)}
                    </ListItem>
                    {currentRecurringPurge?.[0]?.end !== null ? (
                        <ListItem>
                            <strong>End Date:</strong> {formatDateTimeDisplay(currentRecurringPurge?.[0]?.end)}
                        </ListItem>
                    ) : (
                        <ListItem>
                            <strong>Repeats until canceled</strong>
                        </ListItem>
                    )}
                </UnorderedList>
                <Text mt="spacer-2">
                    If you no longer need this series,{" "}
                    <strong>press "Delete" to remove this job and any future pending jobs</strong>. Otherwise, select
                    "Cancel."
                </Text>
            </>
        ),
    };

    return (
        <Flex>
            {requestId ? (
                <>
                    <Tooltip label="Recurring" aria-label="Recurring Clear Cache Job">
                        <IconButtonRepeat onClick={onRecurringOpen} aria-label="Recurring Clear Cache" height="24px" />
                    </Tooltip>
                    <div style={{ display: "none" }}>
                        <ModalButton
                            isOpen={isRecurringOpen}
                            onClose={onRecurringClose}
                            confirmation={recurringConfirmation}
                            onAction={() => deleteRecurringPurgeCache()}
                        ></ModalButton>
                    </div>
                </>
            ) : (
                <>
                    <Tooltip label="Delete Clear Cache Job" aria-label="Delete Clear Cache job">
                        <IconButtonDelete onClick={onOpen} aria-label="Delete Clear Cache Job" height="24px" />
                    </Tooltip>
                    <div style={{ display: "none" }}>
                        <ModalButton
                            isOpen={isOpen}
                            onClose={onClose}
                            confirmation={confirmation}
                            onAction={() => deletePurgeCacheJob()}
                        ></ModalButton>
                    </div>
                </>
            )}
        </Flex>
    );
};
