import { Card, CardBody, Text, Flex, Spacer, HStack, Badge } from "@chakra-ui/react";
import { formatDateTimeDisplay, truncate } from "../../lib";
import { PurgeCacheRowActions } from "./PurgeCacheRowActions";

type MobileCardProps = {
    date: string;
    id: string;
    requestedBy: string;
    status: string;
    customerUrl: string;
    pathname: string;
    storeId: string;
};

export const MobileListPurgeSchedule = ({
    date,
    id,
    requestedBy,
    status,
    customerUrl,
    pathname,
    storeId,
}: MobileCardProps) => {
    return (
        <Card width="100%" size="sm">
            <CardBody>
                <HStack>
                    <Text fontWeight={"bold"}>{formatDateTimeDisplay(date)}</Text>
                    {status === "PENDING" ? (
                        <>
                            <Spacer />
                            <Badge variant="subtle" colorScheme="blue">
                                {status}
                            </Badge>
                        </>
                    ) : null}
                </HStack>
                <Flex width="100%" alignItems={"center"}>
                    <Text size={"sm"}>By:&nbsp;</Text>
                    <Text display={{ base: "none", sm: "block" }}>{requestedBy.split(" - ")[0] ?? requestedBy}</Text>
                    <Text display={{ base: "block", sm: "none" }}>
                        {truncate(requestedBy.split(" - ")[0] ?? requestedBy, 25)}
                    </Text>
                </Flex>
                <Flex width="100%" alignItems={"center"}>
                    <Text size={"sm"}>Page:&nbsp;</Text>
                    <Text display={{ base: "none", sm: "block" }}>{pathname}</Text>
                    <Text display={{ base: "block", sm: "none" }}>{truncate(pathname, 25)}</Text>
                </Flex>
                <HStack justifyContent={"flex-end"}>
                    <PurgeCacheRowActions
                        id={id}
                        customerUrl={customerUrl}
                        requestedBy={requestedBy}
                        storeId={storeId}
                    />
                </HStack>
            </CardBody>
        </Card>
    );
};
