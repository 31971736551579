import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const StaticVsDynamicContentPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Static Vs. Dynamic Content"}
            />
            <Header pb={"spacer-8"}>Static Vs. Dynamic Content</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Static and dynamic content work together to make up the websites people see every day. While they are
                similar in the sense that they are both types of web content, they have different methods of delivery
                and generation.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Static Content
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Static content is the more basic of the two. As its name suggests, it consists of any content that does
                not change and does not rely on user interactions. Examples include CSS files and images.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Because these items do not change, they can be created ahead of time and stored in a web server to be
                delivered to users. Static content is easy to store, or cache, since it can be pre-generated, which
                leads to quick delivery times.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Again, this content is consistent, so when the static file reaches someone's browser, it will look the
                same no matter where the user might be located.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Dynamic Content
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Dynamic content refers to anything that is updated or generated in real time based on user requests,
                input, location, etc.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Examples include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Forms</ListItem>
                <ListItem pb={"spacer-4"}>Shopping carts </ListItem>
                <ListItem pb={"spacer-4"}>Checkout pages</ListItem>
                <ListItem pb={"spacer-4"}>Advertisements</ListItem>
                <ListItem pb={"spacer-4"}>Product recommendations</ListItem>
                <ListItem pb={"spacer-6"}>Personalized greetings/messages</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The purpose of dynamic content is to enhance your users' experience and keep them coming back for more
                of your store and products, but there can be a cost when it comes to site speed and performance.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This is because of the difficulty in caching dynamic content. Instead of being created ahead of time and
                stored in a content delivery network (CDN), dynamic content has to be spun up as a new HTML file on the
                server side with each new user, interaction, etc.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Initially, that meant dynamic content was viewed as uncacheable, as the process had to be implemented
                through the origin server, but new technologies have paved the way to deliver dynamic content from a
                cache.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {MAIN_PRODUCT} does exactly that, using innovative caching techniques to store your
                content not just in a CDN, but on the edge—as close to your customers as possible.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    Our engineers configure your site's dynamic content to work seamlessly with {BRAND_NAME}'s{" "}
                    {MAIN_PRODUCT}, so be sure to{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>{" "}
                    if you make any changes to your dynamic content, especially with your cart. It is essential to cache
                    your dynamic content correctly to guarantee your visitors have a smooth, rapid-delivery experience.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The results speak for themselves, with {BRAND_NAME} clients seeing reduced latency, remarkable
                performance scores, and significant boosts in organic traffic.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
