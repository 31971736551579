import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useDeleteOrganizationMutation } from "../../hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../Routes";

type Props = {
    isDeleteOpen: boolean;
    onDeleteClose: () => void;
    orgId: string;
};

export const DeleteOrgAlertDialog = ({ isDeleteOpen, onDeleteClose, orgId }: Props) => {
    const cancelRef = useRef(null);
    const deleteOrganization = useDeleteOrganizationMutation();
    const navigate = useNavigate();
    const nostraOrgId = "00000000-0000-0000-0000-000000000000";
    const location = useLocation();

    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });

    const handleOrganizationDelete = () => {
        const onDeleteSuccess = () => {
            if (location.pathname === `${ROUTES.ALL_ORGANIZATIONS}`) {
                navigate(0);
            } else {
                navigate(ROUTES.HOME);
            }
            showToast({
                title: "Success",
                description: "Organization deleted successfully",
                status: "success",
            });
        };

        const onDeleteError = (error: any) => {
            onDeleteClose();
            showToast({
                title: "Error",
                description:
                    error.message ||
                    "There was an error deleting the organization. Please try again in a few moments. If this error persists, please contact Customer Success.",
                status: "error",
            });
        };

        deleteOrganization.mutate(orgId, {
            onSuccess: onDeleteSuccess,
            onError: onDeleteError,
        });
    };

    return (
        <AlertDialog isOpen={isDeleteOpen} onClose={onDeleteClose} leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Organization
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    {orgId === nostraOrgId ? (
                        <AlertDialogBody>The Nostra organization cannot be deleted.</AlertDialogBody>
                    ) : (
                        <>
                            <AlertDialogBody as="b">
                                Please note: You cannot delete this organization until all its stores are deleted.
                            </AlertDialogBody>
                            <AlertDialogBody>
                                Are you sure you want to delete this organization? This action cannot be undone.
                            </AlertDialogBody>
                        </>
                    )}
                    <AlertDialogFooter>
                        <Button variant={"outline"} colorScheme={"button-primary"} onClick={onDeleteClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme={"button-delete"}
                            onClick={handleOrganizationDelete}
                            ml={3}
                            isDisabled={orgId === nostraOrgId}
                        >
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
