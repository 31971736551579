import { useQuery } from "@tanstack/react-query";
import { Store } from "../../types/types";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";

export const useStores = () => {
    const config = {
        queryKey: ["stores"],
        queryFn: () => fetchStores(),
    };

    return useQuery(config);
};

const fetchStores = async (): Promise<Store[]> => {
    const url = `${SVC_URLS["ecom"]}/store/`;
    const options = await fetchOptions();
    const response = await fetch(url, options);
    return fetchResponse(response);
};
