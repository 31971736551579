import { AllStoresReport } from "./AllStoresReport";
import { PageContainer } from "../../../PageContainer";

export const AllStoresReportsPage = () => {
    return (
        <PageContainer>
            <div style={{ height: "100%", width: "100%", marginBottom: "60px" }}>
                <AllStoresReport />
            </div>
        </PageContainer>
    );
};
