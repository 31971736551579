import { useMutation, UseMutationResult, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { handleErrors } from "../../lib/http/handleErrors";
import {
    DeploymentQueue,
    DeploymentQueueResponse,
    EnqueueDeploymentOptions,
    EnqueueResponse,
    QueryOptions,
} from "../../types";
import { useToast } from "@chakra-ui/react";

export const useEnqueueDeploymentMutation = (
    {
        onDeploymentStart,
        onPollingStart,
        onPollingEnd,
        onDeploymentSuccess,
        polling = true,
        showToast = false,
    }: EnqueueDeploymentOptions,
    customerUrl: string = "",
) => {
    const [queryOptions, setQueryOptions] = useState<QueryOptions>(DEFAULT_QUERY_OPTIONS);
    const [isDeployPending, setIsDeployPending] = useState(false);
    const [pendingDeployment, setPendingDeployment] = useState<PendingDeployment | undefined>(undefined);
    const { data: deploymentQueue, remove: purgeDeploymentQueueCache } = useQuery(
        QUERY_KEY_PENDING_DEPLOYMENTS,
        () => fetchDeploymentQueue(customerUrl),
        queryOptions,
    );
    const triggerToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    const stopPolling = useCallback(() => {
        purgeDeploymentQueueCache();
        setIsDeployPending(false);
        setPendingDeployment(undefined);
        setQueryOptions(DEFAULT_QUERY_OPTIONS);
        onPollingEnd && onPollingEnd();
    }, [onPollingEnd, purgeDeploymentQueueCache]);

    const startPolling = useCallback(
        (response: EnqueueResponse) => {
            const {
                store: { customerUrl },
                queue,
            } = response;
            purgeDeploymentQueueCache();
            setIsDeployPending(true);
            setPendingDeployment({ customerUrl, queue });
            setQueryOptions(POLLING_QUERY_OPTIONS);
            onPollingStart && onPollingStart();
        },
        [onPollingStart, purgeDeploymentQueueCache],
    );

    /**
     * Stop polling when the deployment is no longer in progress
     */
    useEffect(() => {
        if (deploymentQueue === undefined || pendingDeployment === undefined || isDeployPending === false) return;

        if (isDeployPending) {
            const { inProgress } = getDeploymentStatus(pendingDeployment, deploymentQueue);
            if (!inProgress) {
                stopPolling();
                onDeploymentSuccess && onDeploymentSuccess();
            }
        }
    }, [deploymentQueue, isDeployPending, onDeploymentSuccess, pendingDeployment, stopPolling]);

    const mutation = useMutation({
        mutationFn: enqueueDeployment(onDeploymentStart),

        onSuccess: (response) => {
            polling ? startPolling(response) : void 0;
            if (showToast) {
                triggerToast({
                    title: "Deploying ...",
                    status: "info",
                    description: `Changes to ${customerUrl} have been queued for deployment. You may proceed with operations as usual.`,
                    isClosable: false,
                });
            }
        },
        onError: (error) => {
            console.error(error);
            if (showToast)
                triggerToast({
                    title: "Error",
                    status: "error",
                    description: `An unexpected error occurred while applying the changes to ${customerUrl}. Please contact support if the problem persists.`,
                });
        },
    }) as DeploymentMutation;

    mutation.isPolling = isDeployPending;
    return mutation;
};

type PendingDeployment = {
    customerUrl: string;
    queue: DeploymentQueue;
};

const enqueueDeployment = (onDeploymentStart: any) => async (customerUrl: string) => {
    onDeploymentStart && onDeploymentStart();
    const ENQUEUE_DEPLOY_BLADE_PATH = `${SVC_URLS["cronos"]}/worker/enqueue`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify({ customerUrl: customerUrl }),
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(ENQUEUE_DEPLOY_BLADE_PATH, options).catch(handleErrors);

    return fetchResponse(response);
};

const fetchDeploymentQueue = async (customerUrl: string) => {
    const PENDING_DEPLOYS_BLADE_PATH = `${SVC_URLS["cronos"]}/worker/pending`;
    const options = await fetchOptions({
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(PENDING_DEPLOYS_BLADE_PATH, options).catch(handleErrors);

    const body = await fetchResponse(response);
    return body;
};

const getDeploymentStatus = (currentDeployment: PendingDeployment, deploymentQueue: DeploymentQueueResponse) => {
    if (isEmptyObject(deploymentQueue)) return { inProgress: false };

    const { customerUrl, queue } = currentDeployment;
    const inProgress = Boolean(
        deploymentQueue[customerUrl] &&
            deploymentQueue[customerUrl].requests &&
            deploymentQueue[customerUrl].requests[queue.id],
    );

    return { inProgress };
};

const DEFAULT_QUERY_OPTIONS: QueryOptions = {
    enabled: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    cacheTime: 0,
};

const POLLING_QUERY_OPTIONS: QueryOptions = {
    enabled: true,
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    cacheTime: 0,
};

const QUERY_KEY_PENDING_DEPLOYMENTS = ["pendingDeploys"];

// @Future: replace with lodash _.isEqual()
const isEmptyObject = (obj: any) => JSON.stringify(obj) === "{}";

type DeploymentMutation = UseMutationResult<EnqueueResponse, unknown, string, unknown> & {
    isPolling: boolean;
};
