import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box, Alert, AlertIcon, Spacer } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const WhenAndWhyToPurgeCachePage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"When And Why To Clear Your Cache"}
            />
            <Header pb={"spacer-8"}>When And Why To Clear Your Cache</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Imagine your website's cache as a "snapshot" of your website's pages, helping your site load quickly for
                visitors. This snapshot captures your content and information at a particular point in time, so if you
                change something major, the snapshot might become outdated.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {MAIN_PRODUCT} is designed to prevent this problem from occurring so none of your users
                end up with old prices or a sold out product. {BRAND_NAME}'s solution will{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_AUTOMATED_CACHE_CLEARING} target="new">
                    automatically clear the cache
                </Link>
                &nbsp;for you after major changes via a Shopify webhook we install during your onboarding process or via
                Salesforce Commerce Cloud polling.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This ensures your site will always show the most recent information after these significant updates:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Updating a product(s)</ListItem>
                <ListItem pb={"spacer-4"}>Changing inventory levels</ListItem>
                <ListItem pb={"spacer-6"}>Updating a collection(s)</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                However, there are still moments when you might want to manually use {BRAND_NAME}'s clear cache feature:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Text as="b">After making minor yet crucial changes:</Text> If you've made small adjustments that
                    don't trigger our automated system, but feel they are vital for your visitors to see right away, you
                    can clear the cache.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Text as="b">When your website appears unusual:</Text> If sections of your website seem off or out
                    of place, it might be due to an outdated cache. Clearing it manually can help get your content back
                    on track.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Text as="b">When you make post plugin/theme adjustments:</Text> If you have tweaked settings, added
                    code, or made other minor changes to plugins/themes, consider clearing the cache to keep your
                    content up to date.
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Special Note On Dynamic Content And Your Cart
            </SubHeader>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    Dynamic content is content that changes based on user interaction or real-time factors, and it's
                    essential to manage it correctly for caching. Your shopping cart, which shows different items for
                    each customer based on what they've added, is a classic example.
                    <Spacer my={"spacer-4"} />
                    Before you go live with updates that involve dynamically loaded content, especially changes to your
                    cart, let {BRAND_NAME} know. This ensures we can configure the cache appropriately, guaranteeing
                    your visitors have a smooth, accurate experience without facing outdated cart information or any
                    other outdated dynamic elements.
                </Text>
            </Alert>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
