import { ROUTES } from "../Routes";
import { Layout } from "../layouts/Layout";
import { OrganizationPage, ListUsers, ListUsersTab, StoresListTab } from "../pages";

export const ownerRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: ROUTES.ORGANIZATION,
                element: <OrganizationPage />,
                children: [
                    { path: "users", element: <ListUsersTab /> },
                    { path: "stores", element: <StoresListTab /> },
                ],
            },
            { path: ROUTES.LIST_USERS, element: <ListUsers /> },
        ],
    },
];
