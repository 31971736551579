import { TableContainer, Text, useMediaQuery, useToast, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import { DataTable, Link, PathNameDisplay } from "../../components";
import { usePendingScheduledPurgeCache, useStore } from "../../hooks";
import { PendingCacheRow, Store } from "../../types";
import { LoadingPage } from "../LoadingPage";
import { MobileListPurgeSchedule } from "./MobileListPurgeSchedule";
import { PurgeCacheRowActions } from "./PurgeCacheRowActions";
import { formatDateTimeDisplay } from "../../lib";
import { CacheScheduleFieldSet } from "./CacheScheduleFieldSet";
import { RecurringScheduleFieldSet } from "./RecurringScheduleFieldSet";
import { PageContainer } from "../PageContainer";
import { ROUTES } from "../../Routes";

type Props = {
    store: Store;
};

export const ClearCacheTab = ({ store }: Props) => {
    const showToast = useToast({ variant: "subtle", duration: 5000 });
    const { data } = useStore();
    const [isMobile] = useMediaQuery("(max-width: 744px)");
    const { data: pendingData, isError, isLoading } = usePendingScheduledPurgeCache(data?.id ?? "", store.customerUrl);

    const scheduledPurges = useMemo(() => {
        return pendingData?.sort((a: PendingCacheRow, b: PendingCacheRow) => {
            return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
        });
    }, [pendingData]);

    const customerUrl = data?.customerUrl ?? "";
    const [rows, setRows] = useState<any>([]);
    const columns = useMemo(() => buildColumns(customerUrl, store.id), [customerUrl, store.id]);

    useEffect(
        function renderTable() {
            if (!scheduledPurges) return;

            setRows(scheduledPurges);
        },
        [customerUrl, scheduledPurges],
    );

    useEffect(
        function handleError() {
            if (isError) {
                showToast({
                    title: "Error",
                    description:
                        "We encountered an error loading this page. If this error persists, please contact Customer Success",
                    status: "error",
                });
            }
        },
        [isError, showToast],
    );
    const recordsFound = rows.length > 0;

    if (isLoading || data === undefined) return <LoadingPage />;

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxW="1440px">
            <Text display={{ base: "none", lg: "block" }}>
                Clear your cache when you make changes to your site that must enter the queue immediately or enter the
                queue at a certain time to reflect updated content as needed. For more detailed information on this,
                please refer to our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                    How To Clear Your Cache documentation
                </Link>
                .
            </Text>
            <Text py={"spacer-4"} display={{ base: "none", lg: "block" }}>
                Otherwise, changes are reflected at a regular interval.
            </Text>
            {recordsFound && (
                <Text fontWeight={"bold"} pt={{ base: "spacer-2", md: "0" }}>
                    All times are your local timezone.
                </Text>
            )}
            <CacheScheduleFieldSet customerUrl={customerUrl} store={store} />
            <RecurringScheduleFieldSet customerUrl={customerUrl} store={store} />
            {rows?.length === 0 || rows === undefined ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    style={{ marginTop: "6rem" }}
                    justifyContent="center"
                    display="flex"
                >
                    None Scheduled
                </Text>
            ) : (
                <>
                    {isMobile ? (
                        <VStack spacing="4" width="100%" className="alternate-bg-card">
                            {rows.map((row: PendingCacheRow) => (
                                <MobileListPurgeSchedule
                                    key={row.date}
                                    id={row.id}
                                    date={row.date}
                                    requestedBy={row.requestedBy}
                                    status={row.status}
                                    customerUrl={store.customerUrl}
                                    pathname={row.metadata?.pathNames?.join(", ") ?? "entire site"}
                                    storeId={store.id}
                                />
                            ))}
                        </VStack>
                    ) : (
                        <TableContainer width="100%" style={{ marginTop: "1rem" }} data-attr="table-container">
                            <DataTable columns={columns} data={rows} />
                        </TableContainer>
                    )}
                </>
            )}
        </PageContainer>
    );
};

const buildColumns = (customerUrl: string, storeId: string) => {
    const columnHelper = createColumnHelper<PendingCacheRow>();
    const columns = [
        columnHelper.display({
            id: "action",
            cell: (props) => (
                <PurgeCacheRowActions
                    storeId={storeId}
                    id={props.row.original.id}
                    customerUrl={customerUrl}
                    requestedBy={props.row.original.requestedBy}
                />
            ),
            header: "Action",
        }),
        columnHelper.accessor("requestedBy", {
            cell: (props) => <Text>{props.getValue().split(" - ")[0] ?? props.getValue()}</Text>,
            header: "Requested By",
        }),
        columnHelper.accessor("date", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Date",
        }),
        columnHelper.accessor("metadata", {
            cell: (props) => PathNameDisplay(props.getValue()?.pathNames),
            header: "Page",
        }),
        columnHelper.accessor("status", {
            cell: (props) => props.getValue(),
            header: "Status",
        }),
    ];

    return columns;
};
