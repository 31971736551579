import { containsAnyQueryParameters } from "./containsAnyQueryParameters";
import { containsQueryParameter } from "./containsQueryParameter";

export const addQueryParameter = (url: string, key: string, value: string | boolean): string => {
    if (url === "") return url;
    if (containsQueryParameter(url, key)) return url;

    const delimeter = containsAnyQueryParameters(url) ? "&" : "?";

    return `${url}${delimeter}${key}=${value}`;
};
