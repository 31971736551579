import { usePerformanceReport } from "../../../../hooks";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const TTFBVsSource = () => {
    const reportParams = {
        report: "ttfb_by_source",
    };
    const { data, isLoading, isError } = usePerformanceReport(reportParams);
    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
        </RenderMetric>
    );
};
