import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { ScheduleRecurringPurgeCacheRequest } from "../../types";

export const useScheduleRecurringCacheMutation = () => {
    return useMutation(scheduleRecurringPurgeCache);
};

const scheduleRecurringPurgeCache = async ({
    customerUrl,
    formData,
}: {
    customerUrl: string;
    formData: ScheduleRecurringPurgeCacheRequest;
}) => {
    const recurringPurgeCache = `${SVC_URLS["cronos"]}/cache/recurring`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(recurringPurgeCache, options).catch(handleErrors);

    return fetchResponse(response);
};
