import { PageContainer } from "../../PageContainer";
import { Text, Box } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const ABTestingPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"A/B Testing"}
                menuItem1Link={ROUTES.DOCUMENTATION_AB_TESTING}
                menuItem2={"A/B Testing Overview"}
            />
            <Header pb={"spacer-8"}>A/B Testing Overview</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s customers rely on data to inform content strategies and advertising decisions. Why add
                yet another app when you can count on an easy-to-use solution through {BRAND_NAME} instead?
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our server-side A/B testing will help you optimize your content for conversions while eliminating the
                compounding variable of slow site speed. In other words, you will get more accurate results faster
                thanks to our edge-delivered software.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Traditional A/B testing, or split testing, is all about experimentation. A percentage of your users will
                receive one version of your site, while the rest will receive another version. Armed with new insights
                from the resulting data, you can decide which version will lead to the most conversions and capitalize
                on new opportunities you might otherwise have missed.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Beat Traditional Testing
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Unlike traditional A/B testing, which is built on the client side, {BRAND_NAME}'s server-side solution
                is not hindered by slow, inconsistent site speeds. {BRAND_NAME} pre-builds and caches each of your
                tests, ensuring quick delivery, reliable data, and a better customer experience overall.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s A/B testing is supported by our {MAIN_PRODUCT}, so you can count on quick and efficient
                results and say goodbye to slow load times and flickering content that will impact the fidelity of your
                tests.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Ultimately, our A/B testing will help you produce engaging, personalized layouts, all while maximizing
                results even before the test concludes.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                {BRAND_NAME}'s Weightless Testing Solution
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                We currently offer two levels of A/B Testing at an additional price. The first is our weightless
                self-service testing, through which you can make changes to your site's text and HTML. These changes can
                be made directly through the {BRAND_NAME} portal.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your experiments can be set up quickly and easily thanks to our user-friendly editor. Simply select the
                element in question, add your eye-catching edits, and save. Not only does the A/B Testing editor keep
                track of your updates, but it also provides a variety of different device previews so you can be
                confident your edits are responsive and engaging.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s weightless A/B Testing seamlessly integrates with Google Tag Manager and Google Analytics
                4, so you will be able to monitor your experiments and make decisions accordingly.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This product is currently being developed and expanded, so you can look forward to even more A/B
                optimizations and capabilities.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                {BRAND_NAME}'s White Glove Testing Service
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have specific experimentation needs, {BRAND_NAME} will work with you to create a bespoke A/B
                testing tool to achieve those goals. Our team will work with you to determine your required capabilities
                and deliver a package catered to your testing needs.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                    textDecoration="underline"
                >
                    Contact support
                </Link>{" "}
                for more information and pricing on both our weightless A/B Testing solution and our white glove A/B
                Testing service.{" "}
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
