import { useToast, Text, VStack, HStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../components/DataTable";
import { PageContainer } from "../PageContainer";
import { generateListExperimentsQueryKey, useListExperiments, useStore } from "../../hooks";
import { Experiment, Store } from "../../types";
import { useEffect, useMemo } from "react";
import { ExperimentRowActions } from "./ExperimentRowActions";
import { ToggleExperimentActivation } from "./ToggleExperimentActivation";
import { Header, SubHeader, StoreSwitchHeader } from "../../components";
import { LoadingPage } from "../LoadingPage";
import { useQueryClient } from "@tanstack/react-query";

export const ListExperimentsPage = () => {
    const storeQuery = useStore();
    const experimentsQuery = useListExperiments(storeQuery.data?.id);
    const { experiments, store, isLoading } = useData([experimentsQuery, storeQuery]);
    const queryClient = useQueryClient();

    const columns = useMemo(
        () => buildColumns(queryClient, store?.id ?? "", store?.customerUrl ?? "", store?.edgeEnabled ?? false),
        [queryClient, store?.id, store?.customerUrl, store?.edgeEnabled],
    );

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <PageContainer>
            <VStack alignItems={"baseline"}>
                <HStack justifyContent={"space-between"} width={"100%"} pb="spacer-1">
                    <Header>Experiments</Header>

                    {store && <StoreSwitchHeader orgId={store.orgId} currentStore={store.customerUrl} />}
                </HStack>
                <HStack width="100%" justifyContent={"space-between"} alignItems={"baseline"}>
                    <SubHeader className="trackCustomerUrl">{store?.customerUrl}</SubHeader>
                </HStack>
            </VStack>

            {experiments === undefined || experiments.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-body-meta-regular",
                        md: "text-body-meta-medium",
                    }}
                >
                    No Experiments Found!
                </Text>
            ) : (
                <DataTable columns={columns} data={experiments} />
            )}
        </PageContainer>
    );
};

const buildColumns = (queryClient: any, storeId: string, customerUrl: string, edgeEnabled: boolean) => {
    const columnHelper = createColumnHelper<Experiment>();
    const columns = [
        columnHelper.display({
            id: "actions",
            cell: (props) => (
                <>
                    <ExperimentRowActions experimentId={props.row.original.id} customerUrl={customerUrl} />
                </>
            ),
            header: "Actions",
        }),
        columnHelper.accessor("active", {
            cell: (props) => (
                <ToggleExperimentActivation
                    isChecked={props.getValue() ?? false}
                    experiment={props.row.original}
                    edgeEnabled={edgeEnabled}
                    onDeploymentSuccess={() => {
                        queryClient.invalidateQueries(generateListExperimentsQueryKey(storeId));
                    }}
                    customerUrl={customerUrl}
                />
            ),
            header: "Active",
        }),
        columnHelper.accessor("name", {
            cell: (props) => <>{props.getValue()}</>,
            header: "Name",
        }),
        columnHelper.display({
            id: "pageset",
            cell: (props) => <>{props.row.original.pageset.name}</>,
            header: "Pageset",
        }),
    ];

    return columns;
};

type UseDataResponse = {
    isLoading: boolean;
    store: Store | undefined;
    experiments: Experiment[] | undefined;
};

const useData = ([experiments, store]: Array<any>): UseDataResponse => {
    const isLoading = experiments.isLoading || store.isLoading;
    const isError = experiments.isError || store.isError;
    const showToast = useToast();
    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "There was an error loading this page. Please try again in a few moments.",
                status: "error",
                duration: 5000,
                variant: "subtle",
                isClosable: true,
            });
        }
    }, [isError, showToast]);

    return {
        isLoading,
        store: store.data,
        experiments: experiments.data,
    };
};
