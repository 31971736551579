import { useListSpeedExperiments } from "../../hooks/queries";
import { VirtualizedDataTable } from "../../components";
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { LoadingPage } from "../LoadingPage";
import { SpeedExperimentData } from "../../types";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { formatDateTimeDisplay } from "../../lib";

type Props = {
    storeId: string;
};

export const SpeedExperimentHistoryTable = ({ storeId }: Props) => {
    const { data: speedExperiments, isLoading } = useListSpeedExperiments(storeId);

    const columns = useMemo(() => buildColumns(), []);

    if (isLoading) return <LoadingPage />;

    return (
        <div style={{ margin: "6% 0 6% 0" }}>
            {speedExperiments === undefined || speedExperiments.length === 0 ? (
                <Alert status="info">
                    <AlertIcon />
                    No Speed Experiment History Found
                </Alert>
            ) : (
                <VirtualizedDataTable data={speedExperiments} columns={columns} />
            )}
        </div>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<SpeedExperimentData>();
    const columns = [
        columnHelper.display({
            id: "id",
            cell: (props) => props.row.original.id,
            header: "Experiment ID",
        }),
        columnHelper.accessor("startedAt", {
            cell: (props) => {
                return props.getValue() ? formatDateTimeDisplay(props.getValue()) : "No data";
            },
            header: "Experiment Start Date",
        }),
        columnHelper.accessor("endedAt", {
            cell: (props) => {
                let value = formatDateTimeDisplay(props.getValue());
                if (props.row.original.active === true) {
                    value = "Ongoing";
                } else if (value === "-") {
                    value = "No data";
                }
                return value;
            },
            header: "Experiment End Date",
        }),
    ];
    return columns;
};
