export const asCustomerUrl = (url: string) => {
    // remove the protocol
    let customerUrl = url.replace(/(^\w+:|^)\/\//, "");
    // remove the path
    customerUrl = customerUrl.split("/")[0];

    if (customerUrl.startsWith("https")) {
        customerUrl = customerUrl.replace("https", "");
    }

    if (customerUrl.startsWith("http")) {
        customerUrl = customerUrl.replace("http", "");
    }

    return customerUrl;
};
