import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { PendingRecurringCacheResponse } from "../../types";

export const useListRecurringCachePurges = (storeId: string, customerUrl: string) => {
    return useQuery({
        queryKey: ["recurringCachePurges", storeId],
        queryFn: async () => await listRecurringCachePurges(storeId, customerUrl),
    });
};

const listRecurringCachePurges = async (
    storeId: string,
    customerUrl: string,
): Promise<PendingRecurringCacheResponse> => {
    const recurringCachePurges = `${SVC_URLS["cronos"]}/cache/recurring/${storeId}`;
    const options = await fetchOptions({
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(recurringCachePurges, options).catch(handleErrors);
    return fetchResponse(response);
};
