import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { handleErrors } from "../../lib/http/handleErrors";

export const useToggleExperimentActivationMutation = () => {
    return useMutation({
        mutationFn: toggleExperimentActivation,
    });
};

const toggleExperimentActivation = async ({
    experimentId,
    customerUrl,
}: {
    experimentId: string;
    customerUrl: string;
}) => {
    const TOGGLE_EXPERIMENT_ACTIVATION_URL = `${SVC_URLS["experiment"]}/experiment/${experimentId}/toggle`;
    const options = await fetchOptions({
        method: "PUT",
        headers: {
            Store: customerUrl,
        },
    });

    const response = await fetch(TOGGLE_EXPERIMENT_ACTIVATION_URL, options).catch(handleErrors);

    return fetchResponse(response);
};
