import { TableContainer, Table, Tbody, Tr, Th, Td, Button, Stack, VStack, Spacer, Text } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { useNavigate, useSearchParams, generatePath, useLocation } from "react-router-dom";
import { ROUTES } from "../Routes";
import { Header, SubHeader, Link } from "../components";
import { useStore } from "../hooks";
import { NotFound404 } from "./ErrorPages/NotFound404";
import { LoadingPage } from "./LoadingPage";

export const CreateStoreConfirmationPage = () => {
    const location = useLocation();
    const customerUrl = location.pathname.split("/")[4];
    const navigate = useNavigate();
    const { data: store, isLoading } = useStore();

    const orgId = location.pathname.split("/")[2];
    let [query] = useSearchParams();

    const params = {
        zoneId: query.get("zoneId") !== "" ? query.get("zoneId") : "External Account",
        name: query.get("name") !== "undefined" ? query.get("name") : "External Account",
        value: query.get("value") !== "undefined" ? query.get("value") : "External Account",
    };

    /**
     * Loading State
     */
    if (isLoading === true) return <LoadingPage />;

    /**
     * Store not found
     */
    if (store === undefined) return <NotFound404 />;

    return (
        <PageContainer>
            <VStack alignItems="baseline">
                <Header>Confirmation - {store.name}</Header>
                <SubHeader>
                    Copy the following DNS TXT record and add it to your domain's DNS records. Skip this step if the
                    customer is using an external account.
                </SubHeader>
                <Text></Text>
            </VStack>

            <TableContainer pb="spacer-16">
                <Table variant="unstyled" size="sm" width="100%">
                    <Tbody>
                        {params.name ? (
                            <>
                                <Tr>
                                    <Th>Text Record Name:</Th>
                                    <Td>{params.name}</Td>
                                </Tr>
                                <Tr>
                                    <Th>Text Record Value:</Th>
                                    <Td>{params.value}</Td>
                                </Tr>
                            </>
                        ) : null}

                        <Tr>
                            <Th>Zone Id:</Th>
                            <Td>{params.zoneId}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>

            <Stack spacing={"spacer-4"} direction="row" alignItems={"end"}>
                <Link to={generatePath(ROUTES.ORGANIZATION, { orgId })}>&lt;&lt; Back to Organization</Link>
                <Spacer />
                <Button
                    variant={"outline"}
                    colorScheme={"button-secondary"}
                    onClick={() => navigate(`${generatePath(ROUTES.STORE_EDIT, { customerUrl })}`)}
                >
                    Edit Store
                </Button>
                <Button
                    colorScheme={"button-primary"}
                    onClick={() => navigate(`${generatePath(ROUTES.CREATE_STORE, { orgId })}`)}
                >
                    Add Store
                </Button>
                <Spacer />
            </Stack>
        </PageContainer>
    );
};
