import { ROUTES } from "../Routes";
import { Layout } from "../layouts/Layout";
import {
    ListExperimentsPage,
    EditStorePage,
    ListStores,
    ManageExperimentPage,
    ManageCachePage,
    EdgeDisabledErrorPage,
    EditStoreTab,
    CacheSettingsTab,
    SessionContentTab,
    ShopifyTab,
    SalesforceTab,
    ListStoreGlobalHistoryTab,
    StoreSettingsTab,
    StorePermissionsTab,
    ClearCacheTab,
    CacheHistoryTab,
    ManageCacheDurationTab,
} from "../pages";
import { TabComponentWrapper } from "../components";

export const notInternalRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: ROUTES.LIST_STORES, element: <ListStores /> },
            {
                path: ROUTES.STORE_EDIT,
                element: <EditStorePage />,
                children: [
                    { path: "general", element: <TabComponentWrapper Component={EditStoreTab} /> },
                    { path: "cache-settings", element: <TabComponentWrapper Component={CacheSettingsTab} /> },
                    { path: "session-content", element: <TabComponentWrapper Component={SessionContentTab} /> },
                    { path: "shopify", element: <TabComponentWrapper Component={ShopifyTab} /> },
                    { path: "salesforce", element: <TabComponentWrapper Component={SalesforceTab} /> },
                    { path: "history", element: <TabComponentWrapper Component={ListStoreGlobalHistoryTab} /> },
                    { path: "store-settings", element: <TabComponentWrapper Component={StoreSettingsTab} /> },
                    { path: "permissions", element: <TabComponentWrapper Component={StorePermissionsTab} /> },
                ],
            },
            { path: ROUTES.LIST_EXPERIMENTS, element: <ListExperimentsPage /> },
            { path: ROUTES.MANAGE_EXPERIMENT, element: <ManageExperimentPage /> },
            {
                path: ROUTES.MANAGE_CACHE_AUTHENTICATED,
                element: <ManageCachePage />,
                children: [
                    { path: "clear-cache", element: <TabComponentWrapper Component={ClearCacheTab} /> },
                    { path: "cache-history", element: <TabComponentWrapper Component={CacheHistoryTab} /> },
                    { path: "cache-settings", element: <TabComponentWrapper Component={ManageCacheDurationTab} /> },
                ],
            },
            { path: ROUTES.DEPRECATED_CLEAR_CACHE_PAGE, element: <ManageCachePage /> },
            { path: ROUTES.DISABLED_ERROR_PAGE, element: <EdgeDisabledErrorPage /> },
        ],
    },
];
