import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

const DEFAULT_OPTIONS = {
    showToast: true,
};

export const useDeleteBulletinMutation = (bulletinId: string, options: Options = DEFAULT_OPTIONS) => {
    const queryClient = useQueryClient();
    const { showToast } = { ...DEFAULT_OPTIONS, ...options };

    const triggerToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    return useMutation(() => deleteBulletin(bulletinId), {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["bulletin"] });
            if (showToast)
                triggerToast({
                    title: "Success",
                    status: "success",
                    description: "The bulletin was successfully deleted.",
                });
        },
        onError: () => {
            if (showToast)
                triggerToast({
                    title: "Error",
                    status: "error",
                    description:
                        "An unexpected error occurred while deleting the bulletin. If the problem persists, contact support.",
                });
        },
    });
};

const deleteBulletin = async (bulletinId: string) => {
    const url = `${SVC_URLS["ecom"]}/bulletin/${bulletinId}`;
    const options = await fetchOptions({ method: "DELETE" });
    const response = await fetch(url, options).catch(handleErrors);
    return fetchResponse(response);
};

type Options = {
    showToast: boolean;
};
