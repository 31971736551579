import { useToast, Text, Link } from "@chakra-ui/react";
import { useToggleUserMutation, generateUsersQueryKey } from "../../hooks";
import { Tooltip } from "../../components";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    userId: string;
    active?: boolean;
};

export const ToggleUserStatus = ({ userId, active }: Props) => {
    const showToast = useToast();
    const queryClient = useQueryClient();
    const toggleUserMutation = useToggleUserMutation(userId);

    const handletoggleUserStatus = (userId: string) => {
        if (userId === undefined) return;

        const body = { active: !active };
        const message = active
            ? "Are you sure you want to disable this user?"
            : "Are you sure you want to enable this user?";
        const response = window.confirm(message);

        const toggleUserSuccess = () => {
            queryClient.invalidateQueries(generateUsersQueryKey(userId));

            showToast({
                title: "Success",
                description: `User has been ${!active ? "enabled" : "disabled"}!`,
                status: "success",
                duration: 8000,
                isClosable: true,
                variant: "subtle",
            });
        };

        const toggleUserError = () => {
            showToast({
                title: "Error",
                description: (
                    <Text>
                        We encountered an error while attempting to disable the user. Please wait a few moments and try
                        again. If the issue persists, kindly contact our &nbsp;
                        <Link
                            isExternal
                            textStyle={{
                                base: "text-link-meta-regular",
                                md: "text-link-meta-regular",
                            }}
                            href="https://nostra-corporate.webflow.io/contact-nostra-support"
                        >
                            Customer Success
                        </Link>{" "}
                        &nbsp; team for assistance.
                    </Text>
                ),
                status: "error",
                duration: null,
                isClosable: true,
                variant: "subtle",
            });
        };

        if (response) {
            toggleUserMutation.mutate(body, {
                onSuccess: toggleUserSuccess,
                onError: toggleUserError,
            });
        }
    };

    const toggleUser = active ? "disable" : "enable";

    return (
        <Tooltip label={`${toggleUser} User`} aria-label={`${toggleUser} User`}>
            <Link
                textStyle={{
                    base: "text-link-meta-regular",
                    md: "text-link-meta-regular",
                }}
                onClick={() => handletoggleUserStatus(userId)}
            >
                {toggleUser}
            </Link>
        </Tooltip>
    );
};
