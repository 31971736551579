import { useEffect, useState } from "react";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

export const useDismissedBulletins = () => {
    const { userId } = useAuthenticatedUser();
    const key = `dismissedBulletins_${userId}`;
    const [dismissedBulletins, setDismissedBulletins] = useState<string[]>([]);

    useEffect(
        function loadDismissedBulletinsOnMount() {
            const dismissedBulletinIdsString = localStorage.getItem(key);
            const dismissedBulletinIds = dismissedBulletinIdsString ? JSON.parse(dismissedBulletinIdsString) : [];
            setDismissedBulletins(dismissedBulletinIds);
        },
        [userId, key],
    );

    const dismissBulletin = (bulletinId: string) => {
        // Add the bulletin to dismiss id to local storage
        const updatedDismissedBulletins = [...dismissedBulletins, bulletinId];
        setDismissedBulletins(updatedDismissedBulletins);
        localStorage.setItem(key, JSON.stringify(updatedDismissedBulletins));
    };

    return { dismissedBulletins, dismissBulletin };
};
