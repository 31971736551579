import {
    Heading,
    Text,
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td,
    Divider,
    Center,
    HStack,
    Spacer,
    VStack,
    IconButton,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    useDisclosure,
} from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { EditIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { Experiment, Store } from "../../types";
import { EditWeightDrawer } from "./EditWeightDrawer";
// import { UpdateVariantNameDrawer } from "./UpdateVariantNameDrawer";
import {
    // useDeleteVariantMutation,
    // generateExperimentQueryKey,
    useExperiment,
    useStore,
    // useAuthenticatedUser,
} from "../../hooks";
import { asPercent } from "../../lib/formatters/asPercent";
import { SubHeader, Header, ToggleExperimentButton, Link } from "../../components";
import { formatUrl } from "../../lib/formatters/formatUrl";
// import { buildInternalPreviewUrl } from "../Editor/lib";
import { LinkButton } from "../ListStores/LinkButton";
import { NotFound404 } from "../ErrorPages/NotFound404";
// import { BRAND_NAME } from "../../config/BRAND_NAME";
// import { IconDeviceMobile, IconDeviceDesktop } from "@tabler/icons-react";
import { LoadingPage } from "../LoadingPage";
import { SpeedExperimentHistoryTable } from "./SpeedExperimentHistoryTable";

export const ManageExperimentPage = () => {
    const storeQuery = useStore();
    const experimentQuery = useExperiment();
    const { experiment, store, isLoading } = useData([experimentQuery, storeQuery]);
    // const showToast = useToast();
    // const navigate = useNavigate();
    const params = useParams();
    // const queryClient = useQueryClient();
    // const [currentVariant, setCurrentVariant] = useState<Variation>();
    // const deleteVariantMutation = useDeleteVariantMutation();
    const { isOpen: isOpenWeight, onOpen: onOpenWeight, onClose: onCloseWeight } = useDisclosure();
    const customerUrl = params.customerUrl ?? "";
    // const { isOpen: isOpenEditName, onOpen: onOpenEditName, onClose: onCloseEditName } = useDisclosure();

    // const { isCloudOpsRole } = useAuthenticatedUser();

    // const containsLegacyVariations = useMemo(() => {
    //     if (experiment === undefined) return false;
    //     if (experiment.pageset.sampleUrl === "" || experiment.pageset.sampleUrl === undefined) return true;

    //     return experiment.variations.some((variation) => {
    //         if (variation.name === "Control") return false; // skip/ignore "Control" variation
    //         return variation.actions.some((action) => !action.insertText.includes("data-internal-action-id"));
    //     });
    // }, [experiment]);

    // const deleteVariant = (variantId: string) => {
    //     if (!experiment) return;
    //     const response = window.confirm("Are you sure you want to delete this variation?");

    //     const onDeleteSuccess = () => {
    //         queryClient.invalidateQueries(generateExperimentQueryKey(experiment.id));
    //         showToast({
    //             title: "Success",
    //             description: "Variation deleted successfully, weigths have been re-adjusted!",
    //             status: "success",
    //         });
    //     };

    //     const onDeleteError = (error: any) => {
    //         showToast({
    //             title: error.name || "Error",
    //             description: "An error occurred while trying to delete the variation!",
    //             status: "error",
    //         });
    //     };

    //     if (response) {
    //         deleteVariantMutation.mutate(
    //             { variantId, customerUrl },
    //             {
    //                 onSuccess: onDeleteSuccess,
    //                 onError: onDeleteError,
    //             },
    //         );
    //     }
    // };
    /**
     * Loading state
     */
    if (isLoading) return <LoadingPage />;

    /**
     * Data not found
     */
    if (experiment === undefined || store === undefined) {
        return <NotFound404 />;
    }

    const sampleUrl = formatUrl(experiment.pageset.sampleUrl);

    return (
        <PageContainer>
            <HStack pb={4}>
                <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={generatePath(ROUTES.HOME)}>
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={generatePath(ROUTES.LIST_STORES)}>
                            List Stores
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink
                            as={Link}
                            to={generatePath(ROUTES.LIST_EXPERIMENTS, {
                                customerUrl,
                            })}
                        >
                            List Experiments
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage isLastChild>
                        <Text>Manage Experiment</Text>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HStack>
            <HStack align={"baseline"}>
                <VStack alignItems={"baseline"}>
                    <Header>Manage Experiment</Header>
                    <SubHeader>{experiment.name}</SubHeader>
                </VStack>
                <Spacer />
                <ToggleExperimentButton
                    experiment={experiment}
                    customerUrl={store.customerUrl}
                    edgeEnabled={store.edgeEnabled}
                />
            </HStack>
            <Heading pb={"spacer-4"} as="h3" size="sm">
                Variations
            </Heading>

            {/* {containsLegacyVariations ? (
                <HStack>
                    <Text color={"text-secondary"}>
                        ⚠️ This experiment does not support self-service content editing.{" "}
                        <Link
                            color="text-secondary"
                            textDecoration="underline"
                            target="_blank"
                            to="https://nostra-corporate.webflow.io/contact-nostra-support"
                        >
                            Contact support
                        </Link>{" "}
                        to change content.
                    </Text>
                </HStack>
            ) : (
                <HStack display="flex" alignItems="right" pb="0.25rem" mb={"spacer-2"}>
                    {experiment.active ? (
                        <Text color={"text-secondary"}>
                            Status: <b>Active</b>
                        </Text>
                    ) : (
                        <Text color={"text-secondary"}>What do you want to test?</Text>
                    )}
                </HStack>
            )} */}

            <TableContainer
                border="1px solid"
                borderColor="ui-element-outline-default"
                borderRadius="md"
                padding="0px 10px"
                mb={"spacer-4"}
            >
                <Table variant="simple" size="md">
                    {experiment.active ? (
                        <Tbody>
                            {experiment.variations.map((variation) => {
                                if (variation.id === undefined) return null;

                                // const isControl = variation.name === "Control";
                                // const disableNameEdit = isControl && !isCloudOpsRole;
                                return (
                                    <Tr key={variation.id}>
                                        <Td width="100%">{variation.name}</Td>
                                        <Td>
                                            <Center height="25px">
                                                <Divider orientation="vertical" />
                                            </Center>
                                        </Td>
                                        <Td isNumeric>
                                            <HStack>
                                                <IconButton
                                                    variant="outline"
                                                    aria-label="Edit Weight"
                                                    icon={<EditIcon />}
                                                    onClick={onOpenWeight}
                                                    isDisabled={!experiment.active}
                                                />
                                                <Text minWidth={"45px"}>{asPercent(variation.weight)}</Text>
                                                &nbsp;
                                                <Text>weight</Text>
                                            </HStack>
                                        </Td>
                                        {/* <Td>
                                        <Center height="1.563rem">
                                            <Divider orientation="vertical" />
                                        </Center>
                                    </Td> */}
                                        {/* <Td minWidth={"9.688rem"}>
                                        {sampleUrl ? (
                                            experiment.active || isControl || containsLegacyVariations ? (
                                                <Menu>
                                                    <MenuButton
                                                        as={Button}
                                                        isDisabled={store.edgeEnabled === false}
                                                        title={
                                                            store.edgeEnabled === false
                                                                ? `${BRAND_NAME} services are currently paused.`
                                                                : undefined
                                                        }
                                                        cursor="pointer"
                                                        minWidth={"56px"}
                                                        size="sm"
                                                        colorScheme={"button-primary"}
                                                        variant="outline"
                                                    >
                                                        View
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<IconDeviceDesktop stroke={"1.25"} />}
                                                            isDisabled={experiment.isDesktopEligible === false}
                                                            onClick={() =>
                                                                window.open(
                                                                    buildInternalPreviewUrl(sampleUrl, variation.id!),
                                                                    "_blank",
                                                                    "noreferrer",
                                                                )
                                                            }
                                                        >
                                                            Desktop
                                                        </MenuItem>
                                                        <MenuItem
                                                            isDisabled={experiment.isMobileEligible === false}
                                                            icon={<IconDeviceMobile stroke={"1.25"} />}
                                                            onClick={() =>
                                                                window.open(
                                                                    generatePath(ROUTES.MOBILE_PREVIEW, {
                                                                        experimentId: experiment.id,
                                                                        variationId: variation.id,
                                                                    }),
                                                                    "_blank",
                                                                    "noreferrer",
                                                                )
                                                            }
                                                        >
                                                            Mobile
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            ) : (
                                                <Button
                                                    isDisabled={store.edgeEnabled === false}
                                                    title={
                                                        store.edgeEnabled === false
                                                            ? `${BRAND_NAME} services are currently paused.`
                                                            : undefined
                                                    }
                                                    colorScheme={"button-primary"}
                                                    variant="outline"
                                                    size="sm"
                                                    minWidth={"56px"}
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(ROUTES.CREATE_VARIANT_CONTENT, {
                                                                customerUrl,
                                                                experimentId: experiment.id,
                                                                variantId: variation.id,
                                                            }),
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            )
                                        ) : (
                                            <Text>🚫 Sample URL</Text>
                                        )}
                                    </Td> */}
                                        {/* <Td>
                                        {experiment.active ? null : (
                                            <Menu>
                                                <Tooltip label="More Options" aria-label="More Options">
                                                    <MenuButton as={PlusSquareIcon} cursor="pointer" />
                                                </Tooltip>
                                                <MenuList>
                                                    <MenuItem
                                                        isDisabled={experiment.active ?? disableNameEdit}
                                                        onClick={() => {
                                                            setCurrentVariant(variation);
                                                            onOpenEditName();
                                                        }}
                                                        icon={<EditIcon />}
                                                    >
                                                        Edit Name
                                                    </MenuItem>
                                                    <MenuItem
                                                        isDisabled={experiment.active}
                                                        onClick={() => {
                                                            deleteVariant(variation.id as string);
                                                        }}
                                                        icon={<DeleteIcon />}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        )}
                                    </Td> */}
                                    </Tr>
                                );
                            })}
                            <Tr>
                                <Td colSpan={5}>
                                    <HStack>
                                        <Spacer />
                                        <Text color={"text-link-secondary-default"}>
                                            {!sampleUrl ? null : (
                                                <>
                                                    <LinkButton url={sampleUrl} />
                                                    <Link target="_blank" to={sampleUrl} className="trackCustomerUrl">
                                                        {sampleUrl}
                                                    </Link>
                                                </>
                                            )}
                                        </Text>
                                    </HStack>
                                </Td>
                            </Tr>
                        </Tbody>
                    ) : (
                        <Tbody height="205px" style={{ alignItems: "center" }}>
                            <Tr>
                                <Td colSpan={3} textAlign="center" verticalAlign="middle">
                                    <Text textStyle="text-header-L">No active experiment</Text>
                                </Td>
                            </Tr>
                        </Tbody>
                    )}
                </Table>
            </TableContainer>
            <SpeedExperimentHistoryTable storeId={store.id}></SpeedExperimentHistoryTable>

            {experiment ? (
                <EditWeightDrawer
                    variants={experiment.variations}
                    isOpen={isOpenWeight}
                    onClose={onCloseWeight}
                    experimentId={experiment.id}
                    customerUrl={store.customerUrl}
                />
            ) : null}

            {/* {experiment && currentVariant ? (
                <UpdateVariantNameDrawer
                    isOpen={isOpenEditName}
                    onClose={onCloseEditName}
                    variant={currentVariant}
                    experimentId={experiment.id}
                    customerUrl={store.customerUrl}
                />
            ) : null} */}
        </PageContainer>
    );
};

const useData = ([experiment, store]: Array<any>): {
    isLoading: boolean;
    store: Store | undefined;
    experiment: Experiment | undefined;
} => {
    const isLoading = experiment.isLoading || store.isLoading;

    return {
        isLoading,
        store: store.data,
        experiment: experiment.data,
    };
};
