import data from "./featureFlags.json";

/**
 * Given a feature flag, return whether it is enabled or not for the current environment.
 * If a feature flag is not found, return false.
 * @param flagName
 * @returns boolean
 */
export const getFeatureFlag = (flagName: string): boolean => {
    if (flags[flagName] === undefined) console.warn(`Feature flag ${flagName} not found.`);
    return flags[flagName] || false;
};

const loadFeatureFlags = () => {
    const env: string | undefined = process.env.REACT_APP_FEATURE_FLAG_ENVIRONMENT;
    if (env === undefined) throw new Error("No feature flag environment specified.");
    if (!data) throw new Error("No feature flag definition file.");
    return (data as { [key: string]: any })[env];
};

const flags = loadFeatureFlags();
