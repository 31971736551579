import { useSearchParams } from "react-router-dom";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const OrderTaggingCoverage = () => {
    const [searchParams] = useSearchParams();
    const report = "order_coverage";

    const show_text =
        searchParams.get(`${report}_show_text`) === null || searchParams.get(`${report}_show_text`) === "true"
            ? true
            : false;

    const show_labels = searchParams.get(`${report}_show_labels`) === "true" ? true : false;

    const metricParams = {
        interval_unit: searchParams.get(`${report}_interval_unit`) ?? "days",
        interval_count: searchParams.get(`${report}_interval_count`) ?? 365,
        show_text,
        show_labels,
    };

    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={report} params={metricParams} />
        </RenderMetric>
    );
};
