import { EMBEDDED_QUERY_PARAMETER } from "../../EMBEDDED_QUERY_PARAMETER";
import { applyProtocolToHostname, addQueryParameter } from "../../../../lib";

const VARIATION_ID_QUERY_PARAMETER = "variationId";

export const buildInternalPreviewUrl = (sampleUrl: string, variantId: string): string => {
    let url = applyProtocolToHostname(sampleUrl);
    url = addQueryParameter(url, EMBEDDED_QUERY_PARAMETER, true);
    url = addQueryParameter(url, VARIATION_ID_QUERY_PARAMETER, variantId);

    return url;
};
