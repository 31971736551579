import { Text, Box, HStack, Button } from "@chakra-ui/react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { DataTable } from "../../../components";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateTimeDisplay } from "../../../lib";
import { LoadingPage } from "../../LoadingPage";
import { useCacheSettingsHistory } from "../../../hooks/queries";
import { convertToHighestUnit } from "./convertToHighestUnit";
import { HistoryFields, HistoryRow } from "../../../types";
import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

type Props = {
    customerUrl: string;
};

export const CacheDurationHistoryLog = ({ customerUrl }: Props) => {
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialPage = parseInt(params.get("page") ?? "0", 10);
    const [page, setPage] = useState(initialPage);
    const { data: history, isLoading, refetch } = useCacheSettingsHistory(customerUrl, page.toString());
    const [rows, setRows] = useState<HistoryRow[]>([]);
    const queryClient = useQueryClient();
    const isFetching = useIsFetching();
    const columnHelper = createColumnHelper<HistoryRow>();
    const buildColumns = useCallback(
        () =>
            (history?.fields ?? []).map((field) => {
                const accessor = field as keyof HistoryRow;
                return columnHelper.accessor(accessor, {
                    cell: (props) => {
                        const value = props.getValue() as any;
                        if (field === HistoryFields.Date) {
                            return formatDateTimeDisplay(value);
                        }
                        if (typeof value === "object") {
                            const { amount, unit } = convertToHighestUnit(value.duration);
                            return (
                                <>
                                    <em>path:</em> {value.prefix} <em>duration:</em> {amount} {unit}(s)
                                </>
                            );
                        }
                        if (typeof value === "number") {
                            const { amount, unit } = convertToHighestUnit(value);
                            return `${amount} ${unit}(s)`;
                        }
                        return value;
                    },
                    header: () => field,
                });
            }) ?? [],
        [history?.fields, columnHelper],
    );
    const columns = buildColumns();

    useEffect(
        function setHistoryRows() {
            if (!history?.rows) return;
            setRows(history?.rows);
        },
        [history?.rows],
    );

    // force reload on back/forward navigation
    useEffect(() => {
        const handlePopstate = () => {
            window.location.reload();
        };
        window.addEventListener("popstate", handlePopstate);
        return () => window.removeEventListener("popstate", handlePopstate);
    }, []);

    const setUrlParams = useCallback(
        (page: number) => {
            params.set("page", page.toString());
            const updatedUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.pushState({}, "", updatedUrl);
            return updatedUrl;
        },
        [params],
    );

    const handleNextPage = useCallback(() => {
        if (!history?.hasMore) return;
        setPage((currentPage) => {
            const nextPage = currentPage + 1;
            setUrlParams(nextPage);
            return nextPage;
        });
    }, [setPage, setUrlParams, history?.hasMore]);

    const handlePreviousPage = useCallback(() => {
        setPage((currentPage) => {
            const previousPage = Math.max(currentPage - 1, 0);
            setUrlParams(previousPage);
            return previousPage;
        });
    }, [setPage, setUrlParams]);

    const handleNextPageRefetch = async () => {
        await handleNextPage();
        queryClient.invalidateQueries(["history", customerUrl, "cache-settings"]);
        await refetch();
    };
    const handlePreviousPageRefetch = async () => {
        await handlePreviousPage();
        queryClient.invalidateQueries(["history", customerUrl, "cache-settings"]);
        await refetch();
    };

    if (isLoading || isFetching) return <LoadingPage />;

    return (
        <>
            <Box pt="spacer-12" pb="spacer-24" justifyContent={{ base: "center", md: "flex-start" }}>
                <Text
                    textAlign={{ base: "left", md: "center" }}
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    pb="spacer-2"
                >
                    {history?.title}
                </Text>
                <HStack py={"spacer-4"} justifyContent={"flex-end"}>
                    <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                        Previous
                    </Button>
                    <Button onClick={handleNextPageRefetch} isDisabled={!history?.hasMore} colorScheme="button-primary">
                        Next
                    </Button>
                </HStack>
                {rows.length === 0 ? (
                    <Text
                        textStyle={{
                            base: "text-header-S",
                            md: "text-header-M",
                            lg: "text-header-L",
                        }}
                        justifyContent="center"
                        display="flex"
                        style={{ marginTop: "8rem" }}
                    >
                        None Found
                    </Text>
                ) : null}
                {rows.length > 0 ? <DataTable columns={columns} data={rows} /> : null}
                {rows.length === 0 ? null : (
                    <HStack py={"spacer-8"} justifyContent={"flex-end"}>
                        <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                            Previous
                        </Button>
                        <Button
                            onClick={handleNextPageRefetch}
                            isDisabled={!history?.hasMore}
                            colorScheme="button-primary"
                        >
                            Next
                        </Button>
                    </HStack>
                )}
            </Box>
        </>
    );
};
