import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { EditorView } from "@codemirror/view";
import { useEditorContext } from "../EditorProvider";

export const HtmlEditor = () => {
    const { editorContext, dispatch } = useEditorContext();

    return (
        <CodeMirror
            value={editorContext.selectedElement?.element.current?.innerHTML}
            onChange={(value) => dispatch({ type: "htmlContentUpdated", payload: value })}
            height="500px"
            extensions={[html(), EditorView.lineWrapping]}
        />
    );
};
