import { Text } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { CopyButton } from "../CopyButton";
import { SubHeader } from "../SubHeader";
import { Tooltip } from "../Tooltip";
type Props = PropsWithChildren<{
    legend?: string | React.ReactNode | undefined;
    storeId?: string;
    style?: React.CSSProperties;
}>;

export const FieldSet = ({ legend, children, storeId, ...rest }: Props) => {
    let style = rest.hasOwnProperty("style") ? { ...fieldSetStyle, ...rest.style } : fieldSetStyle;
    delete rest.style;
    return (
        <fieldset style={style} {...rest}>
            <legend>
                <SubHeader
                    px={"spacer-2"}
                    pb={0}
                    style={{ fontWeight: "bold" }}
                    textStyle={{ base: "text-body-large-regular" }}
                >
                    {storeId ? (
                        <Tooltip label={"Copy ID to Clipboard"} aria-label="Copy id to clipboard">
                            <Text as="span" noOfLines={1}>
                                {legend} <CopyButton ml={0} mr={0} value={storeId}></CopyButton>
                            </Text>
                        </Tooltip>
                    ) : (
                        legend
                    )}
                </SubHeader>
            </legend>
            {children}
        </fieldset>
    );
};

const fieldSetStyle = {
    border: "1px solid var(--chakra-colors-chakra-border-color)",
    borderRadius: "var(--chakra-radii-md)",
    padding: "1rem",
    marginBottom: "1rem",
    paddingBottom: "1.25rem",
};
