import {
    Button,
    FormControl,
    Input,
    Textarea,
    VStack,
    Flex,
    useDisclosure,
    useToast,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    Box,
} from "@chakra-ui/react";
import { ChangeEvent, FormEventHandler, useCallback, useMemo } from "react";
import { Label, Tooltip } from "../../../components";
import { useEditorContext } from "../EditorProvider";
import { HtmlEditorModal } from "./HtmlEditorModal";
import { useUpsertActionMutation } from "../hooks/queries/useUpsertActionMutation";
import { useQueryClient } from "@tanstack/react-query";
import { generateExperimentQueryKey, useExperiment, useAuthenticatedUser } from "../../../hooks";
import { getFeatureFlag } from "../../../lib";
import { defaultToastOptions } from "../lib/defaultToastOptions";

const DO_NOT_RELOAD_PAGE = false;

/**
 * Action.insertText is a string that is used to replace the selected text for experiments
 * via String.replace(regex, replacement). The `replacement` string has a special syntax regarding
 * the `$` character. The `$` character is used to reference a capture group from the regex.
 *
 * Therefore, we need to escape all `$` characters in the replacement string with `$$` so that
 * experiments that include things like `$` will work as expected.
 */
const escapeReplacementString = (str: string) => str.replace(/\$/g, "$$$");

export const ExperimentSideBar = () => {
    const { editorContext, dispatch } = useEditorContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: experiment } = useExperiment();
    const upsertActionMutation = useUpsertActionMutation();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const queryClient = useQueryClient();
    const showToast = useToast(defaultToastOptions);

    const onSuccess = useCallback(() => {
        dispatch({ type: "setEditorMode", payload: "BROWSING" });
        showToast({
            title: "Success",
            description: "Your content changes have been saved.",
            status: "success",
        });
        queryClient.invalidateQueries(generateExperimentQueryKey(experiment!.id!));
    }, [showToast, queryClient, experiment, dispatch]);

    const onError = useCallback(
        (data: any) => {
            showToast({
                title: "Error",
                description: "An unknown error has occurred. Please try again. Error code: 121'",
                status: "error",
            });
        },
        [showToast],
    );

    const submitForm: FormEventHandler<HTMLFormElement> = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();

        // Access the form element from the event object
        const form = event.target as HTMLFormElement;

        // Create a new FormData instance with the form element
        const formData = new FormData(form);

        const content = formData.get("textContent") as string;

        if (content === null) {
            showToast({
                title: "Error",
                description: "Something went wrong. Please try again. Error code: 111",
                status: "error",
            });
            throw new Error("content is null");
        }

        const action = JSON.parse(formData.get("action") as string);

        if (action === null) {
            showToast({
                title: "Error",
                description: "Something went wrong. Please try again. Error code: 112",
                status: "error",
            });
            throw new Error("Error: Action is null");
        }

        if (upsertActionMutation.isLoading === false) {
            // Lint Exception: escape character is for the RegExp, not the string.
            // eslint-disable-next-line
            action.insertText = `\$1 data-internal-action-id="${action.id}">${escapeReplacementString(content)}\$3`;
            upsertActionMutation.mutate(action, { onSuccess, onError });
        }

        return DO_NOT_RELOAD_PAGE;
    };

    const showTextEditor = useMemo(() => {
        if (editorContext.selectedElement === undefined) return false;
        return editorContext.mode === "EDITING" && editorContext.selectedElement.interaction === "EDIT_TEXT";
    }, [editorContext.mode, editorContext.selectedElement]);

    const isEditHTMLEnabled = getFeatureFlag("EDITOR_HTML") || isCloudOpsRole;
    const isEditHTMLDisabled =
        !isEditHTMLEnabled || editorContext.selectedElement?.supportedInteractions.includes("EDIT_TEXT");

    return (
        <>
            <Container>
                {editorContext.selectedElement?.element?.original.tagName ? (
                    <Box>
                        {/* Chakra UI bug: https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911 */}
                        <Menu>
                            <MenuButton as={Button} isActive={isOpen}>
                                Edit Element
                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    isDisabled={editorContext.selectedElement?.supportedInteractions.includes(
                                        "EDIT_MARKUP",
                                    )}
                                    onClick={() => {
                                        dispatch({ type: "setEditorMode", payload: "EDITING" });
                                    }}
                                    disabled={
                                        !editorContext.selectedElement?.supportedInteractions.includes("EDIT_TEXT")
                                    }
                                >
                                    Edit Text
                                </MenuItem>
                                <MenuItem
                                    isDisabled={isEditHTMLDisabled}
                                    onClick={() => {
                                        dispatch({ type: "setEditorMode", payload: "EDITING" });
                                        onOpen();
                                    }}
                                    disabled={
                                        !editorContext.selectedElement?.supportedInteractions.includes("EDIT_MARKUP")
                                    }
                                >
                                    {!isEditHTMLEnabled ? (
                                        <Tooltip label="Coming soon!" aria-label="Coming soon">
                                            Edit HTML
                                        </Tooltip>
                                    ) : (
                                        <>Edit HTML</>
                                    )}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                ) : (
                    <Button isDisabled>Select an element</Button>
                )}

                <br />

                {editorContext.selectedElement?.supportedInteractions.includes("EDIT_MARKUP") && isEditHTMLEnabled ? (
                    <FormControl>
                        <form onSubmit={submitForm}>
                            <Input type="hidden" name="action" value={JSON.stringify(editorContext.currentAction)} />
                            <Input
                                type="hidden"
                                name={"textContent"}
                                value={editorContext.selectedElement?.element?.current.innerHTML}
                            />

                            <Flex justifyContent={"space-between"}>
                                <Button
                                    colorScheme="button-primary"
                                    variant="outline"
                                    type="submit"
                                    mt={4}
                                    mb={2}
                                    display={"inline-flex"}
                                    onClick={() => dispatch({ type: "editCanceled" })}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    colorScheme="button-primary"
                                    type="submit"
                                    display={"inline-flex"}
                                    mt={4}
                                    mb={2}
                                    mr={2}
                                    isDisabled={
                                        editorContext.selectedElement?.element.current.innerHTML ===
                                        editorContext.selectedElement?.element.original.innerHTML
                                    }
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </FormControl>
                ) : null}

                {showTextEditor ? (
                    <FormControl>
                        <form onSubmit={submitForm}>
                            <Label htmlFor="textContent">Text:</Label>

                            <Input type="hidden" name="action" value={JSON.stringify(editorContext.currentAction)} />

                            <Textarea
                                name={"textContent"}
                                value={editorContext.selectedElement?.element.current.textContent ?? ""}
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                    const { value } = event.target;
                                    const currentInteraction = editorContext.selectedElement!.interaction;

                                    switch (currentInteraction) {
                                        case "EDIT_TEXT":
                                            dispatch({ type: "textContentUpdated", payload: value });
                                            break;
                                        case "EDIT_MARKUP":
                                            dispatch({ type: "htmlContentUpdated", payload: value });
                                            break;
                                        default:
                                            showToast({
                                                title: "Error",
                                                description: "Something went wrong. Please try again. Error code: 113",
                                                status: "error",
                                            });
                                            throw new Error("Unknown interaction: " + currentInteraction);
                                    }
                                }}
                            />

                            <Flex justifyContent={"space-between"}>
                                <Button
                                    colorScheme="button-primary"
                                    variant="outline"
                                    type="submit"
                                    mt={4}
                                    mb={2}
                                    ml={2}
                                    display={"inline-flex"}
                                    onClick={() => dispatch({ type: "editCanceled" })}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    colorScheme="button-primary"
                                    type="submit"
                                    display={"inline-flex"}
                                    isDisabled={
                                        editorContext.selectedElement?.element.current.textContent ===
                                        editorContext.selectedElement?.element.original.textContent
                                    }
                                    mt={4}
                                    mb={2}
                                    mr={2}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </form>
                    </FormControl>
                ) : null}
            </Container>
            <HtmlEditorModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

type ContainerProps = {
    children: React.ReactNode;
};

const Container = ({ children }: ContainerProps) => {
    return (
        <VStack
            flexDirection={"column"}
            alignItems={"center"}
            align="stretch"
            padding={"24px 12px"}
            gap={"8px"}
            width={"100%"}
            height={"100%"}
            justifyContent={"flex-start"}
            bgColor={"background-action-default"}
            h="100%"
        >
            {children}
        </VStack>
    );
};
