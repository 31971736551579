import {
    Input,
    InputGroup,
    InputRightElement,
    useToast,
    Button,
    Text,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Stack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { FieldSet, IconButtonShow, IconButtonHide, CopyButton, Tooltip, Link } from "../../../components";
import { generateStoreQueryKey, useGenerateAPITokenMutation, generateApiTokenHistoryQueryKey } from "../../../hooks";
import { getFeatureFlag } from "../../../lib";
import { ROUTES } from "../../../Routes";
import { Store } from "../../../types";
import { PageContainer } from "../../PageContainer";
import { StorePermissionsHistoryLog } from "./StorePermissionsHistoryLog";

type Props = {
    store: Store;
};

export const StorePermissionsTab = ({ store }: Props) => {
    const generateAPIToken = useGenerateAPITokenMutation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef(null);
    const [show, setShow] = useState(false);
    const queryClient = useQueryClient();

    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    const handleGenerateAPIToken = () => {
        if (!store.id) return;

        generateAPIToken.mutate(
            { storeId: store.id, customerUrl: store.customerUrl },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                    queryClient.invalidateQueries(generateApiTokenHistoryQueryKey(store.customerUrl));
                    showToast({
                        title: "Sucess",
                        status: "success",
                        description: "The API token has been successfully generated.",
                    });
                },
                onError: () => {
                    showToast({
                        title: "Error",
                        status: "error",
                        description:
                            "There was an error generating the API token. If the problem persists, please contact support.",
                    });
                },
            },
        );
    };

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxWidth={"1440px"}>
            <FieldSet legend="API Token">
                <Text pb={"spacer-4"}>
                    An API token is a unique identifier that grants access to an API (Application Programming
                    Interface). For more detailed information on this, please refer to our API{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_API}>
                        documentation
                    </Link>
                    .
                </Text>
                <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "center", md: "baseline" }}>
                    <InputGroup pb={"spacer-4"} hidden={isEmptyOrNull(store.clientApiToken)}>
                        <Input isDisabled isTruncated type={show ? "text" : "password"} value={store.clientApiToken} />
                        <InputRightElement width="4.5rem">
                            {show ? (
                                <IconButtonHide
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() => {
                                        setShow(!show);
                                    }}
                                />
                            ) : (
                                <IconButtonShow
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() => {
                                        setShow(!show);
                                    }}
                                />
                            )}
                            &nbsp;
                            <Tooltip label={"Copy to Clipboard"} aria-label="Copy to clipboard">
                                <Text as="span">
                                    <CopyButton ml={0} mr={0} value={store.clientApiToken} />
                                </Text>
                            </Tooltip>
                        </InputRightElement>
                    </InputGroup>
                    <Button
                        colorScheme={"button-primary"}
                        onClick={() => {
                            if (isEmptyOrNull(store.clientApiToken)) {
                                handleGenerateAPIToken();
                            } else {
                                onOpen();
                            }
                        }}
                        paddingLeft={{ base: "20px", md: "35px" }}
                        paddingRight={{ base: "20px", md: "35px" }}
                    >
                        Generate New Token
                    </Button>
                    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                    WARNING
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                    What you are about to do will <strong>break</strong> any existing API integrations
                                    if they aren't updated with the new token value. Do you want to proceed with
                                    regenerate API token?
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        colorScheme="red"
                                        onClick={() => {
                                            onClose();
                                            handleGenerateAPIToken();
                                        }}
                                        ml={3}
                                    >
                                        Generate
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </Stack>
            </FieldSet>
            {getFeatureFlag("STORE_PERMISSIONS_HISTORY") ? (
                <StorePermissionsHistoryLog customerUrl={store.customerUrl} />
            ) : null}
        </PageContainer>
    );
};

const isEmptyOrNull = (clientApiToken: string) => {
    return clientApiToken === null || clientApiToken.length === 0;
};
