import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useDeleteStoreMutation } from "../hooks/queries";
import { Store } from "../types";
import { useDeleteWebhooksMutation } from "../hooks/queries";
import { useStore } from "../hooks/queries";
import { LoadingPage } from "../pages/LoadingPage";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { withDeleteStoreConfirmationModal } from "../lib";

type Props = {
    isOpen: boolean;
    close: () => void;
    storeData: Pick<Store, "id" | "customerUrl">;
    redirectRoute?: string;
};

export const DeleteStoreAlertDialog = ({ isOpen, close, storeData, redirectRoute }: Props) => {
    const location = useLocation();
    const cancelRef = useRef(null);
    const [enableStoreQuery, setEnableStoreQuery] = useState<boolean>(true);
    const { data: store, isLoading } = useStore({
        config: { retry: 0, enabled: enableStoreQuery },
        domain: storeData.customerUrl,
    });
    const deleteStoreMutation = useDeleteStoreMutation(storeData.customerUrl!);
    const deleteWebhooksMutation = useDeleteWebhooksMutation(store?.id ?? "", store?.customerUrl ?? "", {
        showSuccessToast: false,
        showErrorToast: true,
    });
    const queryClient = useQueryClient();

    if (!store) return null;
    if (isLoading) return <LoadingPage />;

    const handleStoreDelete = async () => {
        // if no redirect url is provided, use the current pathname and append the confirmation modal query params to the current page
        if (!redirectRoute) {
            redirectRoute = withDeleteStoreConfirmationModal(location.pathname, store);
        }

        try {
            if (store.workerPlatform === "shopify" && store.shopifyAuthToken) {
                const andCarryOn = () => {};
                await deleteWebhooksMutation.mutateAsync(store.id).catch(andCarryOn);
            }
            await deleteStoreMutation.mutateAsync({
                storeId: store.id,
                customerUrl: store.customerUrl,
                redirectRoute,
            });
        } catch (error) {
            console.error(error);
            return;
        }
        setEnableStoreQuery(false);
        queryClient.invalidateQueries({ queryKey: ["stores"] });
        close();
    };

    return (
        <AlertDialog isOpen={isOpen} onClose={close} leastDestructiveRef={cancelRef} returnFocusOnClose={true}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Store
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Are you sure you want to delete <b>{storeData.customerUrl}</b>? This action cannot be undone.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button variant={"outline"} colorScheme={"button-primary"} onClick={() => close()}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme={"button-delete"}
                            onClick={handleStoreDelete}
                            ml={3}
                            isLoading={deleteWebhooksMutation.isLoading || deleteStoreMutation.isLoading}
                        >
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
