import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useResetPasswordMutation = () => useMutation(resetPassword);

const resetPassword = async (userId: string) => {
    const url = `${SVC_URLS["auth"]}/user/${userId}/reset-password`;
    const options = await fetchOptions({
        method: "PUT",
    });
    const response = await fetch(url, options).catch(handleErrors);
    return fetchResponse(response);
};
