import { useParams } from "react-router-dom";
import { CacheDurationMap, Store } from "../../types/types";
import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";

type Configuration = {
    config?: {
        retry?: number;
        enabled?: boolean;
    };
    domain?: string;
};

// Grabs the store from the parameter customerUrl by default. Otherwise, one must be provided.
export const useStore = (
    { config, domain = "" }: Configuration = {
        config: {
            enabled: true,
        },
        domain: "",
    },
) => {
    const params = useParams<Record<string, string>>(); // @ToDo: Use a more specific type to represent the expected route params
    let customerUrl: string;
    params.customerUrl ? (customerUrl = params.customerUrl) : (customerUrl = domain);

    return useQuery<Store & { cacheDurationMap: CacheDurationMap }>(
        generateStoreQueryKey(customerUrl),
        async () => await fetchStore(customerUrl),
        {
            retry: config?.retry !== undefined ? config.retry : 3,
            enabled: config?.enabled,
            select(data) {
                // Ensure there is always a 'root' entry in the cacheDurationMap
                // fallback to the __global__ value if root is not defined
                data.cacheDurationMap["root"] = data.cacheDurationMap["root"] ?? data.cacheDurationMap["__global__"];
                return data;
            },
        },
    );
};

export const fetchStore = async (customerUrl: string) => {
    const GET_STORE = `${SVC_URLS["ecom"]}/store/${customerUrl}`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(GET_STORE, options).catch(handleErrors);

    return await fetchResponse(response);
};

export const generateStoreQueryKey = (customerUrl: string) => {
    return ["store", customerUrl];
};
