import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { SVC_URLS } from "../../../../config/SVC_URLS";
import { fetchOptions } from "../../../../lib/http/fetchOptions";
import { fetchResponse } from "../../../../lib/http/fetchResponse";
import { handleErrors } from "../../../../lib/http/handleErrors";
import { UpsertActionRequest } from "../../../../types";

export const useUpsertActionMutation = () => {
    const params = useParams();
    const customerUrl = params.customerUrl ?? "";
    const { variantId } = useParams<{ variantId: string }>();
    const url = `${SVC_URLS["experiment"]}/variations/${variantId}/action`;
    return useMutation({ mutationFn: addActionsToExperiment(url, customerUrl) });
};

const addActionsToExperiment = (url: string, customerUrl: string) => async (request: UpsertActionRequest) => {
    const body = JSON.stringify(request);
    const options = await fetchOptions({
        method: "POST",
        body,
        headers: { Store: customerUrl },
    });

    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
