import { useStores } from "../hooks";
import { LoadingPage } from "../pages/LoadingPage";
import { useMemo, useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedUser, generateStoreQueryKey } from "../hooks";
import { Select, ChakraStylesConfig } from "chakra-react-select";

type Props = {
    orgId: string;
    currentStore: string;
};

type SelectOptions = { value: string; label: string };
type OptionsArray = SelectOptions[] | undefined;

export const StoreSwitchHeader = ({ orgId, currentStore }: Props) => {
    const { data: stores, isLoading: storesLoading } = useStores();
    const { isCloudOpsRole, isInternalRole } = useAuthenticatedUser();
    const [isCurrentStore, setCurrentStore] = useState(currentStore);
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const options: OptionsArray = useMemo(() => {
        if (isCloudOpsRole || isInternalRole) {
            return stores
                ?.sort((a, b) => {
                    const storeA = a.customerUrl.toLowerCase();
                    const storeB = b.customerUrl.toLowerCase();
                    if (storeA < storeB) {
                        return -1;
                    }
                    if (storeA > storeB) {
                        return 1;
                    }
                    return 0;
                })
                .map((store) => ({ value: store.customerUrl, label: store.customerUrl }));
        } else {
            return stores
                ?.filter((store) => store.orgId === orgId)
                .sort((a, b) => {
                    const storeA = a.customerUrl.toLowerCase();
                    const storeB = b.customerUrl.toLowerCase();
                    if (storeA < storeB) {
                        return -1;
                    }
                    if (storeA > storeB) {
                        return 1;
                    }
                    return 0;
                })
                .map((store) => ({ value: store.customerUrl, label: store.customerUrl }));
        }
    }, [stores, orgId, isCloudOpsRole, isInternalRole]);

    const updateSelectedStore = (url: string) => {
        setCurrentStore(url);
    };

    const updateStoreUrl = useCallback(
        (url: string) => {
            const currentPage = currentLocation.pathname.split("/").slice(1);
            const currentPerformanceReport = currentPage[3] !== undefined ? `/${currentPage[3]}` : "";
            queryClient.resetQueries({ queryKey: generateStoreQueryKey(url) });

            const updatedStorePath = !currentPage.includes("reports")
                ? `/${currentPage[0]}/${url}/${currentPage[2]}`
                : `/${currentPage[0]}/${url}/${currentPage[2]}${currentPerformanceReport}`;
            navigate(updatedStorePath, { replace: false });
        },
        [currentLocation, navigate, queryClient],
    );

    useEffect(() => {
        if (isCurrentStore !== currentStore) {
            updateStoreUrl(isCurrentStore);
        }
    }, [isCurrentStore, currentStore, updateStoreUrl]);

    if (storesLoading) return <LoadingPage />;

    const chakraStyles: ChakraStylesConfig = {
        input: (provided) => ({
            ...provided,
            w: "300",
        }),
    };

    return (
        <>
            {!options ? null : (
                <Select
                    options={options ?? []}
                    onChange={(selectedStore: any) => updateSelectedStore(selectedStore.value)}
                    placeholder={currentStore}
                    chakraStyles={chakraStyles}
                />
            )}
        </>
    );
};
