import {
    Card,
    CardBody,
    Text,
    VStack,
    useToast,
    HStack,
    Flex,
    Spacer,
    Badge,
    TableContainer,
    Stack,
    useMediaQuery,
    Select,
    Button,
    IconButton,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CacheHistoryRow, Store } from "../../types";
import { LoadingPage } from "../LoadingPage";
import { useCacheHistory, generateCacheHistoryQueryKey } from "../../hooks";
import { formatDateTimeDisplay, truncate } from "../../lib";
import { DataTable, PathNameDisplay, IconButtonRepeat } from "../../components";
import { createColumnHelper } from "@tanstack/react-table";
import { PageContainer } from "../PageContainer";
import { useForm } from "react-hook-form";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    store: Store;
};

export const CacheHistoryTab = ({ store }: Props) => {
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialPage = parseInt(params.get("page") ?? "0", 10);
    const [page, setPage] = useState(initialPage);
    const queryClient = useQueryClient();
    const { register, watch } = useForm<{ filteredBy: string }>();
    const watchSelectedFilter = watch("filteredBy");
    const { data, isLoading, isError, refetch } = useCacheHistory(
        store.id,
        store.customerUrl,
        page.toString(),
        watchSelectedFilter,
    );
    const cacheHistory: CacheHistoryRow[] = useMemo(() => {
        return data?.items ?? [];
    }, [data]);
    const hasMore = data?.hasMore ?? false;

    // force reload on back/forward navigation
    useEffect(() => {
        const handlePopstate = () => {
            window.location.reload();
        };
        window.addEventListener("popstate", handlePopstate);
        return () => window.removeEventListener("popstate", handlePopstate);
    }, []);

    const [rows, setRows] = useState<CacheHistoryRow[]>([]);
    const showToast = useToast({ variant: "subtle", duration: 5000, isClosable: true });
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const columns = useMemo(() => buildColumns(), []);

    useEffect(
        function setTableRows() {
            setRows(cacheHistory);
        },
        [cacheHistory],
    );

    const setFilterParams = useCallback(
        (watchSelectedFilter?: string) => {
            params.set("filteredBy", watchSelectedFilter ?? "usersApi");
            const updatedUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.pushState({}, "", updatedUrl);
            return updatedUrl;
        },
        [params],
    );

    const setUrlParams = useCallback(
        (page: number) => {
            params.set("page", page.toString());
            const updatedUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.pushState({}, "", updatedUrl);
            return updatedUrl;
        },
        [params],
    );

    useEffect(() => {
        async function handleFilterChange() {
            queryClient.invalidateQueries(generateCacheHistoryQueryKey(store.id));
            await setFilterParams(watchSelectedFilter);
            await setUrlParams(0);
            await setPage(0);
            refetch();
        }
        handleFilterChange();
    }, [watchSelectedFilter, queryClient, refetch, setFilterParams, setUrlParams, store.id]);

    useEffect(
        function handleError() {
            if (isError) {
                showToast({
                    title: "Error",
                    description:
                        "We encountered an error loading this page. If this error persists, please contact Customer Success",
                    status: "error",
                });
            }
        },
        [isError, showToast],
    );

    const handleNextPage = useCallback(() => {
        if (!hasMore) return;
        setPage((currentPage) => {
            const nextPage = currentPage + 1;
            setUrlParams(nextPage);
            return nextPage;
        });
    }, [hasMore, setPage, setUrlParams]);

    const handlePreviousPage = useCallback(() => {
        setPage((currentPage) => {
            const previousPage = Math.max(currentPage - 1, 0);
            setUrlParams(previousPage);
            return previousPage;
        });
    }, [setPage, setUrlParams]);

    const recordsFound = rows.length > 0;

    if (isLoading || cacheHistory === undefined) return <LoadingPage />;

    const handleNextPageRefetch = async () => {
        await handleNextPage();
        queryClient.invalidateQueries(generateCacheHistoryQueryKey(store.id));
        await refetch();
    };

    const handlePreviousPageRefetch = async () => {
        await handlePreviousPage();
        queryClient.invalidateQueries(generateCacheHistoryQueryKey(store.id));
        await refetch();
    };

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxW="1440px">
            <form>
                <Stack justifyContent={"flex-start"}>
                    <Text pt="spacer-2" margin={{ base: "auto", lg: "0" }} fontWeight={"bold"}>
                        All times are your local timezone.
                    </Text>
                    <HStack justifyContent={{ base: "center", md: "flex-start" }}>
                        <Text>Filtered By:</Text>
                        <Select
                            maxWidth={{ base: "200", md: "230px" }}
                            size={"sm"}
                            icon={<ChevronDownIcon />}
                            {...register("filteredBy")}
                        >
                            <option value={"usersApi"}>Users & API</option>
                            <option value={"systemAutomation"}>System Automation</option>
                            <option value={"all"}>All</option>
                        </Select>
                    </HStack>
                    <HStack py={"spacer-2"} justifyContent={{ base: "center", md: "flex-end" }}>
                        <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                            Previous
                        </Button>
                        <Button onClick={handleNextPageRefetch} isDisabled={!hasMore} colorScheme="button-primary">
                            Next
                        </Button>
                    </HStack>
                </Stack>
            </form>
            {!recordsFound ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent="center"
                    display="flex"
                    style={{ marginTop: "8rem" }}
                >
                    None Found
                </Text>
            ) : (
                <>
                    {isMobile ? (
                        <VStack width="100%" align={"center"} className="alternate-bg-card" pt={"spacer-4"}>
                            <>
                                {rows.map(({ requestedBy, date, status, id, metadata }: CacheHistoryRow) => (
                                    <Card width="100%" size="sm" key={id}>
                                        <CardBody>
                                            <HStack>
                                                <Text fontWeight={"bold"} display="inline-block">
                                                    {formatDateTimeDisplay(date)}
                                                </Text>
                                                &nbsp;
                                                {status === "SUCCESS" ? (
                                                    <>
                                                        <Spacer />
                                                        <Badge variant="subtle" colorScheme="green">
                                                            {status}
                                                        </Badge>
                                                    </>
                                                ) : null}
                                            </HStack>

                                            <Flex width="100%" alignItems={"center"}>
                                                <Text size={"sm"}>By:&nbsp;</Text>
                                                <Text
                                                    display={{
                                                        base: "none",
                                                        sm: "block",
                                                    }}
                                                >
                                                    {requestedBy.split(" - ")[0] ?? requestedBy}
                                                </Text>
                                                <Text
                                                    display={{
                                                        base: "block",
                                                        sm: "none",
                                                    }}
                                                >
                                                    {truncate(requestedBy.split(" - ")[0] ?? requestedBy, 30)}
                                                </Text>
                                            </Flex>
                                            <Flex width="100%" alignItems={"center"}>
                                                <Text size={"sm"}>Page:&nbsp;</Text>
                                                <Text
                                                    display={{
                                                        base: "none",
                                                        sm: "block",
                                                    }}
                                                >
                                                    {metadata?.pathNames?.join(", ") ?? "entire site"}
                                                </Text>
                                                <Text
                                                    display={{
                                                        base: "block",
                                                        sm: "none",
                                                    }}
                                                >
                                                    {truncate(metadata?.pathNames?.join(", ") ?? "entire site", 30)}
                                                </Text>
                                            </Flex>
                                            {requestedBy?.split(" - ")[1] ? (
                                                <HStack justifyContent={"flex-end"}>
                                                    <IconButtonRepeat />
                                                </HStack>
                                            ) : null}
                                        </CardBody>
                                    </Card>
                                ))}
                            </>
                            <HStack py={"spacer-6"}>
                                <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                                    Previous
                                </Button>
                                <Button
                                    onClick={handleNextPageRefetch}
                                    isDisabled={!hasMore}
                                    colorScheme="button-primary"
                                >
                                    Next
                                </Button>
                            </HStack>
                        </VStack>
                    ) : (
                        <>
                            <TableContainer width={"100%"} pt="spacer-3">
                                <DataTable columns={columns} data={rows} />
                            </TableContainer>
                            <HStack py={"spacer-6"} justifyContent={{ base: "center", md: "flex-end" }}>
                                <Button onClick={handlePreviousPageRefetch} isDisabled={page === 0}>
                                    Previous
                                </Button>
                                <Button
                                    onClick={handleNextPageRefetch}
                                    isDisabled={!hasMore}
                                    colorScheme="button-primary"
                                >
                                    Next
                                </Button>
                            </HStack>
                        </>
                    )}
                </>
            )}
        </PageContainer>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<CacheHistoryRow>();
    let columns = [
        columnHelper.accessor("requestedBy", {
            cell: (props) => (
                <Text>
                    {props.getValue().split(" - ")[0] ?? props.getValue()}{" "}
                    {props.getValue().split(" - ")[1] ? (
                        <IconButtonRepeat background={"none"} cursor={"default"} _hover={{ background: "none" }} />
                    ) : (
                        <IconButton
                            aria-label="does not repeat"
                            background={"none"}
                            cursor={"default"}
                            _hover={{ background: "none" }}
                        />
                    )}
                </Text>
            ),
            header: "Requested By",
        }),
        columnHelper.accessor("date", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Date",
        }),
        columnHelper.accessor("metadata", {
            cell: (props) => PathNameDisplay(props.getValue()?.pathNames),
            header: "Page",
        }),
        columnHelper.accessor("status", {
            cell: (props) => props.getValue(),
            header: "Status",
        }),
    ];

    return columns;
};
