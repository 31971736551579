import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ReportsOverview = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Reports"}
                menuItem1Link={ROUTES.DOCUMENTATION_REPORTS}
                menuItem2={"Overview"}
            />
            <Header pb={"spacer-8"}>Reports Overview</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Welcome to the <strong>Reports</strong> section of {BRAND_NAME}'s documentation, where you will find
                fundamental information about {BRAND_NAME}'s effect on your site, along with pertinent facts and
                figures. To help you navigate through the information with ease, we've outlined our analytics approach
                and the initial report available to you.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Reports" icon in the <strong>Stores</strong> section will take you to the <strong>Reports</strong>{" "}
                page, where you can view your store's data.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/b06c4a93-2daa-4fd3-8e60-4946e8a13356/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1294,427&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=99,239"
                alt="Report icon on Stores page"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The speed reports contain the foremost indicators of your site's performance with {BRAND_NAME} enabled.
                They display recent readouts of what our speed experts believe to be the most important key performance
                indicators regarding site speed.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                These indicators were selected based on best practices for site performance improvement, as defined by
                eCommerce standards from Google and other major platform providers alongside our speed engineers'
                expertise.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s internal analytics are based on the number of sessions in your store, meaning your
                reports represent the total number of visits to your site. For example, you may have one visitor
                interact with a product page only to get distracted and return several hours later, which would count as
                two sessions.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Visit the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS_SPEED} target="new">
                    Speed Reports documentation
                </Link>{" "}
                for a breakdown of these key metrics, or continue reading to gain a better understanding of why you may
                see discrepancies between {BRAND_NAME}'s reports and other platforms' reports, such as Salesforce
                Commerce Cloud or Shopify.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="data-discrepancies">
                What Causes Data Discrepancies?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All analytics platforms report data differently. {BRAND_NAME} has worked diligently to ensure we match
                Shopify's session counts as closely as possible, but this does not mean the numbers will match exactly.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Differences in metric definitions and data collection methods can cause discrepancies between any
                analytics platforms. However, several factors can contribute to disparities between {BRAND_NAME}'s
                reports and your Shopify reports specifically, including:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Time zone discrepancies:</strong> {BRAND_NAME}'s reports follow Coordinated Universal Time
                    (UTC), while your Shopify reports follow your store's time zone found in your general "Store
                    defaults." This will create some differences in the total numbers reported, but the data should
                    still fall in a similar range.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sales channel discrepancies:</strong> {BRAND_NAME} only tracks sessions from Shopify stores'
                    "Online Store" channel. If your internal reporting includes other sales channels or excludes your
                    online store, the numbers will not match.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Historical discrepancies:</strong> Shopify and Salesforce Commerce Cloud likely have more
                    historical data accessible based on the date you onboarded with {BRAND_NAME}, when our system gained
                    access to all the required permissions, etc. The amounts of historical data storage can vary widely
                    depending on your store's circumstances and when {BRAND_NAME}'s reporting became available.
                </ListItem>
            </UnorderedList>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
