import { Container, Flex } from "@chakra-ui/react";
import { LoadingPage } from "../../LoadingPage";
import { PageContainer } from "../../PageContainer";
import { useExperiment } from "../../../hooks";
import { buildInternalPreviewUrl } from "../lib";
import { useParams } from "react-router-dom";
import { NotFound404 } from "../../ErrorPages/NotFound404";
import { mobileViewport } from "../../../styles";
import "../../../App.css";

export const MobilePreviewPage = () => {
    const experimentQuery = useExperiment();
    const { data: experiment, isLoading: experimentLoading, isError: experimentError } = experimentQuery;
    const { variationId } = useParams<{ variationId: string }>();
    const sampleUrl = experiment?.pageset.sampleUrl;

    if (experimentLoading || sampleUrl === undefined || variationId === undefined) {
        return (
            <Container height="100vh">
                <LoadingPage />
            </Container>
        );
    }

    if (experimentError) {
        return <NotFound404 />;
    }

    return (
        <PageContainer maxWidth="100vw" height="100vh" position="absolute" centerContent bgColor="#fafafa">
            <Flex height="100vh" width="100vw" justify="center" align="center">
                <iframe
                    style={mobileViewport}
                    title="Experiment variation mobile preview page"
                    height="667px"
                    width="375px"
                    src={buildInternalPreviewUrl(sampleUrl, variationId)}
                />
            </Flex>
        </PageContainer>
    );
};
