import { useSearchParams } from "react-router-dom";
import { usePerformanceReport } from "../../../../hooks";
import { SortLimitForm } from "./MetricParameters";
import { useMemo } from "react";
import { RenderMetric } from "../RenderMetric";
import { CSVAndSQL } from "../CSVAndSQL";

export const BounceRateByReferer = () => {
    const [searchParams] = useSearchParams();
    const report = "bounce_rate_by_referer";
    const metricParams = useMemo(
        () => ({
            from_hour: searchParams.get("from_hour"),
            upto_hour: searchParams.get("upto_hour"),
            percentile: 1.0,
            limit_by: searchParams.get(`${report}_limit_by`) ?? "relative_acceleration",
        }),
        [searchParams],
    );

    const { data, isLoading, isError } = usePerformanceReport({ report, ...metricParams });

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <RenderMetric data={data} isError={isError} isLoading={isLoading}>
                <CSVAndSQL csv={data?.csv} sql={data?.query} />
                <SortLimitForm prefix={report} params={metricParams} />
            </RenderMetric>
        </div>
    );
};
