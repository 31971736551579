import { useSearchParams } from "react-router-dom";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const AbsoluteCumulativeSessionCount = () => {
    const [searchParams] = useSearchParams();
    const report = "three_splitter";
    const metric = "absolute_cumulative_session_count";
    const prefix = `${metric}_${report}`;

    const metricParams = {
        metric,
        interval_unit: searchParams.get(`${prefix}_interval_unit`) ?? "hours",
        interval_count: searchParams.get(`${prefix}_interval_count`) ?? 8,
    };

    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={prefix} params={metricParams} />
        </RenderMetric>
    );
};
