import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const StatisticalSignificanceDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Reports"}
                menuItem1Link={ROUTES.DOCUMENTATION_REPORTS}
                menuItem2={"Statistical Significance"}
            />
            <Header pb={"spacer-8"}>Statistical Significance</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s split (A/B) testing to measure speed impacts on your site is built on a foundation of
                statistical significance, a globally practiced statistical analysis technique. Thanks to that approach,
                your team can rest easy knowing your reported revenue and performance gains are statistically
                meaningful.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                What Is Statistical Significance?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Statistical significance is a core concept in statistics that considers a dataset and determines whether
                a desired outcome is present in the whole population rather than randomly in the sample. In other
                words,&nbsp;
                <strong>
                    reaching statistical significance indicates a consistent effect on the metric in question.
                </strong>
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    The larger your sample size and the greater the delta—the overall change in metric value—the faster
                    you will reach statistical significance as large samples more accurately reflect the whole.
                    Similarly, large deltas are less likely to result from random chance or sampling errors.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For small samples that do reach statistical significance, it will take longer to gain the mathematical
                confidence needed to apply any changes to an entire population.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Very small samples may never reach statistical significance unless there are large changes in behavior
                because the changes seen cannot reliably be applied to the entire population.{" "}
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                When Will You Reach Statistical Significance?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Before putting your store on a split test to compare your original site to your site with {BRAND_NAME},
                our team calculates the approximate timeframe your test needs to reach statistical significance.{" "}
                <strong>This approximation may change depending on the state of your site,</strong> other A/B testing
                you have in progress, irregular user traffic, and more.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can calculate this date range yourself to get a better understanding of your split test's lifespan.
                The steps are as follows:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Get your online store sessions from the past 12 months</strong> from your host platform's
                    analytics. Let's say you have 20,293,560 sessions.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                        Shopify sites must filter out sessions from channels beyond the "Online Store," as {BRAND_NAME}{" "}
                        does not collect other channel data. You can do this easily from the default report on the{" "}
                        <strong>Home</strong>
                        page.
                    </Text>
                </Alert>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-19/85eeecdc-b10e-472b-bb23-3814c23a708f/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1363,467&force_format=png&width=1120.0"
                    alt="Shopify home page"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Get your conversion rate from the past 30 days. </strong>In this example, we'll use 1.26%.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        Enter your conversion rate into the{" "}
                        <Link
                            textDecoration="underline"
                            to="https://www.evanmiller.org/ab-testing/sample-size.html"
                            target="new"
                        >
                            Evan Miller Sample Size Calculator
                        </Link>
                    </strong>{" "}
                    under "Baseline conversion rate."
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-19/166f497a-8a8c-40b0-b03e-dbc0337798ad/screenshot.jpeg?tl_px=0,0&br_px=754,162&force_format=png&width=860"
                    alt="Evan Miller Sample Size Calculator"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Keep the "Minimum Detectable Effect" at 5% </strong>to match default statistical practices.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Update "Statistical power 1-β" to 70% </strong>to receive the earliest results at a
                    reasonable percentile. Learn more about percentiles by reading our{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS_PERCENTILES}>
                        Percentiles documentation
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Select "Relative" instead of "Absolute." </strong>A relative change refers to the percentage
                    difference between the original value and the experimental value, while an absolute change refers to
                    the subtracted difference between two numbers. Relative change is used specifically for clear
                    comparisons.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-19/2984bde0-63a0-4184-bb00-cec2f594be91/screenshot.jpeg?tl_px=0,13&br_px=788,494&force_format=png&width=860"
                    alt="Evan Miller Sample Size Calculator with relative selected"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Double the sample size per variation you receive from the calculator, </strong>assuming you
                    are on a 50/50 split, as you will need that many session present on both sides of your store's split
                    test to achieve statistic significance for your original data and your data with {BRAND_NAME}. This
                    example will have 777,888 sessions total.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Divide your online store sessions from the past 12 months by 365 </strong>to get your
                    approximate number of daily sessions. The example store has about 55,599 daily sessions.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>
                        Divide your calculated sample size by your store's approximate daily sessions to calculate your
                        timeline.{" "}
                    </strong>
                    This store would need about 14 days to reach statistical significance.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-19/1dc9f0fb-a964-4ba7-9e74-9f008bd42208/screenshot.jpeg?tl_px=0,0&br_px=656,333&force_format=png&width=764"
                    alt="Timeline calculation"
                />
            </UnorderedList>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Takeaways
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Statistical significance refers to the claim that an outcome on a set of observed data is not the result
                of chance, but can instead be attributed to a specific cause, such as speed improvements. By achieving
                statistical significance during your split test, you can be confident that the results of your test are
                due to the changes made by {BRAND_NAME}, not by coincidence.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Several critical elements must be considered to achieve statistical significance in A/B testing.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Controlled variability: </strong>Minimize external factors that could affect your results.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                        Avoid making any drastic changes to your site during your split test with {BRAND_NAME}, as this
                        will result in inconclusive results and necessitate restarting the test.
                    </Text>
                </Alert>
                <ListItem pb={"spacer-4"}>
                    <strong>Proper duration: </strong>It is crucial to run the test long enough to achieve statistical
                    significance. A split test at {BRAND_NAME} typically requires 30 days or more, depending on the
                    engagement and traffic your site receives.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Adequate sample size: </strong>Reliable conclusions can only be drawn if enough data is
                    collected. If your split test does not reach the required sample size for statistical significance,
                    the test will have to be extended and may not be feasible for the length of a trial period.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Statistical significance is essential to interpret the data provided by {BRAND_NAME} or any other
                analytics platform. This practice is not exclusive to {BRAND_NAME}; it is a universal standard in
                statistical analysis to reach reliable results.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
