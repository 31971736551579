/**
 * Assumes a JSON response body.
 * Throws exceptions if the response is not ok or if the response body cannot be parsed.
 */
export const fetchResponse = async (response: Response) => {
    const contentType = response.headers.get("content-type");

    const isJson = contentType && contentType.includes("application/json");
    const isHtml = contentType && contentType.includes("text/html");
    const isText = contentType && contentType.includes("text/plain");
    let message = "An unexpected error has occurred. Please contact support if it continues.";
    if (!response.ok) {
        if (isJson) {
            const { error } = await response.json();

            if (typeof error === "object" && error.message) {
                console.error(error);
                message = error.message;
            }

            throw new Error(message);
        }

        if (isHtml) {
            const text = await response.text();
            console.error(text);
            throw new Error(message);
        }

        if (isText) {
            const text = await response.text();
            console.error(text);
            throw new Error(message);
        }
    }

    if (response.status === 204) return "";
    if (response.status === 500) throw new Error(message);

    try {
        const text = await response.text();

        if (isJson) {
            return text.length ? JSON.parse(text) : "";
        }

        if (isHtml) {
            console.debug("Unexpected HTML response: ", text);
        }

        if (isText) {
            console.debug("Unexpected text response: ", text);
        }

        return text;
    } catch (error) {
        console.error(error);
        throw new Error(`Unable to parse JSON response body from ${response.url}`);
    }
};
