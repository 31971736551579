import { generatePath, useNavigate } from "react-router-dom";
import {
    IconButtonExperiment,
    IconButtonOrganization,
    Tooltip,
    IconButtonClearCache,
    IconButtonEdit,
    IconButtonReport,
} from "../../components";
import { useAuthenticatedUser } from "../../hooks";
import { ROUTES } from "../../Routes";

type StoreRowProps = {
    storeId: string | undefined;
    orgId: string | undefined;
    customerUrl: string | undefined;
};

export const StoreRowActions = ({ orgId = "", customerUrl = "" }: StoreRowProps) => {
    const navigate = useNavigate();
    const { isCloudOpsRole, isInternalRole } = useAuthenticatedUser();

    return (
        <>
            {!isInternalRole ? (
                <>
                    <Tooltip label="Manage Cache" aria-label="Manage Cache">
                        <IconButtonClearCache
                            aria-label={"Manage Cache"}
                            onClick={() =>
                                navigate(
                                    generatePath(ROUTES.MANAGE_CACHE_AUTHENTICATED, {
                                        customerUrl,
                                    }),
                                )
                            }
                        />
                    </Tooltip>

                    <Tooltip label="Edit Store" aria-label="Edit Store">
                        <IconButtonEdit
                            aria-label="Edit Store"
                            onClick={() =>
                                navigate(
                                    generatePath(ROUTES.STORE_EDIT, {
                                        customerUrl,
                                    }),
                                )
                            }
                        />
                    </Tooltip>
                </>
            ) : null}

            <Tooltip label="Reports" aria-label="Reports">
                <IconButtonReport
                    aria-label="Reports"
                    onClick={() =>
                        navigate(
                            generatePath(ROUTES.STORE_REPORTS, {
                                customerUrl,
                            }),
                        )
                    }
                />
            </Tooltip>

            {isCloudOpsRole ? (
                <>
                    <Tooltip label="Manage Experiments" aria-label="Manage Experiments">
                        <IconButtonExperiment
                            onClick={() => navigate(generatePath(ROUTES.LIST_EXPERIMENTS, { customerUrl }))}
                            aria-label={"Manage experiments"}
                        />
                    </Tooltip>
                    <Tooltip label="Manage Organization" aria-label="Manage Organization">
                        <IconButtonOrganization
                            onClick={() => navigate(generatePath(ROUTES.ORGANIZATION, { orgId }))}
                            aria-label="Manage Organization"
                        />
                    </Tooltip>
                </>
            ) : null}
        </>
    );
};
