import { useQuery } from "@tanstack/react-query";
import { Store } from "../../types/types";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { fetchResponse } from "../../lib/http/fetchResponse";

export const useShops = () => {
    const config = {
        queryKey: ["shop"],
        queryFn: () => fetchShops(),
    };

    return useQuery(config);
};

const fetchShops = async (): Promise<Store[]> => {
    const url = `${SVC_URLS["portal"]}/shops/`;
    const options = await fetchOptions();
    const response = await fetch(url, options);
    return fetchResponse(response);
};
