import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useListAllShopifyStoreApps = () => {
    return useQuery(["listStoreApps"], async () => await listAllShopifyStoreApps());
};

const listAllShopifyStoreApps = async () => {
    const listShopifyApps = `${SVC_URLS["ecom"]}/app`;
    const options = await fetchOptions();
    const response = await fetch(listShopifyApps, options).catch(handleErrors);

    return fetchResponse(response);
};
