import { useEffect, useRef } from "react";
import { EntriConfig } from "./types";

type EntriConnectModalProps = { config: EntriConfig; onEntriClose: (event: any) => void };
export const EntriConnectModal = ({ config, onEntriClose }: EntriConnectModalProps) => {
    const isOpen = useRef(false);

    /**
     * Register the 'onEntriClose()' event listener
     *
     * Reference: https://developers.entri.com/integrate-with-dns-providers#3-listen-for-the-onentriclose-callback-event
     */
    useEffect(function registerOnEntriClose() {
        window.addEventListener(
            "onEntriClose",
            (event) => {
                isOpen.current = false;
                onEntriClose(event);
            },
            false,
        );
        return () => window.removeEventListener("onEntriClose", onEntriClose, false);
        // NOTE: Only register the event listener onMount, and remove it onUnmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Show the Entri Connect modal
     *
     * Reference: https://developers.entri.com/api-reference#entri-showentri-config
     */
    useEffect(
        function showEntriModal() {
            if (config !== undefined && !isOpen.current) {
                isOpen.current = true;
                // console.log({ config });
                showEntri(config);
            }
        },
        [config],
    );
    return null;
};

// Wrap the global function `showEntri`
const showEntri = (config: EntriConfig) => window.entri.showEntri(config);
