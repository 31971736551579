import { Link, useToast } from "@chakra-ui/react";
import { useResetPasswordMutation } from "../../hooks";
import { Tooltip } from "../../components";

type Props = {
    userId: string;
};

export const ResetPasswordLink = ({ userId }: Props) => {
    const showToast = useToast();
    const resetPasswordMutation = useResetPasswordMutation();

    const handleUserPasswordReset = () => {
        const response = window.confirm("Are you sure you want to reset this user's password?");
        const resetPasswordSuccess = () => {
            showToast({
                title: "Success",
                description: "This user will be forced to change their password upon next login.",
                status: "success",
                duration: 8000,
                isClosable: true,
                variant: "subtle",
            });
        };

        const resetPasswordError = () => {
            showToast({
                title: "Error",
                description:
                    "We encountered an error requesting this password reset. Please wait a few moments and try again. If this error persists, please contact Customer Success.",
                status: "error",
                duration: null,
                isClosable: true,
                variant: "subtle",
            });
        };

        if (response) {
            resetPasswordMutation.mutate(userId, {
                onSuccess: resetPasswordSuccess,
                onError: resetPasswordError,
            });
        }
    };

    return (
        <Tooltip label="Reset Password" aria-label="Reset Password">
            <Link
                textStyle={{
                    base: "text-link-meta-regular",
                    md: "text-link-meta-regular",
                }}
                onClick={handleUserPasswordReset}
            >
                reset password
            </Link>
        </Tooltip>
    );
};
