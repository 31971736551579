import { CSSProperties } from "react";
import { Shop } from "./Shop";
import type { ShopData } from "../../types";

type ShopRowProps = {
    data: {
        itemSpacing: number;
        itemWidth: number;
        itemsPerRow: number;
        filteredShops: ShopData[];
    };
    index: number;
    style: CSSProperties;
};

export const ShopRow = ({ data, index, style }: ShopRowProps) => {
    const items = [];
    const fromIndex = index * data.itemsPerRow;
    const toIndex = Math.min(fromIndex + data.itemsPerRow, data.filteredShops.length);

    for (let i = fromIndex; i < toIndex; i++) {
        items.push(data.filteredShops[i]);
    }

    return (
        <div style={{ display: "flex", gap: `${data.itemSpacing}px`, ...style, height: `${data.itemWidth}px` }}>
            {items.map((shop: any) => (
                <Shop key={shop.customerUrl} shop={shop} style={{ width: data.itemWidth }} />
            ))}
        </div>
    );
};
