import { useMutation } from "@tanstack/react-query";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { SVC_URLS } from "../../config/SVC_URLS";

export const useRegisterWebhooksMutation = (customerUrl: string, storeId: string) => {
    const url = `${SVC_URLS["ops"]}/shopify/store/${storeId}/register-webhooks`;

    return useMutation({ mutationFn: registerWebhooks(url, customerUrl) });
};

const registerWebhooks = (url: string, customerUrl: string) => async () => {
    const options = await fetchOptions({ method: "POST", headers: { Store: customerUrl } });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
