import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect } from "react";

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
};

export function VirtualizedDataTable<Data extends object>({ data, columns }: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });
    const parentRef = React.useRef<HTMLDivElement>(null);
    const headerRef = React.useRef<HTMLTableElement>(null);
    const rowHeight = 73;

    const virtualizer = useVirtualizer({
        count: data?.length ?? 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => rowHeight,
        overscan: 10,
    });

    const visibleColumns = table.getVisibleLeafColumns();
    const tableWidth = "100%";
    const dataWidth = `${visibleColumns[0].getSize() + 100}px`;

    // Sync horizontal scroll between header and body tables
    useEffect(() => {
        const parent = parentRef.current;
        const header = headerRef.current;
        let isSyncingFromParent = false;
        let isSyncingFromHeader = false;

        if (parent && header) {
            const syncScrollFromParent = () => {
                if (isSyncingFromHeader) return;
                isSyncingFromParent = true;
                header.scrollLeft = parent.scrollLeft;
                isSyncingFromParent = false;
            };
            const syncScrollFromHeader = () => {
                if (isSyncingFromParent) return;
                isSyncingFromHeader = true;
                parent.scrollLeft = header.scrollLeft;
                isSyncingFromHeader = false;
            };

            parent.addEventListener("scroll", syncScrollFromParent);
            header.addEventListener("scroll", syncScrollFromHeader);

            return () => {
                parent.removeEventListener("scroll", syncScrollFromParent);
                header.removeEventListener("scroll", syncScrollFromHeader);
            };
        }
    }, [parentRef, headerRef]);

    return (
        <div style={{ width: "100%" }}>
            <div
                ref={headerRef}
                style={{
                    overflowX: "auto",
                    width: "100%",
                }}
            >
                <Table
                    layout="fixed"
                    position="relative"
                    zIndex={10}
                    top={0}
                    background="white"
                    overflow={"auto"}
                    style={{ width: `${tableWidth}px` }}
                >
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => {
                                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                                    const meta: any = header.column.columnDef.meta;
                                    return (
                                        <Th
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                            isNumeric={meta?.isNumeric}
                                            paddingInlineStart={2}
                                            paddingInlineEnd={2}
                                            fontFamily="Inter"
                                            fontSize={{ base: "0.75rem", md: "1rem" }}
                                            width={dataWidth}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}

                                            <chakra.span pl="4">
                                                {header.column.getIsSorted() ? (
                                                    header.column.getIsSorted() === "desc" ? (
                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                    ) : (
                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                    )
                                                ) : null}
                                            </chakra.span>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                </Table>
            </div>
            <div
                ref={parentRef}
                style={{
                    height: data?.length > 10 ? "calc(100vh - 200px)" : "auto",
                    overflow: "auto",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        height: virtualizer.getTotalSize(),
                        width: `${tableWidth}px`,
                        position: "relative",
                        minHeight: "fit-content",
                        marginBottom: "spacer-12",
                    }}
                >
                    <Table layout="fixed" style={{ width: `${tableWidth}px` }}>
                        <Tbody>
                            {virtualizer.getVirtualItems().map((virtualRow, index) => {
                                const row = table.getRowModel().rows[virtualRow.index];
                                return (
                                    <Tr
                                        key={row.id}
                                        style={{
                                            height: `${virtualRow.size}px`,
                                            transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                                            backgroundColor:
                                                virtualRow.index % 2 === 0 ? "var(--chakra-colors-gray-100)" : "white",
                                        }}
                                    >
                                        {table.getHeaderGroups()[0].headers.map((header, cellIndex) => {
                                            const cell = row.getAllCells().find((data) => data.column.id === header.id);
                                            if (!cell) return null;
                                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type thiscorrectly
                                            const meta: any = cell.column.columnDef.meta;
                                            return (
                                                <Td
                                                    key={cell.id}
                                                    isNumeric={meta?.isNumeric}
                                                    paddingInlineStart={2}
                                                    paddingInlineEnd={2}
                                                    fontSize={{ base: "0.875rem", md: "1.05rem" }}
                                                    width={dataWidth}
                                                    whiteSpace={"normal"}
                                                    wordBreak={"break-word"}
                                                    overflowWrap={"break-word"}
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}
