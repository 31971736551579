import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ShopifyLoadingSpeedDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify"}
                menuItem1Link={ROUTES.DOCUMENTATION_SHOPIFY_PII}
                menuItem2={`How Shopify Defines Loading Speed`}
            />
            <Header pb={"spacer-8"}>How Shopify Defines Loading Speed</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have ever used Shopify's Web Performance report, you might have noticed Loading Speed is one of
                the metrics.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    Loading Speed, according to Shopify's definition, does not reflect your site's overall speed and
                    performance.
                </strong>{" "}
                Rather, it reflects your store's Largest Contentful Paint, one of the Core Web Vitals tracked by the
                Chrome User Experience Report.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-12/6207f9ff-bfd1-4a7b-8d24-65ade5554174/screenshot.jpeg?tl_px=0,0&br_px=1196,546&force_format=png&width=1120.0"
                alt="Shopify loading speed graph"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify's Web Performance dashboard presents your Core Web Vitals as a separate report for your
                convenience. The Core Web Vitals indicate how your store performs over time based on real user data
                compiled from a segment of Google Chrome users across millions of websites.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While Shopify has slightly changed the terms used in its report, each one matches an original Core Web
                Vital.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Loading Speed equates to Largest Contentful Paint</ListItem>
                <ListItem pb={"spacer-4"}>Interactivity equates to Interaction to Next Paint</ListItem>
                <ListItem pb={"spacer-6"}>Visual Stability equates to Cumulative Layout Shift</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX}>
                    Chrome User Experience Report documentation
                </Link>
                &nbsp;to better understand each of these metrics and the CrUX report as a whole.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Web Performance Report Overview
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While the data in Shopify's Web Performance dashboard is also available in your store's CrUX report,
                investigating one metric at a time can help you understand how a change in your store might have
                affected your performance or users.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/92854c27-0469-4121-ae67-8a04c1ef1494/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1325,615&force_format=png&width=1120.0"
                alt="Web performance dashboard"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify's documentation{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/online-store/web-performance/web-performance-dashboard"
                    target="new"
                >
                    notes
                </Link>
                &nbsp;that{" "}
                <strong>
                    any store's online performance can be affected by additions such as apps, analytics tracking pixels,
                    media size and quality, third-party code, and even your theme code.
                </strong>{" "}
                Because of that possibility, the individual Web Performance reports display additional information and
                markers to highlight changes within your store.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The example below shows a Loading Speed report, which contains LCP measurements in more detail over
                time. You can view the metric by different devices or timeframes, and you can hover over the event
                markers that indicate when a new app was installed, a theme was edited, etc.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/e323b1b3-7165-4089-8426-3e2535906fb0/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1328,863&force_format=png&width=1120.0"
                alt="Loading Speed report"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Shopify's Loading Speed metric measures your Largest Contentful Paint (LCP), not your overall site
                    speed and performance. While your LCP can benefit from {BRAND_NAME}'s core products, it is only one
                    indicator of your store's health and can be influenced by several factors.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about Shopify's analytics and term definitions by visiting our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_SHOPIFY}>
                    Shopify Analytics documentation
                </Link>
                , or{" "}
                <Link
                    textDecoration="underline"
                    to="https://nostra-corporate.webflow.io/contact-nostra-support"
                    target="new"
                >
                    contact support
                </Link>
                &nbsp;with additional questions.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
