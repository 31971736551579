import { PageContainer } from "../../PageContainer";
import { Text, Box, Code } from "@chakra-ui/react";
import { Header, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const AppsByStoreResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify Apps"}
                menuItem1Link={ROUTES.DOCUMENTATION_INSTALLED_APPS}
                menuItem2={"Apps By Store"}
            />
            <Header pb={"spacer-8"}>Apps By Store</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                From within the Edit Store page, select the "Shopify" tab and scroll beyond the "Shopify Integration"
                and "Webhooks" information to reach the apps.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/1c4dd2fe-4528-4001-898d-10a0efd6ebb8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2085,1244&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=636,71"
                alt="Edit store page with Shopify tab"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Shopify Applications" section displays all of the apps the selected store has installed currently
                or in the past.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The status of the application's installation is indicated by the <Code>Active</Code> badge. Apps that
                have been uninstalled will show <Code>Deleted</Code>. The next badge indicates whether the app is{" "}
                <Code>Supported</Code> or <Code>Unsupported</Code>, meaning that app works well with {BRAND_NAME} or
                interferes with {BRAND_NAME}'s core functions.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By clicking on the linked image icon, application title, or one of the badges, users will be taken to
                that app's Single Application Page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/2b70a98c-637a-470b-a084-147754f42790/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1944,1281&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=158,79"
                alt="Apps per store"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
