import { Button, HStack, Input, useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FieldError, Label } from "../../components";
import { useEnqueueDeploymentMutation, generateStoreQueryKey, useStoreMutation } from "../../hooks";
import { Store, UpdateStoreFormData, UpdateStoreRequest } from "../../types";
import { PageContainer } from "../PageContainer";

export const SalesforceTab = ({ store }: Props) => {
    const {
        salesforceApiClientId,
        salesforceApiClientSecret,
        salesforceOrgId,
        salesforceShortCode,
        salesforceSiteId,
        salesforceTenantId,
    } = store;

    const defaultValues: UpdateStoreFormData = {
        salesforceApiClientId,
        salesforceApiClientSecret,
        salesforceOrgId,
        salesforceShortCode,
        salesforceSiteId,
        salesforceTenantId,
    };

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<UpdateStoreFormData>({
        defaultValues: defaultValues,
    });
    const { onSubmit } = useSalesforceSubmit(store);
    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }}>
            <VStack align={"left"}>
                <form onSubmit={handleSubmit(onSubmit)} id="salesforceTab">
                    <Label htmlFor="salesforceApiClientId">API Client ID</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceApiClientId"
                        {...register("salesforceApiClientId")}
                        defaultValue={store.salesforceApiClientId}
                    />
                    <FieldError>{errors.salesforceApiClientId?.message}</FieldError>
                    <Label htmlFor="salesforceApiClientSecret">API Client Secret</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceApiClientSecret"
                        {...register("salesforceApiClientSecret")}
                        defaultValue={store.salesforceApiClientSecret}
                    />
                    <FieldError> </FieldError>
                    <Label htmlFor="salesforceOrgId">Org ID</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceOrgId"
                        {...register("salesforceOrgId")}
                        defaultValue={store.salesforceOrgId}
                    />
                    <FieldError> </FieldError>
                    <Label htmlFor="salesforceShortCode">Short Code</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceShortCode"
                        {...register("salesforceShortCode")}
                        defaultValue={store.salesforceShortCode}
                    />
                    <FieldError> </FieldError>
                    <Label htmlFor="salesforceSiteId">Site ID</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceSiteId"
                        {...register("salesforceSiteId")}
                        defaultValue={store.salesforceSiteId}
                    />
                    <FieldError> </FieldError>
                    <Label htmlFor="salesforceTenantId">Tenant ID</Label>
                    <Input
                        placeholder=""
                        type="text"
                        id="salesforceTenantId"
                        {...register("salesforceTenantId")}
                        defaultValue={store.salesforceTenantId}
                    />
                    <FieldError> </FieldError>

                    <HStack>
                        <Button type="submit" colorScheme={"button-primary"} ml="auto">
                            Submit
                        </Button>
                    </HStack>
                </form>
            </VStack>
        </PageContainer>
    );
};

const useSalesforceSubmit = (store: Store) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const storeMutation = useStoreMutation(store.id, store.customerUrl);
    const queryClient = useQueryClient();
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });

    const onSubmit = (formData: UpdateStoreFormData) => {
        const {
            salesforceApiClientId,
            salesforceApiClientSecret,
            salesforceOrgId,
            salesforceShortCode,
            salesforceSiteId,
            salesforceTenantId,
        } = formData;
        const requestBody: UpdateStoreRequest = {
            salesforceApiClientId,
            salesforceApiClientSecret,
            salesforceOrgId,
            salesforceShortCode,
            salesforceSiteId,
            salesforceTenantId,
        };

        storeMutation.mutate(requestBody, {
            onSuccess: () => {
                enqueueDeployment.mutate(store.customerUrl);
                queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                showToast({
                    title: "Success",
                    description: `Salesforce settings updated successfully.`,
                    status: "success",
                });
            },
            onError: (error) => {
                console.error(error);
                showToast({
                    title: "Error",
                    description: "There was an error updating salesforce settings. Try again in a few moments.",
                    status: "error",
                });
            },
        });
    };

    return {
        onSubmit,
    };
};

type Props = {
    store: Store;
};
