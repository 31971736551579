import { Stack, HStack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PageContainer } from "../PageContainer";
import { Header, Search, SubHeader } from "../../components";
import { useShops } from "../../hooks";
import { ShopRow } from "./ShopRow";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { LoadingPage } from "../LoadingPage";
import type { ShopData } from "../../types";

const searchFilterFunction = (shop: ShopData, searchTerm: string) =>
    shop.customerUrl.toLowerCase().includes(searchTerm) || shop.name.toLowerCase().includes(searchTerm);

export const ShopPage = () => {
    const [filteredShops, setFilteredShops] = useState<any[]>([]);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const { data: shops, isError, isLoading } = useShops();
    const showToast = useToast({ variant: "subtle", duration: 5000, isClosable: true });

    useEffect(
        function handleError() {
            if (isError) {
                showToast({
                    title: "Error",
                    description:
                        "We encountered an error loading this page. If this error persists, please contact Customer Success",
                    status: "error",
                });
            }
        },
        [isError, showToast],
    );

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <Stack style={{ height: "calc(100vh - 200px)", display: "flex", flexDirection: "column" }}>
                <HStack justifyContent="space-between" pb={"spacer-6"} alignItems="center">
                    <Header>Shop</Header>
                    <Search
                        data={shops}
                        setFilteredData={setFilteredShops}
                        setSearchIsLoading={setSearchIsLoading}
                        filterFunction={searchFilterFunction}
                    />
                </HStack>
                {searchIsLoading ? (
                    <LoadingPage />
                ) : filteredShops.length !== 0 ? (
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => {
                            const ITEM_SPACING = 16;
                            const ITEM_WIDTH = 500;
                            const ITEM_SIZE = ITEM_WIDTH + ITEM_SPACING;
                            const ITEMS_PER_ROW = Math.floor(width / ITEM_SIZE) || 1;
                            const ROW_COUNT = Math.ceil(filteredShops?.length / ITEMS_PER_ROW);
                            const ITEM_DATA = {
                                filteredShops,
                                itemsPerRow: ITEMS_PER_ROW,
                                itemWidth: ITEM_WIDTH,
                                itemSpacing: ITEM_SPACING,
                            };
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={ROW_COUNT}
                                    itemData={ITEM_DATA}
                                    itemSize={ITEM_SIZE}
                                >
                                    {ShopRow}
                                </List>
                            );
                        }}
                    </AutoSizer>
                ) : (
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <SubHeader>No shops found</SubHeader>
                    </div>
                )}
            </Stack>
        </PageContainer>
    );
};
