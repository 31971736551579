import { SVC_URLS } from "../../config/SVC_URLS";
import { useMutation } from "@tanstack/react-query";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { useEnqueueDeploymentMutation } from "./useEnqueueDeploymentMutation";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const DEFAULT_OPTIONS = {
    showToast: true,
};

export const useDeleteStoreMutation = (customerUrl: string, options: Options = DEFAULT_OPTIONS) => {
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false, showToast: true }, customerUrl);
    const { showToast } = { ...DEFAULT_OPTIONS, ...options };
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const triggerToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    return useMutation(
        ({ storeId }: { storeId: string; customerUrl: string; redirectRoute?: string }) =>
            deleteStore(storeId, customerUrl),
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries({ queryKey: ["stores"] });
                enqueueDeployment.mutate(customerUrl);
                if (variables.redirectRoute) {
                    navigate(variables.redirectRoute);
                }

                if (showToast)
                    triggerToast({
                        title: "Success - Store",
                        status: "success",
                        description: `${customerUrl} has had all services disabled and has been deleted pending a successful deployment.`,
                    });
            },
            onError: () => {
                if (showToast)
                    triggerToast({
                        title: "Error - Store",
                        status: "error",
                        description:
                            "An unexpected error occurred while deleting the store. If the problem persists, contact support.",
                    });
            },
        },
    );
};

const deleteStore = async (storeId: string, customerUrl: string) => {
    const url = `${SVC_URLS["ecom"]}/store/${storeId}`;
    const options = await fetchOptions({ method: "DELETE", headers: { Store: customerUrl } });
    const response = await fetch(url, options).catch(handleErrors);
    return fetchResponse(response);
};

type Options = {
    showToast: boolean;
};
