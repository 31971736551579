import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ExperimentSideBar } from "../pages/Editor/components/ExperimentSideBar";
import { ExperimentationEditorHeader } from "./ExperimentationEditorHeader";
import { HEADER_HEIGHT } from "./Layout";

export const ExperimentationEditorLayout = () => {
    return (
        <>
            <Grid
                templateAreas={`"header header"
                        "main sidebar"`}
                gridTemplateRows={`${HEADER_HEIGHT} 1fr`}
                gridTemplateColumns={`1fr 320px`}
                h="100vh"
            >
                {/* header */}
                <GridItem area={"header"}>
                    <ExperimentationEditorHeader />
                </GridItem>

                {/* page content */}
                <GridItem area={"main"}>
                    <Outlet />
                </GridItem>

                {/* right sidebar */}
                <GridItem area={"sidebar"}>
                    <ExperimentSideBar />
                </GridItem>
            </Grid>
        </>
    );
};
