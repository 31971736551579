import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconTestPipe } from "@tabler/icons-react";

export const IconButtonExperiment = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"List Experiments"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconTestPipe stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
