import { SVC_URLS } from "../../config/SVC_URLS";
import { handleErrors, fetchOptions, fetchResponse } from "../../lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateStoreRequest } from "../../types/requests";
import { generateStoreQueryKey } from "./useStore";

export const useStoreMutation = (storeId: string, customerUrl: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: putStore(storeId, customerUrl),
        onSuccess(_data, _variables, _context) {
            queryClient.invalidateQueries(generateStoreQueryKey(customerUrl));
        },
    });
};

const putStore = (storeId: string, customerUrl: string) => async (data: UpdateStoreRequest) => {
    const url = `${SVC_URLS["ecom"]}/store/${storeId}`;
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
