import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SpeedExperimentData } from "../../types";

export const useListSpeedExperiments = (storeId: string) => {
    return useQuery(["speedExperiments", storeId], async () => await listSpeedExperiments(storeId));
};

const listSpeedExperiments = async (storeId: string): Promise<SpeedExperimentData[]> => {
    const speedExperiments = `${SVC_URLS["experiment"]}/experiment/${storeId}/experiment/speed`;
    const options = await fetchOptions();
    const response = await fetch(speedExperiments, options).catch(handleErrors);
    return fetchResponse(response);
};
