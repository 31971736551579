import { IconButton, forwardRef } from "@chakra-ui/react";
import { IconGraph } from "@tabler/icons-react";

export const IconButtonReport = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Graph"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconGraph stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
