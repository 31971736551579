import { WebhooksResponse } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";

export const useListWebhooks = (storeId: string, customerUrl: string) => {
    return useQuery(generateListWebhooksQueryKey(storeId), async () => await listWebhooks(storeId, customerUrl));
};

const listWebhooks = async (storeId: string, customerUrl: string): Promise<WebhooksResponse> => {
    const LIST_WEBHOOKS = `${SVC_URLS["ops"]}/shopify/store/${storeId}/webhooks`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(LIST_WEBHOOKS, options).catch(handleErrors);

    return fetchResponse(response);
};

export const generateListWebhooksQueryKey = (storeId: string) => ["webhooks", storeId];
