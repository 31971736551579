import { Accordion } from "@chakra-ui/react";
import { ROUTES } from "../Routes";
import { Link, MenuItemDropDown } from "../components";
import { MenuItem } from "./ApplicationSideBar";
import { useAuthenticatedUser, useStores } from "../hooks";
import { useMemo } from "react";

export const DocumentationMenu = () => {
    const { isAdminRole, isCloudOpsRole, isInternalRole, orgId } = useAuthenticatedUser();
    const { data: stores } = useStores();
    const hasShopifyStores = useMemo(() => {
        return stores?.filter((store) => store.orgId === orgId && store.workerPlatform === "shopify");
    }, [stores, orgId]);

    return (
        <Accordion allowToggle>
            <MenuItemDropDown
                menuItems={[
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}>
                                    <Link to={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}>Tour The Portal</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_INITIAL_TIPS}>
                                    <Link to={ROUTES.DOCUMENTATION_INITIAL_TIPS}>Initial Tips</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_WHEN_TO_CONTACT_NOSTRA}>
                                    <Link to={ROUTES.DOCUMENTATION_WHEN_TO_CONTACT_NOSTRA}>Making Updates</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE}>
                                    <Link to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE}>Edge Delivery</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_CRAWLER_OPTIMIZATION}>
                                    <Link to={ROUTES.DOCUMENTATION_CRAWLER_OPTIMIZATION}>Bot Optimization</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_101}>
                                    <Link to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_101}>Edge 101</Link>
                                </MenuItem>,
                            ]}
                        >
                            Getting Started
                        </MenuItemDropDown>
                    </Accordion>,
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}>
                                    <Link to={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}>When To Clear</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                                    <Link to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>How To Clear</Link>
                                </MenuItem>,
                                <>
                                    {hasShopifyStores && hasShopifyStores.length !== 0 ? (
                                        <MenuItem to={ROUTES.DOCUMENTATION_AUTOMATED_CACHE_CLEARING}>
                                            <Link to={ROUTES.DOCUMENTATION_AUTOMATED_CACHE_CLEARING}>Automation</Link>
                                        </MenuItem>
                                    ) : null}
                                </>,
                                <MenuItem to={ROUTES.DOCUMENTATION_CACHE_HIT_RATIO}>
                                    <Link to={ROUTES.DOCUMENTATION_CACHE_HIT_RATIO}>Cache Hit Ratio</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>
                                    <Link to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>Cache Settings</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_CACHING_101}>
                                    <Link to={ROUTES.DOCUMENTATION_CACHING_101}>Caching 101</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_URL_PATHS}>
                                    <Link to={ROUTES.DOCUMENTATION_URL_PATHS}>URL Paths</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT}>
                                    <Link to={ROUTES.DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT}>Static/Dynamic</Link>
                                </MenuItem>,
                            ]}
                        >
                            Clear Cache
                        </MenuItemDropDown>
                    </Accordion>,
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_TURN_OFF_ON}>
                                    <Link to={ROUTES.DOCUMENTATION_TURN_OFF_ON}>Turn On/Off</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA}>
                                    <Link to={ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA}>Test Your Site</Link>
                                </MenuItem>,
                            ]}
                        >
                            Debugging
                        </MenuItemDropDown>
                    </Accordion>,
                    <>
                        {hasShopifyStores && hasShopifyStores.length !== 0 ? (
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    maxBgWidth="12rem"
                                    menuItems={[
                                        <MenuItem to={ROUTES.DOCUMENTATION_REPORTS}>
                                            <Link to={ROUTES.DOCUMENTATION_REPORTS}>Overview</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_REPORTS_SPEED}>
                                            <Link to={ROUTES.DOCUMENTATION_REPORTS_SPEED}>Speed Reports</Link>
                                        </MenuItem>,
                                        <>
                                            {isCloudOpsRole || isInternalRole ? (
                                                <MenuItem to={ROUTES.DOCUMENTATION_REPORTS_CRUX}>
                                                    <Link to={ROUTES.DOCUMENTATION_REPORTS_CRUX}>CrUX Reports</Link>
                                                </MenuItem>
                                            ) : null}
                                        </>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_REPORTS_PERCENTILES}>
                                            <Link to={ROUTES.DOCUMENTATION_REPORTS_PERCENTILES}>Percentiles</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_REPORTS_STATISTICAL_SIGNIFICANCE}>
                                            <Link to={ROUTES.DOCUMENTATION_REPORTS_STATISTICAL_SIGNIFICANCE}>
                                                Stat. Significance
                                            </Link>
                                        </MenuItem>,
                                    ]}
                                >
                                    Reports
                                </MenuItemDropDown>
                            </Accordion>
                        ) : null}{" "}
                    </>,
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_API}>
                                    <Link to={ROUTES.DOCUMENTATION_API}>Overview</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_API_GENERATE_TOKEN}>
                                    <Link to={ROUTES.DOCUMENTATION_API_GENERATE_TOKEN}>Generate Token</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_API_ENTIRE_SITE}>
                                    <Link to={ROUTES.DOCUMENTATION_API_ENTIRE_SITE}>Clear Site</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_API_SINGLE_PAGE}>
                                    <Link to={ROUTES.DOCUMENTATION_API_SINGLE_PAGE}>Clear Single Page</Link>
                                </MenuItem>,
                            ]}
                        >
                            API
                        </MenuItemDropDown>
                    </Accordion>,
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}>
                                    <Link to={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}>Testing Defaults</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX}>
                                    <Link to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX}>CrUX Report</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_THIRD_PARTY_LIGHTHOUSE}>
                                    <Link to={ROUTES.DOCUMENTATION_THIRD_PARTY_LIGHTHOUSE}>Lighthouse</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_THIRD_PARTY_PAGESPEED_INSIGHTS}>
                                    <Link to={ROUTES.DOCUMENTATION_THIRD_PARTY_PAGESPEED_INSIGHTS}>PageSpeed</Link>
                                </MenuItem>,
                            ]}
                        >
                            Third Party Tools
                        </MenuItemDropDown>
                    </Accordion>,
                    <>
                        {hasShopifyStores && hasShopifyStores.length !== 0 ? (
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    maxBgWidth="12rem"
                                    menuItems={[
                                        <MenuItem to={ROUTES.DOCUMENTATION_SHOPIFY_PII}>
                                            <Link to={ROUTES.DOCUMENTATION_SHOPIFY_PII}>Data Access</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_SHOPIFY}>
                                            <Link to={ROUTES.DOCUMENTATION_SHOPIFY}>Analytics</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_SHOPIFY_LOADING_SPEED}>
                                            <Link to={ROUTES.DOCUMENTATION_SHOPIFY_LOADING_SPEED}>Loading Speed</Link>
                                        </MenuItem>,
                                    ]}
                                >
                                    Shopify
                                </MenuItemDropDown>
                            </Accordion>
                        ) : null}
                    </>,
                    <Accordion allowToggle>
                        <MenuItemDropDown
                            maxBgWidth="12rem"
                            menuItems={[
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_ROI}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_ROI}>ROI Overview</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_TRADITIONAL}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_TRADITIONAL}>Traditional</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_KEY_REVENUE}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_KEY_REVENUE}>Key Revenue</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_CUSTOMER_JOURNEY}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_CUSTOMER_JOURNEY}>User Journey</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_WEB_VITALS}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_WEB_VITALS}>Web Vitals</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_SEARCH_ENGINE_RANKING}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_SEARCH_ENGINE_RANKING}>Page Ranking</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_ADVERTISING}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_ADVERTISING}>Advertising</Link>
                                </MenuItem>,
                                <MenuItem to={ROUTES.DOCUMENTATION_METRICS_CRAWLER_OPTIMIZATION}>
                                    <Link to={ROUTES.DOCUMENTATION_METRICS_CRAWLER_OPTIMIZATION}>Bot Optimization</Link>
                                </MenuItem>,
                            ]}
                        >
                            Metrics
                        </MenuItemDropDown>
                    </Accordion>,
                    <>
                        {isCloudOpsRole ? (
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    maxBgWidth="12rem"
                                    menuItems={[
                                        <MenuItem to={ROUTES.DOCUMENTATION_AB_TESTING}>
                                            <Link to={ROUTES.DOCUMENTATION_AB_TESTING}>Overview</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_MANAGE_EXPERIMENTS}>
                                            <Link to={ROUTES.DOCUMENTATION_MANAGE_EXPERIMENTS}>Experiments</Link>
                                        </MenuItem>,
                                    ]}
                                >
                                    A/B Testing
                                </MenuItemDropDown>
                            </Accordion>
                        ) : null}
                    </>,

                    <>
                        {!isAdminRole ? (
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    maxBgWidth="12rem"
                                    menuItems={[
                                        <MenuItem to={ROUTES.DOCUMENTATION_MANAGE_USERS}>
                                            <Link to={ROUTES.DOCUMENTATION_MANAGE_USERS}>Manage Users</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_MANAGE_ORGANIZATION}>
                                            <Link to={ROUTES.DOCUMENTATION_MANAGE_ORGANIZATION}>Organization</Link>
                                        </MenuItem>,
                                        <>
                                            {isCloudOpsRole ? (
                                                <MenuItem to={ROUTES.DOCUMENTATION_MANAGE_ALL_ORGANIZATIONS}>
                                                    <Link to={ROUTES.DOCUMENTATION_MANAGE_ALL_ORGANIZATIONS}>
                                                        All Organizations
                                                    </Link>
                                                </MenuItem>
                                            ) : null}
                                        </>,
                                    ]}
                                >
                                    Organization
                                </MenuItemDropDown>
                            </Accordion>
                        ) : null}
                    </>,
                    <>
                        {isCloudOpsRole ? (
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    maxBgWidth="12rem"
                                    menuItems={[
                                        <MenuItem to={ROUTES.DOCUMENTATION_INSTALLED_APPS}>
                                            <Link to={ROUTES.DOCUMENTATION_INSTALLED_APPS}>Installed Apps</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_SINGLE_APP}>
                                            <Link to={ROUTES.DOCUMENTATION_SINGLE_APP}>Single App Page</Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.DOCUMENTATION_APPS_BY_STORE}>
                                            <Link to={ROUTES.DOCUMENTATION_APPS_BY_STORE}>Apps By Store</Link>
                                        </MenuItem>,
                                    ]}
                                >
                                    Shopify Apps
                                </MenuItemDropDown>
                            </Accordion>
                        ) : null}
                    </>,
                ]}
            >
                Documentation
            </MenuItemDropDown>
        </Accordion>
    );
};
