import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { ShopifyApps } from "../../types";

export const useListAppsByStore = (storeId: string, customerUrl: string) => {
    return useQuery(["appsByStore"], async () => await listAppsByStore(storeId, customerUrl));
};

const listAppsByStore = async (storeId: string, customerUrl: string): Promise<ShopifyApps> => {
    const listStoreApps = `${SVC_URLS["ecom"]}/store/${storeId}/app`;
    const options = await fetchOptions({
        headers: { Store: customerUrl },
    });
    const response = await fetch(listStoreApps, options).catch(handleErrors);
    return fetchResponse(response);
};
