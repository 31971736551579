import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { DeleteScheduledPurgeCacheRequest } from "../../types";

export const useDeletePurgeMutation = () => useMutation(deleteSchedulePurgedCache);

const deleteSchedulePurgedCache = async (request: DeleteScheduledPurgeCacheRequest) => {
    const DELETE_SCHEDULE_PURGED_CACHE_URL = `${SVC_URLS["cronos"]}/cache/${request.id}`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            Store: request.customerUrl,
        },
    });

    const response = await fetch(DELETE_SCHEDULE_PURGED_CACHE_URL, options).catch(handleErrors);

    return fetchResponse(response);
};
