import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconEyeOff } from "@tabler/icons-react";

export const IconButtonHide = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Hide"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconEyeOff stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
