import { Text } from "@chakra-ui/react";
import { useStoreModeHistoryLog } from "../hooks";
import { Store } from "../types";
import { storeModeLabel, formatDateTimeDisplay } from "../lib";
import { Link, AlertMessage } from ".";

export const StoreStatusAlert = ({ store }: { store: Store }) => {
    const { data: storeStatusLog, status, isLoading } = useStoreModeHistoryLog(store.id, store.customerUrl);
    if (status !== "success" || !storeStatusLog || isLoading) return null;
    const reason = storeStatusLog?.[0]?.reason ?? "No reason provided";
    const lastUpdated =
        storeStatusLog?.[0]?.date !== undefined ? formatDateTimeDisplay(storeStatusLog?.[0]?.date) : "no date provided";

    return (
        <AlertMessage status="warning">
            <Text>
                <Link textDecoration="underline" to={`https://${store.customerUrl}`} target="new">
                    {store.customerUrl}
                </Link>{" "}
                is in status: <strong>{storeModeLabel(store.mode)}</strong> for reason: <em>{reason}</em> as of{" "}
                <em>{lastUpdated}</em>
            </Text>
        </AlertMessage>
    );
};
