import { StoreModes } from "../types";
import { storeModeLabel } from "./storeModeLabel";

export const storeModeBadge = (mode: StoreModes) => {
    switch (mode) {
        case StoreModes.Enabled:
            return { label: storeModeLabel(mode), color: "green" };
        case StoreModes.Testing:
            return { label: storeModeLabel(mode), color: "yellow" };
        case StoreModes.Disabled:
            return { label: storeModeLabel(mode), color: "red" };
        case StoreModes.DisabledByProvider:
            return { label: storeModeLabel(mode), color: "red" };
        default:
            return { label: storeModeLabel(mode), color: "red" };
    }
};
