import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";

export const useGenerateAPITokenMutation = () => useMutation({ mutationFn: generateAPIToken });

const generateAPIToken = async ({ storeId, customerUrl }: { storeId: string; customerUrl: string }) => {
    const generateApiTokenUrl = `${SVC_URLS["ecom"]}/store/${storeId}/token`;
    const options = await fetchOptions({ method: "POST", headers: { Store: customerUrl } });
    const response = await fetch(generateApiTokenUrl, options).catch(handleErrors);

    return fetchResponse(response);
};
