import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconBuildingBank } from "@tabler/icons-react";

export const IconButtonOrganization = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Manage Organization"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconBuildingBank stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
