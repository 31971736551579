import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { ToggleUserRequest } from "../../types";

export const useToggleUserMutation = (userId: string) => useMutation({ mutationFn: toggleUser(userId) });

const toggleUser = (userId: string) => async (request: ToggleUserRequest) => {
    const url = `${SVC_URLS["auth"]}/user/${userId}`;
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(request),
    });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
