import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon, Code } from "@chakra-ui/react";
import { Header, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ApiGenerateTokenDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"API"}
                menuItem1Link={ROUTES.DOCUMENTATION_API}
                menuItem2={"Generate Your Token"}
            />
            <Header pb={"spacer-8"}>Generate Your Token </Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                An API token is a unique identifier that grants access to an API (Application Programming Interface). It
                serves as a form of authentication, allowing users or applications to make requests to the API securely.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To access {BRAND_NAME}'s API, users must include an <Code>Authorization</Code> header in their request
                containing an API token. Store owners can generate or regenerate that API token as needed through the
                portal to do so.{" "}
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Only store owners will be able to see your store's API token within the {BRAND_NAME} portal. This
                    means store administrators <strong>cannot</strong> view or (re)generate your store's secure token.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                From the <strong>Stores</strong> or <strong>Organization</strong> sections, select the “Edit Store”
                icon.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/f0f13a71-d3e0-4f75-9dc1-9f0b5fabd2de/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1294,427&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=64,239"
                alt="Edit store icon"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Once you are on the <strong>Edit Store</strong> page, select the "Permissions" tab. If you have not
                generated a token, you will see a brief description with the option to generate a new token.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-15/f660c7f5-c3ee-434f-922e-a0fc27ac19e1/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2242,771&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=972,55"
                alt="Generate first API token"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have already generated an API token with {BRAND_NAME}, you will have several options:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>View your existing token by selecting the eye-shaped icon</ListItem>
                <ListItem pb={"spacer-4"}>Copy your existing token to your clipboard</ListItem>
                <ListItem pb={"spacer-6"}>Generate a new token</ListItem>
            </UnorderedList>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-15/e6abf610-b31f-4acf-8d06-efdd6ca674e7/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2216,732&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=762,225"
                alt="Generate new API token"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Generating a new token will <strong>break</strong> any existing API integrations if they aren't
                    updated with your new token value. Verify that existing integrations are ready for an update before
                    proceeding.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The portal will track your token generation history. View your permission history to see when your token
                was last generated and who initiated the generation.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/a774580c-92eb-42ef-abe4-a05484f84120/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1908,734&force_format=png&width=1120.0"
                alt="Generate new API token"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
