import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { Organizations } from "../../types/types";

export const useListAllOrganizations = () => {
    return useQuery(["organizations"], async () => await listAllOrganizations());
};

const listAllOrganizations = async (): Promise<Organizations> => {
    const listOrganizations = `${SVC_URLS["auth"]}/org`;
    const options = await fetchOptions();
    const response = await fetch(listOrganizations, options).catch(handleErrors);
    return fetchResponse(response);
};
