import { useCustomerPerformanceReport } from "../../../../hooks";
import { RenderMetric } from "../RenderMetric";

type PerformanceProps = {
    dateRange: { from_hour: string; upto_hour: string };
};

export const Performance = ({ dateRange }: PerformanceProps) => {
    const report = "performance";

    const { data, isLoading, isError } = useCustomerPerformanceReport({ report, ...dateRange });

    return <RenderMetric data={data} isError={isError} isLoading={isLoading} />;
};
