import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
    Input,
    Button,
    Select,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
} from "@chakra-ui/react";
import { FieldError, Label } from "../../../../components";

type SortLimitFormProps = {
    prefix: string;
    params: { limit?: number | string; sort?: string; limit_direction?: string; limit_by: string };
};

export const SortLimitForm = ({ prefix, params }: SortLimitFormProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...("sort" in params ? { [`${prefix}_sort`]: params.sort } : {}),
            ...("limit_direction" in params ? { [`${prefix}_limit_direction`]: params.limit_direction } : {}),
            ...("limit" in params ? { [`${prefix}_limit`]: params.limit } : {}),
            [`${prefix}_limit_by`]: params.limit_by,
        },
    });

    const handleMetricSubmit = (data: any) => {
        const allParams = {
            ...Object.fromEntries(searchParams.entries()),
            ...data,
        };
        setSearchParams(allParams);
    };

    return (
        <Accordion allowToggle>
            <AccordionItem>
                <AccordionButton>
                    <span>Metric Parameters</span>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <form onSubmit={handleSubmit(handleMetricSubmit)}>
                        {/* Limit */}
                        {"limit" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Limit
                                </Label>
                                <Input
                                    type="number"
                                    {...register(`${prefix}_limit`, { required: true })}
                                    maxWidth={150}
                                />
                                <FieldError>{errors[`${prefix}_limit`]?.message}</FieldError>
                            </>
                        )}

                        {/* Limit Direction */}
                        {"limit_direction" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Limit Direction
                                </Label>
                                <Select
                                    {...register(`${prefix}_limit_direction`, { required: true })}
                                    isRequired={true}
                                    maxWidth="fit-content"
                                    display={"inline-flex"}
                                >
                                    <option value="ASC">Ascending (Bottom)</option>
                                    <option value="DESC">Descending (Top)</option>
                                </Select>
                                <FieldError>{errors[`${prefix}_limit_direction`]?.message}</FieldError>
                            </>
                        )}

                        {/* Limit By */}
                        <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                            Limit By
                        </Label>
                        <Select
                            {...register(`${prefix}_limit_by`, { required: true })}
                            isRequired={true}
                            maxWidth="fit-content"
                            display={"inline-flex"}
                        >
                            <option value="relative_acceleration">Relative Acceleration</option>
                            <option value="acceleration">Absolute Acceleration</option>
                            {!prefix.includes("bounce_rate") && <option value="seen_count_all">Seen Count</option>}
                        </Select>
                        <FieldError>{errors[`${prefix}_limit_by`]?.message}</FieldError>

                        {/* Sort By */}
                        {"sort" in params && (
                            <>
                                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                                    Sort Chart By
                                </Label>
                                <Select
                                    {...register(`${prefix}_sort`, { required: true })}
                                    isRequired={true}
                                    maxWidth="fit-content"
                                    display={"inline-flex"}
                                >
                                    <option value="relative_acceleration">Relative Acceleration</option>
                                    <option value="acceleration">Absolute Acceleration</option>
                                    <option value="seen_count_all">Seen Count</option>
                                </Select>
                                <FieldError>{errors[`${prefix}_sort`]?.message}</FieldError>
                            </>
                        )}

                        {/* Submit */}
                        <Button colorScheme={"button-primary"} type="submit">
                            Submit
                        </Button>
                    </form>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};
