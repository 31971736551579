import Plot from "react-plotly.js";
import { LoadingPage } from "../../LoadingPage";
import { Alert, AlertIcon } from "@chakra-ui/react";

type RenderMetricProps = {
    children?: React.ReactNode;
    isLoading: boolean;
    isError: boolean;
    data: {
        data: any;
        layout: any;
        config?: any;
        message?: string;
    };
};

export const RenderMetric = ({ data, children, isLoading, isError }: RenderMetricProps) => {
    const renderMetric = () => {
        if (isLoading === true) return <LoadingPage />;

        if (isError === true) {
            return (
                <Alert status="error">
                    <AlertIcon />
                    There was an error fetching data.
                </Alert>
            );
        }

        if (data?.message) {
            return (
                <Alert status="info">
                    <AlertIcon />
                    {data.message}
                </Alert>
            );
        }

        return (
            <>
                <Plot data={data.data} layout={data.layout} config={data.config} />
            </>
        );
    };

    return (
        <div style={{ height: "100%", width: "100%" }}>
            {renderMetric()}
            {children}
        </div>
    );
};
