import { useSearchParams } from "react-router-dom";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const Humps = () => {
    const [searchParams] = useSearchParams();
    const report = "humps";
    const metricParams = {
        interval_count: searchParams.get(`${report}_interval_count`) ?? 250,
        buckets: searchParams.get(`${report}_buckets`) ?? 48,
        cvr_revenue: searchParams.get(`${report}_cvr_revenue`) ?? "cvr",
    };
    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={report} params={metricParams} />
        </RenderMetric>
    );
};
