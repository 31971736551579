import { EditorContext } from "../../../types";
import { EditorContextAction } from "../../../types";
import { EDIT_CANCELED, SET_EDITOR_MODE, UPDATE_TEXT_CONTENT } from "../lib/events";
import { postMessage } from "../lib/postMessage";
import { viewports } from "./viewports";

export const editorContextReducer = (prev: EditorContext, action: EditorContextAction): EditorContext => {
    // debug(action);
    const { type, payload } = action;
    switch (type) {
        case "setEditorMode":
            postMessage(SET_EDITOR_MODE, { mode: payload });
            if (payload === "BROWSING") {
                return {
                    ...prev,
                    mode: payload,
                    currentAction: undefined,
                    selectedElement: undefined,
                };
            }
            return {
                ...prev,
                mode: payload,
            };

        case "textContentUpdated":
            if (!prev.selectedElement) return prev;
            const current = prev.selectedElement.element.current;
            current.textContent = payload;

            postMessage(UPDATE_TEXT_CONTENT, current.textContent);
            return {
                ...prev,
                selectedElement: {
                    ...prev.selectedElement,
                    element: {
                        ...prev.selectedElement.element,
                        current,
                    },
                },
            };

        case "htmlContentUpdated":
            if (prev.selectedElement === undefined) {
                return prev;
            }

            const currentElement = prev.selectedElement.element.current.cloneNode(true) as Element;
            currentElement.innerHTML = payload;

            return {
                ...prev,
                selectedElement: {
                    ...prev.selectedElement,
                    element: {
                        ...prev.selectedElement.element,
                        current: currentElement,
                    },
                },
            };

        case "setExperiment":
            return {
                ...prev,
                experiment: payload,
            };

        case "setViewport":
            const viewport = viewports.find((v) => v.name === payload);
            if (!viewport) {
                throw new Error(`Viewport not found: ${payload}`);
            }
            return {
                ...prev,
                viewport,
            };

        case "editCanceled":
            postMessage(SET_EDITOR_MODE, { mode: "BROWSING" });
            postMessage(EDIT_CANCELED, {});
            return {
                ...prev,
                mode: "BROWSING", // set the mode back to browsing
                selectedElement: undefined, // clear the selected element
            };

        case "editHtmlCanceled":
            if (!prev.selectedElement) return prev;

            const original = prev.selectedElement.element.original.cloneNode(true) as Element;

            postMessage(SET_EDITOR_MODE, { mode: "BROWSING" });
            return {
                ...prev,
                selectedElement: {
                    ...prev.selectedElement,
                    element: {
                        ...prev.selectedElement.element,
                        current: original,
                    },
                },
            };

        case "elementSelected":
            return {
                ...prev,
                currentAction: payload.action,
                selectedElement: payload.element,
            };

        default: {
            const errorMessage = `Unknown EditorContext action: ${type}`;
            throw new Error(errorMessage);
        }
    }
};

// const debug = ({ type, payload }: EditorContextAction) => {
//   console.debug(`~~ EditorContext Reducer ~~~`);
//   console.debug(`type: ${type}`);
//   console.debug(`payload: ${payload}`);
//   console.debug(`~~~~~~~~~~~~~~~~~~~~~~~~~~~`);
// };
