import { useEffect, useState } from "react";
import { Text, IconButton, HStack, Input, Td, Tr, Select, Spinner } from "@chakra-ui/react";
import { EditIcon, DeleteIcon, CheckIcon } from "@chakra-ui/icons";
import { type CacheDurationInput } from "./types";
import { unitOfTimeOptions } from "./AddItemForm";
import { useAuthenticatedUser } from "../../../hooks";
import { DurationUnitType } from "../../../types";
import { unitOfTimeLabel } from "./unitOfTimeLabel";

type Props = {
    item: CacheDurationInput;
    onDelete: (path: string) => void;
    onEdit: (path: string, item: CacheDurationInput) => void;
    isSaving: string;
};
const cloudOpsOnly = ["__global__"];
const editPathBlacklist = ["__global__", "/"];

function Item({ item, onDelete, onEdit, isSaving }: Props) {
    const [isEditing, setIsEditing] = useState(false);
    const [editItem, setEditItem] = useState<CacheDurationInput>(item);
    const { isCloudOpsRole } = useAuthenticatedUser();

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        onEdit(item.prefix, editItem);
        setIsEditing(false);
    };

    // Effect to attach and clean up the keydown event listener
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsEditing(false);
            }
        };

        // Attach the event listener to the whole document
        document.addEventListener("keydown", handleKeyDown);

        // Clean up the event listener
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, []);

    return (
        <Tr>
            {isEditing ? (
                <>
                    <Td width="60%">
                        {editPathBlacklist.includes(editItem.prefix) ? (
                            <Text pl="16px">{formatPath(editItem.prefix)}</Text>
                        ) : (
                            <Input
                                name="path"
                                value={formatPath(editItem.prefix)}
                                onChange={(e) => setEditItem((prev) => ({ ...prev, ...{ prefix: e.target.value } }))}
                            />
                        )}
                    </Td>

                    {isCloudOpsRole ? (
                        <Td width="10%">
                            <Text pl="16px">{item.seconds ? item.seconds : "-"}</Text>
                        </Td>
                    ) : null}

                    <Td width="250px">
                        <Input
                            name="amountOfTime"
                            type="number"
                            pattern="\d*"
                            display={"inline"}
                            w={"55px"}
                            value={editItem["amountOfTime"]}
                            onChange={(e) => setEditItem((prev) => ({ ...prev, ...{ amountOfTime: e.target.value } }))}
                        />
                        &nbsp;
                        <Select
                            id="unitOfTime"
                            name="unitOfTime"
                            value={editItem["unitOfTime"]}
                            maxWidth={"120px"}
                            display={"inline-block"}
                            onChange={(e) =>
                                setEditItem((prev: CacheDurationInput) => {
                                    return { ...prev, ...{ unitOfTime: e.target.value as DurationUnitType } };
                                })
                            }
                            required
                        >
                            {unitOfTimeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                    </Td>
                </>
            ) : (
                <>
                    <Td width="60%">
                        <Text pl="16px">{formatPath(editItem.prefix)}</Text>
                    </Td>
                    {isCloudOpsRole ? (
                        <Td width="10%">
                            <Text pl="16px">{item.seconds ? item.seconds : "-"}</Text>
                        </Td>
                    ) : null}

                    <Td width="250px">
                        <Text display={"inline"} pl="16px">
                            {item.amountOfTime ? item.amountOfTime : "-"}
                        </Text>
                        <Text display={"inline"} pl="16px">
                            {item.unitOfTime ? unitOfTimeLabel(item.unitOfTime) : "-"}
                        </Text>
                    </Td>
                </>
            )}
            <Td minWidth="150px">
                {cloudOpsOnly.includes(item.prefix) && !isCloudOpsRole ? null : (
                    <Actions
                        isSaving={isSaving}
                        isEditing={isEditing}
                        handleSave={handleSave}
                        handleEdit={handleEdit}
                        onDelete={onDelete}
                        item={item}
                    />
                )}
            </Td>
        </Tr>
    );
}

type ActionsProps = {
    item: CacheDurationInput;
    isSaving: string;
    isEditing: boolean;
    handleSave: () => void;
    handleEdit: () => void;
    onDelete: (path: string) => void;
};

const Actions = ({ item, isSaving, isEditing, handleSave, handleEdit, onDelete }: ActionsProps) => {
    const deleteBlacklist = ["__global__", "/"];
    const showSpinner = isSaving === item.prefix;
    return (
        <HStack>
            {showSpinner ? (
                <Spinner height="40px" width="40px" color="primary-default" />
            ) : isEditing ? (
                <IconButton icon={<CheckIcon />} onClick={handleSave} aria-label="Save" />
            ) : (
                <IconButton icon={<EditIcon />} onClick={handleEdit} aria-label="Edit" />
            )}

            {deleteBlacklist.includes(item.prefix) || showSpinner ? null : (
                <IconButton
                    ml={"spacer-4"}
                    icon={<DeleteIcon />}
                    onClick={() => onDelete(item.prefix)}
                    aria-label="Delete"
                />
            )}
        </HStack>
    );
};

const formatPath = (path: string) => {
    switch (path) {
        case "__global__":
            return "DEFAULT";
        case "/":
            return "HOMEPAGE";
        default:
            return path;
    }
};

export default Item;
