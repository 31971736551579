import { Button, Text, useDisclosure } from "@chakra-ui/react";
import { truncate } from "../lib";
import { MessageDisplayModal } from "../pages/EditStorePage/MessageDisplayModal";

export const MessageDisplay = (message: string) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const messageLength = message.length;
    if (messageLength === 0) {
        return <Text>No message</Text>;
    }
    if (messageLength <= 90) {
        return <Text>{message}</Text>;
    }
    return (
        <Text>
            {messageLength <= 80 ? message : truncate(message, 80)}{" "}
            <Button variant={"link"} colorScheme="button-primary" size="sm" onClick={onOpen}>
                {messageLength <= 90 ? "" : "[ + click to view ]"}
            </Button>
            <MessageDisplayModal isOpen={isOpen} onClose={onClose} message={message} />
        </Text>
    );
};
