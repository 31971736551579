import { Text, Image, Box, Button, Center, VStack } from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { useNavigate } from "react-router-dom";
import logo from "../../images/Error_illustration.svg";
import { Header } from "../../components";
import { useAuthenticatedUser } from "../../hooks";
import { Login } from "../../authentication/Login";

export const NotFound404 = () => {
    const { isAuthenticated } = useAuthenticatedUser();
    const navigate = useNavigate();

    return (
        <PageContainer>
            {isAuthenticated ? (
                <VStack spacing={4} align="stretch">
                    <Center>
                        <Box display="flex" boxSize="lg">
                            <Image src={logo} />
                        </Box>
                    </Center>
                    <Center>
                        <Header pb={4} as="h1">
                            Error 404
                        </Header>
                    </Center>
                    <Center>
                        <Text>Sorry, the page you were looking for could not be found.</Text>
                    </Center>
                    <Center>
                        <Button colorScheme="button-primary" onClick={() => navigate("/")}>
                            Go Back Home
                        </Button>
                    </Center>
                </VStack>
            ) : (
                <Login />
            )}
        </PageContainer>
    );
};
