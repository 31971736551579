export const queryParameters = (params: any): string => {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
            // If the value is an array, append each element separately under the same key
            value.forEach((item) => searchParams.append(key, item));
        } else {
            // For non-array values, use the 'set' method
            searchParams.set(key, value);
        }
    });

    return searchParams.toString();
};
