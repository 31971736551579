import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ApplicationHeader } from "./ApplicationHeader";
import { ApplicationSideBar } from "./ApplicationSideBar";

export const Layout = () => {
    return (
        <>
            <Grid
                templateAreas={{
                    base: `"header header"
                  "main main"`,
                    md: `"header header"
                  "nav main"`,
                }}
                gridTemplateRows={{ base: "1fr", md: `${HEADER_HEIGHT} 1fr` }}
                gridTemplateColumns={{ base: "1fr", md: "236px 1fr" }}
                h={{ md: "100vh" }}
            >
                {/* header */}
                <GridItem area={"header"}>
                    <ApplicationHeader />
                </GridItem>

                {/* left nav */}
                <GridItem display={{ base: "none", md: "initial" }} area={"nav"}>
                    <ApplicationSideBar />
                </GridItem>

                {/* page content */}
                <GridItem height={`calc(100vh - ${HEADER_HEIGHT})`} area={"main"}>
                    <Outlet />
                </GridItem>
            </Grid>
        </>
    );
};

export const HEADER_HEIGHT = "72px";
