import { ROUTES } from "../Routes";
import { Layout } from "../layouts/Layout";
import {
    CreateNewCustomer,
    ConfirmationPage,
    OrganizationPage,
    CreateStorePage,
    CreateStoreConfirmationPage,
    ListUsers,
    ListOrganizationsPage,
    ListBulletinsPage,
    CreateBulletinPage,
    EditBulletinPage,
    AppsByStoreResource,
    MonitoringPage,
    ListUsersTab,
    StoresListTab,
} from "../pages";

export const cloudOpsRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: ROUTES.ORGANIZATION,
                element: <OrganizationPage />,
                children: [
                    { path: "users", element: <ListUsersTab /> },
                    { path: "stores", element: <StoresListTab /> },
                ],
            },
            { path: ROUTES.CREATE_CUSTOMER, element: <CreateNewCustomer /> },
            { path: ROUTES.CONFIRMATION_PAGE, element: <ConfirmationPage /> },
            { path: ROUTES.CREATE_STORE, element: <CreateStorePage /> },
            { path: ROUTES.CREATE_STORE_CONFIRMATION, element: <CreateStoreConfirmationPage /> },
            { path: ROUTES.LIST_USERS, element: <ListUsers /> },
            { path: ROUTES.ALL_ORGANIZATIONS, element: <ListOrganizationsPage /> },
            { path: ROUTES.LIST_BULLETINS_PAGE, element: <ListBulletinsPage /> },
            { path: ROUTES.CREATE_BULLETIN, element: <CreateBulletinPage /> },
            { path: ROUTES.BULLETIN_EDIT, element: <EditBulletinPage /> },
            { path: ROUTES.DOCUMENTATION_APPS_BY_STORE, element: <AppsByStoreResource /> },
            { path: ROUTES.MONITORING_PAGE, element: <MonitoringPage /> },
        ],
    },
];
