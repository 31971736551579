import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    VStack,
    Button,
    useDisclosure,
    Avatar,
    Box,
    HStack,
    Badge,
    Text,
} from "@chakra-ui/react";

import { useStores, useAuthenticatedUser } from "../../hooks";
import { useNavigate, generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../Routes";
import {
    IconButtonEdit,
    IconButtonExperiment,
    IconButtonDelete,
    IconButtonClearCache,
    SubHeader,
    Tooltip,
    DeleteStoreAlertDialog,
} from "../../components";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { subdomainOf, storeModeBadge } from "../../lib";
import { useState, useMemo } from "react";
import { Store } from "../../types";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingPage } from "../LoadingPage";
import { NotFound404 } from "../ErrorPages";

export const StoresListTab = () => {
    const { orgId } = useParams();
    const { isLoading, isError, data: stores } = useStores();
    const navigate = useNavigate();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const queryClient = useQueryClient();
    const [storeData, setStoreData] = useState<Pick<Store, "id" | "customerUrl">>({
        id: "",
        customerUrl: "",
    });

    const filteredStoresData = useMemo(() => {
        return stores?.filter((store) => store.orgId === orgId);
    }, [stores, orgId]);

    if (filteredStoresData === undefined || isLoading) return <LoadingPage />;
    if (isError) return <NotFound404 />;

    const openDeleteStoreAlertDialog = (storeId: string, customerUrl: string) => {
        setStoreData({
            id: storeId,
            customerUrl: customerUrl,
        });
        onOpen();
    };

    const closeDeleteStoreAlertDialog = () => {
        queryClient.invalidateQueries({ queryKey: ["stores"] });
        onClose();
    };

    return (
        <VStack width="100%" pt="spacer-2" alignItems={"baseline"}>
            <Flex alignSelf={{ md: "flex-end" }}>
                {isCloudOpsRole ? (
                    <Button
                        ml={"auto"}
                        colorScheme={"button-primary"}
                        onClick={() =>
                            navigate(
                                generatePath(ROUTES.CREATE_STORE, {
                                    orgId: orgId,
                                }),
                            )
                        }
                    >
                        Create
                    </Button>
                ) : (
                    <div style={{ height: "40px" }}></div>
                )}
            </Flex>
            {!filteredStoresData || filteredStoresData?.length === 0 ? (
                <SubHeader alignSelf="center">No stores found</SubHeader>
            ) : null}
            {filteredStoresData?.map((store) => {
                const { label, color } = storeModeBadge(store.mode);
                return (
                    <Flex
                        key={store.id}
                        padding={"spacer-2"}
                        paddingBottom={{ base: "0", md: "spacer-2" }}
                        border={"1px"}
                        borderColor={"gray.200"}
                        borderRadius={"0.75rem"}
                        width={"100%"}
                    >
                        <Avatar name={store.customerUrl} />
                        <Box ml="3">
                            {isCloudOpsRole ? (
                                <SubHeader mr="auto" pb="0" textStyle={"text-body-regular"}>
                                    <Menu>
                                        <MenuButton as="b">
                                            <ChevronDownIcon />
                                            {store.customerUrl}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                icon={<ExternalLinkIcon />}
                                                as="a"
                                                target="_blank"
                                                href={`https://admin.shopify.com/store/${subdomainOf(
                                                    `${store.ecomUrl}`,
                                                )}`}
                                            >
                                                Shopify Admin
                                            </MenuItem>
                                            <MenuItem
                                                icon={<ExternalLinkIcon />}
                                                as="a"
                                                target="_blank"
                                                href={`https://${store.customerUrl}`}
                                            >
                                                {store.customerUrl}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </SubHeader>
                            ) : (
                                <SubHeader
                                    mr="auto"
                                    pb="0"
                                    textStyle={"text-body-regular"}
                                    as="a"
                                    target="_blank"
                                    href={`https://${store.customerUrl}`}
                                >
                                    <b>{store.customerUrl}</b>
                                </SubHeader>
                            )}
                            <Text>
                                <Badge colorScheme={color} mt={{ base: "spacer-1", md: "spacer-2" }}>
                                    {label}
                                </Badge>
                            </Text>
                            <HStack
                                height={{ base: "30%", lg: "15px" }}
                                width="100%"
                                mt={{ base: "spacer-3", md: "spacer-4" }}
                                mb={{ base: "0", md: "spacer-4" }}
                            >
                                <Tooltip label="Clear Cache" aria-label="Clear Cache">
                                    <IconButtonClearCache
                                        aria-label={"Clear Cache"}
                                        onClick={() =>
                                            navigate(
                                                generatePath(ROUTES.MANAGE_CACHE_AUTHENTICATED, {
                                                    customerUrl: `${store.customerUrl}`,
                                                }),
                                            )
                                        }
                                    />
                                </Tooltip>
                                <Tooltip label="Edit Store" aria-label="Edit store details">
                                    <IconButtonEdit
                                        onClick={() =>
                                            navigate(
                                                generatePath(ROUTES.STORE_EDIT, {
                                                    customerUrl: store.customerUrl,
                                                }),
                                            )
                                        }
                                        aria-label={"Edit Store"}
                                    />
                                </Tooltip>
                                {isCloudOpsRole ? (
                                    <Tooltip label="Manage Experiments" aria-label="Manage experiments">
                                        <IconButtonExperiment
                                            onClick={() =>
                                                navigate(
                                                    generatePath(ROUTES.LIST_EXPERIMENTS, {
                                                        customerUrl: store.customerUrl,
                                                    }),
                                                )
                                            }
                                            aria-label={"Edit Experiment"}
                                        />
                                    </Tooltip>
                                ) : null}
                                {isCloudOpsRole ? (
                                    <Tooltip label="Delete Store" aria-label="Delete store">
                                        <IconButtonDelete
                                            onClick={() => openDeleteStoreAlertDialog(store.id, store.customerUrl)}
                                            aria-label={"Delete store"}
                                        />
                                    </Tooltip>
                                ) : null}
                            </HStack>
                        </Box>
                    </Flex>
                );
            })}
            <DeleteStoreAlertDialog
                isOpen={isOpen}
                close={closeDeleteStoreAlertDialog}
                storeData={storeData}
            ></DeleteStoreAlertDialog>
        </VStack>
    );
};
