import { useMutation } from "@tanstack/react-query";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { SVC_URLS } from "../../config";
import { UpdateBulletinRequest } from "../../types";
import { useParams } from "react-router-dom";

export const useUpdateBulletinMutation = () => {
    const { bulletinId } = useParams<{ bulletinId: string }>();
    if (!bulletinId) throw new Error("bulletinId is required");
    const url = `${SVC_URLS["ecom"]}/bulletin/${bulletinId}`;

    return useMutation({ mutationFn: updateBulletin(url) });
};

const updateBulletin = (url: string) => async (request: UpdateBulletinRequest) => {
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(request),
    });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
