import { CopyIcon } from "@chakra-ui/icons";
import { IconButton, useClipboard, useToast } from "@chakra-ui/react";

type CopyButtonProps = {
    value: string | undefined | null;
    success?: string | undefined;
    ml?: number;
    mr?: number;
};

export const CopyButton = ({ success, value, ml, mr }: CopyButtonProps) => {
    const { onCopy } = useClipboard(value ?? "");
    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const copy = () => {
        onCopy();
        showToast({
            title: "Copied to clipboard",
            description: success ?? value,
            status: "success",
        });
    };

    if (ml === undefined) {
        ml = 2;
    }

    if (mr === undefined) {
        mr = 2;
    }

    return (
        <IconButton
            ml={ml}
            mr={mr}
            size="xs"
            variant="ghost"
            colorScheme="gray"
            aria-label="Copy Store ID"
            fontSize="18px"
            onClick={copy}
            icon={<CopyIcon />}
        />
    );
};
