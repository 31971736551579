import { DurationUnitType } from "../../../types";

export const unitOfTimeLabel = (unit: DurationUnitType): string => {
    switch (unit) {
        case DurationUnitType.Second:
            return "Second(s)";
        case DurationUnitType.Minute:
            return "Minute(s)";
        case DurationUnitType.Hour:
            return "Hour(s)";
        case DurationUnitType.Day:
            return "Day(s)";
        case DurationUnitType.Week:
            return "Week(s)";
        default:
            console.error(`Unknown unit of time: ${unit}`);
            return "";
    }
};
