import { Flex } from "@chakra-ui/react";
import { useNavigate, generatePath } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { IconButtonEdit, Tooltip } from "../../components";

type ExperimentRowProps = {
    experimentId: string;
    customerUrl: string;
};

export const ExperimentRowActions = ({ experimentId, customerUrl }: ExperimentRowProps) => {
    const navigate = useNavigate();

    return (
        <Flex>
            <Tooltip label="Edit Experiment" aria-label="Edit Experiment">
                <IconButtonEdit
                    onClick={() =>
                        navigate(
                            generatePath(ROUTES.MANAGE_EXPERIMENT, {
                                customerUrl,
                                experimentId,
                            }),
                        )
                    }
                    aria-label={"Edit Experiment"}
                />
            </Tooltip>
        </Flex>
    );
};
