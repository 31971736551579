import dayjs from "dayjs";
import { CrUXReportType } from "../../../../types";

export const percentFormatter = (value: number) => {
    return `${Math.round(value * 100)}%`;
};

export const dateFormatter = (date: string) => {
    return dayjs(date).format("MMM 'YY");
};

export const labelDateFormatter = (date: string) => {
    return dayjs(date).format("MMM D, YYYY");
};

export const closestDate = (date: string, report: CrUXReportType) => {
    const diff = report.data.map((d) =>
        Math.abs((new Date(date) as unknown as number) - new Date(d.firstDate).getTime()),
    );
    const index = diff.indexOf(Math.min(...diff));
    return report.data[index].firstDate;
};

export const colors = {
    blue: "#1a76ff",
    orange: "#ffa500",
    red: "#ff0000",
    teal: "#008080",
    green: "#55cd70",
    yellow: "#ffc800",
    purple: "#800080",
};
;
