import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Table,
    Tbody,
    TableContainer,
    Tr,
    Td,
    Divider,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    Flex,
} from "@chakra-ui/react";
import { PlusSquareIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { ExperimentVariation } from "../../../types";
import { postMessage, SELECT_EXISTING_CHANGE } from "../lib";
import { useDeleteActionMutation, generateExperimentQueryKey, useMotionProps } from "../../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { Header } from "../../../components";
import { defaultToastOptions } from "../lib/defaultToastOptions";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    variation: ExperimentVariation | undefined;
    customerUrl: string;
};

export const ChangeDrawer = ({ isOpen, onClose, variation, customerUrl }: Props) => {
    const queryClient = useQueryClient();
    const showToast = useToast(defaultToastOptions);
    const deleteActionMutation = useDeleteActionMutation();
    const motionProps = useMotionProps();
    const deleteAction = (actionId: string, variationId: string) => {
        if (!variation) return;
        const response = window.confirm("Are you sure you want to delete this change?");

        const onDeleteSuccess = () => {
            queryClient.invalidateQueries(generateExperimentQueryKey(variation.experimentId));
            showToast({
                title: "Change deleted",
                description: "Your change has been deleted.",
                status: "success",
            });
        };

        const onDeleteError = () => {
            showToast({
                title: "Error",
                description: "Something went wrong. Please try again.",
                status: "error",
            });
        };

        if (response) {
            deleteActionMutation.mutate(
                { actionId, variationId, customerUrl },
                { onSuccess: onDeleteSuccess, onError: onDeleteError },
            );
        }
    };

    return (
        <Drawer size={{ base: "xs", md: "sm" }} isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent motionProps={motionProps}>
                <Flex align="center" m={3}>
                    <DrawerCloseButton position="static" />
                    <Header textStyle="text-header-M" ml={3}>
                        Changes
                    </Header>
                </Flex>
                <Divider />
                <DrawerBody>
                    <TableContainer>
                        <Table variant="simple">
                            <Tbody>
                                {variation?.actions.map((action, index) => (
                                    <Tr key={action.id}>
                                        <Td>{index + 1}</Td>
                                        <Td>
                                            <Text
                                                textStyle={{
                                                    base: "text-body-meta-regular",
                                                    md: "text-body-meta-bold",
                                                }}
                                            >
                                                Text/HTML
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text
                                                textStyle={{
                                                    base: "text-body-meta-regular",
                                                    md: "text-body-meta-medium",
                                                }}
                                            >
                                                Replace
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Menu>
                                                <MenuButton as={PlusSquareIcon} cursor="pointer" />
                                                <MenuList>
                                                    <MenuItem
                                                        icon={<EditIcon />}
                                                        onClick={() => {
                                                            postMessage(SELECT_EXISTING_CHANGE, {
                                                                actionId: action.id,
                                                            });
                                                            onClose();
                                                        }}
                                                        textStyle={{
                                                            base: "text-body-meta-regular",
                                                            md: "text-body-meta-medium",
                                                        }}
                                                    >
                                                        Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        icon={<DeleteIcon />}
                                                        textStyle={{
                                                            base: "text-body-meta-regular",
                                                            md: "text-body-meta-medium",
                                                        }}
                                                        onClick={() =>
                                                            deleteAction(action.id as string, variation.id as string)
                                                        }
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
