import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { User } from "../../types";

export const useListUsers = (byOrgId = "") => {
    return useQuery(generateUsersQueryKey(byOrgId), async () => await listUsers(byOrgId));
};

const listUsers = async (byOrgId = ""): Promise<User[]> => {
    const filters = { byOrgId };
    const LIST_USERS = byOrgId ? `${SVC_URLS["auth"]}/user?${queryParameters(filters)}` : `${SVC_URLS["auth"]}/user`;
    const options = await fetchOptions();
    const response = await fetch(LIST_USERS, options).catch(handleErrors);

    return fetchResponse(response);
};

export const generateUsersQueryKey = (byOrgId = ""): string[] => ["users", byOrgId];

const queryParameters = (params: any): string => {
    return Object.keys(params)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join("&");
};
