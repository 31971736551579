import { useToast, Text, HStack, Badge } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState, useMemo } from "react";
import { useStores, useAuthenticatedUser } from "../../hooks";
import { truncate, storeModeBadge } from "../../lib";
import { Store } from "../../types";
import { PageContainer } from "../PageContainer";
import { LinkButton } from "./LinkButton";
import { StoreRowActions } from "./StoreRowActions";
import { LoadingPage } from "../LoadingPage";
import { Header, Tooltip, VirtualizedDataTable, SubHeader, Link, Search } from "../../components";
import { ROUTES } from "../../Routes";
import { generatePath } from "react-router-dom";

type StoreRow = Store;

const searchFilterFunction = (store: Store, searchTerm: string) =>
    store.customerUrl.toLowerCase().includes(searchTerm) || store.org.name.toLowerCase().includes(searchTerm);

export const ListStores = () => {
    const [rows, setRows] = useState<StoreRow[]>([]);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const { data: stores, isError, isLoading } = useStores();
    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const columns = useMemo(() => buildColumns(storeModeBadge), []);

    useEffect(
        function handleError() {
            if (isError) {
                showToast({
                    title: "Error",
                    description:
                        "An unexpected error has occurred. Please wait a few moments and try again. If the problem persists, please contact customer success.",
                    status: "error",
                });
            }
        },
        [isError, showToast],
    );

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer style={{ display: "flex", flexDirection: "column" }}>
            <HStack justifyContent="space-between" pb={"spacer-6"} alignItems="center">
                <Header>Stores</Header>
                <Search
                    data={stores}
                    setFilteredData={setRows}
                    setSearchIsLoading={setSearchIsLoading}
                    filterFunction={searchFilterFunction}
                />
            </HStack>
            {rows.length === 0 ? (
                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <SubHeader>No stores found</SubHeader>
                </div>
            ) : (
                <>{searchIsLoading ? <LoadingPage /> : <VirtualizedDataTable data={rows} columns={columns} />}</>
            )}
        </PageContainer>
    );
};

const Mode = (props: any) => {
    const { isInternalRole } = useAuthenticatedUser();
    const { label, color } = props.storeModeBadge(props.row.original.mode);
    const customerUrl = props.row.original.customerUrl;

    if (isInternalRole) {
        return <Badge colorScheme={color}>{label}</Badge>;
    }

    return (
        <>
            <LinkButton
                url={generatePath(ROUTES.STORE_EDIT, {
                    customerUrl,
                })}
            />
            <Link
                to={generatePath(ROUTES.STORE_EDIT, {
                    customerUrl,
                })}
                target="new"
            >
                <Badge colorScheme={color}>{label}</Badge>
            </Link>
        </>
    );
};

const buildColumns = (storeModeBadge: Function) => {
    const columnHelper = createColumnHelper<Store>();
    const columns = [
        columnHelper.display({
            id: "actions",
            cell: (props) => (
                <StoreRowActions
                    storeId={props.row.original.id}
                    orgId={props.row.original.orgId}
                    customerUrl={props.row.original.customerUrl}
                ></StoreRowActions>
            ),
            header: "Actions",
        }),

        columnHelper.accessor("org", {
            cell: (props) => <Text>{props.getValue().name}</Text>,
            header: "Organization",
        }),

        columnHelper.accessor("customerUrl", {
            cell: (props) => (
                <>
                    <LinkButton url={`https://${props.getValue()}`} />
                    <Tooltip label={props.getValue()} aria-label={props.getValue()}>
                        <Link to={`https://${props.getValue()}`} target="new">
                            {truncate(props.getValue(), 20)}
                        </Link>
                    </Tooltip>
                </>
            ),
            header: "Store",
        }),

        columnHelper.accessor("mode", {
            cell: (props) => <Mode {...props} storeModeBadge={storeModeBadge} />,
            header: "Status",
        }),
    ];

    return columns;
};
