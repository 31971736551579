import { forwardRef, chakra } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export const Link = forwardRef(({ children, ...rest }: any, ref) => (
    <chakra.link
        ref={ref}
        as={ReactRouterLink}
        color={"text-link-primary-default"}
        _hover={{
            color(theme) {
                return theme.colors["text-link-primary-hover"];
            },
        }}
        _activeLink={{
            color(theme) {
                return theme.colors["text-link-primary-pressed"];
            },
        }}
        {...rest}
    >
        {children}
    </chakra.link>
));
