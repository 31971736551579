import { StoreModes } from "../types";

export const storeModeLabel = (mode: StoreModes) => {
    let label = "";

    switch (mode) {
        case StoreModes.Disabled:
            label = "Disabled";
            break;
        case StoreModes.Testing:
            label = "Testing";
            break;
        case StoreModes.DisabledByProvider:
            label = "Disabled By Provider";
            break;
        case StoreModes.Enabled:
            label = "Enabled";
            break;
        case StoreModes.DnsSetup:
            label = "DNS Setup";
            break;
        default:
            label = "Unknown";
    }

    return label;
};
