import { Button, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { PathNameDisplayModal } from "../pages/Customer/ManageCacheDurationTab/PathNameDisplayModal";

export const PathNameDisplay = (pathNames: string[]) => {
    const [expanded, setExpanded] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();

    if (!pathNames || pathNames.length === 0) {
        return <Text>entire site</Text>;
    }
    const amountOfPaths = pathNames.length;
    if (amountOfPaths === 1) {
        return <Text>{pathNames[0]}</Text>;
    }
    const handleClickView = () => {
        setExpanded(!expanded);
        onOpen();
    };

    if (amountOfPaths > 1) {
        return (
            <Text>
                {amountOfPaths} pages{" "}
                <Button variant={"link"} onClick={handleClickView}>
                    [ + click to view ]
                </Button>
                <PathNameDisplayModal isOpen={isOpen} onClose={onClose} pathNames={pathNames} />
            </Text>
        );
    }
};
