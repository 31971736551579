import { CrUXReport } from "..";
import { useCRUXReports } from "../../../../hooks";
import { CrUXReportType } from "../../../../types";
import { LoadingPage } from "../../../LoadingPage";

const supportedMetrics = [
    "experimental_time_to_first_byte",
    "first_contentful_paint",
    "largest_contentful_paint",
    "cumulative_layout_shift",
    "interaction_to_next_paint",
    "form_factors",
    "navigation_types",
];

export const CrUXReportsTab = () => {
    const { data, isLoading } = useCRUXReports();

    const metrics = data?.filter((report: CrUXReportType) => supportedMetrics.includes(report.metric));

    if (isLoading === true) return <LoadingPage />;

    return (
        <div>
            {metrics &&
                supportedMetrics.map((report: string) => {
                    const metric = metrics.find((metric) => metric.metric === report);
                    return metric ? <CrUXReport key={report} report={metric} /> : null;
                })}
        </div>
    );
};
