import {
    Badge,
    useToast,
    VStack,
    HStack,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    useDisclosure,
    Button,
} from "@chakra-ui/react";
import { useParams, useNavigate, useLocation, generatePath, Outlet } from "react-router-dom";
import { useOrg, useAuthenticatedUser } from "../../hooks";
import { useEffect, useState, useMemo } from "react";
import { PageContainer } from "../PageContainer";
import { LoadingPage } from "../LoadingPage";
import { Header, SubHeader } from "../../components";
import { DeleteOrgAlertDialog } from "./DeleteOrgAlertDialog";
import { NotFound404 } from "../ErrorPages";
import { TabPaths } from "../../types";
import { ROUTES } from "../../Routes";

export const OrganizationPage = () => {
    const { organization, orgId, isLoading } = useData();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const navigate = useNavigate();
    const location = useLocation();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const [currentTab, setCurrentTab] = useState("");

    const tabPaths: TabPaths = useMemo(() => {
        return {
            Users: "users",
            Stores: "stores",
        };
    }, []);

    useEffect(() => {
        if (orgId) {
            const path = location.pathname.split("/").pop();
            const matchingKey = Object.keys(tabPaths).find((key) => tabPaths[key] === path);
            const initialTab = matchingKey ? tabPaths[matchingKey] : Object.keys(tabPaths)[0];
            const basePath = generatePath(ROUTES.ORGANIZATION, { orgId });
            if (!matchingKey && currentTab === "") {
                setCurrentTab(initialTab);
                navigate(`${basePath}/${tabPaths[initialTab]}`, { replace: true });
            }
            if (matchingKey) {
                setCurrentTab(matchingKey);
            }
        }
    }, [location.pathname, tabPaths, navigate, orgId, currentTab]);

    const handleTabChange = (index: string) => {
        const basePath = generatePath(ROUTES.ORGANIZATION, { orgId });
        const path = tabPaths[index];
        navigate(basePath + "/" + path);
    };

    if (isLoading) return <LoadingPage />;

    const orgBadge = {
        label: organization?.active ? "Active" : "Inactive",
        color: organization?.active ? "green" : "red",
    };

    return (
        <>
            {organization?.deleted ? (
                <NotFound404 />
            ) : (
                <PageContainer>
                    <HStack align={"baseline"}>
                        <VStack alignItems={"baseline"} width="100%">
                            <Header>Manage Organization</Header>
                            <SubHeader pb={{ base: "spacer-1", md: "spacer-0" }} mr="auto">
                                {organization?.name}
                                <Badge ml="spacer-1" colorScheme={orgBadge.color}>
                                    {orgBadge.label}
                                </Badge>
                            </SubHeader>

                            <Tabs
                                isLazy
                                width="100%"
                                index={Object.keys(tabPaths).indexOf(currentTab)}
                                onChange={(index) => handleTabChange(Object.keys(tabPaths)[index])}
                            >
                                <TabList>
                                    {isCloudOpsRole ? (
                                        <Button
                                            pb={{ base: "spacer-1", md: "spacer-0" }}
                                            mr="auto"
                                            variant="outline"
                                            colorScheme="button-secondary"
                                            type="button"
                                            size="sm"
                                            onClick={onDeleteOpen}
                                        >
                                            Delete
                                        </Button>
                                    ) : (
                                        <div style={{ width: "90%" }}></div>
                                    )}
                                    {Object.keys(tabPaths).map((tabName) => {
                                        return <Tab key={tabName}>{tabName}</Tab>;
                                    })}
                                </TabList>
                                <TabPanels>
                                    <Outlet />
                                </TabPanels>
                            </Tabs>
                        </VStack>
                    </HStack>
                    <DeleteOrgAlertDialog isDeleteOpen={isDeleteOpen} onDeleteClose={onDeleteClose} orgId={orgId} />
                </PageContainer>
            )}
        </>
    );
};

const useData = () => {
    const showToast = useToast();
    const { orgId } = useParams();
    if (!orgId) throw new Error("Org ID is required");
    const { isError: organizationError, isLoading: organizationLoading, data: organizationData } = useOrg(orgId);
    const isError = organizationError;
    const isLoading = organizationLoading;

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description:
                    "There was an error loading the Organization or User information. Please try again in a few moments. If this error persists, please contact Customer Success!",
                status: "error",
                duration: 4000,
                isClosable: true,
                variant: "subtle",
            });
        }
    }, [isError, showToast]);

    return {
        organization: organizationData,
        orgId: orgId,
        isLoading,
        isError,
    };
};
