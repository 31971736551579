import { useEffect, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useStoreLogs } from "./queries";
import { StoreModes } from "../types";
import { isStoreMode } from "../lib";

export const useStoreModeHistoryLog = (storeId: string, customerUrl: string) => {
    const { data, isLoading, isError, status } = useStoreLogs(storeId, customerUrl);
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    useEffect(
        function handleError() {
            if (!isError) return;
            showToast({
                title: "Error",
                description:
                    "There was an error fetching the store history. Please try again in a few minutes. If the problem persists, please contact support.",
                status: "error",
            });
        },
        [isError, showToast],
    );

    const rows = useMemo(
        function transformHistoryLogs() {
            if (!data) return [];
            return data
                .filter(({ entry }) => entry.startsWith("store mode:"))
                .map(({ id, createdAt, entry, createdBy }) => {
                    return {
                        id,
                        date: createdAt,
                        user: createdBy,
                        status: getStoreModeFromLogEntry(entry),
                        reason: getReasonFromLogEntry(entry), // parse the reason from the entry
                        entry,
                    };
                });
        },
        [data],
    );

    return {
        data: rows,
        isLoading,
        status,
    };
};

const getReasonFromLogEntry = (entry: string): string => {
    const REASON_TOKEN = "Reason: ";
    const INDEX_OF_REASON = 1;
    return entry.split(REASON_TOKEN)[INDEX_OF_REASON]?.trim() ?? "No reason provided";
};

const getStoreModeFromLogEntry = (entry: string): StoreModes => {
    const INDEX_OF_MODE_WITH_REMAINING_ENTRY = 1;
    const INDEX_OF_MODE_WITHOUT_REMAINING_ENTRY = 1;
    const MODE_TOKEN = "store mode: ";
    const MODE_QUOTE = "'";
    // parse the mode from the entry
    let mode = entry.split(MODE_TOKEN)[INDEX_OF_MODE_WITH_REMAINING_ENTRY]?.split(MODE_QUOTE)[
        INDEX_OF_MODE_WITHOUT_REMAINING_ENTRY
    ];
    if (!isStoreMode(mode)) {
        console.error(`Unknown store.mode: `, mode);
        mode = StoreModes.Unknown;
    }

    return mode as StoreModes;
};
