import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { User, EditUserRequest } from "../../types";

export const useEditUserMutation = () => {
    return useMutation(({ requestBody, userId }: { requestBody: EditUserRequest; userId: string }) =>
        editUser({ requestBody, userId }),
    );
};

const editUser = async ({ requestBody, userId }: { requestBody: EditUserRequest; userId: string }): Promise<User> => {
    const EDIT_USER = `${SVC_URLS["auth"]}/user/${userId}`;
    const options = await fetchOptions({
        method: "PUT",
        body: JSON.stringify(requestBody),
    });
    const response = await fetch(EDIT_USER, options).catch(handleErrors);
    return fetchResponse(response);
};
