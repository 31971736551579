import { Divider, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { Header } from "../../../../components";
import { CrUXReportType } from "../../../../types";
import { Link } from "../../../../components";
import { HistogramReport, PercentilesReport, FractionReport } from "./";
import { useMediaQuery } from "@chakra-ui/react";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../../config/BRAND_NAME";

export const CrUXReport = ({ report }: { report: CrUXReportType }) => {
    const [isMedium] = useMediaQuery("(max-width: 1440px)");

    const link =
        report.name === "Navigation Types"
            ? "https://developer.chrome.com/blog/crux-navigation-types"
            : `https://web.dev/${report.name.toLowerCase()}/`;

    return (
        <>
            <Header style={{ marginTop: "20px" }}>
                {report.name} - ({report.metric})
            </Header>
            <Divider style={{ margin: "20px 0" }} />
            {report.name !== "Form Factors" ? (
                <div>
                    Learn more about <strong>{report.name}</strong>{" "}
                    <Link to={link} textDecoration="underline" target="_blank">
                        here
                    </Link>
                </div>
            ) : null}
            {report.metric === "navigation_types" && (
                <Alert style={{ marginTop: "20px" }} status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text>
                        It is important to note that "navigate_cache" refers to the HTTP cache in the browser,{" "}
                        <strong>not</strong>
                        &nbsp;{BRAND_NAME}'s advanced {MAIN_PRODUCT} caching.
                    </Text>
                </Alert>
            )}
            <div style={{ display: "flex", flexDirection: isMedium ? "column" : "row" }}>
                {report.types.includes("percentilesTimeseries") && <PercentilesReport report={report} />}
                {report.types.includes("histogramTimeseries") && <HistogramReport report={report} />}
            </div>
            {report.types.includes("fractionTimeseries") && <FractionReport report={report} />}
        </>
    );
};
