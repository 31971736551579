import { Stack, VStack, AlertTitle, Box, CloseButton, Flex } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { WelcomeToNostraContent } from "./Documentation/GettingStarted/WelcomeToNostraPage";
import { useStores, useListBulletin, useDismissedBulletins, useAuthenticatedUser } from "../hooks";
import { AlertMessage, EntriCloseEvent, StoreStatusAlert } from "../components";
import { useMemo } from "react";
import { Bulletin } from "../types";
import { LoadingPage } from "./LoadingPage";
import { getFeatureFlag } from "../lib";
import { StoreModes } from "../types";
import { DnsSetup } from "../components/DnsSetup/DnsSetup";

const EntriLocalStorage = {
    dnsConfigurationKey: (customerUrl: string) => `dnsConfiguration_${customerUrl}`,
    COMPLETED_SUCCESSFULLY: "success",
};

export const Home = () => {
    const { data: stores, isLoading: storesLoading } = useStores();
    const isEntriEnabled = getFeatureFlag("ENTRI_DNS_INTEGRATION");
    const storeInDnsSetupMode = useMemo(() => {
        if (!isEntriEnabled) return undefined;
        return stores?.find(
            (store) =>
                store.mode === StoreModes.DnsSetup &&
                store.workerPlatform === "shopify" &&
                localStorage.getItem(EntriLocalStorage.dnsConfigurationKey(store.customerUrl)) !==
                    EntriLocalStorage.COMPLETED_SUCCESSFULLY,
        );
    }, [stores, isEntriEnabled]);
    const { data: bulletins, isLoading: bulletinsLoading } = useListBulletin({ filter: "published" });
    const filteredStores = useMemo(
        () =>
            stores
                ?.filter((store) => store.mode !== StoreModes.Enabled)
                .sort((a, b) => {
                    const createdAtA =
                        Array.isArray(a?.logs) && a?.logs.length === 1 ? new Date(a?.logs[0]?.createdAt) : undefined;
                    const createdAtB =
                        Array.isArray(b?.logs) && b?.logs.length === 1 ? new Date(b?.logs[0]?.createdAt) : undefined;
                    if (!createdAtA || !createdAtB) {
                        return 0;
                    }
                    if (createdAtA > createdAtB) {
                        return -1;
                    }
                    if (createdAtA < createdAtB) {
                        return 1;
                    }
                    return 0;
                }),
        [stores],
    );

    const { isOwnerRole } = useAuthenticatedUser();
    const { dismissedBulletins, dismissBulletin } = useDismissedBulletins();
    const activeBulletins: Bulletin[] = useMemo(() => {
        if (!bulletins) return [];

        return bulletins?.filter((bulletin: Bulletin) => !dismissedBulletins.includes(bulletin.id)); // Exclude dismissed bulletins
    }, [bulletins, dismissedBulletins]);

    if (storesLoading || bulletinsLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <Stack>
                {activeBulletins.length > 0
                    ? activeBulletins.map((bulletin, bulletinId) => (
                          <AlertMessage key={bulletinId} status={bulletin.type === "danger" ? "error" : bulletin.type}>
                              <Flex direction="row">
                                  <Box width="100%">
                                      <AlertTitle>{bulletin.title}</AlertTitle>
                                      <div dangerouslySetInnerHTML={{ __html: bulletin.description }} />
                                  </Box>
                                  <CloseButton onClick={() => dismissBulletin(bulletin.id)} alignSelf="flex-start" />
                              </Flex>
                          </AlertMessage>
                      ))
                    : null}
            </Stack>
            <WelcomeToNostraContent />
            <VStack alignItems={"left"}>
                {filteredStores?.map((store) => <StoreStatusAlert key={store.id} store={store} />)}
            </VStack>
            {isEntriEnabled && isOwnerRole && storeInDnsSetupMode ? (
                <DnsSetup
                    store={storeInDnsSetupMode}
                    onClose={(event?: EntriCloseEvent) => {
                        if (event?.detail.success) {
                            localStorage.setItem(
                                EntriLocalStorage.dnsConfigurationKey(storeInDnsSetupMode.customerUrl),
                                EntriLocalStorage.COMPLETED_SUCCESSFULLY,
                            );
                        }
                    }}
                />
            ) : null}
        </PageContainer>
    );
};
