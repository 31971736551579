import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Code, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, Link, SubHeader } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ApiSinglePageDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"API"}
                menuItem1Link={ROUTES.DOCUMENTATION_API}
                menuItem2={"Clear Single Page"}
            />
            <Header pb={"spacer-8"}>Clear Single Page</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The following information will guide you through using {BRAND_NAME}'s API to clear your cache by a
                single page or a collection of pages. There are two key steps to making a successful request:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Token generation:</strong> Users must obtain an API token through the {BRAND_NAME} portal.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Utilization: </strong>A <Code>POST</Code> request allows users to hit the sitewide endpoint,{" "}
                    <Code maxWidth={{ base: "95%" }}>https://developer.nostra.ai/v1/cache/purge</Code>. To specify a
                    single page, you must include a request body containing the URL path that needs to be cleared.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can broaden this feature to include a group of pages by selecting the proper URL path. For example,{" "}
                <Code>/products</Code> would clear the cache for any URL that included that path, including{" "}
                <Code>/products/example-shirt</Code> and <Code>/products/example-hat</Code>.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Example Request
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Please note you will need to generate a token specific to your store. The following token is an example
                and will result in an error.{" "}
            </Text>
            <Code mb={"spacer-6"} padding={"spacer-6"} width="100%">
                curl -X POST -H "Authorization: Token 00000000-0000-0000-000000000000" -H "Store: examplestore.com"
                https://developer.nostra.ai/v1/cache/purge
            </Code>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The request body can include one or several URL paths, along with a date and time for scheduling. If no
                date is included, the page path(s) will be entered into the queue immediately.
            </Text>
            <Code mb={"spacer-6"} padding={"spacer-6"} width="100%">
                &#123;
                <br />
                <span style={{ marginLeft: "20px" }}>
                    "pathNames": ["/products/example-hat", "/products/example-shirt", "/collections"],
                </span>{" "}
                <br />
                <span style={{ marginLeft: "20px" }}>"date":"2025-01-01T00:00:00Z"</span>
                <br />
                &#125;
            </Code>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    The request body is optional. If you do not include it or if you only include the "date" key-value
                    pair, you will clear your entire site's cache.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Response
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With a successful request, the endpoint will return an HTTP status code of <Code>201</Code> and an
                object indicating when your site's cache-clearing request entered the queue. The clearing process is
                typically completed within a few minutes.{" "}
            </Text>
            <Code mb={"spacer-6"} padding={"spacer-6"} width="100%">
                Response Status Code: 201
                <br />
                <br />
                &#123;
                <br />
                <span style={{ marginLeft: "20px" }}>"enqueuedAt": "2024-02-12T20:07:27.679Z"</span>
                <br />
                &#125;
            </Code>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                More Information
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For more information on when to clear a single page versus your entire site, visit the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                    How To Clear Your Cache documentation
                </Link>
                . If you still have questions or concerns,&nbsp;
                <Link textDecoration="underline" to={ROUTES.SUPPORT} target="new">
                    contact support
                </Link>
                .
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
