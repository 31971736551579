import { SVC_URLS } from "../../config/SVC_URLS";
import { useMutation } from "@tanstack/react-query";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useDeleteOrganizationMutation = () => useMutation({ mutationFn: deleteOrganization });

const deleteOrganization = async (orgId: string) => {
    const url = `${SVC_URLS["auth"]}/org/${orgId}`;
    const options = await fetchOptions({ method: "DELETE" });
    const response = await fetch(url, options).catch(handleErrors);
    if (response.status === 422) {
        throw new Error("This organization still has active stores. Please delete the stores and try again.");
    }
    return fetchResponse(response);
};
