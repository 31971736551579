import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, Stack, HStack } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";
import { ChatIcon } from "@chakra-ui/icons";

export const AdvertisingMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Advertising Metrics`}
            />
            <Header pb={"spacer-8"}>Advertising Metrics</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Paid advertisements come with a quality score, which is used in comparison to other advertisers to
                influence how an ad is prioritized and displayed to customers—and how much it costs. {BRAND_NAME} can
                help with your quality score, and we expect to increase the other engagement metrics you are tracking.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    As with any metric related to {BRAND_NAME}, keep in mind our products are optimization tools. If
                    your advertisements do not meet other recommended standards related to content, keywords, etc., the
                    following metric gains will be restricted.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Increase Your Ad Quality Score
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In the case of Google Ads, your{" "}
                <Link
                    textDecoration="underline"
                    to={"https://support.google.com/google-ads/answer/6167118"}
                    target="new"
                >
                    quality score
                </Link>{" "}
                is determined by a combination of expected clickthrough rate, customer relevance, and landing page
                experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} will specifically benefit your landing page experience. One of Google's{" "}
                <Link
                    textDecoration="underline"
                    to={"https://support.google.com/google-ads/answer/6167130?sjid=4644587207465971483-NC"}
                    target="new"
                >
                    recommendations
                </Link>{" "}
                to boost the landing page experience is: "Improve loading speed.{" "}
                <strong>
                    The speed at which your page loads can be the difference between someone bouncing or buying."
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our {MAIN_PRODUCT} does exactly that to give your advertisements a better standing over your
                competitors' ads.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Boost Your Ad Ranking
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                An advertisement's ranking plays a key part in the Google Ads auction when it comes time to determine
                when and where your ad is displayed. To qualify for the auction at all, ads must clear the minimum
                threshold.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Meta hosts a similar ad auction, and while ad quality is not explicitly named as one of the factors they
                use when assessing an ad in the auction, the documentation{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.facebook.com/business/help/423781975167984"}
                    target="new"
                >
                    states
                </Link>{" "}
                that <strong>ad quality is a vital part of creating "higher quality experiences for people."</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In other words, both companies are invested in displaying good ads that will keep customers on their
                advertising platforms. One of the factors they consider is the quality of both the advertisement itself
                and the connected landing page. If an ad is not ranked highly enough, both Google Ads and Meta will not
                display it at all.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As {BRAND_NAME} boosts your ad quality scores, we also positively contribute to your ad ranking, helping
                your ad be as competitive as possible.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Lift Ad Conversion
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As your users interact with your advertisement and have a fast, responsive experience from start to
                finish, we expect to see a boost in your ad conversions as well as your overall ad quality scores and
                rankings.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google Ads{" "}
                <Link
                    textDecoration="underline"
                    to={"https://support.google.com/google-ads/answer/7543502"}
                    target="new"
                >
                    reports
                </Link>
                ,{" "}
                <strong>
                    "One of the best and easiest ways to get better results from your mobile ads is to improve the speed
                    of your landing pages."
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Yelp's{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://engineeringblog.yelp.com/2021/01/boosting-user-conversion-with-ux-performance-wins.html"
                    }
                    target="new"
                >
                    engineering team
                </Link>{" "}
                spent months making frontend and backend optimizations, such as caching and server-side rendering, on
                their advertising purchase flow page to improve performance and saw a{" "}
                <strong>15% increase in conversion rate.</strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Terra, a news and entertainment platform,{" "}
                <Link
                    textDecoration="underline"
                    to={"https://web.dev/case-studies/terra-prefetching-case-study?hl=en"}
                    target="new"
                >
                    relied
                </Link>{" "}
                on prefetching and time-to-first-byte (TTFB) reductions to receive an{" "}
                <strong>
                    11% increase in their mobile ad click-through rate (CTR) and a 30% increase in their desktop ad CTR.
                </strong>
            </Text>
            <HStack mb={"spacer-6"} backgroundColor={"blue.100"} p={"spacer-6"} alignItems={"center"}>
                <ChatIcon boxSize={6} />
                <Stack pl={"spacer-4"}>
                    <Text textStyle={"text-header-S"} mb={"spacer-2"}>
                        "Website experience plays a major role in impacting conversion rates. Reducing friction in the
                        purchase journey helps to generate sales and revenue. Every second of delay in webpage loading
                        causes customers to abandon carts. Using {BRAND_NAME}'s {MAIN_PRODUCT} ensured we saw faster
                        website speed and{" "}
                        <Link
                            textDecoration="underline"
                            to={"https://www.nostra.ai/success-stories/nostra-featured-in-meta-case-study-beachwaver"}
                            target="new"
                        >
                            better campaign results from our Facebook ads
                        </Link>
                        ."
                    </Text>
                    <Text textStyle={"text-body-regular"}>—Erin Potempa-Wall, Co-Founder and COO, Beachwaver</Text>
                </Stack>
            </HStack>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Strengthen Ad Engagement
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While {BRAND_NAME} does not change the content or structure of your advertisements, our rapid content
                delivery from the edge will benefit your landing page engagement rates.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Faster landing pages will help your users find the product they are seeking sooner while improving the
                broader user experience across your site at the same time.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google Ad Manager shared results from a study called "
                <Link
                    textDecoration="underline"
                    to={"https://blog.google/products/admanager/the-need-for-mobile-speed/"}
                    target="new"
                >
                    The need for mobile speed
                </Link>
                ," stating,{" "}
                <strong>"Publishers whose mobile sites load in 5 seconds earn up to 2x more mobile ad revenue</strong>{" "}
                than those whose sites load in 19 seconds." Good mobile performance also resulted in 25% higher ad
                viewability and 70% longer average sessions.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Decrease Cost Per Click/Mile
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                CPC/CPM prices are affected by how good the user experience is. This is because ads that lead to bounces
                cost more money as they water down the hosting platform's value.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Meta clearly{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.facebook.com/business/help/423781975167984"}
                    target="new"
                >
                    says
                </Link>
                , <strong>"Ads with a lower quality ranking tend to cost more."</strong> In boosting your ad's quality
                scores, {BRAND_NAME} will gradually help lower your CPC or CPM, depending on how you are paying for your
                advertisement.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Please note this downward trend may take up to 45-90 days to average into your tracked data. You will
                also need to keep your ad consistent to see this metric. If you regularly change your keywords and other
                factors, it will be difficult to determine the impact of speed as the context changes.{" "}
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
