import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "../../hooks";
import { SVC_URLS } from "../../config/SVC_URLS";
import { Store } from "../../types";
import { UseQueryResult } from "@tanstack/react-query";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SuccessCrUXRportsResponse } from "../../types";

export const useCRUXReports = () => {
    const storeQuery = useStore();
    const { store } = useData(storeQuery);
    return useQuery({
        queryKey: ["crux-reports", store?.id],
        queryFn: () => fetchCRUXReports(store),
        enabled: store !== undefined,
    });
};

const fetchCRUXReports = async (store: Store | undefined): Promise<SuccessCrUXRportsResponse> => {
    const endpoint = `${SVC_URLS["portal"]}/reports/crux`;
    const options = await fetchOptions({
        headers: { Store: store!.customerUrl },
    });
    const response = await fetch(endpoint, options).catch(handleErrors);
    return fetchResponse(response);
};

const useData = (storeQuery: UseQueryResult<Store, unknown>) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const store = storeQuery.data;
    const isLoading = storeQuery.isLoading;
    const isError = storeQuery.isError;

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "There was an error loading this page. Please try again in a few moments.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    return {
        store,
        isLoading,
    };
};
