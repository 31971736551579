import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useRef } from "react";

type Props = PropsWithChildren<{
    isOpen: boolean;
    onClose: () => void;
    onAction: () => void;
}>;

export const ToggleExperimentModal = ({ children, isOpen, onClose, onAction }: Props) => {
    const cancelRef = useRef(null);

    return (
        <>
            <Button colorScheme={"button-primary"} variant={"outline"}>
                {children}
            </Button>
            <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} returnFocusOnClose={true}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            <Text align="center" textStyle="text-header-2XL" color="alert-error-default" pt="spacer-6">
                                WARNING
                            </Text>
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            <Text textStyle="text-body-large-regular" align="center" mb="spacer-4">
                                Disabling this experiment will permanently end this test.
                            </Text>
                            <Text textStyle="text-body-large-regular" align="center">
                                When you enable this test again, all users will be reassigned to new test groups and new
                                reports will be created.
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                onClick={onClose}
                                style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    background: "var(--chakra-colors-button-primary-500)",
                                    color: "var(--chakra-colors-white)",
                                }}
                                flex="1"
                                height="77px"
                                variant={"outline"}
                            >
                                Keep My Experiment Active
                            </Button>
                            <Button
                                onClick={() => {
                                    onAction();
                                    onClose();
                                }}
                                ml={3}
                                style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                }}
                                flex="1"
                                minHeight="fit-content"
                                height="77px"
                                variant={"outline"}
                                colorScheme={"button-secondary"}
                            >
                                Permanently Deactivate My Current Experiment
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
