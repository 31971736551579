import { SessionContent } from "../types/types";

export const useSessionContentTemplate = (): string => {
    const sessionDefaultData: SessionContent[] = [
        {
            id: "session-content",
            name: "Session Content",
            pageset: {
                name: "Session Content",
                pagePattern:
                    "^\\/([a-z][a-z]\\/?)?(-[a-z]{2}\\/?)?($|products|collections|search|pages|blogs|variants|recommendations)(\\/[^.]*?)?$",
            },
            variations: [
                {
                    id: "session-content-variation",
                    weight: 1,
                    name: "Session Content Control",
                    order: 0,
                    actions: [
                        {
                            replaceSelector: '(<script id="web-pixels-manager-setup">)([\\S\\s]*?)(<\\/script>)',
                            insertText: "$1$3",
                        },
                        {
                            replaceSelector: "(<\\/body>)",
                            insertText: `
                            <script async id="session-content" type="text/javascript">
                                setTimeout(() => {
                                    const skipCacheURL = new URL(window.location.href); 
                                    skipCacheURL.searchParams.set('skipCache', 'true');
                                    fetch(skipCacheURL.toString()).then(response => response.text()).then(doc => {
                                        const parser = new DOMParser()
                                        const fetchDocument = parser.parseFromString(doc, 'text/html')
                                        const webPixelSelector = '#web-pixels-manager-setup';
                                        const wpcontainer = document.querySelector(webPixelSelector)
                                        const wpfetchContainer = fetchDocument.querySelector(webPixelSelector);
                                        wpcontainer.innerHTML = wpfetchContainer.innerHTML;
                                    });
                                }, 200);
                            </script>$1`,
                        },
                    ],
                },
            ],
        },
    ];
    return JSON.stringify(sessionDefaultData, null, 2);
};
