import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box } from "@chakra-ui/react";
import { Header } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const EdgeDelivery101Page = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Getting Started"}
                menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                menuItem2={"Edge Delivery 101"}
            />
            <Header pb={"spacer-8"}>Edge Delivery 101</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A good understanding of edge servers starts with a good understanding of origin servers. Origin servers
                receive requests from your users and deliver the correct response.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Say someone is visiting your site's homepage for the first time by entering the URL or clicking on a
                link. In navigating to your site, they send a request to an origin server. The origin server receives
                that request, puts together the necessary HTML document (along with all the resources needed to complete
                it), and sends a response with the completed page back to the user's browser to be displayed to the
                user.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have high-speed internet access, you are probably used to those responses coming back in the
                blink of an eye, but that is not always the case. Even major e-commerce platforms like Shopify and
                Salesforce Commerce Cloud cluster their origin servers in specific locations…which may or may not be
                close to you and your users. That means your user's request may have to travel hundreds of extra miles
                to reach your origin server, leaving plenty of room for slower load times and a reduced user experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Edge servers, on the other hand, are a means of bringing servers as close to users as possible,
                resulting in significant reductions of website latency and increases in reliability.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Additional benefits include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Text as="b">Consistent performance,</Text> especially for users who live in more remote areas. Edge
                    servers can analyze and store their own data to minimize issues that arise during network
                    disruptions.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Text as="b">More secure data.</Text> Edge servers are able to store the majority of data they
                    handle locally, reducing the time sensitive information has to travel and providing strong
                    encryption measures.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Text as="b">Enhanced speed,</Text> as large amounts of data do not have to travel as far from the
                    server to the user and vice versa.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most major e-commerce platforms have resources that accelerate your site in place already. For example,
                Shopify accelerates images, CSS, and JavaScript on the cdn.shopify.com domain. However, that built-in
                acceleration does not solve the problem of your site-specific data having to cross large distances.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} steps into the gap to accelerate the rest of your content for you. Our {MAIN_PRODUCT}{" "}
                utilizes over 310 edge locations, which are strategically located within 50 milliseconds of 97% of the
                world's population.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The {MAIN_PRODUCT} works alongside your ecommerce platform's origin servers to speed up content delivery
                through smart caching and other methods. In addition to being physically closer to your users, our edge
                locations store up-to-date versions of your website's pages to prevent your users' requests from having
                to travel to the origin server and back time and time again.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                That means your users can make requests and get rapid-fire responses that allow them to start
                interacting with your website faster than ever! If that sounds too good to be true, we are happy to
                report it isn't. That is the power of {BRAND_NAME}'s {MAIN_PRODUCT}.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
