import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { CreateStoreRequest } from "../../types";

const CREATE_STORE_URL = `${SVC_URLS["ecom"]}/store/`;

export const useCreateStoreMutation = () => useMutation({ mutationFn: createStore });

const createStore = async (body: CreateStoreRequest) => {
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(body),
    });

    const response = await fetch(CREATE_STORE_URL, options).catch(handleErrors);

    return fetchResponse(response);
};
