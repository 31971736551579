import { Text, InputGroup, Input, InputRightElement, useToast, Select, Stack, useMediaQuery } from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { Header, Tooltip } from "../../components";
import { useListAllShopifyStoreApps } from "../../hooks";
import { IconSearch, IconX } from "@tabler/icons-react";
import { useForm } from "react-hook-form";
import { useCallback, useState, useEffect, useMemo } from "react";
import { InstalledApps } from "../../types";
import debounce from "lodash.debounce";
import { LoadingPage } from "../LoadingPage";
import { ChevronDownIcon } from "@chakra-ui/icons";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { ListApp } from "./ListApp";

const getSortFunction = (sortBy: string | null) => {
    switch (sortBy) {
        case "name":
            return (a: any, b: any) => (a.title.trim() > b.title.trim() ? 1 : -1);
        case "mostInstalled":
            return (a: any, b: any) => b.EcomStoreApp.length - a.EcomStoreApp.length;
        default:
            return () => 0;
    }
};

type ListAppsFilters = {
    searchTerm: string;
    sortBy: "name" | "mostInstalled";
};

export const ListAppsPage = () => {
    const { data: apps, isError, isLoading } = useListAllShopifyStoreApps();
    const [filteredApps, setFilteredApps] = useState<InstalledApps>([]);
    const [isMobile] = useMediaQuery("(max-width: 744px)");
    const [filters, setFilters] = useState<ListAppsFilters>({
        searchTerm: "",
        sortBy: "name",
    });
    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const { register, watch, resetField } = useForm({
        defaultValues: {
            searchTerm: "",
            sortBy: "name",
        },
    });

    const watchedSearchTerm = watch("searchTerm");

    const applyFilters = useCallback((apps: InstalledApps, filters: ListAppsFilters) => {
        const sortFunction = getSortFunction(filters.sortBy);
        return new Promise<InstalledApps>((resolve) => {
            const results =
                filters.searchTerm === ""
                    ? apps.sort(sortFunction)
                    : apps
                          .filter((app) => app.title.trim().toLowerCase().includes(filters.searchTerm.toLowerCase()))
                          .sort(sortFunction);
            resolve(results);
        });
    }, []);

    useEffect(
        function filterWhenAppsUpdated() {
            if (apps === undefined) return;
            applyFilters(apps, filters).then((results) => setFilteredApps(() => [...results]));
        },
        [apps, filters, setFilteredApps, applyFilters],
    );

    useEffect(
        function handleError() {
            if (isError) {
                showToast({
                    title: "Error",
                    description: "An unexpected error has occurred. If the problem persists, please contact support.",
                    status: "error",
                });
            }
        },
        [isError, showToast],
    );

    const debounceSearchInput = useMemo(
        () =>
            debounce((value) => {
                setFilters((prev) => ({ ...prev, searchTerm: value }));
            }, 500),
        [setFilters],
    );

    const handleFilterChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            debounceSearchInput(event.target.value);
        },
        [debounceSearchInput],
    );

    const handleClearInput = () => {
        resetField("searchTerm");
        setFilters((prev) => ({ ...prev, searchTerm: "" }));
    };

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <Stack
                justifyContent="space-between"
                pb={{ base: "spacer-2", md: "spacer-6" }}
                alignItems={{ base: "none", md: "center" }}
                direction={{ base: "column", md: "row" }}
            >
                <Header>Installed Apps</Header>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack direction={{ base: "column", md: "row" }}>
                        <InputGroup size="md">
                            <Input
                                width={{ base: "300px", md: "400px" }}
                                aria-label="Search by URL"
                                id="searchTerm"
                                {...register("searchTerm", {
                                    onChange: (event) => handleFilterChange(event),
                                })}
                                placeholder="Search"
                            />
                            <InputRightElement display={{ base: "none", md: "flex" }}>
                                {watchedSearchTerm.length === 0 ? (
                                    <IconSearch stroke={"1.25"} />
                                ) : (
                                    <Tooltip label={"Clear search input"} aria-label={"Clear search input"}>
                                        <IconX stroke={"1.25"} onClick={handleClearInput} cursor="pointer" />
                                    </Tooltip>
                                )}
                            </InputRightElement>
                        </InputGroup>
                        <Select
                            maxWidth={"200px"}
                            icon={<ChevronDownIcon />}
                            {...register("sortBy", {
                                onChange: (event) => setFilters((prev) => ({ ...prev, sortBy: event.target.value })),
                            })}
                        >
                            <option value="name">Name</option>
                            <option value="mostInstalled">Most Installed</option>
                        </Select>
                    </Stack>
                </form>
            </Stack>
            {filteredApps?.length === 0 || apps?.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent="center"
                    display="flex"
                    style={{ marginTop: "8rem" }}
                >
                    None Found
                </Text>
            ) : (
                <div style={{ height: "calc(100vh - 200px)" }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => {
                            const ITEM_SPACING = 8;
                            const ITEM_SIZE = isMobile ? 160 : 120;
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={filteredApps.length}
                                    itemSize={ITEM_SIZE + ITEM_SPACING}
                                >
                                    {({ index, style }) => (
                                        <ListApp app={filteredApps[index]} style={{ ...style, height: ITEM_SIZE }} />
                                    )}
                                </List>
                            );
                        }}
                    </AutoSizer>
                </div>
            )}
        </PageContainer>
    );
};
