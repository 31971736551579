import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header } from "../../../components";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const WelcomeToNostraPage = () => {
    return (
        <PageContainer>
            <WelcomeToNostraContent />
        </PageContainer>
    );
};

export const WelcomeToNostraContent = () => {
    return (
        <>
            <Header py={"spacer-8"}>Welcome To {BRAND_NAME}</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                The {BRAND_NAME} portal is your one-stop resource for monitoring your store as we help you turn visitors
                into customers faster. Explore insights into how our services drastically reduce latency and improve
                site performance scores while leveling up your technical expertise.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                Learn more about {BRAND_NAME}'s core products below, and get the support you need to have a great
                experience with {BRAND_NAME}.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    Our <Text as="b">{MAIN_PRODUCT}</Text> utilizes more than 310 edge locations, smart caching, early
                    hints, bot detection, and much more to quickly make your website the fastest on the internet.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    Our <Text as="b">{SECONDARY_PRODUCT}</Text> dynamically allocates web traffic and optimizes your
                    site for bots from search engines, leading to improvements in search engine optimization.
                </ListItem>
            </UnorderedList>
        </>
    );
};
