import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";

export const useDeleteFavoriteCachePathMutation = () => {
    return useMutation(deleteFavoriteCachePathMutation);
};

const deleteFavoriteCachePathMutation = async ({ customerUrl, path }: { customerUrl: string; path: string }) => {
    const deleteFavoritePaths = `${SVC_URLS["portal"]}/cache/favorites`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Store: customerUrl,
        },
        body: JSON.stringify(path),
    });
    const response = await fetch(deleteFavoritePaths, options).catch(handleErrors);
    return fetchResponse(response);
};
