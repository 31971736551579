import { CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import { Button, useClipboard, useToast } from "@chakra-ui/react";
import { useAuthenticatedUser } from "../../../hooks";

type CSVAndSQLProps = {
    sql: string | undefined;
    csv: string | undefined;
};

export const CSVAndSQL = ({ sql, csv }: CSVAndSQLProps) => {
    const { isCloudOpsRole } = useAuthenticatedUser();
    const { onCopy } = useClipboard(sql ?? "");
    const showToast = useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });

    const copy = () => {
        onCopy();
        showToast({
            title: "Copied to clipboard",
            description: "The SQL has been added to your clipboard",
            status: "success",
        });
    };

    const blob = new Blob([csv ?? ""], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    if (!isCloudOpsRole) {
        return null;
    }

    return (
        <div style={{ display: "flex", gap: "8px", margin: "20px 0" }}>
            {csv && (
                <Button>
                    <a href={url} download="data.csv">
                        Download CSV <DownloadIcon />
                    </a>
                </Button>
            )}
            {sql && (
                <Button onClick={copy}>
                    Copy SQL <CopyIcon />
                </Button>
            )}
        </div>
    );
};
