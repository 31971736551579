import { Global } from "@emotion/react";

const Fonts = () => (
    <Global
        styles={`
        /* GT Super */
        @font-face {
            font-family: 'GT Super';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url('/fonts/GT-Super-Display-Regular.woff2') format('woff2'),
                url('/fonts/GT-Super-Display-Regular.woff') format('woff');
        }
        
        
        /* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
        @font-face {
            font-family:'GT Super Ds Trial Bd It';
            src: url('/fonts/GT Super Ds Trial Bd It.eot');
            src: url('/fonts/GT Super Ds Trial Bd It.eot?#iefix') format('embedded-opentype'),
                url('/fonts/GT Super Ds Trial Bd It.woff2') format('woff2'),
                url('/fonts/GT Super Ds Trial Bd It.woff') format('woff'),
                url('/fonts/GT Super Ds Trial Bd It.svg#GT Super Ds Trial Bd It') format('svg');
            font-weight: 700;
            font-style: italic;
            font-stretch: normal;
            unicode-range: U+0020-00FE;
        }

        /* Generated by Fontie <http://fontie.pixelsvsbytes.com> */
        @font-face {
            font-family:'GT Super Ds Trial Lt It';
            src: url('/fonts/GT Super Ds Trial Lt It.eot');
            src: url('/fonts/GT Super Ds Trial Lt It.eot?#iefix') format('embedded-opentype'),
                url('/fonts/GT Super Ds Trial Lt It.woff2') format('woff2'),
                url('/fonts/GT Super Ds Trial Lt It.woff') format('woff'),
                url('/fonts/GT Super Ds Trial Lt It.svg#GT Super Ds Trial Lt It') format('svg');
            font-weight: 300;
            font-style: italic;
            font-stretch: normal;
            unicode-range: U+0020-00FE;
        }
                
        
        /* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Lt';
    src: url('/fonts/GT Super Ds Trial Lt.eot');
	src: url('/fonts/GT Super Ds Trial Lt.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Lt.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Lt.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Lt.svg#GT Super Ds Trial Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Md It';
    src: url('/fonts/GT Super Ds Trial Md It.eot');
	src: url('/fonts/GT Super Ds Trial Md It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Md It.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Md It.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Md It.svg#GT Super Ds Trial Md It') format('svg');
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Rg It';
    src: url('/fonts/GT Super Ds Trial Rg It.eot');
	src: url('/fonts/GT Super Ds Trial Rg It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Rg It.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Rg It.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Rg It.svg#GT Super Ds Trial Rg It') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Rg';
    src: url('/fonts/GT Super Ds Trial Rg.eot');
	src: url('/fonts/GT Super Ds Trial Rg.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Rg.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Rg.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Rg.svg#GT Super Ds Trial Rg') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Su It';
    src: url('/fonts/GT Super Ds Trial Su It.eot');
	src: url('/fonts/GT Super Ds Trial Su It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Su It.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Su It.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Su It.svg#GT Super Ds Trial Su It') format('svg');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Ds Trial Su';
    src: url('/fonts/GT Super Ds Trial Su.eot');
	src: url('/fonts/GT Super Ds Trial Su.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Ds Trial Su.woff2') format('woff2'),
		url('/fonts/GT Super Ds Trial Su.woff') format('woff'),
		url('/fonts/GT Super Ds Trial Su.svg#GT Super Ds Trial Su') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bd It';
    src: url('/fonts/GT Super Txt Trial Bd It.eot');
	src: url('/fonts/GT Super Txt Trial Bd It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bd It.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bd It.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bd It.svg#GT Super Txt Trial Bd It') format('svg');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bd';
    src: url('/fonts/GT Super Txt Trial Bd.eot');
	src: url('/fonts/GT Super Txt Trial Bd.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bd.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bd.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bd.svg#GT Super Txt Trial Bd') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bk It';
    src: url('/fonts/GT Super Txt Trial Bk It.eot');
	src: url('/fonts/GT Super Txt Trial Bk It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bk It.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bk It.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bk It.svg#GT Super Txt Trial Bk It') format('svg');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bk';
    src: url('/fonts/GT Super Txt Trial Bk.eot');
	src: url('/fonts/GT Super Txt Trial Bk.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bk.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bk.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bk.svg#GT Super Txt Trial Bk') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bl It';
    src: url('/fonts/GT Super Txt Trial Bl It.eot');
	src: url('/fonts/GT Super Txt Trial Bl It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bl It.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bl It.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bl It.svg#GT Super Txt Trial Bl It') format('svg');
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Bl';
    src: url('/fonts/GT Super Txt Trial Bl.eot');
	src: url('/fonts/GT Super Txt Trial Bl.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Bl.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Bl.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Bl.svg#GT Super Txt Trial Bl') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Md It';
    src: url('/fonts/GT Super Txt Trial Md It.eot');
	src: url('/fonts/GT Super Txt Trial Md It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Md It.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Md It.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Md It.svg#GT Super Txt Trial Md It') format('svg');
    font-weight: 500;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Md';
    src: url('/fonts/GT Super Txt Trial Md.eot');
	src: url('/fonts/GT Super Txt Trial Md.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Md.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Md.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Md.svg#GT Super Txt Trial Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Rg It';
    src: url('/fonts/GT Super Txt Trial Rg It.eot');
	src: url('/fonts/GT Super Txt Trial Rg It.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Rg It.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Rg It.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Rg It.svg#GT Super Txt Trial Rg It') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'GT Super Txt Trial Rg';
    src: url('/fonts/GT Super Txt Trial Rg.eot');
	src: url('/fonts/GT Super Txt Trial Rg.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GT Super Txt Trial Rg.woff2') format('woff2'),
		url('/fonts/GT Super Txt Trial Rg.woff') format('woff'),
		url('/fonts/GT Super Txt Trial Rg.svg#GT Super Txt Trial Rg') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
        
        `}
    />
);

export default Fonts;
