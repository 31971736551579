import { Stack } from "@chakra-ui/react";
import { PageContainer } from "./PageContainer";
import { Header } from "../components";

const url = "https://success.nostra.ai/";

export const MonitoringPage = () => {
    return (
        <PageContainer>
            <Stack style={{ height: "100%" }}>
                <Header>Edge Monitoring Report</Header>
                <iframe style={{ width: "100%", aspectRatio: 1, flexGrow: 1 }} src={url} title="Nostra Status Page" />
            </Stack>
        </PageContainer>
    );
};
