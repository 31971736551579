import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconDatabaseX } from "@tabler/icons-react";

export const IconButtonClearCache = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Clear Cache"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconDatabaseX stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
