// import { Span, SpanProcessor } from "@opentelemetry/sdk-trace-web";
import { Span, SpanProcessor } from "@opentelemetry/sdk-trace-base";

// Global variable to store the current user
let loggedInUser: string = "Not Logged In";

export class LoggedInUserSpanProcessor implements SpanProcessor {
    async onStart(span: Span): Promise<void> {
        span.setAttribute("loggedInUser", loggedInUser);
    }
    onEnd(span: Span): void {}
    async forceFlush(): Promise<void> {}
    async shutdown(): Promise<void> {}
}

// Export a function to update the current user
export const setLoggedInUser = (email: string) => {
    loggedInUser = email;
};
