import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { PendingCacheResponse } from "../../types";

export const usePendingScheduledPurgeCache = (storeId: string, customerUrl: string) => {
    return useQuery(
        generatePendingCacheQueryKey(storeId),
        async () => await pendingScheduledPurgeCache(storeId, customerUrl),
    );
};

const pendingScheduledPurgeCache = async (storeId: string, customerUrl: string): Promise<PendingCacheResponse> => {
    const PURGE_CACHE = `${SVC_URLS["cronos"]}/cache/pending/${storeId}`;
    const options = await fetchOptions({
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(PURGE_CACHE, options).catch(handleErrors);

    return fetchResponse(response);
};

export const generatePendingCacheQueryKey = (storeId: string): string[] => ["scheduledPurges", storeId];
