import { useStore } from "../hooks";
import { Store } from "../types";
import { FC } from "react";

interface TabComponentWrapperProps {
    Component: React.ComponentType<{ store: Store }>;
}

export const TabComponentWrapper: FC<TabComponentWrapperProps> = ({ Component }) => {
    const { data: store } = useStore();
    return <Component store={store!} />;
};
