import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Heading,
    Spacer,
    Button,
    Flex,
    Box,
    Code,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { CopyButton, Link, Tooltip } from "../../components";
import { subdomainOf } from "../../lib";
import { STACK } from "../../lib/generateWorkerName";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const validQueryParams = (ecomUrl: string | null, customerUrl: string | null, zoneId: string | null) =>
    ecomUrl !== null && customerUrl !== null && zoneId !== null;

/**
 * This modal is displayed when a store is deleted. It provides the user with the next steps to take.
 * To use this modal, the following query parameters are required:
 *
 * - modal: must have the value "delete-store-confirmation"
 * - ecomUrl: used to build the shopify admin url to uninstall the Nostra shopify app
 * - customerUrl: the url of the store
 * - zoneId: the zoneId of the store
 */
export const DeleteStoreConfirmationModal = ({ isOpen, onClose }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ecomUrl = searchParams.get("ecomUrl");
    const customerUrl = searchParams.get("customerUrl");
    const zoneId = searchParams.get("zoneId");
    if (!validQueryParams(ecomUrl, customerUrl, zoneId)) {
        console.error("Missing query parameters for DeleteStoreConfirmationModal");
        onClose();
        return null;
    }

    const removeQueryParams = () => {
        const params = new URLSearchParams(searchParams);
        params.delete("modal");
        params.delete("ecomUrl");
        params.delete("customerUrl");
        params.delete("zoneId");
        setSearchParams(params);
    };

    const shopifyStoreId = subdomainOf(ecomUrl ?? "");
    const shopifyUninstallUrl = `https://admin.shopify.com/store/${shopifyStoreId}/settings/apps/development/`;
    const debugUrl = `https://${customerUrl}?debug`;
    const scheduledDeploymentUrl = `https://api.nostra.${STACK}/cronos/v1/worker/pending`;

    return (
        <Modal
            isCentered
            size={"3xl"}
            isOpen={isOpen}
            onClose={() => {
                onClose();
            }}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading display={"inline-block"} as="h2" size="md">
                        Confirmation
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody px="spacer-12">
                    <Heading as="h1" size="lg">
                        {customerUrl} has been deleted from Nostra.
                    </Heading>
                    <Text pt="spacer-4">
                        A{" "}
                        <Link color="blue.600" target="_blank" textDecoration="underline" to={scheduledDeploymentUrl}>
                            final deployment has been scheduled
                        </Link>
                        .{" "}
                    </Text>
                    <Text pt="spacer-4">
                        <strong>Next</strong> you will need to{" "}
                        <Link color="blue.600" target="_blank" textDecoration="underline" to={shopifyUninstallUrl}>
                            uninstall the Nostra shopify app.
                        </Link>
                    </Text>
                    <Text pt="spacer-4" hideBelow="md">
                        <strong>Then</strong> the Zone needs to be removed from Cloudflare:
                    </Text>
                    <Tooltip label="Copy ZoneId" aria-label="Copy ZoneId" hideBelow="md">
                        <Box as="span" display="inline-block" hideBelow="md">
                            <CopyButton
                                value={zoneId}
                                success={`Cloudflare Zone Id has been copied to your clip board: ${zoneId}`}
                            ></CopyButton>
                        </Box>
                    </Tooltip>
                    <Code hideBelow="md">{zoneId}</Code>

                    <Text pt="spacer-4">
                        <strong>Finally</strong>, confirm the services are no longer available on the storefront:
                    </Text>
                    <Text pt="spacer-1">
                        <Link color="blue.600" target="_blank" textDecoration="underline" to={debugUrl}>
                            {debugUrl}
                        </Link>
                    </Text>
                    <Flex py="spacer-6">
                        <Spacer />
                        <Button
                            type="submit"
                            colorScheme="button-primary"
                            onClick={() => {
                                removeQueryParams();
                                onClose();
                            }}
                            size="lg"
                        >
                            Finish
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
