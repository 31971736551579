import { Authenticator } from "@aws-amplify/ui-react";
import { useSearchParams, useRoutes } from "react-router-dom";
import { Layout } from "./layouts/Layout";
import { Login } from "./authentication/Login";
import { ExperimentationEditorLayout } from "./layouts/ExperimentationEditorLayout";
import { useAuthenticatedUser, useOrg } from "./hooks";
import { ROUTES } from "./Routes";
import {
    EditorPage,
    EditorProvider,
    NotFound404,
    MobilePreviewPage,
    Home,
    GetHelpNowPage,
    StatusPage,
    ReportsPage,
    CrUXReportsTab,
    RevenueReportsTab,
    PerformanceReportsTab,
    CustomerReportsTab,
} from "./pages";
import {
    cloudOpsRoutes,
    notInternalRoutes,
    ownerRoutes,
    documentationRoutes,
    internalAndCloudOpsRoutes,
} from "./routing";
import { DeleteStoreConfirmationModal } from "./pages/EditStorePage/DeleteStoreConfirmationModal";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const MyRoutes = () => {
    const { isAuthenticated, isCloudOpsRole, isOwnerRole, isInternalRole, isAdminRole, orgId } = useAuthenticatedUser();
    const [hasPushedEvent, setHasPushedEvent] = useState(false);
    window.dataLayer = window.dataLayer || [];

    const { data: orgData, isLoading } = useOrg(orgId);

    // Add user type + org name to datalayer for GA4 tracking
    const updateDataLayer = (userType: string | undefined, orgName: string | undefined) => {
        window.dataLayer.push({
            event: "user_type_updated",
            user_type: userType,
            organization_name: orgName,
        });
    };
    useEffect(() => {
        if (!isAuthenticated) {
            updateDataLayer(undefined, undefined);
            setHasPushedEvent(false);
        }
    }, [isAuthenticated]);
    useEffect(() => {
        const organizationName = orgData?.name;
        if (isAuthenticated && !hasPushedEvent && !isLoading && organizationName) {
            isCloudOpsRole && updateDataLayer("CloudOps", organizationName);
            isOwnerRole && updateDataLayer("Owner", organizationName);
            isInternalRole && updateDataLayer("Internal", organizationName);
            isAdminRole && updateDataLayer("Admin", organizationName);
            setHasPushedEvent(true);
        }
    }, [
        isCloudOpsRole,
        isOwnerRole,
        isInternalRole,
        isAdminRole,
        hasPushedEvent,
        isAuthenticated,
        isLoading,
        orgData?.name,
    ]);

    const unauthenticatedRoutes = [
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "*",
            element: <NotFound404 />,
        },
    ];

    const authenticatedRoutes = [
        {
            path: "/",
            element: <Layout />,
            children: [
                { path: "/", element: <Home /> },
                { path: ROUTES.GET_HELP_NOW, element: <GetHelpNowPage /> },
                { path: ROUTES.STATUS_PAGE, element: <StatusPage /> },
                {
                    path: ROUTES.STORE_REPORTS,
                    element: <ReportsPage />,
                    ...(isCloudOpsRole || isInternalRole
                        ? {
                              children: [
                                  { path: "crux", element: <CrUXReportsTab /> },
                                  { path: "revenue", element: <RevenueReportsTab /> },
                                  { path: "performance", element: <PerformanceReportsTab /> },
                                  { path: "customer", element: <CustomerReportsTab /> },
                              ],
                          }
                        : {}),
                },
            ],
        },
        ...(isCloudOpsRole ? cloudOpsRoutes : []),
        ...(isOwnerRole ? ownerRoutes : []),
        ...(!isInternalRole ? notInternalRoutes : []),
        ...(isInternalRole || isCloudOpsRole ? internalAndCloudOpsRoutes : []),
        ...documentationRoutes,
        {
            path: ROUTES.CREATE_VARIANT_CONTENT,
            element: <EditorProvider children={<ExperimentationEditorLayout />} />,
            children: [{ path: ROUTES.CREATE_VARIANT_CONTENT, element: <EditorPage /> }],
        },
        {
            path: ROUTES.MOBILE_PREVIEW,
            element: <MobilePreviewPage />,
        },
    ];
    return useRoutes([...(isAuthenticated ? authenticatedRoutes : []), ...unauthenticatedRoutes]);
};

const DELETE_STORE_CONFIRMATION_MODAL = "delete-store-confirmation";

export const App = () => {
    const {
        isOpen: isConfirmationModalOpen,
        onOpen: openConfirmationModal,
        onClose: closeConfirmationModal,
    } = useDisclosure();
    const [searchParams] = useSearchParams();
    const modal = searchParams.get("modal");
    useEffect(() => {
        if (modal === DELETE_STORE_CONFIRMATION_MODAL) {
            openConfirmationModal();
        }
    }, [modal, openConfirmationModal]);

    return (
        <Authenticator.Provider>
            <MyRoutes />
            {modal === DELETE_STORE_CONFIRMATION_MODAL ? (
                <DeleteStoreConfirmationModal isOpen={isConfirmationModalOpen} onClose={closeConfirmationModal} />
            ) : null}
        </Authenticator.Provider>
    );
};
