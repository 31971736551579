import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useApiTokenHistory = (customerUrl: string) => {
    return useQuery(generateApiTokenHistoryQueryKey(customerUrl), () => getApiTokenHistory(customerUrl));
};

const getApiTokenHistory = async (customerUrl: string) => {
    const url = `${SVC_URLS["portal"]}/history/token`;
    const options = await fetchOptions({
        headers: { Store: customerUrl },
    });
    const response = await fetch(url, options).catch(handleErrors);
    return fetchResponse(response);
};

export const generateApiTokenHistoryQueryKey = (customerUrl: string) => {
    return ["apiToken", customerUrl];
};
