import { VStack, useToast, Flex, Link, Spacer, Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useForm, UseFormWatch } from "react-hook-form";
import { Label, InputJson } from "../../components";
import { generateStoreQueryKey, useStoreMutation, useEnqueuePurgeCacheMutation } from "../../hooks";
import { toJSON } from "../../lib";
import { Store, UpdateStoreFormData, UpdateStoreRequest } from "../../types";
import { PageContainer } from "../PageContainer";
import { useSessionContentTemplate } from "../../data/sessionDefaultData";
import { useEffect, useState } from "react";

export const SessionContentTab = ({ store }: Props) => {
    const defaultValues: UpdateStoreFormData = buildDefaultValues(store);
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        watch,
    } = useForm<UpdateStoreFormData>({
        defaultValues: defaultValues,
    });

    const sessionContentTemplate = useSessionContentTemplate();

    const { onSubmit } = useSubmitSessionContent(store, {
        watch,
        defaultSessionContent: defaultValues.sessionContent,
    });

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }}>
            <VStack align={"left"}>
                <form onSubmit={handleSubmit(onSubmit)} id="shopifyTab">
                    <Flex>
                        <Label htmlFor="sessionContent">Session Content</Label>
                        <Spacer />
                        <Link
                            textDecoration="underline"
                            fontSize="sm"
                            p="20px 0px 0px"
                            onClick={() => setValue("sessionContent", sessionContentTemplate)}
                        >
                            Use Default Template
                        </Link>
                    </Flex>

                    <InputJson
                        {...{
                            name: "sessionContent",
                            placeholder: sessionContentTemplate,
                            formSettings: {
                                register,
                                setValue,
                                errors,
                                watch,
                            },
                        }}
                    ></InputJson>

                    <Label htmlFor="sessionContentTestCode">Session Content Test Code</Label>
                    <InputJson
                        {...{
                            name: "sessionContentTestCode",
                            formSettings: {
                                register,
                                setValue,
                                errors,
                                watch,
                            },
                        }}
                    ></InputJson>

                    <Flex pb="spacer-10">
                        <Button colorScheme={"button-primary"} ml="auto" type="submit">
                            Submit
                        </Button>
                    </Flex>
                </form>
            </VStack>
        </PageContainer>
    );
};

type SessionContentFormVariables = {
    watch: UseFormWatch<UpdateStoreFormData>;
    defaultSessionContent: string | undefined;
};

const useSubmitSessionContent = (store: Store, { watch, defaultSessionContent }: SessionContentFormVariables) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const storeMutation = useStoreMutation(store.id, store.customerUrl);
    const queryClient = useQueryClient();
    const enqueuePurgeCacheMutation = useEnqueuePurgeCacheMutation();

    // Track changes to session content field
    const watchedSessionContent = watch("sessionContent");
    const [hasSessionContentChanged, setHasSessionContentChanged] = useState(false);
    useEffect(() => {
        watchedSessionContent !== defaultSessionContent
            ? setHasSessionContentChanged(true)
            : setHasSessionContentChanged(false);
    }, [watchedSessionContent, defaultSessionContent]);

    const onSubmit = (formData: UpdateStoreFormData) => {
        const onError = () => {
            showToast({
                title: "Error",
                description: "Unable to parse JSON. Please fix the syntax and try again.",
                status: "error",
                duration: null,
            });
        };

        const requestBody: UpdateStoreRequest = {
            sessionContent: toJSON(formData.sessionContent, { onError }),
            sessionContentTestCode: formData.sessionContentTestCode,
        };

        storeMutation.mutate(requestBody, {
            onSuccess: () => {
                if (hasSessionContentChanged) {
                    const purgeRequest = {
                        customerUrl: store.customerUrl!,
                    };
                    // only purge cache when session content has changed
                    enqueuePurgeCacheMutation.mutate(purgeRequest, {
                        onSuccess: () => {
                            showToast({
                                title: "Success: Purge Cache",
                                description: "Purge cache successfully initiated.",
                                status: "success",
                            });
                        },
                        onError: () => {
                            showToast({
                                title: "Error: Purge Cache",
                                description: "An error has occurred. Please wait a few moments and try again.",
                                status: "error",
                                duration: null,
                            });
                        },
                    });
                }

                queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                showToast({
                    title: "Success: Update Store",
                    description: "Store successfully updated.",
                    status: "success",
                });
            },
            onError: () => {
                showToast({
                    title: "Error: Update Store",
                    description: "An error has occurred. Please wait a few moments and try again.",
                    status: "error",
                    duration: null,
                });
            },
        });
    };

    return {
        onSubmit,
    };
};

const buildDefaultValues = (store: Store) => {
    let sessionContentTestCode;
    try {
        // format JSON
        sessionContentTestCode =
            store.sessionContentTestCode === null
                ? ""
                : JSON.stringify(JSON.parse(store.sessionContentTestCode), null, 4);
    } catch (e) {
        // If JSON is invalid, just use the raw string
        if (typeof store.sessionContentTestCode !== "string") {
            sessionContentTestCode = String(store.sessionContentTestCode);
        } else {
            sessionContentTestCode = store.sessionContentTestCode;
        }
    }

    return {
        sessionContent:
            store.sessionContent === null ? JSON.stringify([]) : JSON.stringify(store.sessionContent, null, 4),
        sessionContentTestCode,
    };
};

type Props = {
    store: Store;
};
