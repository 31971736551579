import { useState } from "react";
import { Header } from "../../../../components";
import dayjs from "dayjs";
import { Performance, ReportParameters, AccelerationByURI, SeenByURI } from "./";
import { Text, Divider } from "@chakra-ui/react";
import { Link } from "../../../../components";
import { ROUTES } from "../../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../../config/BRAND_NAME";

export const CustomerReport = () => {
    const defaultDateRange = {
        from_hour: dayjs().subtract(7, "day").startOf("day").format("YYYY-MM-DD"),
        upto_hour: dayjs().endOf("day").format("YYYY-MM-DD"),
    };

    const [dateRange, setDateRange] = useState(defaultDateRange);

    return (
        <>
            <Text textStyle={"text-body-regular"} py={"spacer-6"}>
                {BRAND_NAME}'s speed report includes the most fundamental information about the effect of our{" "}
                {MAIN_PRODUCT} on your site's performance. For more information on the included metrics and how to
                interpret them, please refer to our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS_SPEED} target="new">
                    Speed Reports documentation
                </Link>
                .
            </Text>
            <ReportParameters setDateRange={setDateRange} />
            <Performance dateRange={dateRange} />

            <Header style={{ marginTop: "40px" }}>TTFB</Header>
            <Divider style={{ margin: "20px 0" }} />
            <SeenByURI metric="ttfb_ms" dateRange={dateRange} />
            <AccelerationByURI metric="ttfb_ms" dateRange={dateRange} />

            <Header style={{ marginTop: "40px" }}>FCP</Header>
            <Divider style={{ margin: "20px 0" }} />
            <SeenByURI metric="fcp" dateRange={dateRange} />
            <AccelerationByURI metric="fcp" dateRange={dateRange} />

            <Header style={{ marginTop: "40px" }}>LCP</Header>
            <Divider style={{ margin: "20px 0" }} />
            <SeenByURI metric="lcp" dateRange={dateRange} />
            <AccelerationByURI metric="lcp" dateRange={dateRange} />

            <Header style={{ marginTop: "40px" }}>Total Load</Header>
            <Divider style={{ margin: "20px 0" }} />
            <SeenByURI metric="load_ms" dateRange={dateRange} />
            <AccelerationByURI metric="load_ms" dateRange={dateRange} />

            <Header style={{ marginTop: "40px" }}>Visually Ready</Header>
            <Divider style={{ margin: "20px 0" }} />
            <SeenByURI metric="visually_ready" dateRange={dateRange} />
            <AccelerationByURI metric="visually_ready" dateRange={dateRange} />
        </>
    );
};
