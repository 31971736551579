import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";
import { CreateBulletinRequest } from "../../types";

export const useCreateBulletinMutation = () => {
    return useMutation({ mutationFn: createBulletin });
};

const createBulletin = async (requestBody: CreateBulletinRequest) => {
    const CREATE_BULLETIN_URL = `${SVC_URLS["ecom"]}/bulletin`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(requestBody),
    });

    const response = await fetch(CREATE_BULLETIN_URL, options).catch(handleErrors);

    return fetchResponse(response);
};
