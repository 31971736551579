import { ClipboardEventHandler } from "react";

/**
 * onPaste event handler that transforms a URL to a path when pasted into an input field.
 *
 * Usage:
 *   <Input onPaste={transformUrlToPath((path) => setValue("path", path))} />
 */
export const transformUrlToPath =
    (handlePath: (path: string) => void): ClipboardEventHandler<HTMLInputElement> =>
    (event) => {
        event.preventDefault();
        if (!event.clipboardData) return;

        const value = event.clipboardData.getData("text");
        const transformedValue = parsePath(value);
        handlePath(transformedValue);
    };

const parsePath = (input: string) => {
    try {
        return new URL(input).pathname;
    } catch (error) {
        return input;
    }
};
