import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const Caching101Page = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Caching 101"}
            />
            <Header pb={"spacer-8"}>Caching 101</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s innovative customers use their websites to reach people around the world, a feat that
                requires the constant movement of data.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The modern internet is incredibly fast, but that data still has to travel from a site's origin server
                all the way to the human on the other end of a computer, wherever they may be.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Caching In Non-Technical Terms
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Say you're on a long hike through a world-renowned nature reserve or park. The sun is out, and while the
                scenery is beautiful, you are going through your water much faster than you anticipated because of the
                heat. Luckily, there is a place to refill your bottle halfway through, but it still takes you an hour to
                get there… and then another hour to get back.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Now, imagine park rangers have set up water stations at regular intervals throughout the area. Instead
                of waiting an hour to get more water, you can access this crucial resource every 15 minutes.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In this example, the water is your website's data, which you want your customers to have as soon as
                possible for a great user experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Software engineers, also known as metaphorical park rangers, have created systems that provide efficient
                resource delivery, only on a much faster scale.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                The Technical Explanation
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When a user first visits your homepage, they make a request to get all the files that make up that page.
                If that customer lives in Mexico, but your website is hosted by an origin server in Japan, your data
                would have to travel more than 6,000 miles to reach them.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-26/b2cc7204-ed6e-40d5-a7cb-dfd615e6a5cd/screenshot.jpeg?tl_px=0,9&br_px=940,778&force_format=png&width=1120.0"
                alt="Origin server delivery"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Even with today's technology, that could take several seconds too long, considering latency—how long it
                takes for data to travel from its origin to its destination—is the number one reason shoppers abandon
                carts.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                That's where caching comes in. Copies of your site's data can be cached—or temporarily stored—in CDN
                servers located much closer to your customer's location.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-26/38cc487f-aba1-4315-984b-9d5d5bee61fc/screenshot.jpeg?tl_px=0,9&br_px=940,778&force_format=png&width=1120.0"
                alt="CDN cache delivery"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Even though your website may be hosted by an origin server in Japan, your customers can now make
                requests and receive data from a server a fraction of the distance away. In other words, they are
                getting their water faster!
            </Text>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                The benefits of caching include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Reduced latency</ListItem>
                <ListItem pb={"spacer-4"}>Clear increases in speed</ListItem>
                <ListItem pb={"spacer-4"}>Load reductions for your host server</ListItem>
                <ListItem pb={"spacer-6"}>A much better user experience</ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Standard Caching Versus Caching With {BRAND_NAME}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most popular eCommerce platforms utilize modern content delivery networks (CDNs) such as the Cloudflare
                CDN to provide caching for static assets.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                However, it's crucial to recognize that Shopify and Salesforce Commerce Cloud exclusively cache static
                assets, encompassing images and CSS. In contrast,{" "}
                <strong>
                    {BRAND_NAME}'s strategy revolves around enabling smart caching mechanisms for static <i>and</i>{" "}
                    dynamic content.
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our eCommerce Edge Delivery Platform substantially enhances page load times, with a remarkable{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_ROI}>
                    5-10 time reduction in time-to-first-byte (TTFB)
                </Link>
                , signifying the time it takes for the browser to begin downloading your content. This results in a
                swifter initiation of static asset downloads, fostering a more responsive user experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} additionally has proprietary intelligence to treat your site's dynamic elements as hybrid
                elements, meaning our software identifies dynamic content that can be preprocessed and intelligently
                rehydrates that content for the user as needed, increasing the page's performance even further.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                On the {SECONDARY_PRODUCT} side, {BRAND_NAME} prerenders and caches your pages, simplifying the crawling
                process for search engine bots. This eliminates the need for additional rendering queues and ensures
                faster indexing without compromising on your site's functionality.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s strategy of acceleration-driven lift is grounded in the belief that efficiently
                delivering content to your customers can yield substantial gains in several{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_ROI}>
                    key metrics
                </Link>
                . This viewpoint is substantiated by case studies involving prominent eCommerce platforms such as
                Amazon, Google, and Meta.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about how our core products work by visiting the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE}>
                    {MAIN_PRODUCT}
                </Link>{" "}
                or{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CRAWLER_OPTIMIZATION}>
                    {SECONDARY_PRODUCT}
                </Link>
                &nbsp;documentation.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
