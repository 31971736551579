import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, fetchResponse } from "../../lib";
import { generateListWebhooksQueryKey } from "./useListWebhooks";

const DEFAULT_OPTIONS = {
    showSuccessToast: true,
    showErrorToast: true,
};

export const useDeleteWebhooksMutation = (storeId: string, customerUrl: string, options: Options = DEFAULT_OPTIONS) => {
    const queryClient = useQueryClient();
    const { showSuccessToast, showErrorToast } = { ...DEFAULT_OPTIONS, ...options };

    const triggerToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    return useMutation((id: string) => deleteWebhook(storeId, customerUrl), {
        onSuccess: () => {
            if (showSuccessToast) {
                triggerToast({
                    title: "Success - Webhooks",
                    status: "success",
                    description: "Shopify webhooks successfully deleted.",
                });
            }
            queryClient.invalidateQueries(generateListWebhooksQueryKey(storeId));
        },
        onError: (error) => {
            console.error(error);
            if (showErrorToast)
                triggerToast({
                    title: "Error - Webhooks",
                    status: "error",
                    description:
                        "An error occurred while deleting the Shopify webhooks. Common reasons include missing shopify API keys or uninstalling the Nostra app within Shopify. If you continue to experience issues, please contact support.",
                    isClosable: true,
                });
        },
    });
};

const deleteWebhook = async (storeId: string, customerUrl: string) => {
    const url = `${SVC_URLS["ops"]}/shopify/store/${storeId}/webhooks`;
    const options = await fetchOptions({
        method: "DELETE",
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(url, options);

    return fetchResponse(response);
};

type Options = {
    showSuccessToast?: boolean;
    showErrorToast?: boolean;
};
