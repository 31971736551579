import { Box, Stack, Image } from "@chakra-ui/react";

type Props = {
    src: string;
    loading?: "lazy" | "eager";
    alt: string;
};

export const DocumentationImage = ({ src, loading = "lazy", alt }: Props) => {
    return (
        <Box bg="gray.200" p={{ base: "spacer-2", md: "spacer-4", lg: "spacer-6" }} mb={"spacer-6"}>
            <Stack justifyContent={"center"}>
                <Image src={src} alt={alt} loading={loading}></Image>
            </Stack>
        </Box>
    );
};
