import { useRevenueReport } from "../../../../hooks";
import { useSearchParams } from "react-router-dom";
import { LoadingPage } from "../../../LoadingPage";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { IntervalForm } from "./MetricParameters";

type ConfidenceProps = {
    metricParams: {
        report: string;
        confidence: number;
        absolute: boolean;
        interval_unit: string;
        interval_count: number | string;
    };
};

const Confidence = ({ metricParams }: ConfidenceProps) => {
    const { data, isLoading, isError } = useRevenueReport(metricParams);

    if (isLoading === true) return <LoadingPage />;

    if (isError === true) {
        return (
            <Alert status="error">
                <AlertIcon />
                There was an error fetching data for Confidence at {metricParams.confidence * 100}%.
            </Alert>
        );
    }

    if (data?.message) {
        return (
            <Alert status="info">
                <AlertIcon />
                {data.message}
            </Alert>
        );
    }

    return (
        <>
            <h1>{data?.confidence}</h1>
        </>
    );
};

export const ConfidenceRange = () => {
    const [searchParams] = useSearchParams();
    const confidence_range = [0.7, 0.8, 0.9, 0.95, 0.99];
    const report = "two_arm_lift";

    const absolute =
        searchParams.get(`${report}_absolute`) === null || searchParams.get(`${report}_absolute`) === "false"
            ? false
            : true;

    const metricParams = {
        absolute,
        interval_unit: searchParams.get(`${report}_interval_unit`) ?? "days",
        interval_count: searchParams.get(`${report}_interval_count`) ?? 1,
    };
    return (
        <>
            {confidence_range.map((confidence) => (
                <Confidence metricParams={{ confidence, report, ...metricParams }} />
            ))}
            <IntervalForm style={{ marginTop: "20px" }} prefix={report} params={metricParams} />
        </>
    );
};
