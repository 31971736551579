import { extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from "./checkboxTheme";

const impulsumTheme = {
    breakpoints: {
        base: "0px",
        sm: "414px",
        md: "744px",
        lg: "1080px",
        xl: "1081px",
        "2xl": "1440px",
        xxl: "1440px",
    },
    fonts: {
        heading: "GT Super",
        body: "Inter",
    },
    colors: {
        "checkbox-primary": {
            500: "#011639", // checked
            600: "#112648", // hover
        },
        "button-primary": {
            500: "#011639",
            600: "#112648",
            700: "#000C1F",
        },
        "button-delete": {
            500: "#BE2B13",
            600: "#E03519",
            700: "#961D09",
        },
        "tooltip-primary": "#5F6675",
        "accent-default": "#EEFD56",
        "accent-hover": "#F2FF71",
        "accent-pressed": "#DBE84F",
        "accent-disabled": "#F7FE9B",
        "accent-focus": "#F3FF57",
        "primary-default": "#011639",
        "primary-hover": "#112648",
        "primary-pressed": "#000C1F",
        "primary-disabled": "#8287A0",
        "primary-focus": "#919AC8",
        "secondary-default": "#BAD3F3",
        "secondary-hover": "#D7E6F9",
        "secondary-pressed": "#9EBEE5",
        "secondary-disabled": "#DEE6F5",
        "secondary-focus": "#919AC8",
        "alert-error-default": "#BE2B13",
        "alert-error-hover": "#E03519",
        "alert-error-pressed": "#961D09",
        "alert-error-disabled": "#EDC3C3",
        "alert-error-focus": "#919AC8",
        "alert-warning-default": "#FC9403",
        "alert-warning-hover": "#FFA932",
        "alert-warning-pressed": "#E88700",
        "alert-warning-disabled": "#E3AD61",
        "alert-success-default": "#1AAA55",
        "alert-success-hover": "#1CC661",
        "alert-success-pressed": "#168845",
        "alert-success-disabled": "#6DA785",
        "alert-inform-default": "#1F78D1",
        "alert-inform-hover": "#2793FF",
        "alert-inform-pressed": "#1861AB",
        "alert-inform-disabled": "#8CB0D3",
        "background-light": "#EFF5FF",
        "background-dark": "#011639",
        "background-card": "#FCFCFF",
        "background-page": "#FFFFFF",
        "background-overlay": "#E9F1FF",
        "background-static": "#D1DEF3",
        "background-container-default": "#FFFFFF",
        "background-container-hover": "#E3ECF6",
        "background-container-pressed": "#E0E0E0",
        "background-container-disabled": "#EAEAEA",
        "background-container-active": "#D1DEF3",
        "background-action-default": "#F6FAFF",
        "background-action-hover": "#FCFDFF",
        "background-action-pressed": "#E4E4E4",
        "background-action-disabled": "#EAEDF1",
        "background-action-active": "#E8E8FD",
        "background-error": "#FFDDD8",
        "background-warning": "#FFE5C1",
        "background-success": "#DEFFEC",
        "background-inform": "#E4F2FF",
        "text-light": "#FFFFFF",
        "text-primary": "#283248",
        "text-secondary": "#5F6675",
        "text-disabled": "#CCCCCC",
        "text-button-accent": "#333645",
        "text-button-primary": "#FFFFFF",
        "text-button-secondary": "#333645",
        "text-button-alert": "#FFFFFF",
        "text-badge-solid": "#333645",
        "text-badge-outline": "#4F4FFF",
        "text-badge-subtle": "#4F4FFF",
        "text-link-accent-default": "#EEFD56",
        "text-link-accent-hover": "#F2FF71",
        "text-link-accent-pressed": "#DBE84F",
        "text-link-primary-default": "#333645",
        "text-link-primary-hover": "#45495F",
        "text-link-primary-pressed": "#20222E",
        "text-link-secondary-default": "#5F6675",
        "text-link-secondary-hover": "#D7E6F9",
        "text-link-secondary-pressed": "#9EBEE5",
        "text-success": "#15793E",
        "text-warning": "#CB7600",
        "text-error": "#F6391B",
        "ui-element-divider": "#D2D9E9",
        "ui-element-outline-default": "#E7E7E7",
        "ui-element-outline-active": "#D7DBE7",
        "ui-element-outline-disabled": "#F2F2F2",
    },
    space: {
        "spacer-px": "0.0625rem",
        "spacer-half": "0.125rem",
        "spacer-1": "0.25rem",
        "spacer-2": "0.5rem",
        "spacer-3": "0.75rem",
        "spacer-4": "1rem",
        "spacer-5": "1.25rem",
        "spacer-6": "1.5rem",
        "spacer-7": "1.75rem",
        "spacer-8": "2rem",
        "spacer-9": "2.25rem",
        "spacer-10": "2.5rem",
        "spacer-12": "3rem",
        "spacer-14": "3.5rem",
        "spacer-16": "4rem",
        "spacer-20": "5rem",
        "spacer-24": "6rem",
        "spacer-28": "7rem",
        "spacer-36": "9rem",
        "spacer-40": "10rem",
        "spacer-44": "11rem",
        "spacer-48": "12.25rem",
        "spacer-52": "13rem",
        "spacer-56": "14rem",
        "spacer-60": "15rem",
        "spacer-64": "16rem",
        "spacer-72": "18rem",
        "spacer-80": "20rem",
        "spacer-86": "24rem",
        "spacer-0": "0rem",
    },
    radii: {
        "radii-half": "0.125rem",
        "radii-1": "0.25rem",
        "radii-2": "0.375rem",
        "radii-3": "0.5rem",
        "radii-4": "0.75rem",
        "radii-5": "1rem",
        "radii-6": "6.25rem",
        "radii-card": "0.5rem",
        "radii-modal": "0.5rem",
        alertdialog: "0.5rem",
        "radii-popover": "0.5rem",
    },
    shadows: {
        "elevation-default": "0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
        "elevation-interactive": "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
        "elevation-modals": "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        "elevation-outline": "0px 0px 0px 3px rgba(194, 194, 255, 0.3)",
        "elevation-inner": "inset 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
    },
    textStyles: {
        "text-header-4XL": {
            color: "text-primary",
            fontSize: ["3rem", null, null, "4rem"],
            fontWeight: [700, null, null, 700],
            letterSpacing: ["-0.029999999329447746rem", null, null, "-0.03999999910593033rem"],
            lineHeight: ["3.25rem", null, null, "4.25rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-3XL": {
            color: "text-primary",
            fontSize: ["2.625rem", null, null, "3rem"],
            fontWeight: [700, null, null, 700],
            letterSpacing: ["-0.026249999180436134rem", null, null, "-0.029999999329447746rem"],
            lineHeight: ["2.875rem", null, null, "3.25rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-2XL": {
            color: "text-primary",
            fontSize: ["2.25rem", null, null, "2.75rem"],
            fontWeight: [700, null, null, 700],
            letterSpacing: ["-0.02250000089406967rem", null, null, "-0.027499999850988388rem"],
            lineHeight: ["2.5rem", null, null, "3rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-XL": {
            color: "text-primary",
            fontSize: ["2rem", null, null, "2rem"],
            fontWeight: [500, null, null, 500],
            letterSpacing: ["-0.019999999552965164rem", null, null, "-0.019999999552965164rem"],
            lineHeight: ["2.25rem", null, null, "2.25rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-L": {
            color: "text-primary",
            fontSize: ["1.5rem", null, null, "1.5rem"],
            fontWeight: [600, null, null, 600],
            letterSpacing: ["0rem", null, null, "0rem"],
            lineHeight: ["1.75rem", null, null, "1.75rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "Inter",
        },
        "text-header-M": {
            color: "text-primary",
            fontSize: ["1.5rem", null, null, "1.5rem"],
            fontWeight: [500, null, null, 500],
            letterSpacing: ["-0.014999999664723873rem", null, null, "-0.014999999664723873rem"],
            lineHeight: ["1.75rem", null, null, "1.75rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-S": {
            color: "text-primary",
            fontSize: ["1.25rem", null, null, "1.25rem"],
            fontWeight: [700, null, null, 700],
            letterSpacing: ["0.012500000186264515rem", null, null, "0.012500000186264515rem"],
            lineHeight: ["1.5rem", null, null, "1.5rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-header-XS": {
            color: "text-primary",
            fontSize: ["1.125rem", null, null, "1rem"],
            fontWeight: [700, null, null, 700],
            letterSpacing: ["0.011250000447034836rem", null, null, "-0.009999999776482582rem"],
            lineHeight: ["1.375rem", null, null, "1.25rem"],
            textDecoration: ["none", null, null, "none"],
            fontFamily: "GT Super",
        },
        "text-body-display-2XL": {
            color: "text-primary",
            fontSize: ["3.75rem"],
            fontWeight: [700],
            letterSpacing: ["0rem"],
            lineHeight: ["4.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-display-XL": {
            color: "text-primary",
            fontSize: ["3rem"],
            fontWeight: [700],
            letterSpacing: ["0rem"],
            lineHeight: ["3.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-display-L": {
            color: "text-primary",
            fontSize: ["2.25rem"],
            fontWeight: [300],
            letterSpacing: ["0rem"],
            lineHeight: ["2.75rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-display-M": {
            color: "text-primary",
            fontSize: ["1.5rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["2rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-display-S": {
            color: "text-primary",
            fontSize: ["1.25rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.75rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-display-XS": {
            color: "text-primary",
            fontSize: ["1.125rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-large-regular": {
            color: "text-primary",
            fontSize: ["1.125rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.75rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-large-medium": {
            color: "text-primary",
            fontSize: ["1.125rem"],
            fontWeight: [600],
            letterSpacing: ["0rem"],
            lineHeight: ["1.75rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-large-bold": {
            color: "text-primary",
            fontSize: ["1.125rem"],
            fontWeight: [700],
            letterSpacing: ["0rem"],
            lineHeight: ["1.75rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-regular": {
            color: "text-primary",
            fontSize: ["1rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-medium": {
            color: "text-primary",
            fontSize: ["1rem"],
            fontWeight: [600],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-bold": {
            color: "text-primary",
            fontSize: ["1rem"],
            fontWeight: [700],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-meta-regular": {
            color: "text-primary",
            fontSize: ["0.875rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.25rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-meta-medium": {
            color: "text-primary",
            fontSize: ["0.875rem"],
            fontWeight: [500],
            letterSpacing: ["0rem"],
            lineHeight: ["1.25rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-meta-bold": {
            color: "text-primary",
            fontSize: ["0.875rem"],
            fontWeight: [700],
            letterSpacing: ["0rem"],
            lineHeight: ["1.25rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-floating-label-regular": {
            color: "text-primary",
            fontSize: ["0.75rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.125rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-floating-label-medium": {
            color: "text-primary",
            fontSize: ["0.75rem"],
            fontWeight: [500],
            letterSpacing: ["0.15625rem"],
            lineHeight: ["1rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-body-floating-label-bold": {
            color: "text-primary",
            fontSize: ["0.75rem"],
            fontWeight: [700],
            letterSpacing: ["0.3125rem"],
            lineHeight: ["1rem"],
            textDecoration: ["none"],
            fontFamily: "Inter",
        },
        "text-link-body-large": {
            color: "text-link-accent-default",
            fontSize: ["1.75rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["2.25rem"],
            textDecoration: ["underline"],
            fontFamily: "Inter",
        },
        "text-link-body-medium": {
            color: "text-link-accent-default",
            fontSize: ["1.25rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["underline"],
            fontFamily: "Inter",
        },
        "text-link-body-small": {
            color: "text-link-accent-default",
            fontSize: ["1rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.25rem"],
            textDecoration: ["underline"],
            fontFamily: "Inter",
        },
        // added- these are not part of the original theme
        "text-link-meta-regular": {
            color: "text-primary",
            fontSize: ["0.875rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.25rem"],
            textDecoration: ["underline"],
            fontFamily: "Inter",
        },
        "text-link-meta-medium": {
            color: "text-link-primary-default",
            fontSize: ["1.25rem"],
            fontWeight: [400],
            letterSpacing: ["0rem"],
            lineHeight: ["1.5rem"],
            textDecoration: ["underline"],
            fontFamily: "Inter",
        },
        // end of the added styles
    },
    components: {
        Checkbox: checkboxTheme,
        Button: {
            baseStyle: {
                borderRadius: "6.25rem",
            },
        },
        Link: {
            variants: {
                "text-link-meta-regular": {
                    textDecoration: ["underline"],
                },
            },
        },
        TextPairing: {
            parts: ["label", "subLabel"],
            variants: {
                "textpairing-body-display-2XL": {
                    label: { variant: "text-body-display-2XL" },
                    subLabel: { variant: "text-body-large-regular" },
                    columnGap: { base: "1rem", md: "1rem" },
                },
                "textpairing-body-display-XL": {
                    label: { variant: "text-body-display-XL" },
                    subLabel: { variant: "text-body-large-regular" },
                    columnGap: { base: "0.75rem", md: "0.75rem" },
                },
                "textpairing-body-display-L": {
                    label: { variant: "text-body-display-L" },
                    subLabel: { variant: "text-body-large-regular" },
                    columnGap: { base: "0.75rem", md: "0.75rem" },
                },
                "textpairing-body-display-M": {
                    label: { variant: "text-body-display-M" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { base: "0.5rem", md: "0.5rem" },
                },
                "textpairing-body-display-S": {
                    label: { variant: "text-body-display-S" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { base: "0.5rem", md: "0.5rem" },
                },
                "textpairing-header-4XL": {
                    label: { variant: "text-header-4XL" },
                    subLabel: { variant: "text-body-display-S" },
                    columnGap: { md: "1rem", base: "1rem" },
                },
                "textpairing-header-3XL": {
                    label: { variant: "text-header-3XL" },
                    subLabel: { variant: "text-body-display-S" },
                    columnGap: { md: "1rem", base: "1rem" },
                },
                "textpairing-header-2XL": {
                    label: { variant: "text-header-2XL" },
                    subLabel: { variant: "text-body-display-S" },
                    columnGap: { md: "0.75rem", base: "0.75rem" },
                },
                "textpairing-header-M": {
                    label: { variant: "text-header-M" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { md: "0.625rem", base: "0.25rem" },
                },
                "textpairing-header-L": {
                    label: { variant: "text-header-L" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { md: "0.75rem", base: "0.5rem" },
                },
                "textpairing-header-XL": {
                    label: { variant: "text-header-XL" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { md: "0.75rem", base: "0.5rem" },
                },
                "textpairing-body-bold": {
                    label: { variant: "text-body-bold" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { base: "0.25rem", md: "0.25rem" },
                },
                "textpairing-body-bold-meta": {
                    label: { variant: "text-body-bold" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.25rem", md: "0.25rem" },
                },
                "textpairing-body-meta-bold": {
                    label: { variant: "text-body-meta-bold" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.125rem", md: "0.125rem" },
                },
                "textpairing-body-medium": {
                    label: { variant: "text-body-medium" },
                    subLabel: { variant: "text-body-regular" },
                    columnGap: { base: "0.25rem", md: "0.25rem" },
                },
                "textpairing-body-regular-meta": {
                    label: { variant: "text-body-regular" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.25rem", md: "0.25rem" },
                },
                "textpairing-body-medium-meta": {
                    label: { variant: "text-body-medium" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.25rem", md: "0.25rem" },
                },
                "textpairing-body-meta-medium": {
                    label: { variant: "text-body-meta-medium" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.125rem", md: "0.125rem" },
                },
                "textpairing-body-meta-regular": {
                    label: { variant: "text-body-meta-regular" },
                    subLabel: { variant: "text-body-meta-regular" },
                    columnGap: { base: "0.125rem", md: "0.125rem" },
                },
            },
        },
    },
};

export default extendTheme(impulsumTheme);
