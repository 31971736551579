import { useRef, useEffect } from "react";
import {
    BuildSelectorRequest,
    BuildSelectorResponse,
    ExperimentAction,
    SelectedElementContext,
    Experiment,
    EditorContextSelectedElement,
    Action,
} from "../../../types";
import { useEditorContext } from "../EditorProvider";
import { loadIframe } from "../loadIframe";
import { ELEMENT_SELECTED, IFRAME_LOADED, REPLACE_HTML_FRAGMENT, SET_EDITOR_MODE } from "../lib/events";
import { htmlToElement } from "../lib/html/htmlToElement";
import { sharedRefs } from "../lib/sharedRefs";
import { useExperiment } from "../../../hooks";
import { uuid } from "../../../lib/uuid";
import { useParams } from "react-router";
import { postMessage } from "../lib/postMessage";
import { useBuildSelectorMutation } from "./queries/useBuildSelectorMutation";
import { useToast } from "@chakra-ui/react";
import { defaultToastOptions } from "../lib/defaultToastOptions";

const NODE_TYPE = {
    ELEMENT: 1,
    TEXT: 3,
    COMMENT: 8,
};

const hasNodeChildrenOfType = (node: Node, nodeType: number): boolean => {
    if (node.hasChildNodes()) {
        for (let i = 0; i < node.childNodes.length; i++) {
            let type = node.childNodes[i].nodeType;

            // If the nodeType is not 3 (Text) and not 8 (Comment)
            if (type === nodeType) {
                return true;
            }
        }
    }
    return false;
};

export const useExperimentationEditor = () => {
    const iframeRef: React.RefObject<HTMLIFrameElement> = useRef(null);
    const { dispatch } = useEditorContext();
    const { data: experiment, isLoading } = useExperiment();
    const selectorMutation = useBuildSelectorMutation(experiment?.pageset.sampleUrl ?? "");
    const { variantId } = useParams<{ variantId: string }>();

    /** On page load */
    useEffect(() => {
        initializeIframe(iframeRef);
        return () => {
            window.onmessage = null;
        };
    }, []);

    const showToast = useToast(defaultToastOptions);

    /** on experiment loaded */
    useEffect(() => {
        if (experiment === undefined) return; // wait for the data

        dispatch({ type: "setExperiment", payload: experiment });
        window.onmessage = (event: MessageEvent) => {
            // @ToDo: security check to make sure the message is coming from the expected origin
            // console.debug(event.origin);

            const { name, data } = event.data;

            // ~~~~ DEBUG ~~~~
            // if (name !== 'mouseout' && name !== undefined) {
            //   console.debug("[Portal] Received: ", { name, data });
            // }
            // ~~~~ DEBUG ~~~~

            switch (name) {
                case "SHOW_TOAST":
                    showToast({
                        title: data.title,
                        description: data.description,
                        status: data.status,
                        duration: null,
                    });
                    break;
                case IFRAME_LOADED:
                    // console.debug("[Portal] iframe-loaded");
                    break;

                case ELEMENT_SELECTED:
                    // console.debug('[PORTAL] Received: ELEMENT_SELECTED', {data});
                    const {
                        htmlTag,
                        isDirtyClientSide,
                        isExistingAction,
                        isValidSelection,
                        htmlFragmentId,
                        actionId,
                    }: SelectedElementContext = data;
                    if (experiment === undefined) {
                        showToast({
                            title: "Error",
                            description: "Something went wrong. Please try again. Error code: 114",
                            status: "error",
                        });
                        throw new Error("Experiment is undefined");
                    }

                    if (variantId === undefined) {
                        showToast({
                            title: "Error",
                            description: "Something went wrong. Please try again. Error code: 115",
                            status: "error",
                        });
                        throw new Error("VariationId is undefined");
                    }

                    // @ToDo: Handle the use case where an action is within the DOM tree of the selected element
                    if (!isValidSelection.isValid) {
                        const element = htmlToElement(htmlTag) ?? "";
                        const tagName = element ? element.tagName : "";
                        showToast({
                            title: "Warning",
                            description: `The <${tagName}> element you chose is not currently valid for selection: \n\n"${isValidSelection.reason}"`,
                            status: "warning",
                        });
                        break;
                    }

                    if (htmlFragmentId === null) {
                        console.error("htmlFragmentId is null");
                        showToast({
                            title: "Error",
                            description: "The selected element is not available on the server side document.",
                            status: "error",
                        });
                    }

                    // Rebuild the node
                    const element = htmlToElement(htmlTag);
                    if (element === null) {
                        showToast({
                            title: "Error",
                            description: "Something went wrong. Please try again. Error code: 116",
                            status: "error",
                        });

                        throw new Error("Could not build element from htmlTag: ", data.htmlTag);
                    }

                    /**
                     * EXISTING ACTION PATH
                     */
                    if (isExistingAction) {
                        if (actionId === undefined) {
                            showToast({
                                title: "Error",
                                description: "Something went wrong. Please try again. Error code: 117",
                                status: "error",
                            });
                            throw new Error(
                                "SelectedElementContext.actionid is undefined, while isExistingAction is true",
                            );
                        }

                        try {
                            const currentAction = findAction(experiment, variantId, actionId);
                            const selectedElement = buildEditorContextSelectedElement(element);
                            dispatch({
                                type: "elementSelected",
                                payload: { element: selectedElement, action: currentAction },
                            });
                        } catch (error) {
                            showToast({
                                title: "Error",
                                description: "Something went wrong. Please try again. Error code: 119",
                                status: "error",
                            });
                        }
                        break;
                    }

                    /**
                     * NEW ACTION PATH && DIRTY HTML FRAGMENT PATH
                     */
                    // Get the replaceSelector & server side htmlFragment
                    const serverSideMarkupRequest: BuildSelectorRequest = {
                        htmlFragmentId,
                    };
                    selectorMutation.mutate(serverSideMarkupRequest, {
                        onSuccess: (response: BuildSelectorResponse) => {
                            const { replaceSelector } = response;
                            // When dirty, replace the element with the server side html fragment
                            if (isDirtyClientSide) {
                                postMessage(REPLACE_HTML_FRAGMENT, response);
                                return;
                            }

                            const currentAction = buildAction(replaceSelector);
                            const selectedElement = buildEditorContextSelectedElement(element);

                            dispatch({
                                type: "elementSelected",
                                payload: { element: selectedElement, action: currentAction },
                            });
                        },
                        onError: () => {
                            // @ToDo: Record this in Honeycomb
                            showToast({
                                title: "Error",
                                description: "Unable to match the selected element with the server side document.",
                                status: "error",
                            });
                        },
                    });

                    break;
                case SET_EDITOR_MODE:
                    dispatch({ type: "setEditorMode", payload: data.mode });
            }
        };
    }, [experiment]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Load the iframe when the experiment is loaded
     */
    useEffect(() => {
        if (experiment === undefined || isLoading || variantId === undefined) return;
        loadIframe(experiment.pageset.sampleUrl, variantId);
    }, [experiment, isLoading, variantId]);

    // Return the public API for the ExperimentationEditor hook
    return { iframeRef };
};

const initializeIframe = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
    if (iframeRef.current === null) throw new Error("iframeRef is null");

    // assign the ref to the sharedRefs so that it can be injected into standard functions.
    sharedRefs.iframeRef = iframeRef;
};

const buildSupportedInteractions = (element: Element): Array<string> => {
    const interactions: Array<string> = [];

    if (hasNodeChildrenOfType(element, NODE_TYPE.ELEMENT)) {
        interactions.push("EDIT_MARKUP");
    }

    if (hasNodeChildrenOfType(element, NODE_TYPE.TEXT) && !hasNodeChildrenOfType(element, NODE_TYPE.ELEMENT)) {
        interactions.push("EDIT_TEXT");
    }

    return interactions;
};

const buildAction = (replaceSelector: string): ExperimentAction => {
    return {
        id: uuid(),
        replaceSelector,
        action: "replace",
        insertText: "",
    };
};

const findAction = (experiment: Experiment, variationId: string, actionId: string | null): Action => {
    if (actionId === null) throw new Error("ActionId is null");

    const variation = experiment.variations.find((v) => v.id === variationId);
    if (variation === undefined) {
        throw new Error("Could not find variation: " + variationId);
    }

    const action = variation.actions.find((a) => a.id === actionId);
    if (action === undefined) {
        throw new Error("Could not find action: " + actionId);
    }

    return action;
};

const buildEditorContextSelectedElement = (element: Element): EditorContextSelectedElement => {
    // Build the supported interactions
    //  @ToDo: Deciding the interaction should not happen until the user choose the interaction
    const supportedInteractions = buildSupportedInteractions(element);
    const interaction = hasNodeChildrenOfType(element, NODE_TYPE.ELEMENT) ? "EDIT_MARKUP" : "EDIT_TEXT";

    // Update editorContext with the selected element
    // This function always sets current and original (based on the click)
    // Then always read from current in HTML Editor

    return {
        element: {
            original: element,
            current: element.cloneNode(true) as Element,
        },
        supportedInteractions,
        interaction,
    };
};
