import { useParams } from "react-router-dom";
import { Experiment, ExperimentResponse, PageSet } from "../../types/types";
import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";

type Configuration = {
    id?: string;
    urlSlug?: string;
};

export const useExperiment = (
    { id = "", urlSlug = "experimentId" }: Configuration = {
        id: "",
        urlSlug: "experimentId",
    },
) => {
    const params = useParams<Record<string, string>>(); // @ToDo: Use a more specific type to represent the expected route params
    const customerUrl = params.customerUrl!;
    const experimentId = getExperimentId(id, params[urlSlug]);
    return useQuery<Experiment>(["experiment", experimentId], () => getExperiment(experimentId, customerUrl));
};

export const generateExperimentQueryKey = (experimentId: string): string[] => ["experiment", experimentId];

const getExperimentId = (id: string, urlParameter: string | undefined): string => {
    const experimentId = (id || urlParameter) ?? "";
    return experimentId;
};

const getExperiment = async (experimentId: string, customerUrl: string): Promise<Experiment> => {
    const GET_EXPERIMENT = `${SVC_URLS["experiment"]}/experiment/${experimentId}`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(GET_EXPERIMENT, options).catch(handleErrors);

    return transformExperiment(await fetchResponse(response));
};

const transformExperiment = (experiment: ExperimentResponse): Promise<Experiment> => {
    const pageset: PageSet = experiment.pageset;
    Reflect.deleteProperty(experiment, "pageset");

    return Promise.resolve({
        ...experiment,
        pageset,
    });
};
