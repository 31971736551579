import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, List, ListItem, Alert, AlertIcon, ListIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { useAuthenticatedUser } from "../../../hooks";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const ManageOrganizationResource = () => {
    const { isCloudOpsRole } = useAuthenticatedUser();

    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Organization"}
                menuItem1Link={ROUTES.DOCUMENTATION_MANAGE_USERS}
                menuItem2={"Manage Organization"}
            />
            <Header pb={"spacer-8"}>Manage Organization</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The <strong>Manage Organization</strong> page provides the same resources you have available through the{" "}
                <strong>Manage Users</strong> page and the <strong>Stores</strong> section of the portal.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="create-users">
                Users Tab
            </SubHeader>
            {isCloudOpsRole ? (
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    In the <strong>Users</strong> tab, you will see a list of your organization's users, displaying
                    their email, role ("Store Administrator," "Organization Owner," or "CloudOps - Super User"), and
                    active status.
                </Text>
            ) : (
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    In the <strong>Users</strong> tab, you will see a list of your organization's users, displaying
                    their email, role ("Store Administrator" or "Organization Owner"), and active status.
                </Text>
            )}
            <DocumentationImage
                src={
                    isCloudOpsRole
                        ? "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-05/fb6ce81c-f517-4b71-bb9e-1891cc5d6a09/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2196,985&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=934,95"
                        : "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-22/90bad7cb-e4bd-424c-beee-5983709ff536/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1350,584&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1030,69"
                }
                alt="Manage Users tab"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You will have the following abilities per user:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Create a new user</ListItem>
                <ListItem pb={"spacer-4"}>Reset a user's password</ListItem>
                <ListItem pb={"spacer-4"}>Disable a user</ListItem>
                <ListItem pb={"spacer-4"}>Update a user</ListItem>
                <ListItem pb={"spacer-6"}>Delete a user</ListItem>
            </UnorderedList>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                Our system manages profiles via email, so user emails cannot be updated. To change a user's email,
                create a new user profile and delete the current profile.
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Please note if you give a user "Organization Owner" access, they will have all the same capabilities as
                any store owner. Users with the "Store Administrator" role cannot manage other users or access the
                Manage Organizations page.
            </Text>
            {isCloudOpsRole ? (
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    CloudOps users can give new users a "CloudOps - Super User" role. Use caution when assigning one of
                    these roles outside the {BRAND_NAME} organization, as the role has access to every resource
                    portal-wide.
                </Text>
            ) : null}
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="create-users">
                Stores Tab
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In the <strong>Stores</strong> tab, you will see a list of all the stores you have registered with&nbsp;
                {BRAND_NAME}. You may recognize the icons beneath each store from the <strong>Stores</strong> section.
                Each one is an active link to that page or tool within the portal:
            </Text>
            <List textStyle={"text-body-regular"} pl={"spacer-6"}>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Clear Cache
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TURN_OFF_ON}>
                    <ListItem pb={isCloudOpsRole ? "spacer-4" : "spacer-6"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Edit Store
                    </ListItem>
                </Link>
                {isCloudOpsRole ? (
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_MANAGE_EXPERIMENTS}>
                        <ListItem pb={"spacer-4"}>
                            <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Manage Experiments
                        </ListItem>
                    </Link>
                ) : null}
                {isCloudOpsRole ? (
                    <a href="#delete-store" style={{ textDecoration: "underline" }}>
                        <ListItem pb={"spacer-6"}>
                            <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Delete Store
                        </ListItem>
                    </a>
                ) : null}
            </List>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about these resources by selecting the links above.
            </Text>
            {isCloudOpsRole ? (
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-05/34aee036-c2cd-4ff4-bfaa-58d8f6e47c9d/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2141,1155&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1012,85"
                    alt="Stores tab"
                />
            ) : null}
            {isCloudOpsRole ? (
                <>
                    <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="delete-store">
                        Create/Delete Store Or Organization
                    </SubHeader>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        As a CloudOps user, you can create a new store under any organization or delete the entire
                        organization. Please verify that you are under the correct organization before creating or
                        deleting a new store.
                    </Text>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        Deleting a store will simultaneously delete that store's webhooks if it is on the Shopify
                        platform.
                    </Text>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        No organization deletions are possible without first deleting every store associated with that
                        organization. Note that the {BRAND_NAME} organization itself cannot be deleted.
                    </Text>
                    <DocumentationImage
                        src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-02-05/3cb7f5b7-196d-483b-9e1d-0bc1a299242a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=887,508&force_format=png&width=1120.0"
                        alt="Delete organization button"
                    />
                </>
            ) : null}
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
