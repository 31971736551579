import { Tooltip as ChakraTooltip, forwardRef } from "@chakra-ui/react";

// Unable to integrate the theme into the tooltip due to this issue: https://github.com/chakra-ui/chakra-ui/issues/4695
export const Tooltip = forwardRef(({ children, ...rest }, ref) => {
    return (
        <ChakraTooltip ref={ref} hasArrow bg={"tooltip-primary"} color={"text-light"} {...rest}>
            {children}
        </ChakraTooltip>
    );
});
