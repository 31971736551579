import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";
import { CreateOnboardingCustomerRequest } from "../../types/requests";

export const useOnboardCustomerMutation = () => {
    return useMutation({ mutationFn: onboardCustomer });
};

const onboardCustomer = async (newCustomer: CreateOnboardingCustomerRequest) => {
    const CREATE_CUSTOMER = `${SVC_URLS["auth"]}/onboard`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(newCustomer),
        headers: { Store: newCustomer.customerUrl },
    });
    const response = await fetch(CREATE_CUSTOMER, options).catch(handleErrors);

    return fetchResponse(response);
};
