import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Amplify, Hub } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import impulsumTheme from "./theme/impulsumTheme";
import "@fontsource/inter/400.css";
import Fonts from "./components/_ui/Fonts";
import { ThemeProvider as AmplifyThemeProvider, Theme as AmplifyTheme } from "@aws-amplify/ui-react";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { LoggedInUserSpanProcessor, setLoggedInUser } from "./lib/LoggedInUserSpanProcessor";

const provider = new WebTracerProvider({
    resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: "app-portal",
    }),
});

// First, add the processor that adds the user attribute
provider.addSpanProcessor(new LoggedInUserSpanProcessor());

// Then add the exporter processor
const otlpExporter = new OTLPTraceExporter({
    url: `${process.env.REACT_APP_OTEL_BASE_URL}/v1/traces`,
});

provider.addSpanProcessor(new BatchSpanProcessor(otlpExporter));
// provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

provider.register({
    // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
    contextManager: new ZoneContextManager(),
});

// Registering instrumentations
registerInstrumentations({
    instrumentations: [
        getWebAutoInstrumentations({
            // load custom configuration for xml-http-request instrumentation
            "@opentelemetry/instrumentation-xml-http-request": {
                clearTimingResources: true,
                propagateTraceHeaderCorsUrls: [
                    /.+/g, //Regex to match your backend urls. This should be updated.
                ],
            },
            "@opentelemetry/instrumentation-fetch": {
                propagateTraceHeaderCorsUrls: [
                    /.+/g, //Regex to match your backend urls. This should be updated.
                ],
            },
        }),
    ],
});

// Conditional ESM module loading (Node.js and browser)
// @ts-ignore: Property 'UrlPattern' does not exist
if (!globalThis.URLPattern) {
    import("urlpattern-polyfill")
        .then(() => {
            // The polyfill has been loaded, you can now use `URLPattern` in this module
        })
        .catch((err) => {
            // Handle any errors that occurred while loading the polyfill
            console.error("Failed to load URLPattern polyfill", err);
        });
}

const queryClient = new QueryClient();

const validateConfiguration = (configuration: any) => {
    Object.entries(configuration).forEach((config) => {
        if (!config[1]) {
            console.error(configuration);
            throw new Error(`AWS Cognito Configuration Missing: '${config[0]}: ${config[1]}'`);
        }
    });
};

const cognitoConfiguration = {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOLID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENTID,
};
validateConfiguration(cognitoConfiguration);
Amplify.configure(cognitoConfiguration);

Hub.listen("auth", ({ payload }) => {
    const { event, data } = payload;
    const { username } = data;

    if (event === "signIn") {
        setLoggedInUser(username);
    }

    if (event === "signOut") {
        setLoggedInUser("logged out");
    }
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

/**
 * Amplify Theme
 */
const theme: AmplifyTheme = {
    name: "my-theme",
    tokens: {
        colors: {
            brand: {
                primary: {
                    80: { value: "#011639" }, // nostra dark blue
                    90: { value: "#273b5a" },
                },
                // ...
            },
        },
    },
};

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ChakraProvider theme={impulsumTheme}>
                <Fonts />
                <ColorModeScript />
                <AmplifyThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <>
                          <App />
                          <ReactQueryDevtools initialIsOpen={false} />
                        </>
                    </QueryClientProvider>
                </AmplifyThemeProvider>
            </ChakraProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
