import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SucessHistoryCacheResponse } from "../../types";

export const useCacheHistory = (storeId: string, customerUrl: string, page: string, watchSelectedFilter: string) => {
    return useQuery(
        generateCacheHistoryQueryKey(storeId),
        async () => await getCacheHistory(storeId, customerUrl, page, watchSelectedFilter),
    );
};

const getCacheHistory = async (
    storeId: string,
    customerUrl: string,
    page: string,
    watchSelectedFilter: string,
): Promise<SucessHistoryCacheResponse> => {
    if (!watchSelectedFilter) {
        watchSelectedFilter = "usersApi";
    }
    const CACHE_HISTORY = `${SVC_URLS["cronos"]}/cache/success/${storeId}?page=${page}&filteredBy=${watchSelectedFilter}`;
    const options = await fetchOptions({
        headers: {
            Store: customerUrl,
        },
    });
    const response = await fetch(CACHE_HISTORY, options).catch(handleErrors);

    return fetchResponse(response);
};

export const generateCacheHistoryQueryKey = (storeId: string): string[] => ["cacheHistory", storeId];
