import { Text, VStack, Flex } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { EnableEdgeDeliveryButton, Header } from "../../components";
import { BRAND_NAME } from "../../config/BRAND_NAME";
import { useStore } from "../../hooks";
import { PageContainer } from "../PageContainer";

export const EdgeDisabledErrorPage = () => {
    const { data: store } = useStore();
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (store === undefined) return;
        if (store.edgeEnabled) {
            navigate(location.state.previousPath);
        }
    }, [store, navigate, location.state.previousPath]);

    if (store === undefined) {
        return <>Loading ...</>;
    }

    return (
        <PageContainer>
            <Flex
                alignItems={"stretch"}
                direction="column"
                height={"100%"}
                alignContent={"center"}
                justifyContent={"center"}
                flexGrow={"1"}
            >
                <Flex alignSelf={"stretch"} justifySelf={"stretch"} flexGrow={"1"}></Flex>
                <VStack direction="column" height={"50%"} flexGrow={"2"}>
                    <Header pb={"spacer-4"}>Oh No! 😲</Header>

                    <Text
                        textStyle={{
                            base: "text-body-meta-regular",
                            md: "text-body-display-S",
                            lg: "text-body-display-M",
                        }}
                        textAlign={"center"}
                    >
                        {BRAND_NAME} is currently turned off.
                    </Text>
                    <Text
                        textStyle={{
                            base: "text-body-meta-regular",
                            md: "text-body-display-S",
                            lg: "text-body-display-M",
                        }}
                        textAlign={"center"}
                    >
                        Turn {BRAND_NAME} services back on to use this feature.
                    </Text>

                    <EnableEdgeDeliveryButton
                        storeId={store.id}
                        customerUrl={store.customerUrl}
                        onSuccess={() => queryClient.invalidateQueries(["store", store.id])}
                    />
                </VStack>
            </Flex>
        </PageContainer>
    );
};
