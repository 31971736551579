import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { useParams } from "react-router-dom";
import { SingleShopifyApp } from "../../types";

export const useListSingleShopifyApp = () => {
    const { appId } = useParams();
    if (!appId) {
        throw new Error("storeId is required");
    }
    return useQuery(["singleShopifyApp", appId], async () => await listSingleShopifyApp(appId));
};

const listSingleShopifyApp = async (appId: string): Promise<SingleShopifyApp> => {
    const listSingleApp = `${SVC_URLS["ecom"]}/app/${appId}`;
    const options = await fetchOptions();
    const response = await fetch(listSingleApp, options).catch(handleErrors);
    return fetchResponse(response);
};
