import { PageContainer } from "../PageContainer";
import { Text, Box, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, Link } from "../../components";
import { ROUTES } from "../../Routes";
import { BRAND_NAME } from "../../config/BRAND_NAME";

export const GetHelpNowPage = () => {
    return (
        <PageContainer>
            <Header pb={"spacer-8"}>Troubleshoot</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                If you are visiting this page, it's likely that you have made site changes or are experiencing an issue
                and are looking for resources to help you maintain optimal performance and functionality with{" "}
                {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                We understand that bugs and issues can still arise even with the best technology. That's why we've put
                together this guide to provide you with tips and resources to help you troubleshoot any issues you may
                encounter, regardless of when they arise.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                <b>Select the option below that best describes your current situation</b> for instructions on how and
                when to use the tools {BRAND_NAME} has available:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    You made small changes to your site and need to{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                        clear your cache
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    You made large changes to your site and are unsure if you need to{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_WHEN_TO_CONTACT_NOSTRA}>
                        contact {BRAND_NAME} for reconfiguration
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    You are experiencing an issue and want to{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA}>
                        compare your site with and without {BRAND_NAME}
                    </Link>
                    —without disabling our services.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    You are experiencing an issue or emergency and need to{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TURN_OFF_ON}>
                        turn {BRAND_NAME}'s services off
                    </Link>{" "}
                    to conduct testing.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>
                &nbsp;if you have additional questions or need help with your debugging process. Our team will respond
                as soon as possible.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
