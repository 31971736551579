import { VStack, Heading, Table, Tbody, Th, Thead, Tr, Box, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { CacheDurationMap, DurationUnitType, Store } from "../../../types";
import AddItemForm from "./AddItemForm";
import Item from "./Item";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { convertToHighestUnit } from "./convertToHighestUnit";
import { CacheDurationInput } from "./types";
import { useAuthenticatedUser, useEnqueueDeploymentMutation, useStoreMutation } from "../../../hooks";
import { LoadingPage } from "../../LoadingPage";
import { amountOfTimeToSeconds } from "./amountOfTimeToSeconds";
import { CacheDurationHistoryLog } from "./CacheDurationHistoryLog";
import { getFeatureFlag } from "../../../lib";

dayjs.extend(duration);

type Props = {
    store: Store;
};
const EMPTY = "";

export const ManageCacheDurationTab = ({ store }: Props) => {
    const storeMutation = useStoreMutation(store.id ?? "", store.customerUrl ?? "");
    const showToast = useToast({ variant: "subtle", isClosable: true, duration: 5000 });
    const { isCloudOpsRole } = useAuthenticatedUser();
    const enqueueDeployment = useEnqueueDeploymentMutation({
        polling: false,
        onDeploymentStart: () => {
            setTimeout(() => {
                showToast({
                    status: "info",
                    title: "Deployment started",
                    description: `Deploying changes to ${store?.customerUrl} ...`,
                });
            }, 5000);
        },
    });
    const [items, setItems] = useState<CacheDurationInput[]>(cacheDurationMapToCacheInput(store.cacheDurationMap));
    const [isSaving, setIsSaving] = useState("");
    const [isAddingNewEntry, setIsAddingNewEntry] = useState<boolean>(false);
    useEffect(
        function updateItemsOnStoreChange() {
            if (store === undefined) return;
            const updatedItems = cacheDurationMapToCacheInput(store.cacheDurationMap);
            setItems((_prev) => [...updatedItems]);
        },
        [store],
    );

    // Loading
    if (store === undefined) {
        return <LoadingPage />;
    }

    const addItem = (item: CacheDurationInput) => {
        item.seconds = amountOfTimeToSeconds(item.amountOfTime, item.unitOfTime as DurationUnitType);
        const cacheDurationMap = mergeCacheDurationMapPrefixes(store, item);
        setIsAddingNewEntry(true);
        const request = { ...{ cacheDurationMap } };
        storeMutation.mutate(request, {
            onSuccess: () => {
                setIsAddingNewEntry(false);
                setItems((prev) => [...prev, item].sort(alphabetically)); // optimistically update the rows
                showToast({
                    status: "success",
                    title: "Success",
                    description: `Added custom cache duration for path: ${item.prefix}`,
                });
                enqueueDeployment.mutate(store.customerUrl);
            },
            onError: () => {
                showToast({
                    status: "error",
                    title: "Error",
                    description: `Failed to add cache duration for path ${item.prefix}. If this issue persists, please contact support.`,
                });
            },
        });
    };

    const deleteItem = (prefix: string) => {
        const { cacheDurationMap } = store;
        if (cacheDurationMap.prefixes === undefined) cacheDurationMap.prefixes = [];

        const index = cacheDurationMap.prefixes.findIndex((item) => item.prefix === prefix);
        // This should not happen
        if (index === -1) {
            showToast({
                status: "error",
                title: "Error",
                description: "Paths have become out of sync. Please refresh the page and try again.",
            });
            return;
        }

        setIsSaving(prefix);
        cacheDurationMap.prefixes.splice(index, 1); // remove the path
        storeMutation.mutate(
            { ...{ cacheDurationMap } },
            {
                onSuccess: () => {
                    setIsSaving(EMPTY);
                    setItems(items.filter((item) => item.prefix !== prefix)); // optimistically update the row
                    showToast({
                        status: "success",
                        title: "Success",
                        description: `Deleted custom cache duration for ${prefix}.`,
                    });
                    enqueueDeployment.mutate(store.customerUrl);
                },
                onError: () => {
                    showToast({
                        status: "error",
                        title: "Error",
                        description: `Failed to delete ${prefix}. If this issue persists, please contact support.`,
                    });
                },
            },
        );
    };

    const editItem = (prefix: string, newItem: CacheDurationInput) => {
        const { cacheDurationMap } = store;

        // update paths via prefix key
        cacheDurationMap.prefixes = cacheDurationMap.prefixes?.map((item) =>
            item.prefix === prefix ? inputToCacheDurationPrefix(newItem) : item,
        );

        // update root path
        if (prefix === "/") {
            cacheDurationMap.root = amountOfTimeToSeconds(newItem.amountOfTime, newItem.unitOfTime as DurationUnitType);
        }

        // update global path
        if (prefix === "__global__") {
            cacheDurationMap.__global__ = amountOfTimeToSeconds(
                newItem.amountOfTime,
                newItem.unitOfTime as DurationUnitType,
            );
        }

        const request = { ...{ cacheDurationMap } };
        setIsSaving(prefix);
        storeMutation.mutate(request, {
            onSuccess: () => {
                setIsSaving(EMPTY);
                showToast({
                    status: "success",
                    title: "Success",
                    description: `Cache duration for '${newItem.prefix}' has been updated.`,
                });
                enqueueDeployment.mutate(store.customerUrl);
            },
            onError: () => {
                showToast({
                    status: "error",
                    title: "Error",
                    description: `There was an error updating the cache duration for '${newItem.prefix}'`,
                });
            },
        });

        // Optimistically update the row
        setItems(items.map((item) => (item.prefix === prefix ? newItem : item)).sort(alphabetically));
    };

    return (
        <VStack width="100%" alignItems={"baseline"} pt="spacer-4" pb="spacer-16">
            <Box>
                <AddItemForm onAddItem={addItem} store={store} isAddingNewEntry={isAddingNewEntry} />

                <Heading pt={"spacer-8"} pb={"spacer-4"} as="h3" size="md">
                    Paths with custom cache durations:
                </Heading>

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th w="60%">Path</Th>
                            {isCloudOpsRole ? <Th width="5%">Seconds</Th> : null}
                            <Th minW={"250px"}>Duration</Th>
                            <Th minWidth="150px">Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {items.map((item) => {
                            return (
                                <Item
                                    key={item.prefix}
                                    item={item}
                                    onDelete={deleteItem}
                                    onEdit={editItem}
                                    isSaving={isSaving}
                                />
                            );
                        })}
                    </Tbody>
                </Table>

                {getFeatureFlag("CACHE_SETTINGS_HISTORY") ? (
                    <CacheDurationHistoryLog customerUrl={store.customerUrl} />
                ) : null}
            </Box>
        </VStack>
    );
};

/**
 * Transform the cache duration map to a format that can be used in the form
 */
const cacheDurationMapToCacheInput = (cacheDurationMap: CacheDurationMap): CacheDurationInput[] => {
    const global = {
        prefix: "__global__",
        seconds: cacheDurationMap.__global__,
        unitOfTime: convertToHighestUnit(cacheDurationMap.__global__).unit,
        amountOfTime: convertToHighestUnit(cacheDurationMap.__global__).amount.toString(),
    };

    const root = {
        prefix: "/",
        seconds: cacheDurationMap.root ?? cacheDurationMap.__global__,
        unitOfTime: convertToHighestUnit(cacheDurationMap.root ?? cacheDurationMap.__global__).unit,
        amountOfTime: convertToHighestUnit(cacheDurationMap.root ?? cacheDurationMap.__global__).amount.toString(),
    };

    const currentPrefixes = cacheDurationMap.prefixes ?? [];
    const prefixes: CacheDurationInput[] = currentPrefixes.map(({ prefix, duration }) => {
        return {
            prefix,
            seconds: duration,
            unitOfTime: convertToHighestUnit(duration).unit,
            amountOfTime: convertToHighestUnit(duration).amount.toString(),
        };
    });

    return [root, ...prefixes.sort(alphabetically), global];
};

/**
 * Sort the cache duration items alphabetically, and with '/' first and '__global__' last
 */
const alphabetically = (a: CacheDurationInput, b: CacheDurationInput) => {
    // Ensure that items with a prefix of '/' come first
    if (a.prefix === "/" && b.prefix !== "/") return -1;
    if (b.prefix === "/" && a.prefix !== "/") return 1;

    // Ensure that items with a prefix of '__global__' come last
    if (a.prefix === "__global__" && b.prefix !== "__global__") return 1;
    if (b.prefix === "__global__" && a.prefix !== "__global__") return -1;

    // Fallback to alphabetical comparison for all other items
    return a.prefix.localeCompare(b.prefix);
};

const inputToCacheDurationPrefix = (item: CacheDurationInput) => ({
    prefix: item.prefix,
    duration: amountOfTimeToSeconds(item.amountOfTime, item.unitOfTime as DurationUnitType),
});

const mergeCacheDurationMapPrefixes = (
    store: Store & { cacheDurationMap: CacheDurationMap },
    item: CacheDurationInput,
): CacheDurationMap => {
    const { cacheDurationMap } = store;
    const existingPrefixes = cacheDurationMap.prefixes ?? [];
    const newPrefix = inputToCacheDurationPrefix(item);
    // update paths with the new prefix
    cacheDurationMap.prefixes = existingPrefixes ? [...existingPrefixes, ...[newPrefix]] : [newPrefix];
    return cacheDurationMap;
};
