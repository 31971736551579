import { Modal, ModalOverlay, ModalHeader, ModalContent, ModalBody, ModalCloseButton, Text } from "@chakra-ui/react";

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    message: string;
};

export const MessageDisplayModal = ({ isOpen, onClose, message }: ModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="3xl"
            scrollBehavior="inside"
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Message</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>{<pre>{message}</pre>}</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
