import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useCloudFlareZone = (customerUrl: string, zoneId: string = "", queryOptions = {}) => {
    return useQuery<CloudFlareZoneResponse | undefined>(
        ["cloudflareZone", zoneId],
        getCloudFlareZone(customerUrl, zoneId),
        queryOptions,
    );
};

const getCloudFlareZone =
    (customerUrl: string, zoneId: string) => async (): Promise<CloudFlareZoneResponse | undefined> => {
        if (!zoneId) return;

        const options = await fetchOptions({
            headers: {
                Store: customerUrl,
            },
        });
        const response = await fetch(`${SVC_URLS.portal}/cloudflare/zone/${zoneId}`, options).catch(handleErrors);
        return fetchResponse(response);
    };

type ZoneId = string;
type CloudFlareZoneResponse = {
    id: ZoneId;
    verificationKey: string | null;
};
