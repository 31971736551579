type IframeReference = React.RefObject<HTMLIFrameElement> | null;

type SharedRefs = {
    iframeRef: IframeReference;
    isReady: (ref: IframeReference) => boolean;
};

const isReady = (ref: IframeReference): boolean => {
    const message = "Error: iFrame is not ready.";
    const isNull = ref === null || ref.current === null;
    const isIframe = !isNull && ref.current.localName === "iframe";

    if (isNull) throw new Error(message);

    if (isIframe && ref.current.contentWindow === null) throw new Error(message);

    return true;
};

/**
 * Pragmatic dependency injection for the iframeRef
 */
export const sharedRefs: SharedRefs = {
    iframeRef: null,
    isReady: isReady,
};
