import { PageContainer } from "./PageContainer";
import {
    VStack,
    HStack,
    Avatar,
    Box,
    Flex,
    Divider,
    useToast,
    Link,
    useDisclosure,
    Badge,
    Text,
} from "@chakra-ui/react";
import { Header, SubHeader, Tooltip, Search } from "../components";
import { useListAllOrganizations, useAuthenticatedUser } from "../hooks";
import { useEffect, useState, useMemo } from "react";
import { LoadingPage } from "./LoadingPage";
import { ROUTES } from "../Routes";
import { useNavigate, generatePath } from "react-router-dom";
import { DeleteOrgAlertDialog } from "./OrganizationPage/DeleteOrgAlertDialog";
import { Organization } from "../types";
import { formatDateTimeDisplay } from "../lib";

const searchFilterFunction = (org: Organization, searchTerm: string) => org.name.toLowerCase().includes(searchTerm);

export const ListOrganizationsPage = () => {
    const [filteredOrgs, setFilteredOrgs] = useState<Organization[]>([]);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const getAllOrganizations = useListAllOrganizations();
    const { data: organizations, isError, isLoading } = getAllOrganizations;
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [orgId, setOrgId] = useState("");
    const { orgId: primaryOrgId } = useAuthenticatedUser();

    useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });

    const showToast = useToast();

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description:
                    "An unexpected error has occurred. If the problem persists, please contact customer success.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    const primaryOrgName = useMemo(() => {
        const orgName = organizations?.find((org: Organization) => org.id === primaryOrgId)?.name ?? "Undefined";
        return orgName;
    }, [organizations, primaryOrgId]);

    if (isLoading) return <LoadingPage />;
    if (!organizations) return <SubHeader>No organizations were found</SubHeader>;

    const handleDeleteOrganization = (id: string) => {
        onOpen();
        setOrgId(id);
    };

    return (
        <PageContainer style={{ display: "flex", flexDirection: "column" }}>
            <HStack align={"baseline"} pb={"spacer-6"}>
                <VStack alignItems={"baseline"} width="100%">
                    <Header>Manage Organizations</Header>
                    <SubHeader pb={{ base: "spacer-1", md: "spacer-0" }} mr="auto">
                        Primary Organization:&nbsp;
                        <Badge colorScheme={"green"}>{primaryOrgName}</Badge>
                    </SubHeader>
                </VStack>
                <Search
                    data={organizations}
                    setFilteredData={setFilteredOrgs}
                    filterFunction={searchFilterFunction}
                    setSearchIsLoading={setSearchIsLoading}
                />
            </HStack>
            {searchIsLoading ? (
                <LoadingPage />
            ) : filteredOrgs.length !== 0 ? (
                filteredOrgs.map((organization: Organization) => (
                    <Flex
                        key={organization.id}
                        padding={"spacer-4"}
                        border={"1px"}
                        borderColor={"gray.200"}
                        borderRadius={"0.75rem"}
                        width={"100%"}
                        marginBottom={"spacer-2"}
                    >
                        <Avatar name={organization.name} />
                        <Box ml="3">
                            <Text fontWeight="bold">{organization.name}</Text>
                            <Text fontSize="sm" textOverflow={"ellipsis"} overflow="hidden" paddingY={"spacer-2"}>
                                <b>Email:</b> {organization.onboardingEmail}
                            </Text>
                            <Text fontSize="sm" textOverflow={"ellipsis"} overflow="hidden" paddingBottom={"spacer-2"}>
                                <b>Onboarded:</b> {formatDateTimeDisplay(organization.createdAt)}
                            </Text>
                            <HStack height={{ base: "30%", lg: "15px" }} width="100%">
                                <Tooltip label="Manage Organization" aria-label="Manage Organization">
                                    <Link
                                        textStyle={{
                                            base: "text-link-meta-regular",
                                            md: "text-link-meta-regular",
                                        }}
                                        onClick={() => {
                                            navigate(generatePath(ROUTES.ORGANIZATION, { orgId: organization.id }));
                                        }}
                                    >
                                        manage
                                    </Link>
                                </Tooltip>
                                <Divider orientation={"vertical"} />
                                <Tooltip label="Delete Organization" aria-label="Delete Organization">
                                    <Link
                                        textStyle={{
                                            base: "text-link-meta-regular",
                                            md: "text-link-meta-regular",
                                        }}
                                        onClick={() => handleDeleteOrganization(organization.id)}
                                    >
                                        delete
                                    </Link>
                                </Tooltip>
                            </HStack>
                        </Box>
                    </Flex>
                ))
            ) : (
                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <SubHeader>No organizations found</SubHeader>
                </div>
            )}
            <DeleteOrgAlertDialog isDeleteOpen={isOpen} onDeleteClose={onClose} orgId={orgId} />
        </PageContainer>
    );
};
