import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const KeyRevenueMetric = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Key Revenue Metric`}
            />
            <Header pb={"spacer-8"}>Key Revenue Metric</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As mentioned in the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_TRADITIONAL}>
                    Traditional Metrics
                </Link>
                &nbsp;section, analyzing revenue while assessing {BRAND_NAME} should involve excluding discounts,
                returns, etc., as those additions are unrelated to the buying experience affected by speed.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Gross Market Value
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Gross market value (GMV) provides the purest perspective on revenue as it considers the gross value of
                orders and excludes factors like shipping and taxes.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With GMV, you can examine not only the gross value but also:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>What your customers paid when they initially selected "buy"</ListItem>
                <ListItem pb={"spacer-6"}>How post-selling and upselling influence your revenue</ListItem>
            </UnorderedList>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Forbes shared the{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.forbes.com/advisor/business/software/website-statistics/"}
                        target="new"
                    >
                        Top Website Statistics For 2024
                    </Link>
                    , which included the following data from Visual Capitalist:{" "}
                    <strong>
                        "Websites that load slowly cost retailers $2.6 billion US dollars in sales each year."
                    </strong>
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Amazon conducted a{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.conductor.com/academy/page-speed-resources/faq/amazon-page-speed-study/"}
                    target="new"
                >
                    study
                </Link>{" "}
                that also supports the benefits of speed on revenue, sharing that{" "}
                <strong>for every 100 milliseconds in page load delay, there is a 1% cost in revenue.</strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                GMV is {BRAND_NAME}'s preferred revenue metric as it makes the most statistical sense with the impacts
                of speed. Expect to see your revenue positively influenced when using {BRAND_NAME} without the variance
                created by shipping rates, discounts, taxes, and more.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
