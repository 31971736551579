import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Heading,
    Spacer,
    Flex,
    FormControl,
    FormHelperText,
    Input,
    Select,
    Link,
    useToast,
    useDisclosure,
} from "@chakra-ui/react";
import { FieldError, Label, Tooltip } from "../../components";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuthenticatedUser, useEditUserMutation, generateUsersQueryKey } from "../../hooks";
import { User, EditUserRequest } from "../../types";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    user: User;
};

export const EditUserModal = ({ user }: Props) => {
    const { onOpen: onEditUserOpen, onClose: onEditUserClose, isOpen: isEditUserOpen } = useDisclosure();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const editUserMutation = useEditUserMutation();
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const queryClient = useQueryClient();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<EditUserFormData>();

    const onEditUserSuccess = () => {
        queryClient.invalidateQueries(generateUsersQueryKey(user.orgId));
        showToast({
            title: "Success",
            description: "You have successfully updated this user's information.",
            status: "success",
        });
        onEditUserClose();
    };

    const onEditUserError = () => {
        showToast({
            title: "Error",
            description:
                "There was an error updating this user's information. Please try again in a few moments. If the problem persists, please contact support.",
            status: "error",
        });
    };

    const onSubmit: SubmitHandler<EditUserFormData> = async (formData) => {
        if (!user.id) return;
        const requestBody: EditUserRequest = formData;
        editUserMutation.mutate(
            { requestBody, userId: user.id },
            {
                onSuccess: onEditUserSuccess,
                onError: onEditUserError,
            },
        );
    };

    return (
        <>
            <Tooltip label="Update" aria-label="Update user information">
                <Link
                    textStyle={{
                        base: "text-link-meta-regular",
                        md: "text-link-meta-regular",
                    }}
                    onClick={onEditUserOpen}
                >
                    update
                </Link>
            </Tooltip>
            <Modal
                isCentered
                size={"3xl"}
                isOpen={isEditUserOpen}
                onClose={onEditUserClose}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading as="h2" size="md">
                            Update User
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl maxWidth={{ md: "100%" }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Label htmlFor="name">Name</Label>
                                <Input
                                    id="name"
                                    type="name"
                                    isRequired
                                    defaultValue={user.name}
                                    {...register("name", {
                                        required: "Name is required",
                                    })}
                                />
                                <FieldError>{errors.name?.message}</FieldError>
                                <Label htmlFor="email">Email</Label>
                                <Input id="email" type="email" isDisabled mb="spacer-4" defaultValue={user.email} />
                                <FormHelperText as="b">
                                    Our system manages profiles via email, so your email cannot be updated. To change
                                    your email, you will need to create a new user profile and delete your current
                                    profile.
                                </FormHelperText>
                                <FieldError></FieldError>
                                <Label htmlFor="phone">Phone</Label>
                                <Input
                                    id="phone"
                                    type="phone"
                                    placeholder="Phone (Optional)"
                                    defaultValue={user.phone}
                                    {...register("phone")}
                                />
                                <FieldError></FieldError>
                                <Label htmlFor="role">Role</Label>
                                <Select id="role" isRequired defaultValue={user.role} {...register("role")}>
                                    <option value="Admin">Store Administrator</option>
                                    <option value="Owner">Organization Owner</option>
                                    {isCloudOpsRole && <option value="CloudOps">CloudOps - Super User</option>}
                                </Select>
                                <FieldError></FieldError>
                                <Flex pb="spacer-6">
                                    <Spacer />
                                    <Button
                                        variant="outline"
                                        colorScheme="button-primary"
                                        onClick={onEditUserClose}
                                        mr={3}
                                    >
                                        Cancel
                                    </Button>
                                    <Button loadingText="Updating User" type="submit" colorScheme="button-primary">
                                        Submit
                                    </Button>
                                </Flex>
                            </form>
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

type EditUserFormData = {
    phone: string;
    name: string;
    role: "Admin" | "Owner" | "CloudOps" | "Internal";
};
