import { useMemo, useCallback, useEffect } from "react";
import type { ChangeEvent } from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { IconSearch, IconX } from "@tabler/icons-react";
import debounce from "lodash.debounce";
import { Tooltip } from "./";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const INPUT_SEARCH_DELAY_MILLISECONDS = 500;

type SearchProps = {
    data: any[] | undefined;
    setFilteredData: (data: any[]) => void;
    filterFunction: (item: any, searchTerm: string) => boolean;
    setSearchIsLoading: (isLoading: boolean) => void;
};

const getSearchTermFromUrl = (): string => new URLSearchParams(window.location.search).get("search") ?? "";

export const Search = ({ data, setFilteredData, filterFunction, setSearchIsLoading }: SearchProps) => {
    const navigate = useNavigate();
    const { register, resetField, watch, getValues } = useForm({
        defaultValues: {
            searchTerm: getSearchTermFromUrl(),
        },
    });

    const updateQueryParams = useCallback(
        (searchTerm: string) => {
            navigate({
                search: searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : "",
            });
        },
        [navigate],
    );

    const filterData = useCallback(
        (data: any[], searchTerm: string) => {
            return new Promise<any[]>((resolve) => {
                setSearchIsLoading(true);
                setTimeout(() => {
                    const filteredSearch = data.filter((item) => filterFunction(item, searchTerm));

                    if (filteredSearch.length === 0) {
                        setSearchIsLoading(false);
                        resolve([]);
                    }

                    updateQueryParams(searchTerm);

                    setSearchIsLoading(false);
                    resolve(filteredSearch);
                }, 0);
            });
        },
        [updateQueryParams, setSearchIsLoading, filterFunction],
    );

    useEffect(
        function filterWhenStoresUpdated() {
            if (data === undefined) return;

            if (getValues("searchTerm") === "") {
                setFilteredData(data);
            } else {
                filterData(data, getValues("searchTerm")).then(setFilteredData);
            }
        },
        [data, filterData, setFilteredData, getValues],
    );

    const watchedSearchTerm = watch("searchTerm");

    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                if (data === undefined) return;

                if (searchTerm === "") {
                    setFilteredData(data);
                }

                filterData(data, searchTerm.toLocaleLowerCase()).then(setFilteredData);
            }, INPUT_SEARCH_DELAY_MILLISECONDS),
        [filterData, data, setFilteredData],
    );

    const handleUserSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => debouncedSearch(e.target.value),
        [debouncedSearch],
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") handleClearSearch();
        if (e.key === "Enter") e.preventDefault();
    };

    const handleClearSearch = () => {
        if (data === undefined) return;
        resetField("searchTerm", { defaultValue: "" });
        updateQueryParams("");
        setFilteredData(data);
    };

    return (
        <form style={{ width: "50%", display: "infline-flex" }}>
            <InputGroup size="md">
                <Input
                    aria-label="Search by URL"
                    id="searchTerm"
                    {...register("searchTerm", {
                        onChange: (event) => handleUserSearch(event),
                    })}
                    placeholder="Search"
                    onKeyDown={handleKeyDown}
                />
                <InputRightElement width="4.5rem">
                    {watchedSearchTerm.length === 0 ? (
                        <IconSearch stroke={"1.25"} />
                    ) : (
                        <Tooltip label={"Clear search input"} aria-label={"Clear search input"}>
                            <IconX stroke={"1.25"} onClick={handleClearSearch} cursor="pointer" />
                        </Tooltip>
                    )}
                </InputRightElement>
            </InputGroup>
        </form>
    );
};
