import { HStack, Button, TableContainer, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { DataTable, Header } from "../../components";
import { useListBulletin } from "../../hooks";
import { ROUTES } from "../../Routes";
import { PageContainer } from "../PageContainer";
import { Bulletin } from "../../types";
import { LoadingPage } from "../LoadingPage";
import { BulletinRowActions } from "./BulletinRowActions";
import { formatDateTimeDisplay } from "../../lib";

export const ListBulletinsPage = () => {
    const { data: bulletins, isLoading } = useListBulletin();
    const navigate = useNavigate();

    const columns = useMemo(() => buildColumns(), []);

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <HStack justifyContent="space-between" pb={"spacer-6"}>
                <Header>Bulletins</Header>
                <Button
                    colorScheme={"button-primary"}
                    size="sm"
                    onClick={() => {
                        navigate(ROUTES.CREATE_BULLETIN);
                    }}
                >
                    Create
                </Button>
            </HStack>
            {bulletins === undefined || bulletins.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-body-meta-regular",
                        md: "text-body-meta-medium",
                    }}
                >
                    No Bulletins Found!
                </Text>
            ) : (
                <TableContainer>
                    <DataTable columns={columns} data={bulletins} />
                </TableContainer>
            )}
        </PageContainer>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<Bulletin>();
    const columns = [
        columnHelper.display({
            id: "actions",
            cell: (props) => <BulletinRowActions bulletinId={props.row.original.id} />,
            header: "Actions",
        }),
        columnHelper.accessor("type", {
            cell: (props) => {
                return props.getValue();
            },
            header: "Type",
        }),
        columnHelper.accessor("title", {
            cell: (props) => {
                return props.getValue();
            },
            header: "Title",
        }),
        columnHelper.accessor("publishAt", {
            cell: (props) => {
                return formatDateTimeDisplay(props.getValue());
            },
            header: "Publish Date",
        }),
        columnHelper.accessor("unpublishAt", {
            cell: (props) => {
                return formatDateTimeDisplay(props.getValue());
            },
            header: "Unpublish Date",
        }),
    ];
    return columns;
};
