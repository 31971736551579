import { getIdToken } from "../auth/getIdToken";

/**
 * Fetch options for JSON requests with an Authorization header for Nostra services.
 */
export const fetchOptions = async (options: RequestInit = {}): Promise<RequestInit> => {
    const idToken = await getIdToken();
    const defaultOptions: DefaultFetchOptions = {
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
    };

    if (options.body !== undefined) {
        defaultOptions.headers["Content-Type"] = "application/json";
    }

    const merged = {
        ...defaultOptions,
        ...options,
        headers: {
            ...defaultOptions.headers,
            ...options.headers,
        },
    };

    return merged;
};

type DefaultFetchOptions = {
    headers: {
        Authorization: string;
        "Content-Type"?: "application/json" | undefined;
    };
};
