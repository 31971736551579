import { PageContainer } from "../../PageContainer";
import { Text, Box, Code, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const URLPathsPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Understanding URL Paths"}
            />
            <Header pb={"spacer-8"}>Understanding URL Paths</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                URL paths, or Uniform Resource Locator paths, are components of a web address that specify the location
                of a particular resource on the internet. They are commonly used to navigate across and/or access
                specific pages or resources within a website.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A URL path typically follows the domain name in a URL and is separated by slashes (<Code>/</Code>). Each
                path segment represents a different level of hierarchy or directory within the website's structure.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For example, consider the URL: <Code>https://www.example.com/products/electronics/laptops</Code>.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Code>https://</Code> is the protocol.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>www.example.com</Code> is the domain name.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Code>/products/electronics/laptops</Code> is the URL path.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To further understand a URL path, consider the following breakdown:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Code>/products</Code> is the top-level directory.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>/electronics</Code> is a subdirectory within products.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Code>/laptops</Code> is a subdirectory within electronics.{" "}
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                eCommerce stores use URL paths for various reasons, including allowing customers to navigate a site
                easily, facilitating SEO optimization, enabling campaign tracking, and more.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By leveraging these URL paths, {BRAND_NAME} offers a valuable resource to improve your site speed
                further by&nbsp;
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CACHE_SETTINGS} target="new">
                    customizing your cache duration
                </Link>{" "}
                to fit a single page's needs.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
