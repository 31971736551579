import { useParams, generatePath, useNavigate } from "react-router-dom";
import { FieldError, FieldSet, Header, Label, SubHeader } from "../../components";
import { PageContainer } from "../PageContainer";
import { useBulletin, useUpdateBulletinMutation } from "../../hooks";
import { LoadingPage } from "../LoadingPage";
import { Button, Flex, FormControl, Input, Select, Spacer, useToast, VStack, Text } from "@chakra-ui/react";
import { Bulletin, BulletinFormData } from "../../types";
import { useForm } from "react-hook-form";
import { formatDateInputField, formatDateTimeToISO } from "../../lib";
import { ROUTES } from "../../Routes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { BRAND_FOUNDING_MIN_DATE } from "../../config/BRAND_NAME";

export const EditBulletinPage = () => {
    const { bulletinId } = useParams<{ bulletinId: string }>();
    const { data: bulletin, isLoading } = useBulletin(bulletinId ?? "");

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer>
            <VStack alignItems={"baseline"}>
                <Header>Edit Bulletin</Header>
                <SubHeader>{bulletin?.title}</SubHeader>
            </VStack>
            <EditBulletinForm bulletin={bulletin} />
        </PageContainer>
    );
};

export const EditBulletinForm = ({ bulletin }: { bulletin: Bulletin }) => {
    const defaultValues = buildDefaultValues(bulletin);
    const updateBulletin = useUpdateBulletinMutation();
    const navigate = useNavigate();
    const showToast = useToast({ duration: 5000, isClosable: true, variant: "subtle" });
    const queryClient = useQueryClient();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm<BulletinFormData>({ defaultValues });

    useEffect(() => {
        register("description");
    }, [register]);

    const onDescriptionChange = (content: string) => {
        setValue("description", content);
    };

    const handleUpdateBulletin = (formData: BulletinFormData) => {
        const { type, title, description, publishAt, unpublishAt } = formData;
        const request: BulletinFormData = {
            type,
            title,
            description,
            publishAt: formatDateTimeToISO(publishAt),
            unpublishAt: formatDateTimeToISO(unpublishAt),
        };

        const onSuccess = () => {
            queryClient.invalidateQueries({ queryKey: ["bulletin", bulletin.id] });
            navigate(generatePath(ROUTES.LIST_BULLETINS_PAGE));
            showToast({
                title: "Success",
                description: "Bulletin updated successfully.",
                status: "success",
            });
        };
        const onError = () => {
            showToast({
                title: "Error",
                description: "An error occurred while updating the bulletin. If this error persists, contact support.",
                status: "error",
            });
        };

        updateBulletin.mutate(request, { onSuccess, onError });
    };

    const richTextToolbarSettings = [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
    ];

    return (
        <FormControl>
            <form onSubmit={handleSubmit(handleUpdateBulletin)}>
                <FieldSet legend="Bulletin Details">
                    <Label htmlFor="type">Type</Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Select the bulletin type which determines the styling for the bulletin when displayed.
                    </Text>
                    <Select {...register("type")} placeholder="Select Type" isRequired={true}>
                        <option value="info">Informational</option>
                        <option value="warning">Warning</option>
                        <option value="danger">Danger</option>
                    </Select>
                    <FieldError />
                    <Label htmlFor="title">Title</Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Enter the title of the bulletin using plain text.
                    </Text>
                    <Input
                        width={"100%"}
                        placeholder="Enter a title"
                        defaultValue={bulletin.title}
                        {...register("title", { required: { value: true, message: "Title is required" } })}
                    />
                    <FieldError>{errors.title?.message}</FieldError>
                    <Label htmlFor="description">Body</Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        Enter the description of the bulletin using the rich text editor. Please note that{" "}
                        <strong>links should be underlined</strong> so they are clearly visible.
                    </Text>
                    <ReactQuill
                        defaultValue={defaultValues.description}
                        theme="snow"
                        onChange={onDescriptionChange}
                        modules={{ toolbar: richTextToolbarSettings }}
                    ></ReactQuill>
                    <FieldError>{errors.description?.message}</FieldError>

                    <Label htmlFor="publishAt">Publish At</Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        The bulletin will be visible to users from this date and time. To publish the bulletin
                        immediately, select today's date and the current time.
                    </Text>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        defaultValue={formatDateInputField(bulletin.publishAt)}
                        {...register("publishAt")}
                        min={BRAND_FOUNDING_MIN_DATE}
                    />
                    <FieldError>{errors.publishAt?.message}</FieldError>

                    <Label htmlFor="unpublishAt">Unpublish At</Label>
                    <Text mb={2} textStyle="text-body-meta-regular">
                        The bulletin will no longer be visible to users after this date and time.
                    </Text>
                    <Input
                        width={{ base: "100%", md: "25%" }}
                        minWidth={{ md: "15rem" }}
                        type="datetime-local"
                        defaultValue={formatDateInputField(bulletin.unpublishAt)}
                        {...register("unpublishAt")}
                        min={BRAND_FOUNDING_MIN_DATE}
                    />
                    <FieldError>{errors.unpublishAt?.message}</FieldError>
                </FieldSet>
                <Flex pb="spacer-6">
                    <Spacer />
                    <Button isLoading={updateBulletin.isLoading} colorScheme={"button-primary"} type="submit">
                        Submit
                    </Button>
                </Flex>
            </form>
        </FormControl>
    );
};

const buildDefaultValues = (bulletin: Bulletin) => ({
    type: bulletin.type,
    title: bulletin.title,
    publishAt: formatDateInputField(bulletin.publishAt),
    unpublishAt: formatDateInputField(bulletin.unpublishAt),
    description: bulletin.description,
});
