import { Input, Text, Button, HStack, Select, InputGroup, InputLeftAddon, VStack, Spacer } from "@chakra-ui/react";
import { FieldError, FieldSet, Link } from "../../../components";
import { type CacheDurationInput } from "./types";
import { CacheDurationMap, DurationUnitType, Store } from "../../../types";
import { FieldErrors, SubmitHandler, useForm } from "react-hook-form";
import { amountOfTimeToSeconds } from "./amountOfTimeToSeconds";
import { transformUrlToPath } from "../../../lib/transformUrlToPath";
import { ROUTES } from "../../../Routes";
import { unitOfTimeLabel } from "./unitOfTimeLabel";

type Props = {
    onAddItem: (item: CacheDurationInput) => void;
    store: Store & { cacheDurationMap: CacheDurationMap };
    isAddingNewEntry: boolean;
};

type AddCustomCacheDurationFormData = {
    path: string;
    amountOfTime: string;
    unitOfTime: string;
};

const defaultValues = {
    path: "",
    amountOfTime: "2",
    unitOfTime: "week",
};

const AddItemForm = ({ onAddItem, store, isAddingNewEntry }: Props) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        setValue,
    } = useForm<AddCustomCacheDurationFormData>({ defaultValues });

    const submitForm: SubmitHandler<AddCustomCacheDurationFormData> = (data) => {
        const cacheDurationInput = {
            prefix: data.path,
            seconds: amountOfTimeToSeconds(data.amountOfTime, data.unitOfTime as DurationUnitType),
            unitOfTime: data.unitOfTime as DurationUnitType,
            amountOfTime: data.amountOfTime,
        };

        onAddItem(cacheDurationInput);
        reset(); // reset the form
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <FieldSet legend="Add a custom cache duration">
                <Text pb={"spacer-8"}>
                    The cache duration controls the length of time a page exists in the cache and can be controlled on
                    an individual or group of pages level based on the path from which the document is served. The
                    duration is the number of time these paths should be cached before expiration. For more detailed
                    information on this, please refer to our{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>
                        Custom Cache Settings documentation
                    </Link>
                    .
                </Text>
                <VStack width={"100%"} align="left">
                    <HStack width="800px">
                        <Text minWidth={"80px"}>The path:</Text>
                        <InputGroup>
                            <InputLeftAddon>https://{store.customerUrl}</InputLeftAddon>
                            <Input
                                onPaste={transformUrlToPath((path) => setValue("path", path))}
                                {...register("path", {
                                    required: {
                                        value: true,
                                        message: "Path is required",
                                    },
                                    validate: (value: string) => {
                                        const { prefixes } = store.cacheDurationMap ?? [];

                                        const exists = prefixes?.find((prefix) => prefix.prefix === value);

                                        if (exists) {
                                            return `"${value}" already exists. To change the cache duration, edit the value below`;
                                        }

                                        if (value === "__global__") {
                                            return "Cannot use '__global__' as a path";
                                        }

                                        if (value === "/") {
                                            return "Homepage already exists, edit the value below to change the cache duration";
                                        }

                                        if (!value.startsWith("/")) {
                                            return "All paths must start with '/'";
                                        }

                                        return true;
                                    },
                                })}
                                placeholder="/products/example-t-shirt"
                            />
                        </InputGroup>
                    </HStack>
                    <HStack width="800px">
                        <Text>will exist in the cache for:&nbsp;</Text>
                        <Input
                            {...register("amountOfTime", {
                                required: {
                                    value: true,
                                    message: "Please enter the amount of time the path should be cached",
                                },
                                validate: (value) => {
                                    if (value === "0") {
                                        return "The amount of time the path should be cached must be greater than 0";
                                    }
                                },
                            })}
                            type="number"
                            width="50px"
                        />
                        <Select
                            {...register("unitOfTime", {
                                required: {
                                    value: true,
                                    message: "Please select the unit of time",
                                },
                            })}
                            maxWidth="120px"
                            required
                        >
                            {unitOfTimeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                        <Spacer />
                        <Button
                            isLoading={isAddingNewEntry}
                            colorScheme="button-primary"
                            type="submit"
                            paddingX={"20px"}
                        >
                            Add
                        </Button>
                    </HStack>
                    <FieldError>{firstError(errors)}</FieldError>
                </VStack>
            </FieldSet>
        </form>
    );
};

const firstError = (errors: FieldErrors<AddCustomCacheDurationFormData>) => {
    const key = Object.keys(errors).find(
        (key) => errors[key as keyof FieldErrors<AddCustomCacheDurationFormData>]?.message,
    );
    return errors[key as keyof FieldErrors<AddCustomCacheDurationFormData>]?.message;
};

type UnitOfTimeOption = {
    value: DurationUnitType;
    label: string;
};

export const unitOfTimeOptions: UnitOfTimeOption[] = [
    {
        value: DurationUnitType.Week,
        label: unitOfTimeLabel(DurationUnitType.Week),
    },
    {
        value: DurationUnitType.Day,
        label: unitOfTimeLabel(DurationUnitType.Day),
    },
    {
        value: DurationUnitType.Hour,
        label: unitOfTimeLabel(DurationUnitType.Hour),
    },
    {
        value: DurationUnitType.Minute,
        label: unitOfTimeLabel(DurationUnitType.Minute),
    },
    {
        value: DurationUnitType.Second,
        label: unitOfTimeLabel(DurationUnitType.Second),
    },
];

export default AddItemForm;
