import { useMutation } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { User, CreateUserRequest } from "../../types";

export const useCreateUserMutation = () => {
    return useMutation((requestBody: CreateUserRequest) => createUser(requestBody));
};

const createUser = async (requestBody: CreateUserRequest): Promise<User> => {
    const CREATE_USER = `${SVC_URLS["auth"]}/user`;
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(requestBody),
    });
    const response = await fetch(CREATE_USER, options).catch(handleErrors);

    return fetchResponse(response);
};
