import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useStore } from "..";
import { SVC_URLS } from "../../config/SVC_URLS";
import { Store } from "../../types";
import { fetchOptions, handleErrors, fetchResponse, queryParameters } from "../../lib";

export const useCustomerPerformanceReport = (reportParams: any) => {
    const storeQuery = useStore();
    const { store } = useData(storeQuery);

    const params = queryParameters(reportParams);

    const enabled = store !== undefined;

    return useQuery({
        queryKey: ["performance-reports", store?.id, params],
        queryFn: () => fetchReports(store, params),
        enabled,
        refetchOnWindowFocus: false,
    });
};

const fetchReports = async (store: Store | undefined, params: string) => {
    const endpoint = `${SVC_URLS["portal"]}/reports/customer/?${params}`;
    const options = await fetchOptions({
        headers: { Store: store!.customerUrl },
    });
    const response = await fetch(endpoint, options).catch(handleErrors);
    return fetchResponse(response);
};

const useData = (storeQuery: UseQueryResult<Store, unknown>) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const store = storeQuery.data;
    const isLoading = storeQuery.isLoading;
    const isError = storeQuery.isError;

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description:
                    "There was an error loading this page. Contact support if the problem persists.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    return {
        store,
        isLoading,
    };
};
