import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useRef } from "react";

type Props = PropsWithChildren<{
    isOpen: boolean;
    isLoading?: boolean;
    onClick?: () => void;
    onClose: () => void;
    onAction: () => void;
    confirmation: {
        title: string;
        description: string | JSX.Element;
    };
    buttonStyle?: string;
    innerButtonText?: string;
}>;

export const ModalButton = ({
    children,
    isOpen,
    onClose,
    onAction,
    confirmation,
    buttonStyle,
    innerButtonText,
    ...rest
}: Props) => {
    const cancelRef = useRef(null);

    return (
        <>
            <Button {...rest} colorScheme={"button-primary"} variant={"outline"}>
                {children}
            </Button>
            <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} returnFocusOnClose={true}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {confirmation.title}
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>{confirmation.description}</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button variant={"outline"} colorScheme={"button-primary"} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme={buttonStyle ? buttonStyle : "button-delete"}
                                onClick={() => {
                                    onAction();
                                    onClose();
                                }}
                                ml={3}
                            >
                                {innerButtonText ? innerButtonText : "Delete"}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};
