import { ROUTES } from "../Routes";
import { Layout } from "../layouts/Layout";

import { ShopPage, ListAppsPage, ListStores, SingleAppPage, AllStoresReportsPage } from "../pages";

export const internalAndCloudOpsRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: ROUTES.LIST_STORES, element: <ListStores /> },
            { path: ROUTES.SHOP, element: <ShopPage /> },
            { path: ROUTES.INSTALLED_APPS, element: <ListAppsPage /> },
            { path: ROUTES.SHOPIFY_APP, element: <SingleAppPage /> },
            { path: ROUTES.ALL_STORES_REPORT, element: <AllStoresReportsPage /> },
        ],
    },
];
