import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAuthenticatedUser, useCloudFlareZone, generateEntriAuthQueryKey } from "../../hooks";
import { useEntriAuth } from "../../hooks/queries/useEntriAuth";
import { useEntriCheckDomain } from "../../hooks/queries/useEntriCheckDomain";
import { Store } from "../../types";
import { defaultEntriConfig } from "./defaultEntriConfig";
import { EntriConnectModal } from "./EntriConnectModal";
import { EntriCloseEvent, EntriConfig } from "./types";

type DnsSetupProps = {
    store: Store;
    onClose?: (event?: EntriCloseEvent) => void;
};

export const DnsSetup = ({ store, onClose }: DnsSetupProps) => {
    const queryClient = useQueryClient();
    const { verificationKey, entriConfig } = useEntriConfiguration(store);
    const { data: domainSupport, isLoading: isLoadingDomainSupport } = useEntriCheckDomain(
        store.customerUrl,
        entriConfig,
    );
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    useEffect(() => {
        if (domainSupport?.showEntriConnect && verificationKey === null && !isLoadingDomainSupport) {
            const message = `Missing DNS Zone verification key for ${store.customerUrl}. Please contact support.`;
            showToast({
                status: "warning",
                title: "Warning",
                description: message,
                duration: null,
            });

            onClose && onClose();
        }
    }, [verificationKey, domainSupport, isLoadingDomainSupport, store.customerUrl, showToast, onClose]);

    // Return early if we don't have the necessary data
    const isSupported = domainSupport?.showEntriConnect && !isLoadingDomainSupport;
    if (
        !entriConfig.token ||
        !entriConfig.userId ||
        !entriConfig.dnsRecords.domain[0].value ||
        !entriConfig.dnsRecords.subDomain[0].value ||
        isLoadingDomainSupport ||
        !isSupported
    )
        return null;

    const onEntriClose = (event: EntriCloseEvent) => {
        const { detail } = event;
        if (detail.error && detail.error?.code === "SessionError") {
            const queryKey = generateEntriAuthQueryKey(entriConfig.userId ?? "");
            queryClient.invalidateQueries({ queryKey });
        }

        onClose && onClose(event);
    };

    return <EntriConnectModal config={entriConfig} onEntriClose={onEntriClose} />;
};

const useEntriConfiguration = (store: Store) => {
    const [entriConfig, setEntriConfig] = useState<EntriConfig>(defaultEntriConfig);
    const { userId } = useAuthenticatedUser();
    const { data: entriAuth, isError: entriAuthError, isLoading: entriAuthLoading } = useEntriAuth(store.customerUrl);
    const {
        data: zone,
        isError: cloudflareZoneError,
        isLoading: cloudflareZoneLoading,
    } = useCloudFlareZone(store.customerUrl, store?.zoneId, {
        refetchOnWindowFocus: false,
    });

    const isError = entriAuthError || cloudflareZoneError;
    const isLoading = entriAuthLoading || cloudflareZoneLoading;
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });

    useEffect(
        function showErrorToast() {
            if (isError) {
                showToast({
                    status: "error",
                    title: "Error",
                    description:
                        "An error occurred while fetching the necessary data for DNS setup. Please contact support.",
                    duration: null,
                });
            }
        },
        [isError, showToast],
    );

    useEffect(
        function updateAuthToken() {
            if (entriAuth?.token) {
                setEntriConfig((prevConfig: EntriConfig) => ({
                    ...prevConfig,
                    token: entriAuth?.token,
                }));
            }
        },
        [entriAuth?.token],
    );

    useEffect(
        function updateUserId() {
            if (userId) {
                setEntriConfig((prevConfig: EntriConfig) => ({
                    ...prevConfig,
                    userId: userId,
                }));
            }
        },
        [userId],
    );

    useEffect(
        function updateVerificationKey() {
            if (zone?.verificationKey) {
                setEntriConfig((prevConfig: EntriConfig) => {
                    const newDnsRecords = { ...prevConfig.dnsRecords };
                    newDnsRecords.domain[0].value = zone?.verificationKey ?? "";
                    newDnsRecords.subDomain[0].value = zone?.verificationKey ?? "";
                    return {
                        ...prevConfig,
                        dnsRecords: newDnsRecords,
                    };
                });
            }
        },
        [zone?.verificationKey],
    );

    return {
        userId,
        entriConfig,
        entriAuthToken: entriAuth?.token,
        verificationKey: zone?.verificationKey,
        isLoading,
        isError,
    };
};
