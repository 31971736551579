import {
    Button,
    Flex,
    FormControl,
    Input,
    Select,
    Spacer,
    useToast,
    InputGroup,
    InputLeftAddon,
    Checkbox,
    Text,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Label, FieldError, Header, FieldSet } from "../components";
import { useOnboardCustomerMutation } from "../hooks";
import { generateRandomPassword, asCustomerUrl } from "../lib";
import { CreateOnboardingCustomerRequest, TextRecord } from "../types";
import { PageContainer } from "./PageContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Component accessed from main menu
export const CreateNewCustomer = () => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        setValue,
        watch,
    } = useForm<NewCustomerFormData>({
        defaultValues: initialFormValues,
    });
    const [ecomUrlPlaceholder, setEcomUrlPlaceholder] = useState("production-nostra.myshopify.com");

    const navigate = useNavigate();
    const externalCloudflareAccount = watch("externalCloudflareAccount");

    const onSuccess = () => {
        showToast({
            title: "Success",
            description:
                "You have successfully created a new customer. Please copy the DNS TXT record and add it to your domain's DNS records.",
            status: "success",
        });
        reset(initialFormValues);
    };

    const onError = (error: any) => {
        showToast({
            title: error.name || "Error",
            description: error.message,
            status: "error",
        });
    };

    const createOnboardingCustomerMutation = useOnboardCustomerMutation();

    // this useEffect takes care of error: Cannot update a component while rendering a different component - React
    useEffect(() => {
        if (createOnboardingCustomerMutation.isSuccess) {
            const zoneId: string = createOnboardingCustomerMutation.data.store.zoneId;
            const customerUrl: string = createOnboardingCustomerMutation.data.store.customerUrl;
            const textRecord: TextRecord = createOnboardingCustomerMutation.data.txtRecord;

            navigate(
                `/confirmation/${customerUrl}?zoneId=${zoneId}&name=${encodeURIComponent(
                    textRecord.name,
                )}&value=${encodeURIComponent(textRecord.value)}`,
            );
        }
    }, [createOnboardingCustomerMutation, navigate]);

    const onSubmit: SubmitHandler<NewCustomerFormData> = async (formData) => {
        const request: CreateOnboardingCustomerRequest = createOnboardingCustomerRequest(formData);

        createOnboardingCustomerMutation.mutate(request, {
            onError,
            onSuccess,
        });
    };

    const applyStoreUrlMask = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedInput = asCustomerUrl(e.target.value);
        const name = e.target.name as keyof NewCustomerFormData;
        setValue(name, maskedInput, { shouldValidate: true });
    };

    // Clear override values if using internal Cloudflare account
    useEffect(() => {
        if (!externalCloudflareAccount) {
            setValue("workerAccountOverride", "");
            setValue("workerApiTokenOverride", "");
        }
    }, [externalCloudflareAccount, setValue]);

    return (
        <PageContainer>
            <Header pb={"spacer-6"}>Create New Customer</Header>
            <FormControl maxWidth={{ md: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <>
                        <Label htmlFor="workerPlatform">Platform</Label>
                        <Select
                            {...register("workerPlatform")}
                            name="workerPlatform"
                            id="workerPlatform"
                            defaultValue="shopify"
                            onChange={(e) => setEcomUrlPlaceholder(deriveEcomUrlPlaceholder(e.target.value))}
                        >
                            <option value="shopify">Shopify</option>
                            <option value="salesforce">Salesforce</option>
                        </Select>
                        <FieldError> </FieldError>
                    </>
                    <Label htmlFor="name">Full Name</Label>
                    <Input
                        type="text"
                        id="name"
                        {...register("name", {
                            required: {
                                value: true,
                                message: "Name is required",
                            },
                        })}
                        isInvalid={!!errors.name}
                    />
                    <FieldError>{errors.name?.message}</FieldError>

                    <Label htmlFor="orgName">Organization Name</Label>
                    <Input
                        type="text"
                        id="orgName"
                        {...register("orgName", {
                            required: {
                                value: true,
                                message: "Organization name is required",
                            },
                        })}
                        isInvalid={!!errors.orgName}
                    />
                    <FieldError>{errors.orgName?.message}</FieldError>

                    <Label htmlFor="storeName">Store Name</Label>
                    <Input
                        type="text"
                        id="storeName"
                        {...register("storeName", {
                            required: {
                                value: true,
                                message: "Store name is required",
                            },
                        })}
                        isInvalid={!!errors.storeName}
                    />
                    <FieldError>{errors.storeName?.message}</FieldError>

                    <Label htmlFor="email">Email</Label>
                    <Input
                        type="email"
                        id="email"
                        {...register("email", {
                            required: {
                                value: true,
                                message: "Email is required",
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                            },
                        })}
                        isInvalid={!!errors.email}
                    />
                    <FieldError>{errors.email?.message}</FieldError>

                    <Label htmlFor="customerUrl">Customer URL</Label>
                    <InputGroup>
                        <InputLeftAddon children="https://" />
                        <Input
                            type="text"
                            id="customerUrl"
                            placeholder="www.speed-preview.com"
                            {...register("customerUrl", {
                                required: {
                                    value: true,
                                    message: "Customer URL is required",
                                },
                            })}
                            onChange={applyStoreUrlMask}
                            isInvalid={!!errors.customerUrl}
                        />
                    </InputGroup>
                    <FieldError>{errors.customerUrl?.message}</FieldError>

                    <Label htmlFor="ecomUrl">Ecom URL</Label>
                    <InputGroup mb="spacer-4">
                        <InputLeftAddon children="https://" />
                        <Input
                            type="text"
                            id="ecomUrl"
                            placeholder={ecomUrlPlaceholder}
                            {...register("ecomUrl", {
                                required: {
                                    value: true,
                                    message: "Ecom URL is required",
                                },
                            })}
                            onChange={applyStoreUrlMask}
                            isInvalid={!!errors.ecomUrl}
                        />
                    </InputGroup>
                    <FieldError>{errors.ecomUrl?.message}</FieldError>
                    <FieldSet legend="Zone Management">
                        <Checkbox
                            spacing={4}
                            size="md"
                            py={"spacer-2"}
                            id="externalCloudflareAccount"
                            {...register("externalCloudflareAccount")}
                        >
                            Using external Cloudflare account
                        </Checkbox>
                        <Text py={"spacer-2"}>Worker Account Override</Text>
                        <Input
                            id="workerAccountOverride"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            {...register("workerAccountOverride")}
                            isDisabled={!externalCloudflareAccount}
                            isRequired={externalCloudflareAccount}
                        />
                        <Text py={"spacer-6"} pb={"spacer-2"}>
                            Worker API Token Override
                        </Text>
                        <Input
                            id="workerApiTokenOverride"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            {...register("workerApiTokenOverride")}
                            isDisabled={!externalCloudflareAccount}
                            isRequired={externalCloudflareAccount}
                        />
                    </FieldSet>
                    <FieldError></FieldError>

                    <Flex pb="spacer-6">
                        <Spacer />
                        <Button
                            isLoading={createOnboardingCustomerMutation.isLoading}
                            loadingText="Creating Customer"
                            type="submit"
                            colorScheme="button-primary"
                        >
                            Create Customer
                        </Button>
                    </Flex>
                </form>
            </FormControl>
        </PageContainer>
    );
};

const createOnboardingCustomerRequest = (data: NewCustomerFormData): CreateOnboardingCustomerRequest => {
    const tempPassword = generateRandomPassword();
    return {
        storeName: data.storeName,
        orgName: data.orgName,
        email: data.email,
        name: data.name,
        password: tempPassword,
        customerUrl: data.customerUrl,
        ecomUrl: data.ecomUrl,
        workerPlatform: data.workerPlatform,
        workerAccountOverride: data.workerAccountOverride ?? "",
        workerApiTokenOverride: data.workerApiTokenOverride ?? "",
    };
};

const deriveEcomUrlPlaceholder = (workerPlatform: string) => {
    switch (workerPlatform) {
        case "salesforce":
            return "commcloud.dev-bktf-sfccspeed-com.cc-ecdn.net";
        case "shopify":
            return "production-nostra.myshopify.com";
        default:
            return "";
    }
};

const initialFormValues: NewCustomerFormData = {
    name: "",
    orgName: "",
    storeName: "",
    email: "",
    customerUrl: "",
    ecomUrl: "",
    workerPlatform: "shopify",
    workerAccountOverride: "",
    workerApiTokenOverride: "",
    externalCloudflareAccount: false,
};

type NewCustomerFormData = {
    orgName: string;
    storeName: string;
    email: string;
    name: string;
    customerUrl: string;
    ecomUrl: string;
    workerPlatform: "salesforce" | "shopify";
    workerAccountOverride?: string;
    workerApiTokenOverride?: string;
    externalCloudflareAccount: boolean;
};
