import { sharedRefs, buildInternalPreviewUrl } from "./lib";

export const loadIframe = (sampleUrl: string, variantId: string): void => {
    const { iframeRef, isReady } = sharedRefs;

    const url = buildInternalPreviewUrl(sampleUrl, variantId);

    if (!isReady(iframeRef))
        throw new Error(`Unable to set iFrame.src property to ${sampleUrl}. iframeRef is not ready.`);

    iframeRef!.current!.src = url;
};
