import { Store } from "../types";

export const withDeleteStoreConfirmationModal = (
    route: string,
    store: Pick<Store, "zoneId" | "customerUrl" | "ecomUrl">,
) => {
    const { zoneId, customerUrl, ecomUrl } = store;
    const queryParams = new URLSearchParams();
    queryParams.append("modal", "delete-store-confirmation");
    queryParams.append("zoneId", zoneId);
    queryParams.append("customerUrl", customerUrl);
    queryParams.append("ecomUrl", ecomUrl);

    return `${route}?${queryParams.toString()}`;
};
