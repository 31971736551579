export const generateWorkerName = async (storeHostname: string, platform: string): Promise<string> => {
    const stack = process.env.REACT_APP_STACK ?? "STACK_MISSING";
    const hex = await digestMessage(storeHostname);
    const storeHash = hex.slice(0, 8).toLowerCase();

    return `${stack}-${platform}-blade-${storeHash}`;
};

/**
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
async function digestMessage(message: string): Promise<string> {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
    return hashHex;
}

export const STACK = process.env.REACT_APP_STACK ?? "dev";
