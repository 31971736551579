export const userTypeBadge = (role: string) => {
    switch (role) {
        case "Admin":
            return { label: "Admin", color: "blue" };
        case "Owner":
            return { label: "Owner", color: "purple" };
        case "CloudOps":
            return { label: "CloudOps", color: "teal" };
        default:
            return { label: "Unknown", color: "gray" };
    }
};

export const userStatusBadge = (status: boolean | undefined) => {
    switch (status) {
        case true:
            return { label: "Active", color: "green" };
        case false:
            return { label: "Inactive", color: "red" };
        default:
            return { label: "Unknown", color: "gray" };
    }
};
