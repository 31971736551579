import { Button, useToast } from "@chakra-ui/react";
import { focusManager } from "@tanstack/react-query";
import { BRAND_NAME } from "../config/BRAND_NAME";
import { useEnqueueDeploymentMutation, useStoreMutation } from "../hooks";

type Props = {
    storeId: string;
    customerUrl: string;
    onSuccess?: () => void;
};

export const EnableEdgeDeliveryButton = ({ storeId, customerUrl, onSuccess }: Props) => {
    const storeMutation = useStoreMutation(storeId, customerUrl);
    const enqueueDeployment = useEnqueueDeploymentMutation({
        onDeploymentSuccess: () => {
            focusManager.setFocused(undefined);
            onSuccess && onSuccess();
        },
    });
    const showToast = useToast();

    const turnOnEdgeDelivery = () => {
        focusManager.setFocused(false);
        storeMutation.mutate(
            {
                edgeEnabled: true,
            },
            {
                onSuccess: () => enqueueDeployment.mutate(customerUrl),
                onError: () => {
                    showToast({
                        title: "Error",
                        description: `There was an error enabling ${BRAND_NAME}. Wait a few minutes and try again.`,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            },
        );
    };

    return (
        <Button
            size={{ base: "sm", md: "md", lg: "lg", xl: "lg" }}
            _hover={{
                backgroundColor: "primary-hover",
                boxShadow: "elevation-modals",
            }}
            colorScheme={"button-primary"}
            onClick={turnOnEdgeDelivery}
            isLoading={storeMutation.isLoading || enqueueDeployment.isLoading || enqueueDeployment.isPolling}
            loadingText={"Loading ..."}
        >
            Turn {BRAND_NAME} On
        </Button>
    );
};
