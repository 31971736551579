export const generateRandomPassword = (length = 10): string => {
    let password = "";
    const charset = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const arrayOfNums = new Uint32Array(length);
    window.crypto.getRandomValues(arrayOfNums);

    arrayOfNums.forEach((num) => {
        password += charset[num % charset.length];
    });

    if (!isValidPassword(password)) {
        return generateRandomPassword();
    }
    return password;
};

/*
AWS Amplify Password Requirements:
Password minimum length 8 character(s)
Contains at least 1 number
Contains at least 1 special character
Contains at least 1 uppercase letter
Contains at least 1 lowercase letter
Function below checks if password meets AWS requirements
*/
const isValidPassword = (password: string) => {
    const passwordRegExp = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/,
    );
    return passwordRegExp.test(password);
};
