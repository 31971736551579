import { useCustomerPerformanceReport } from "../../../../hooks";
import { RenderMetric } from "../RenderMetric";

type SeenByURIProps = {
    dateRange: { from_hour: string; upto_hour: string };
    metric: string;
};

export const SeenByURI = ({ metric, dateRange }: SeenByURIProps) => {
    const metricParams = {
        report: "performance_by_uri",
        limit_direction: "DESC",
        limit_by: "seen_count_all",
        metric,
        ...dateRange,
    };

    const { data, isLoading, isError } = useCustomerPerformanceReport(metricParams);

    return <RenderMetric data={data} isError={isError} isLoading={isLoading} />;
};
