import { useState, useEffect } from "react";

type Width = number | undefined;

export const useMotionProps = () => {
    const [viewportWidth, setViewportWidth] = useState<Width>(window.visualViewport?.width);

    useEffect(() => {
        const handleResize = (event: any) => {
            // Update the state with the new viewport width
            setViewportWidth(event.target.width);
        };

        // Listen for visualViewport resize events and update viewportWidth
        window.visualViewport?.addEventListener("resize", handleResize);

        // Clean up the event listener when component is unmounted
        return () => {
            window.visualViewport?.removeEventListener("resize", handleResize);
        };
    }, []);

    const motionProps =
        viewportWidth && viewportWidth >= 2000
            ? {
                  initial: "none",
                  animate: "none",
                  exit: "none",
              }
            : {};

    return motionProps;
};
