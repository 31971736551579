import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const EdgeDeliveryEnginePage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Getting Started"}
                menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                menuItem2={"Edge Delivery Engine™"}
            />
            <Header pb={"spacer-8"}>Edge Delivery Engine&trade;</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Meet {BRAND_NAME}'s {MAIN_PRODUCT}. Forget the days when customizing content required sacrificing
                delivery speed. With {BRAND_NAME}'s innovative solution, you will receive the speed of a headless CMS in
                minutes, not months.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} specializes in optimizing ecommerce platforms built on Shopify and Salesforce Commerce
                Cloud. Learn how our {MAIN_PRODUCT} will help your site achieve cutting-edge results with ease while
                working in tandem with the apps and codebase you already have in place.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Optimize Your Delivery And Expand Your Reach
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It all comes down to how content arrives at your site. Platforms like Shopify rely on content delivery
                networks, or CDNs, to store customers' static content, such as images and CSS, so content reaches users
                faster. However, traditional CDNs have some limitations.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not only do traditional CDNs focus on storing{" "}
                <Link to={`${ROUTES.DOCUMENTATION_STATIC_VS_DYNAMIC_CONTENT}`} textDecoration="underline">
                    basic static content
                </Link>
                —the parts of your website that stay the same for every user—but their performance capabilities are
                limited to their networked locations.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} is the answer. Our hybrid server-side rendering product enables smart caching mechanisms
                for more of your content—including dynamic content—and utilizes more than 310 edge locations, which are
                strategically located within 50 milliseconds of 97% of the world's population.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With serverless functions operating on our edge servers, our software makes decisions before your pages
                load, ensuring content is delivered to your users faster—and typically improving measured page load
                times by 20-30%.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                How {BRAND_NAME}'s {MAIN_PRODUCT} Works
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} acts as a proxy service, or gateway, between your site and your incoming traffic, which
                consists of either real human users or bots. Being a proxy means {BRAND_NAME} has the permission and
                access required to make enhancements to your content before serving either type of user.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The first step in the {MAIN_PRODUCT} process is determining whether a user is a human or a bot.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-08/e521d3fb-2caf-4c57-8b67-44474b5b6d21/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=690,544&force_format=png&width=983"
                alt="EDE bot detection graphic"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the user is a bot, they are served pre-rendered content—the entire page in its simplest, most
                bot-friendly form—from one of our 310+ distributed edge servers via {BRAND_NAME}'s {SECONDARY_PRODUCT}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the user is a real human, they are served the full, interactive page from the nearest edge server
                via&nbsp;
                {BRAND_NAME}'s {MAIN_PRODUCT}. The key steps are as follows:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Our software goes to your store and gets a page. </ListItem>
                <ListItem pb={"spacer-4"}>
                    {MAIN_PRODUCT} applies multiple types of optimizations to the page's contents to improve the overall
                    user experience. Note that the page will not be pre-rendered, as it likely contains many dynamic
                    elements and personalized logic that need careful consideration—which the {MAIN_PRODUCT}{" "}
                    intelligently provides.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    {BRAND_NAME} has proprietary intelligence to treat your site's dynamic elements as hybrid elements,
                    meaning our software identifies dynamic content that can be preprocessed and intelligently
                    rehydrates that content for the user as needed, increasing the page's performance even further.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    Once those advanced optimizations have been applied, our {MAIN_PRODUCT} caches the page on the edge
                    and streams the page so the content can be delivered to your human visitors faster than ever.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    {BRAND_NAME}'s smart cache is constantly on the lookout for updates via webhooks, automatically
                    refreshing your page's stored content as you make product updates such as price adjustments,
                    inventory shifts, and theme changes. This keeps your site continuously refreshed in the cache.{" "}
                </ListItem>
            </UnorderedList>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    These steps will repeat as needed for your site. For example, when you first turn {BRAND_NAME} on or
                    when you clear your cache, {BRAND_NAME}'s {MAIN_PRODUCT} will cycle through the process of
                    optimizing and caching your pages so they are always fresh for visitors.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-08/d603aba8-879b-43bc-af97-4c80d002609c/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=940,741&force_format=png&width=1120.0"
                alt="EDE breakdown graphic"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This process happens without needing any tagging or code added to your site, so your users will
                effortlessly receive the best experience and load times possible.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The results are clear. Our customers see a remarkable 5-10 time reduction in their time-to-first-byte
                (TTFB), signifying the time it takes for a browser to start downloading your content. This results in
                your shop's assets downloading sooner, fostering a more responsive user experience and contributing to
                our {MAIN_PRODUCT} making your website the fastest on the internet.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Accelerate Your Success
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s cutting-edge delivery makes the most of smart caching and advanced performance
                optimizations to manage your constantly evolving ecommerce store and produce substantial enhancements in
                your site's performance.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For anyone looking to improve sitewide loading times and performance, {BRAND_NAME}'s {MAIN_PRODUCT} is
                key, and the benefits continue with {BRAND_NAME}'s {SECONDARY_PRODUCT} product designed to enhance your
                search engine optimization.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The {MAIN_PRODUCT} approach to improving load times through high-speed infrastructure and intelligent
                page speed optimizations elevates website performance metrics recognized by Google, positively impacting
                your SEO algorithm rankings and improving your overall customer experience.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
