import { IconButton, forwardRef } from "@chakra-ui/react";
import { IconRepeat } from "@tabler/icons-react";

export const IconButtonRepeat = forwardRef(({ children, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Recurring Cache Job"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconRepeat stroke={"1.25"} />}
        >
            {children}
        </IconButton>
    );
});
