import {
    Box,
    Button,
    HStack,
    Input,
    useToast,
    VStack,
    Text,
    Code,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Flex,
} from "@chakra-ui/react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { Label, FieldError, Header, Link } from "../../components";
import { PageContainer } from "../PageContainer";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { ClassificationRule, Hints, Store, UpdateStoreFormData } from "../../types";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { generateStoreQueryKey, useEnqueueDeploymentMutation, useStoreMutation } from "../../hooks";
import { generateWorkerName, isValidJSON, toJSON } from "../../lib";
import { useAsync } from "react-use";

const classificationPlaceholder = `{
  "some": [
      { "type": "starts-with",   "pattern": "/products" },
      { "type": "not-ends-with", "pattern": ".json" },
      { "type": "not-contains",  "pattern": "/wpm-" }
  ],
  "every": [
      { "type": "starts-with",   "pattern": "/products" },
      { "type": "not-ends-with", "pattern": ".json" },
      { "type": "not-contains",  "pattern": "/wpm-" }
  ],
}`;

const hintsExample = `{
    "/": [
        {
            "type": "preload",
            "value": "https://cdn.shopify.com/assets/theme.min.css?v=8730",
            "as": "style"
        },
        {
            "type": "preload",
            "value": "https://cdn.shopify.com/files/Color_400x.png?v=165",
            "as": "image"
        }
    ]
}`;

export const CacheSettingsTab = ({ store }: Props) => {
    const defaultValues: UpdateStoreFormData = {
        workerRoutes: JSON.stringify(store.workerRoutes, null, 4),
        classificationCacheable: JSON.stringify(store.classificationCacheable, null, 4),
        classificationDocument: JSON.stringify(store.classificationDocument, null, 4),
        classificationProtected: JSON.stringify(store.classificationProtected, null, 4),
        earlyHints: JSON.stringify(store.hints, null, 4),
    };
    if (defaultValues.workerRoutes === "null") defaultValues.workerRoutes = "";
    if (defaultValues.classificationCacheable === "null") defaultValues.classificationCacheable = "";
    if (defaultValues.classificationDocument === "null") defaultValues.classificationDocument = "";
    if (defaultValues.classificationProtected === "null") {
        defaultValues.classificationProtected = "";
    }
    if (defaultValues.earlyHints === "null") defaultValues.earlyHints = "";

    const workerName = useAsync(
        async () => await generateWorkerName(store.customerUrl, store.workerPlatform),
        [store.customerUrl, store.workerPlatform],
    );

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm<UpdateStoreFormData>({
        defaultValues: defaultValues,
    });

    const { onSubmit } = useCacheSubmit(store);

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxWidth={"1440px"}>
            <VStack align={{ base: "left" }}>
                <form onSubmit={handleSubmit(onSubmit)} id="cacheSettingsForm">
                    <Flex alignSelf="flex-end">
                        <Button type="submit" ml={"auto"} colorScheme={"button-primary"}>
                            Submit
                        </Button>
                    </Flex>
                    <Label htmlFor="cacheDurationMap">Cache Duration Map</Label>
                    <Text pb="spacer-2">
                        Editing cache duration has moved to the{" "}
                        <Link
                            to={generatePath(ROUTES.MANAGE_CACHE_AUTHENTICATED, {
                                customerUrl: store.customerUrl,
                            })}
                        >
                            Cache Settings Tab
                        </Link>{" "}
                        of the Manage Cache page.
                    </Text>

                    <Label>Worker Routes</Label>
                    <Text pb="spacer-2">
                        Routes map a request's URL to a Worker. When a matching URL is detected, our caching software
                        directs the request to the associated worker.
                    </Text>

                    <Text pb="spacer-4">
                        The <strong>{store.customerUrl}</strong> worker is: <Code>{workerName.value}</Code>
                    </Text>

                    <Accordion allowMultiple mb={"spacer-4"}>
                        <AccordionItem border={"none"}>
                            <AccordionButton pl={0}>
                                <AccordionIcon />
                                <Text textStyle={"text-body-regular"} fontWeight={"bold"}>
                                    More Info
                                </Text>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Code
                                    display="block"
                                    whiteSpace={"pre"}
                                    overflow={"auto"}
                                    maxWidth={{ base: "100%", sm: "90%" }}
                                    p={"spacer-4"}
                                    children={generateWorkerRoutesPlaceholder(
                                        workerName.value ?? "",
                                        store.customerUrl,
                                    )}
                                />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Box pb={"spacer-4"}>
                        <CodeMirror
                            value={defaultValues.workerRoutes}
                            placeholder={generateWorkerRoutesPlaceholder(workerName.value ?? "", store.customerUrl)}
                            onChange={(value: string, _viewUpdate) => setValue("workerRoutes", value)}
                            extensions={[json()]}
                        />
                        <Input
                            type="hidden"
                            {...register("workerRoutes", {
                                validate: isValidJSON,
                            })}
                        />
                        <FieldError>{errors.workerRoutes?.message}</FieldError>
                    </Box>

                    <Header as={"h3"} textStyle="text-header-M" pt={"spacer-2"} pb={"spacer-2"}>
                        Classifications
                    </Header>

                    <Text pb="spacer-2">
                        Rules, defined as JSON objects with keys <Code>type</Code> and <Code>pattern</Code> dictate
                        behaviors for specific paths.
                    </Text>
                    <Text pb="spacer-4">
                        <strong>HINT:</strong> In order to get an <em>exact match</em> use <Code>matches</Code> with{" "}
                        <Code>^string-to-match$</Code>.
                    </Text>

                    <Accordion allowMultiple mb={"spacer-4"}>
                        <AccordionItem border={"none"}>
                            <AccordionButton pl={0}>
                                <AccordionIcon />
                                <Text textStyle={"text-body-regular"} fontWeight={"bold"}>
                                    More Info
                                </Text>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text pb="spacer-4">
                                    The <Code>type</Code> key can be any of the following values:
                                </Text>

                                <TableContainer
                                    border="1px solid"
                                    borderColor="ui-element-outline-default"
                                    borderRadius="md"
                                    padding="5px 10px"
                                    mb={"spacer-4"}
                                >
                                    <Table variant="striped" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>Type</Th>
                                                <Th>Type (Negative)</Th>
                                                <Th>Operates On</Th>
                                                <Th>Pattern</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>has</Td>
                                                <Td>not-has</Td>
                                                <Td>searchParams</Td>
                                                <Td>string literal</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>ends-with</Td>
                                                <Td>not-ends-with</Td>
                                                <Td>pathname</Td>
                                                <Td>string literal</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>includes</Td>
                                                <Td>not-includes</Td>
                                                <Td>pathname</Td>
                                                <Td>string literal</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>starts-with</Td>
                                                <Td>not-starts-with</Td>
                                                <Td>pathname</Td>
                                                <Td>string literal</Td>
                                            </Tr>
                                            <Tr>
                                                <Td>matches</Td>
                                                <Td>not-matches</Td>
                                                <Td>pathname</Td>
                                                <Td>regular expression</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <Label pb="spacer-4">Example:</Label>
                                <Code
                                    display="block"
                                    whiteSpace={"pre"}
                                    overflow={"auto"}
                                    maxWidth={{ base: "100%", sm: "90%" }}
                                    p={"spacer-4"}
                                    children={classificationPlaceholder}
                                />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>

                    <Label htmlFor="classificationCacheable">Cacheable Paths</Label>
                    <Box pb={"spacer-4"}>
                        <CodeMirror
                            placeholder={classificationPlaceholder}
                            value={defaultValues.classificationCacheable}
                            onChange={(value: string, _viewUpdate) => setValue("classificationCacheable", value)}
                            extensions={[json()]}
                        />
                        <Input
                            type="hidden"
                            {...register("classificationCacheable", {
                                validate: isValidJSON,
                            })}
                        />
                        <FieldError>{errors.classificationCacheable?.message}</FieldError>
                    </Box>

                    <Label htmlFor="classificationDocument">Document Paths</Label>

                    <Box pb={"spacer-4"}>
                        <CodeMirror
                            placeholder={classificationPlaceholder}
                            value={defaultValues.classificationDocument}
                            onChange={(value: string, _viewUpdate) => setValue("classificationDocument", value)}
                            extensions={[json()]}
                        />
                        <Input
                            type="hidden"
                            {...register("classificationDocument", {
                                validate: isValidJSON,
                            })}
                        />
                        <FieldError>{errors.classificationDocument?.message}</FieldError>
                    </Box>

                    <Label htmlFor="classificationProtected">Protected Paths</Label>
                    <Box pb={"spacer-4"}>
                        <CodeMirror
                            placeholder={classificationPlaceholder}
                            value={defaultValues.classificationProtected}
                            onChange={(value: string, _viewUpdate) => setValue("classificationProtected", value)}
                            extensions={[json()]}
                        />
                        <Input
                            type="hidden"
                            {...register("classificationProtected", {
                                validate: isValidJSON,
                            })}
                        />
                        <FieldError>{errors.classificationProtected?.message}</FieldError>
                    </Box>

                    <Header as={"h3"} textStyle="text-header-M" pt={"spacer-2"} pb={"spacer-2"}>
                        Hints
                    </Header>
                    <Text pb="spacer-4">
                        An early hint is a 103 status code used to send a preliminary response to the browser about
                        critical resources.
                    </Text>
                    <Accordion allowMultiple mb={"spacer-4"}>
                        <AccordionItem border={"none"}>
                            <AccordionButton pl={0}>
                                <AccordionIcon />
                                <Text textStyle={"text-body-regular"} fontWeight={"bold"}>
                                    More Info
                                </Text>
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Label pb="spacer-4">Example:</Label>
                                <Code
                                    display="block"
                                    whiteSpace={"pre"}
                                    overflow={"auto"}
                                    maxWidth={{ base: "100%", sm: "90%" }}
                                    p={"spacer-4"}
                                    children={hintsExample}
                                />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                    <Label htmlFor="earlyHints">Early Hints</Label>
                    <Box pb={"spacer-4"} width="100%">
                        <CodeMirror
                            placeholder={"{}"}
                            value={defaultValues.earlyHints}
                            onChange={(value: string, _viewUpdate) => setValue("earlyHints", value)}
                            extensions={[json()]}
                            width="100%"
                        />
                        <Input
                            type="hidden"
                            {...register("earlyHints", {
                                validate: isValidJSON,
                            })}
                        />
                        <FieldError>{errors.earlyHints?.message}</FieldError>
                    </Box>

                    <HStack>
                        <Button type="submit" colorScheme={"button-primary"} ml="auto">
                            Submit
                        </Button>
                    </HStack>
                </form>
            </VStack>
        </PageContainer>
    );
};

const useCacheSubmit = (store: Store) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const storeMutation = useStoreMutation(store.id, store.customerUrl);
    const enqueueDeployment = useEnqueueDeploymentMutation({ polling: false });
    const queryClient = useQueryClient();

    const onSubmit = (formData: UpdateStoreFormData) => {
        const onError = () => {
            showToast({
                title: "Error",
                description: "There was an error updating the cache settings. Try again in a few moments.",
                status: "error",
            });
        };
        const options = { nullValue: {}, onError };
        const workerRoutes: Record<string, string> = toJSON(formData.workerRoutes, options);

        const classificationCacheable: ClassificationRule[] = toJSON(formData.classificationCacheable, { onError });
        let classificationDocument: ClassificationRule[] = toJSON(formData.classificationDocument, { onError });
        let classificationProtected: ClassificationRule[] = toJSON(formData.classificationProtected, { onError });
        let hints: Hints = toJSON(formData.earlyHints, { nullValue: {}, onError });

        const requestBody = {
            workerRoutes,
            classificationCacheable,
            classificationDocument,
            classificationProtected,
            hints,
        };

        storeMutation.mutate(requestBody, {
            onSuccess: () => {
                enqueueDeployment.mutate(store.customerUrl);
                queryClient.invalidateQueries(generateStoreQueryKey(store.customerUrl));
                showToast({
                    title: "Success",
                    description: `Cache settings updated successfully.`,
                    status: "success",
                });
            },
            onError: (error) => {
                console.error(error);
                showToast({
                    title: "Error",
                    description: "There was an error updating the cache settings. Try again in a few moments.",
                    status: "error",
                });
            },
        });
    };

    return {
        onSubmit,
    };
};

const generateWorkerRoutesPlaceholder = (workerName: string, customerUrl: string) => {
    const workerRoutesPlaceholder = `{
    "${customerUrl}/*": "${workerName}",
    "${customerUrl}/cdn*": null,
    "${customerUrl}/wpm*": null
}`;
    return workerRoutesPlaceholder;
};

type Props = {
    store: Store;
};
