import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, Code, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const AutomatedCacheClearings = () => {
    const productCodeString = `
    {
      "admin_graphql_api_id": "gid://shopify/Product/788032119674292922",
      "body_html": "An example T-Shirt",
      "created_at": null,
      "handle": "example-t-shirt",
      "id": 788032119674292922,
      "product_type": "Shirts",
      "published_at": "2021-12-31T19:00:00-05:00",
      "template_suffix": null,
      "title": "Example T-Shirt",
      "vendor": "Acme",
      "status": "active",
      "published_scope": "web",
      "tags": "example, mens, t-shirt",
      "variants": [
        {
          "admin_graphql_api_id": "gid://shopify/ProductVariant/642667041472713922",
          "barcode": null,
          "compare_at_price": "24.99",
          "created_at": "2021-12-29T19:00:00-05:00",
          "id": 642667041472713922,
          "inventory_policy": "deny",
          "position": 1,
          "price": "19.99",
          "product_id": 788032119674292922,
          "sku": null,
          "taxable": true,
          "title": "Small",
          "option1": "Small",
          "option2": null,
          "option3": null,
          "image_id": null,
          "inventory_item_id": null,
          "inventory_quantity": 75,
          "old_inventory_quantity": 75
        },
        {
          "admin_graphql_api_id": "gid://shopify/ProductVariant/757650484644203962",
          "barcode": null,
          "compare_at_price": "24.99",
          "created_at": "2021-12-29T19:00:00-05:00",
          "id": 757650484644203962,
          "inventory_policy": "deny",
          "position": 2,
          "price": "19.99",
          "product_id": 788032119674292922,
          "sku": null,
          "taxable": true,
          "title": "Medium",
          "option1": "Medium",
          "option2": null,
          "option3": null,
          "image_id": null,
          "inventory_item_id": null,
          "inventory_quantity": 50,
          "old_inventory_quantity": 50
        }
      ],
      "options": [],
      "images": [],
      "image": null,
      "media": []
    }`;

    const collectionCodeString = `
    {
      "id": 408372092144951419,
      "handle": "mynewcollection",
      "title": "My New Collection",
      "body_html": "<b>Some HTML</b>",
      "published_at": "2021-12-31T16:00:00-05:00",
      "sort_order": null,
      "template_suffix": null,
      "published_scope": "web",
      "admin_graphql_api_id": "gid://shopify/Collection/408372092144951419"
    }`;

    const inventoryCodeString = `
    {
      "inventory_item_id": 271878346596884015,
      "location_id": 24826418,
      "available": null,
      "admin_graphql_api_id": "gid://shopify/InventoryLevel/24826418?inventory_item_id=271878346596884015"
    }`;

    const themeCodeString = `
    {
      "id": 512162865275216980,
      "name": "Comfort",
      "created_at": "2021-12-31T19:00:00-05:00",
      "role": "main",
      "theme_store_id": 1234,
      "previewable": true,
      "processing": false,
      "admin_graphql_api_id": "gid://shopify/Theme/512162865275216980"
    }`;

    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Automated Cache Clearings"}
            />
            <Header pb={"spacer-8"}>Automated Cache Clearings</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When you onboard your store with {BRAND_NAME}'s {MAIN_PRODUCT}, our advanced system will immediately
                start identifying changes to your site to keep your content optimized for delivery and up-to-date with
                your team's edits.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The {MAIN_PRODUCT} will automatically clear your Shopify store's cache based on several factors. These
                automated cache clearings revolve around five categories:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Product updates,</strong> including product title updates, image changes, description edits,
                    and more
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Collection updates,</strong> including the addition or deletion of a new collection and the
                    addition or deletion of products within a collection
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Inventory changes,</strong> meaning increases or decreases in product quantity that cause an
                    item to go in or out of stock
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>New theme publications</strong> that your customers will see when they visit your store.
                    This does not apply to development or demo themes.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Theme updates,</strong> such as getting a new version release or bug fix of an official
                    Shopify theme
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Remember to{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>{" "}
                    when you make theme changes. While theme changes will trigger an automated cache clearing,{" "}
                    {BRAND_NAME} may need to adjust your store's configuration to ensure your site works seamlessly with{" "}
                    {BRAND_NAME}'s {MAIN_PRODUCT}.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                These broad categories offer helpful overviews of when your cache will automatically clear. However,
                understanding the specific changes that trigger automatic cache clearings will give your team confidence
                in knowing when to manually clear your cache instead.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our system reviews the changes sent through Shopify's category-specific webhook payloads and compares
                them to your store's former data fields. If any of those tracked fields are different, a cache clearing
                will be triggered. Depending on the context, these automated clearings could happen across your entire
                site or for individual pages.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Product Updates
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Product and variant changes are typically the most frequent updates. These modifications trigger
                automated clearings of your store's edge cache for those specific affected pages, rather than across
                your entire site.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    These updates will only clear your cache for product pages such as{" "}
                    <Code>/products/example-shirt</Code>, not high-level collection pages such as{" "}
                    <Code>/collections/shirts</Code>.
                </strong>{" "}
                This behavior enables more of your content to remain in {BRAND_NAME}'s cache as long as possible.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    If you have any third-party applications that publish or unpublish products as they go in and out of
                    stock, you may see a higher automated cache-clearing rate than is optimal. If that is the case for
                    your store,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>{" "}
                    for further consideration. As an example, the Shopify app Wipeout automatically hides products that
                    go out of stock.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link
                    textDecoration="underline"
                    to="https://shopify.dev/docs/api/admin-rest/2024-07/resources/webhook#event-topics-products-update"
                    target="new"
                >
                    relevant Shopify webhooks fields
                </Link>{" "}
                tracked by {BRAND_NAME}'s system during <Code>products/update</Code> events are listed in the code block
                here for your convenience. Changing a product's name, adding tags, adding a variant, removing a variant,
                updating a variant price, changing the vendor, including the production to a specific collection, etc.
                will trigger an automated cache clearing of the product page.
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {productCodeString}
            </Code>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Additional Considerations
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Some Shopify stores use metafields to change customer-facing product page information. However, this
                information is not provided in Shopify's <Code>products/updates</Code> payload. As a result,{" "}
                <strong>
                    metafield changes in a product or its variants will not trigger an automated cache clearing.
                </strong>{" "}
                While this is a limitation with Shopify's data, {BRAND_NAME} can enable metafield cache clearing if
                necessary. Please contact your {BRAND_NAME} team member for us to enable this for you.{" "}
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>{" "}
                to make an official request.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Collection Updates
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Automated cache clearings occur on a collection page when that collection is updated or when products
                are added to or removed from that collection.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    Top-level collection page caches will not automatically be cleared due to inventory or product
                    updates.
                </strong>{" "}
                For an automated clearing to occur, the collection itself must change—for example, its title or body
                description must be modified.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link
                    textDecoration="underline"
                    to="https://shopify.dev/docs/api/admin-rest/2024-07/resources/webhook#event-topics-collections-update"
                    target="new"
                >
                    relevant Shopify webhooks fields
                </Link>{" "}
                tracked by {BRAND_NAME}'s system during <Code>collections/update</Code> events are listed in the code
                block for your convenience. They include collection name changes, collection text descriptions,
                collection page publications, and collection image updates.
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {collectionCodeString}
            </Code>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you update the sort order of products in your collection page, the predefined sorting options in
                Shopify will trigger an automated cache clearing.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    However, if you select "Sort: Manually" and click and drag your products into a new order, Shopify
                    will not send the updated order in the <Code>collections/update</Code> webhook and therefore will
                    not trigger an automated collection page cache clearing. In the case of a manual sort, clear the
                    page's cache manually through the customer portal to keep the order up to date.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-09-25/905f887a-57a2-4838-96a1-e8b699017875/screenshot.jpeg?tl_px=0,0&br_px=1043,743&force_format=jpeg&q=100&width=1120.0"
                alt="Shopify collection edit page showing manual sort option"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Inventory Updates
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Inventory updates occur when a product or variant goes out of stock or comes back in stock.{" "}
                <strong>
                    These automated updates will only clear your cache for product pages, not high-level collection
                    pages or the entire site.
                </strong>{" "}
                This enables more of your content to remain in {BRAND_NAME}'s cache as long as possible.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For instance, going out of stock on <Code>/products/example-shirt</Code> or
                <Code>/collections/shirts/products/example-shirt</Code> would automatically clear that page's cache, but
                the overarching <Code>/collections/shirts</Code> would not be cleared at the same time due to an
                inventory update on a page within the broader collection.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link
                    textDecoration="underline"
                    to="https://shopify.dev/docs/api/admin-rest/2024-07/resources/webhook#event-topics-inventory-levels-update"
                    target="new"
                >
                    relevant Shopify webhooks fields
                </Link>{" "}
                tracked by {BRAND_NAME}'s system during <Code>inventory_levels/update</Code> events are listed in the
                code block for your convenience.
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {inventoryCodeString}
            </Code>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Theme Publications
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Theme publications will trigger an automated clearing of your entire site, not specific pages, as themes
                have a global effect on your content.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        Contact support
                    </Link>{" "}
                    when you make theme changes. While your cache will be automatically updated, {BRAND_NAME} may need
                    to adjust your store's configuration as you make theme changes to ensure your site still works
                    seamlessly with our {MAIN_PRODUCT}.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link
                    textDecoration="underline"
                    to="https://shopify.dev/docs/api/admin-rest/2024-07/resources/webhook#event-topics-themes-publish"
                    target="new"
                >
                    relevant Shopify webhooks fields
                </Link>{" "}
                tracked by {BRAND_NAME}'s system during <Code>themes/publish</Code> events are listed in the code block
                for your convenience.
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {themeCodeString}
            </Code>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Theme Updates
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Theme updates will trigger an automated clearing of your entire site, not specific pages, as themes have
                a global effect on your content.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    An automated cache clearing will only occur when you make official Shopify theme updates,{" "}
                    <strong>not</strong> theme file updates.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Making a theme file update means making changes to your theme via your store's code editor. Whether your
                team members or a third-party app manually change a theme file, those edits will not initiate a cache
                clearing unless otherwise covered by {BRAND_NAME}'s documented categories.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A common example of a theme update that will trigger a cache clearing is changing your theme version to
                match a newer version in the Shopify Theme Store. This is typically done to receive new features, bug
                resolutions, and other improvements from whatever theme you have selected. Learn more about Shopify
                theme updates by visiting the{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/online-store/themes/managing-themes/updating-themes"
                    target="new"
                >
                    documentation
                </Link>
                .
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link
                    textDecoration="underline"
                    to="https://shopify.dev/docs/api/admin-rest/2024-07/resources/webhook#event-topics-themes-update"
                    target="new"
                >
                    relevant Shopify webhooks fields
                </Link>{" "}
                tracked by {BRAND_NAME}'s system during <Code>themes/update</Code> events are listed in the code block
                for your convenience.
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {themeCodeString}
            </Code>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Be sure to{" "}
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    contact support
                </Link>{" "}
                when you make theme changes. While theme changes will initiate an automated cache clearing, {BRAND_NAME}{" "}
                may need to adjust your store's configuration to ensure your site continues to work seamlessly with the{" "}
                {MAIN_PRODUCT}.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
