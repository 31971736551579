import {
    Box,
    Image,
    Spacer,
    Divider,
    Button,
    useDisclosure,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuGroup,
    MenuDivider,
    Switch,
    useBoolean,
    HStack,
    Text,
    MenuOptionGroup,
    MenuItemOption,
    IconButton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../Routes";
import { Link, Tooltip } from "../components";
import { useExperiment, useStore, useAuthenticatedUser } from "../hooks";
import logo from "../images/brand-logo.png";
import { StickyHeader } from "./StickyHeader";
import { ChangeDrawer } from "../pages/Editor/components/ChangeDrawer";
import { ExperimentVariation } from "../types";
import { postMessage } from "../pages/Editor/lib/postMessage";
import { IconDeviceDesktop } from "@tabler/icons-react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useEditorContext } from "../pages/Editor/EditorProvider";
import { IconDotsVertical } from "@tabler/icons-react";
import { viewports } from "../pages/Editor/lib";

export const ExperimentationEditorHeader = () => {
    const { variantId } = useParams();
    const { data: experiment } = useExperiment();
    const manageExperimentRoute = useManageExperimentRoute();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isCloudOpsRole } = useAuthenticatedUser();
    const [showTagNames, setShowTagNames] = useBoolean();
    const { editorContext, dispatch } = useEditorContext();
    const { data: store } = useStore();

    let changesText = "";
    let variation: ExperimentVariation | undefined;
    if (experiment && variantId) {
        variation = experiment.variations.find((v) => v.id === variantId);
        if (variation && variation.actions.length === 0) {
            changesText = "No changes";
        } else if (variation && variation.actions.length === 1) {
            changesText = "1 change";
        } else if (variation && variation.actions.length > 1) {
            changesText = `${variation.actions.length} changes`;
        }
    }

    return (
        <StickyHeader>
            <Box
                display="flex"
                paddingBottom={0}
                minWidth="max-content"
                width="100%"
                height="100%"
                alignItems="center"
                gap="2"
            >
                <Box p="2">
                    <Link to={ROUTES.HOME}>
                        <Image src={logo} />
                    </Link>
                </Box>
                <Spacer />
                <Menu autoSelect={false}>
                    <MenuButton as="button">
                        <HStack>
                            <IconDeviceDesktop stroke={"1.25"} />
                            <Text>{editorContext.viewport.name}</Text>
                            <ChevronDownIcon />
                        </HStack>
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup defaultValue={editorContext.viewport.name} type="radio">
                            {viewports.map((viewport) => {
                                return (
                                    <MenuItemOption
                                        isFocusable
                                        key={viewport.name}
                                        value={viewport.name}
                                        onClick={() =>
                                            dispatch({
                                                type: "setViewport",
                                                payload: viewport.name,
                                            })
                                        }
                                    >
                                        {viewport.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <Spacer />
                {isCloudOpsRole ? (
                    <Button
                        textStyle={"text-body-meta-bold"}
                        variant={"ghost"}
                        onClick={() => {
                            if (editorContext.mode === "EDITING") {
                                dispatch({ type: "editCanceled" });
                            }
                            onOpen();
                        }}
                    >
                        {changesText}
                    </Button>
                ) : (
                    changesText
                )}
                <Box height={"50px"}>
                    <Divider orientation="vertical" />
                </Box>

                <Menu>
                    <Tooltip label="Help" aria-label="Help">
                        <MenuButton
                            backgroundColor={"transparent"}
                            as={IconButton}
                            icon={<IconDotsVertical stroke={"1.25"} />}
                        />
                    </Tooltip>
                    <MenuList>
                        <MenuItem
                            onClick={() => window.open("https://nostra-corporate.webflow.io/contact-nostra-support")}
                        >
                            Support
                        </MenuItem>

                        {isCloudOpsRole ? (
                            <>
                                <MenuDivider />
                                <MenuGroup title="Experimental">
                                    <Switch
                                        size={{ base: "sm", md: "md" }}
                                        isChecked={showTagNames}
                                        onChange={() => {
                                            postMessage("TOGGLE_TAG_NAMES", { value: !showTagNames });
                                            setShowTagNames.toggle();
                                        }}
                                        mr={"spacer-2"}
                                        ml={"spacer-4"}
                                    />
                                    Tag Names
                                </MenuGroup>
                            </>
                        ) : null}
                    </MenuList>
                </Menu>

                <Link ml={25} mr={25} to={manageExperimentRoute}>
                    Done
                </Link>
            </Box>
            <ChangeDrawer
                variation={variation}
                isOpen={isOpen}
                onClose={onClose}
                customerUrl={store?.customerUrl ?? ""}
            />
        </StickyHeader>
    );
};

/**
 * Extract the storeId and experimentId from the URL and generate the manage experiment route
 */
const useManageExperimentRoute = () => {
    const { data: experiment } = useExperiment();
    const { data: store } = useStore();
    const [manageExperimentParams, setManageExperimentParams] = useState({
        customerUrl: "",
        experimentId: "",
    });
    const customerUrl = experiment && store ? store.customerUrl : "";

    useEffect(() => {
        if (experiment && store) {
            setManageExperimentParams({
                customerUrl: customerUrl,
                experimentId: experiment.id,
            });
        }
    }, [experiment, customerUrl, store]);

    return generatePath(ROUTES.MANAGE_EXPERIMENT, manageExperimentParams);
};
