import { SVC_URLS } from "../../config/SVC_URLS";
import { useMutation } from "@tanstack/react-query";
import { fetchOptions, fetchResponse, handleErrors } from "../../lib";

export const useDeleteUserMutation = () => useMutation({ mutationFn: deleteUser });

const deleteUser = async (userId: string) => {
    const url = `${SVC_URLS["auth"]}/user/${userId}`;
    const options = await fetchOptions({ method: "DELETE" });
    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
