import { useMutation } from "@tanstack/react-query";
import { addQueryParameter } from "../../../../lib/http/addQueryParameter";
import { applyProtocolToHostname } from "../../../../lib/http/applyProtocolToHostname";
import { fetchOptions } from "../../../../lib/http/fetchOptions";
import { fetchResponse } from "../../../../lib/http/fetchResponse";
import { handleErrors } from "../../../../lib/http/handleErrors";
import { BuildSelectorRequest } from "../../../../types";

const EDITOR_FRAGMENT_API_QUERY_PARAMETER = "internal-editor-fragment";

export const useBuildSelectorMutation = (storeUrl: string) => {
    let url = applyProtocolToHostname(storeUrl);
    url = addQueryParameter(url, EDITOR_FRAGMENT_API_QUERY_PARAMETER, true);

    return useMutation({ mutationFn: buildSelector(url) });
};

const buildSelector = (url: string) => async (body: BuildSelectorRequest) => {
    const options = await fetchOptions({
        method: "POST",
        body: JSON.stringify(body),
    });

    const response = await fetch(url, options).catch(handleErrors);

    return fetchResponse(response);
};
