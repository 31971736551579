import { Text } from "@chakra-ui/react";

type Props = {
    children?: string | React.ReactNode | undefined;
};

export const FieldError = ({ children }: Props) => (
    <Text color="red.500" fontSize="sm" mt={"4px"} minHeight={"21px"}>
        {children}
    </Text>
);
